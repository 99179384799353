const config = {
  backendBaseUrl: process.env.REACT_APP_BACKEND_HOSTNAME,
  growthBook: {
    apiKey: process.env.REACT_APP_GROWTH_BOOK_API_KEY,
    rootDomain: process.env.REACT_APP_GROWTH_BOOK_ROOT_DOMAIN,
  },
  supabase: {
    url: process.env.REACT_APP_SUPABASE_URL,
    key: process.env.REACT_APP_SUPABASE_ANON_KEY,
  },
  nodeEnv: process.env.NODE_ENV,
};

export default config;
