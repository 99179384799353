import { useDispatch } from 'react-redux';
import {
  setOverallScanId,
  setBasicScanStatus,
  setAdvancedScanResults,
  setAdvancedScanStatus,
  setBasicScanResults,
  setScanTitle as setScanTitleRedux,
  setScanAuthor as setScanAuthorRedux,
  setScanDate as setScanDateRedux,
  setBasicScanServerStatusCode as setBasicScanServerStatusCodeRedux,
  setLatestInputText as setLatestInputTextRedux,
  setEditorDocument as setEditorDocumentRedux,
  setInputTextChangedSinceScan as setInputTextChangedSinceScanRedux,
  setOutOfBasicScans as setOutOfBasicScansRedux,
  setOutOfAdvancedScans as setOutOfAdvancedScansRedux,
} from '../../../app/store/aiUtilitiesHubSlice';

const useUpdateScanData = () => {
  const dispatch = useDispatch();

  const setScanResults = (
    scanResults: any,
    isAdvancedScan: boolean = false
  ) => {
    setInputTextChangedSinceScan(false);
    if (isAdvancedScan) {
      dispatch(setAdvancedScanResults(scanResults));
      // Basic scan results are also set in the case of succeeded advanced scan
      // because advanced scan includes basic scan values
      if (scanResults.serverStatusCode && scanResults.serverStatusCode == 200) {
        dispatch(setBasicScanResults(scanResults));
      }
    } else {
      dispatch(setBasicScanResults(scanResults));
    }
  };

  const setScanStatus = (status: any, isAdvancedScan: boolean = false) => {
    if (isAdvancedScan) {
      dispatch(setAdvancedScanStatus(status));
    } else {
      dispatch(setBasicScanStatus(status));
    }
  };

  const setScanId = (id: string) => {
    dispatch(setOverallScanId({ overallScanId: id }));
  };

  const setLatestInputText = (text: string) => {
    dispatch(setLatestInputTextRedux({ latestInputText: text }));
  };

  const setScanTitle = (title: string) => {
    dispatch(setScanTitleRedux(title));
  };

  const setScanAuthor = (author: string) => {
    dispatch(setScanAuthorRedux(author));
  };

  const setScanDate = (date: string) => {
    dispatch(setScanDateRedux(date));
  };

  const setScanServerStatusCode = (statusCode: number) => {
    dispatch(setBasicScanServerStatusCodeRedux(statusCode));
  };

  const setEditorDocument = (document: any) => {
    dispatch(setEditorDocumentRedux(document));
  };

  const setInputTextChangedSinceScan = (inputTextChanged: boolean) => {
    dispatch(setInputTextChangedSinceScanRedux(inputTextChanged));
  };

  const setOutOfBasicScans = (outOfBasicScans: boolean) => {
    dispatch(setOutOfBasicScansRedux(outOfBasicScans));
  };

  const setOutOfAdvancedScans = (outOfAdvancedScans: boolean) => {
    dispatch(setOutOfAdvancedScansRedux(outOfAdvancedScans));
  };

  return {
    setScanResults,
    setScanStatus,
    setScanId,
    setLatestInputText,
    setScanTitle,
    setScanAuthor,
    setScanDate,
    setScanServerStatusCode,
    setEditorDocument,
    setInputTextChangedSinceScan,
    setOutOfBasicScans,
    setOutOfAdvancedScans,
  };
};

export default useUpdateScanData;
