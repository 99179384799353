import { Link } from 'react-router-dom';

const linkClass =
  'inline-block hover:underline hover:cursor-pointer transition duration-200';

export function FormFooter({ login, isSSO }) {
  return (
    <>
      <div className="mt-4 text-[15px]">
        {login
          ? " Don't have an account yet?"
          : isSSO
            ? 'To use Google or password-based login instead:'
            : 'Already have an account?'}{' '}
        &nbsp;
        <Link to={login ? '/register' : '/login'}>
          <span className={`${linkClass} text-[#50A0FF]`}>
            {login ? 'Create Account' : 'Login'}
          </span>
        </Link>
      </div>
      {!login && (
        <div className="text-center mt-4">
          <span className="text-center text-xs">
            Our{' '}
            <a
              href="https://gptzero.me/terms-of-use.html"
              target="blank"
              className={linkClass}
            >
              Terms of Service
            </a>{' '}
            apply. For more info on our data processing, see our{' '}
            <a
              href="https://gptzero.me/privacy-policy.html"
              target="blank"
              className={linkClass}
            >
              Privacy Policy
            </a>
          </span>
        </div>
      )}
    </>
  );
}

export default FormFooter;
