export const splitIntoWords = (text) => {
  return text
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/\s+/g, ' ')
    .split(' ');
};

export const getNumWords = (text) => {
  // Convert multiple consecutive spaces and/or newlines to a single space to count words
  return splitIntoWords(text).length;
};

/**
 * @description capitalize the fist letter of a string
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string) => {
  if (!string || string.length === 0) {
    return string;
  }
  if (string.length === 1) {
    return string.toUpperCase();
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @description Convert a string to capital/upper case
 * @param string
 * @returns {string}
 */

export const capitalizeString = (string) => {
  if (!string || string.length === 0) {
    return string;
  }
  return string.toUpperCase();
};
/**
 * @description Convert a string to title case
 * @param string
 * @returns {string}
 */
export const toTitleCase = (string) => {
  if (!string || string.length === 0) {
    return string;
  }

  return string.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

/**
 * @description Remove spaces and capitalize the first letter of a string
 * @param inputString
 * @returns {string}
 */

export function removeSpacesAndCapitalizeFirstLetter(inputString) {
  return inputString
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

/**
 * @description Check if a string holds a value and its not null
 * @param value
 * @returns {boolean}
 */
export function isValidStringValue(value) {
  return (
    typeof value === 'string' &&
    value.trim() !== '' &&
    value.toLowerCase() !== 'null'
  );
}

export function getInitials(fullName) {
  return fullName
    .split(' ') // Split the full name into an array of words
    .map((word) => word.charAt(0)) // Extract the first character of each word
    .join('') // Join the extracted characters into a string
    .toUpperCase(); // Convert the string to uppercase
}

export const convertStringToLowerCase = (string) => {
  if (!string || string.length === 0) {
    return string;
  }
  return string.toLowerCase();
};

export const getFirstSentence = (text) => {
  const sentences = text
    .split(/(?<=[.!?])\s|\n/)
    .filter((sentence) => Boolean(sentence.trim()));

  if (sentences.length === 0) {
    return '';
  }

  const firstSentence = sentences[0].trim();
  return firstSentence.length > 100
    ? firstSentence.slice(0, 100)
    : firstSentence;
};
