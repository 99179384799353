import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profile: null,
    data: null,
  },
  reducers: {
    setProfileDetails: (state, action) => {
      state.profile = action.payload?.profile;
      state.data = action.payload?.data;
    },
  },
});

export const { setProfileDetails } = profileSlice.actions;
export default profileSlice.reducer;
