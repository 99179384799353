import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import whitelabelConfig from '../whitelabelConfig';

// Write a custom thunk for handling the streaming response
export const fetchWritingFeedback = (scanId, input) => async (dispatch) => {
  const { endpoint, options } = await processWritingFeedbackInput(
    scanId,
    input
  );
  const baseUrl = whitelabelConfig.gptzero.backendHostname;
  const serverUrl = `${baseUrl}/${endpoint}`;

  dispatch(setWritingFeedbackLoading({ isWritingFeedbackLoading: true }));
  dispatch(setWritingFeedbackTemplate({ document: null }));
  dispatch(setWritingFeedbackChunks({ feedbacks: [] }));

  try {
    const response = await fetch(serverUrl, options);

    if (!response.body || !response.ok) {
      const decoder = new TextDecoder();
      const reader = response.body.getReader();
      const { done, value } = await reader.read();
      const errorObj = JSON.parse(decoder.decode(value));
      Sentry.captureException(
        `No body or ok in writing feedback for input:\n${JSON.stringify(input)}`
      );
      throw new Error(errorObj.error, { cause: response.status });
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    var buffer = '';
    var allChunks = [];

    try {
      let done;

      do {
        const { done, value } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });

        const parts = buffer.split('\n');
        buffer = parts.pop(); // Last line may be incomplete

        for (let i = 0; i < parts.length; i++) {
          if (parts[i]) {
            const data = JSON.parse(parts[i]); // Parse the chunk of feedback

            if (data.documents) {
              dispatch(
                setWritingFeedbackTemplate({
                  document: data.documents[0],
                })
              );
            } else if (data.feedbacks) {
              allChunks = [...allChunks, ...data.feedbacks];
              dispatch(
                setWritingFeedbackChunks({
                  feedbacks: allChunks,
                })
              );
              if (data.feedbacks.length > 0) {
                dispatch(
                  setWritingFeedbackLoading({
                    isWritingFeedbackLoading: false,
                  })
                );
              }
            } else if (data.error) {
              Sentry.captureException(
                `Writing feedback error buffered:\n${JSON.stringify(
                  data
                )}\n\n for input:\n${JSON.stringify(input)}`
              );
              throw new Error(data.error, { cause: data.status });
            }
          }
        }
      } while (!done);
    } catch (error) {
      throw error;
    }

    dispatch(setWritingFeedbackLoading({ isWritingFeedbackLoading: false }));
  } catch (error) {
    Sentry.captureException(error);
    dispatch(setWritingFeedbackLoading({ isWritingFeedbackLoading: false }));
    throw error;
  }
};

const processWritingFeedbackInput = async (scanId, input) => {
  if (typeof input === 'object') {
    try {
      const formData = new FormData();
      formData.append('scanId', scanId);
      formData.append('files', input[0]);

      const endpoint = `v2/writing_feedback/files`;

      var headers = {};
      var options = {
        method: 'POST',
        credentials: 'include',
        body: formData,
      };

      return {
        endpoint: endpoint,
        headers: headers,
        options: options,
      };
    } catch (err) {
      return err;
    }
  } else {
    const endpoint = `v2/writing_feedback/text`;

    var headers = {
      'Content-Type': 'application/json',
    };
    var body = JSON.stringify({
      scanId: scanId,
      document: input,
    });
    var options = {
      headers: headers,
      method: 'POST',
      credentials: 'include',
      body: body,
    };

    return {
      endpoint: endpoint,
      headers: headers,
      options: options,
    };
  }
};

export const writingFeedbackSlice = createSlice({
  name: 'writingFeedback',
  initialState: {
    isWritingFeedbackLoading: false,
    isWritingFeedbackChecked: false,
    writingFeedback: { document: null, feedbacks: [] },
    writingFeedbackFromScanHistory: {},
  },
  reducers: {
    setIsWritingFeedbackChecked(state, action) {
      state.isWritingFeedbackChecked = action.payload.isWritingFeedbackChecked;
    },
    setWritingFeedbackTemplate(state, action) {
      state.writingFeedback.document = action.payload.document;
    },
    setWritingFeedbackChunks(state, action) {
      state.writingFeedback.feedbacks = action.payload.feedbacks;
    },
    setWritingFeedbackLoading(state, action) {
      state.isWritingFeedbackLoading = action.payload.isWritingFeedbackLoading;
    },
    setWritingFeedbackFromScanHistory(state, action) {
      state.writingFeedbackFromScanHistory =
        action.payload.writingFeedbackFromScanHistory;
    },
  },
});

export const {
  setIsWritingFeedbackChecked,
  setWritingFeedbackTemplate,
  setWritingFeedbackChunks,
  setWritingFeedbackLoading,
  setWritingFeedbackFromScanHistory,
} = writingFeedbackSlice.actions;

export default writingFeedbackSlice.reducer;
