export const OAuthOptionButton = ({ onClick, src, altText, label }) => (
  <button className={'btn mt-2 w-full btn-google'} onClick={onClick}>
    <img src={src} className="w-5 h-5 mr-2" alt={altText} />
    {label}
  </button>
);

export const SubmitButton = ({ loading, children, id, disabled = false }) => (
  <button
    id={id}
    type="submit"
    className={'btn w-full btn-primary' + (loading ? ' loading' : '')}
    disabled={disabled}
  >
    {children}
  </button>
);
