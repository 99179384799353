import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WhitelabelContext from '../contexts/WhitelabelContext';
import { apiGetUserTeams } from '../features/enterprise/api/TenantApiCalls';
import { setTenants, setLoading, setError } from '../app/store/userTeamsSlice';

export function useFetchTeams() {
  const dispatch = useDispatch();
  const whitelabelConfig = useContext(WhitelabelContext);

  const params = new URLSearchParams(window.location.search);

  const isAnonymousUser = params.get('isAnonymous') === 'true';

  const { tenants, loading, error } = useSelector((state) => state?.userTeams);

  useEffect(() => {
    if (isAnonymousUser) {
      return; // Do nothing if user is anonymous
    }

    fetchTeams();
  }, [dispatch, whitelabelConfig, isAnonymousUser]);

  async function fetchTeams() {
    dispatch(setLoading({ loading: true }));

    const { success, data, error } = await apiGetUserTeams({
      backendHostname: whitelabelConfig.backendHostname,
    });

    if (success) {
      dispatch(setTenants({ tenants: data }));
    } else {
      dispatch(setError({ error }));
    }

    dispatch(setLoading({ loading: false }));
  }

  return { tenants, loadingTenants: loading, error, fetchTeams };
}

export default useFetchTeams;
