import React, { CSSProperties } from 'react';
import { RadialProgressProps } from './RadialProgress.types';
import './RadialProgress.css';

const RadialProgress: React.FC<RadialProgressProps> = ({
  value,
  size = '4rem',
  className = '',
  showPredictedClass = false,
  predictedClass = 'ai',
  isPlagiarism = false,
}) => {
  const computedStyle = {
    '--value': `${value}`,
    '--size': showPredictedClass ? '90px' : size,
    '--background': isPlagiarism
      ? '#D4351C'
      : showPredictedClass && predictedClass === 'human'
        ? '#A7DDC1'
        : showPredictedClass && predictedClass === 'ai'
          ? '#F8DDA9'
          : '#FEBD69',
    '--color': isPlagiarism
      ? '#D4351C'
      : showPredictedClass && predictedClass === 'human'
        ? '#50C08A'
        : '#FEBD69',
  } as CSSProperties;

  return (
    <div
      data-testid="radial-progress-container"
      className={`radial-progress-container ${className}`}
      style={computedStyle}
    >
      <div className="radial-progress-background"></div>
      <p
        className={`${
          showPredictedClass
            ? 'radial-progress-classification'
            : ' radial-progress-value'
        } ${
          isPlagiarism
            ? 'plagiarism-text-color'
            : predictedClass === 'human'
              ? 'human-text-color'
              : 'ai-text-color'
        } `}
      >
        {showPredictedClass ? predictedClass : `${value}%`}
      </p>
    </div>
  );
};

export default RadialProgress;
