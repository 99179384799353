import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  isAnonymousUser: boolean;
  showLoginModal: boolean;
  showSignUpModal: boolean;
  showConfirmEmailModal: boolean;
  showForgotPasswordModal: boolean;
  emailToVerify: string | null;
  signUpModalSource: string;
}

export interface AuthGlobalState {
  auth: AuthState;
}

const initialState: AuthState = {
  isAnonymousUser: false,
  showLoginModal: false,
  showSignUpModal: false,
  showConfirmEmailModal: false,
  showForgotPasswordModal: false,
  emailToVerify: null,
  signUpModalSource: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAnonymousUser: (state, action) => {
      state.isAnonymousUser = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
    setShowSignUpModal: (state, action) => {
      state.showSignUpModal = action.payload;
    },
    setShowConfirmEmailModal: (state, action) => {
      state.showConfirmEmailModal = action.payload;
    },
    setShowForgotPasswordModal: (state, action) => {
      state.showForgotPasswordModal = action.payload;
    },
    setEmailToVerify: (state, action) => {
      state.emailToVerify = action.payload;
    },
    setSignUpModalSource: (state, action) => {
      state.signUpModalSource = action.payload;
    },
  },
});

export const {
  setIsAnonymousUser,
  setEmailToVerify,
  setShowLoginModal,
  setShowSignUpModal,
  setShowConfirmEmailModal,
  setShowForgotPasswordModal,
  setSignUpModalSource,
} = authSlice.actions;

export default authSlice.reducer;
