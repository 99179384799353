import React, { useState, useEffect } from 'react';

interface InputTextProps {
  labelTitle?: string;
  defaultValue: string;
  updateFormValue: Function;
  updateType: string;
  labelStyle?: string;
  inputStyle?: string;
  containerStyle?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  disabled?: boolean;
}

function NexusInputText({
  labelTitle,
  labelStyle,
  inputStyle,
  containerStyle,
  defaultValue,
  placeholder,
  updateFormValue,
  updateType,
  readOnly,
  required,
  type = null,
  disabled = false,
}: InputTextProps) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  const updateInputValue = (val) => {
    setValue(val);
    updateFormValue({ updateType, value: val });
  };

  return (
    <div className={`form-control w-full ${containerStyle || 'mt-4'}`}>
      {labelTitle && (
        <label className="label-small pl-2 pb-2">
          <span>
            {labelTitle}
            {required && <span className="text-red-500"> *</span>}
          </span>
        </label>
      )}
      <input
        readOnly={readOnly}
        type={type || 'text'}
        value={value ? value : defaultValue}
        placeholder={placeholder || ''}
        onChange={(e) => updateInputValue(e.target.value)}
        className={`body-small rounded-md ${inputStyle} py-2 px-4 border border-solid ${
          disabled
            ? 'border-g0-gray-100 cursor-not-allowed'
            : 'border-g0-gray-400'
        }`}
        disabled={disabled || readOnly}
      />
    </div>
  );
}

// input input-bordered

export default NexusInputText;
