import { createSlice } from '@reduxjs/toolkit';

export const scanSummarySlice = createSlice({
  name: 'scanSummary',
  initialState: {
    scanId: null,
    inputText: null,
    serverStatusCode: null,
    aiProbability: null,
    plagiarismProbability: null,
    computedColor: null,
    aiScanResults: null,
    loading: false,
    isInsideViewChecked: false,
    isMultilingualChecked: false,
    displayWritingAnalysis: false,
    writngStatsCardInitiallyExpanded: false,
  },
  reducers: {
    setScanId(state, action) {
      state.scanId = action.payload.scanId;
    },
    setInputText(state, action) {
      state.inputText = action.payload.inputText;
    },
    setAIProbability(state, action) {
      state.aiProbability = action.payload.aiProbability;
      state.computedColor = action.payload.computedColor;
    },
    setPlagiarismProbability(state, action) {
      state.plagiarismProbability = action.payload.plagiarismProbability;
    },
    setAIScanResults(state, action) {
      state.aiScanResults = action.payload.aiScanResults;
      state.serverStatusCode = action.payload.serverStatusCode;
    },
    setLoadingState(state, action) {
      state.loading = action.payload.loading;
    },
    setIsInsideViewChecked(state, action) {
      state.isInsideViewChecked = action.payload.isInsideViewChecked;
    },
    setServerStatusCode(state, action) {
      state.serverStatusCode = action.payload.serverStatusCode;
    },
    setIsMultilingualChecked(state, action) {
      state.isMultilingualChecked = action.payload.isMultilingualChecked;
    },
    setDisplayWritingAnalysis(state, action) {
      state.displayWritingAnalysis = action.payload.displayWritingAnalysis;
    },
    setWritngStatsCardInitiallyExpanded(state, action) {
      state.writngStatsCardInitiallyExpanded =
        action.payload.writngStatsCardInitiallyExpanded;
    },
  },
});

export const {
  setScanId,
  setInputText,
  setAIScanResults,
  setAIProbability,
  setPlagiarismProbability,
  setLoadingState,
  setIsInsideViewChecked,
  setServerStatusCode,
  setIsMultilingualChecked,
  setDisplayWritingAnalysis,
  setWritngStatsCardInitiallyExpanded,
} = scanSummarySlice.actions;

export default scanSummarySlice.reducer;
