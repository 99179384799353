import { StyledContainer } from '../../atoms/container/container.styles';
import styled from 'styled-components';

export const MainWrapper = styled(StyledContainer)`
  position: relative;
  width: 100%;
  padding: 0 16px;
  flex-direction: column;
  margin: 0 auto;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    width: 91vw;
    height: 788px;
    padding: 30px 0 0 0;
  }
`;

export const SummaryCardWrapper = styled(StyledContainer)`
  width: 100%;
  margin-top: 50px;

  @media (min-width: 1024px) {
    width: 40%;
    margin-top: 0;
    height: unset;
  }
`;

export const EditorWrapper = styled(StyledContainer)`
  width: 100%;
  @media (min-width: 1024px) {
    width: 56%;
    height: 100%;
  }
`;
