const whitelabelConfig = {
  pukkagpt: {
    logo: {
      icon: '/pukkagpt/logo.png',
      header: '/pukkagpt/header_pukkagpt_logo.svg',
    },
    name: 'PukkaGPT',
    label: '',
    dashboardPromoImage: '/pukkagpt/dashboard_promo.svg',
    message: 'Welcome to PukkaGPT. Login with your PukkaGPT to continue',
    domain: '.pukkagpt.com',
    devDomain: '.pukkagpt.com',
    backendHostname: process.env.REACT_APP_PUKKA_BACKEND_HOSTNAME,
    dashboardHostname: process.env.REACT_APP_PUKKA_DASHBOARD_HOSTNAME,
    features: {
      subscriptions: false,
      api: false,
      showTutorial: true,
    },
    tutorials: {
      dashboard: 'vtgPspEu-og',
    },
  },
  pukkagptDemo: {
    logo: {
      icon: '/pukkagpt/logo.png',
      header: '/pukkagpt/header_pukkagpt_logo.svg',
    },
    name: 'PukkaGPT',
    label: '',
    dashboardPromoImage: '/pukkagpt/dashboard_promo.svg',
    message: 'Welcome to PukkaGPT. Login with your PukkaGPT to continue',
    identifyingDomain: 'pukka-app.gptzero.me',
    domain: '.gptzero.me',
    devDomain: 'localhost',
    backendHostname: process.env.REACT_APP_PUKKA_DEMO_BACKEND_HOSTNAME,
    dashboardHostname: process.env.REACT_APP_PUKKA_DEMO_DASHBOARD_HOSTNAME,
    features: {
      subscriptions: false,
      api: false,
      showTutorial: true,
    },
    tutorials: {
      dashboard: 'vtgPspEu-og',
    },
  },
  gptzero: {
    logo: {
      icon: '/origin_logo_bb.svg',
      header: '/origin_logo_bw.svg',
    },
    label: 'GPTZero',
    name: 'GPTZero',
    dashboardPromoImage: '/dashboard-promo.svg',
    message:
      'With a free account, you can scan 40 documents per hour, and access our dashboard. Sign up for one of our premium plans for more features.',
    domain: '.gptzero.me',
    devDomain: 'localhost',
    backendHostname: process.env.REACT_APP_BACKEND_HOSTNAME,
    backendWsHostname: process.env.REACT_APP_BACKEND_WS_HOSTNAME,
    dashboardHostname: process.env.REACT_APP_DASHBOARD_HOSTNAME,
    websiteHostname: process.env.REACT_APP_WEBSITE_HOSTNAME,
    features: {
      subscriptions: true,
      api: true,
      showTutorial: true,
    },
    tutorials: {
      dashboard: 'ZGPjbS0d9C8',
    },
  },
};

export default whitelabelConfig;
