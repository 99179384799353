import { toast } from 'react-toastify';

const displayToast = (type, text, theme = 'light') => {
  if (type === 'success') {
    toast.success(text, {
      theme,
    });
  } else if (type === 'info') {
    toast.info(text, {
      theme,
    });
  } else if (type === 'error') {
    toast.error(text, {
      theme,
    });
  } else if (type === 'warn') {
    toast.warn(text, {
      theme,
    });
  } else if (type === 'warning') {
    toast.warning(text, {
      theme,
    });
  }
};

export { displayToast };
