export const planNames = {
  FREE: 'Free',
  ESSENTIAL: 'Essential',
  PREMIUM: 'Premium',
  PROFESSIONAL: 'Professional',
  ENTERPRISE: 'Enterprise',
  ESSENTIAL_ANNUAL: 'Essential (Annual)',
  PREMIUM_ANNUAL: 'Premium (Annual)',
  PROFESSIONAL_ANNUAL: 'Professional (Annual)',
};

export const planPeriods = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
  SEMI_ANNUAL: 'semi-annual',
};
