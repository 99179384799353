import React from 'react';
import { ProbabilityScoreProps } from './types';
import './styles.css';
import ProbabilityTooltip from '../ProbabilityTooltip';

export function ProbabilityScore({
  probabilityScore,
  tooltipPosition,
}: ProbabilityScoreProps) {
  return (
    <div className={'probability-score-wrapper'}>
      <h1
        id={'ai-scan-results-ai-percentage'}
        className={`probability-score ai-text-color`}
      >
        {probabilityScore}%
      </h1>
      <div
        className={'ur-text ur-text-light ur-text-grey probability-score-text'}
      >
        Probability AI generated
        <span className={'probability-score-tooltip-wrapper'}>
          <ProbabilityTooltip position={tooltipPosition || 'bottom'} />
        </span>
      </div>
    </div>
  );
}

export default ProbabilityScore;
