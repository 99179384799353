import React from 'react';
import { Modal } from 'react-daisyui';

interface CheckEmailModalProps {
  isOpen: boolean;
  toggleOpen: (open: boolean) => void;
}

export const CheckEmailModal = ({
  isOpen,
  toggleOpen,
}: CheckEmailModalProps) => {
  return (
    <Modal
      className="w-full max-w-3xl lg:max-2xl bg-[#FDFAF8] p-10"
      responsive={true}
      open={isOpen}
      onClickBackdrop={() => toggleOpen(false)}
    >
      <Modal.Body>
        <div className="text-center">
          <p className=" text-sm">
            We’ve sent a verification link to your email address. Please check
            your inbox (and your spam/junk folder, just in case) and click the
            link to verify your email.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
