import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import {
  retrieveDeviceId,
  getDeviceIdFromSeachParams,
  getViewportSizeGroup,
} from '../helpers/session';

export const HUBSPOT_EVENTS = {
  LOGIN: 'pe24080138_user_login',
  REGISTRATION: 'pe24080138_user_registration',
};

const SESSION_REPLAY_SAMPLE_RATE = 0.001;

let amplitudeInitWasCalled = false;
let userPropertiesSet = false;

/**
 * Set user ID in Amplitude
 */
export const setUserId = () => {
  const userId = localStorage.getItem('id');
  if (userId) {
    amplitude.setUserId(userId);
  }
};

/**
 * Reset user data in Amplitude
 */
export const resetUserData = () => {
  amplitude.reset();
};

/**
 * Set user properties in Amplitude
 */
export const setUserProperties = () => {
  const identifyEvent = new amplitude.Identify();

  const localStorageKeysToDefault = {
    plan: 'Free',
    email: null,
    orgRole: null,
    fullname: null,
    referralId: null,
    char_limit: null,
    created_at: null,
    batch_limit: null,
    referralName: null,
    isWhiteListed: null,
    isAdvisorAllowlisted: null,
    isTeacherWhitelisted: null,
    studentWhitelistInviteStatus: null,
    teacherWhitelistInviteStatus: null,
    viewPortOnLaunch: getViewportSizeGroup(),
  };
  const emailExists = Boolean(localStorage.getItem('email'));
  if (emailExists) {
    userPropertiesSet = true;
  }
  Object.keys(localStorageKeysToDefault).forEach((key) => {
    const value = localStorage.getItem(key);
    if (value || localStorageKeysToDefault[key] !== null) {
      identifyEvent.set(key, value ?? localStorageKeysToDefault[key]);
    }
  });
  amplitude.identify(identifyEvent);
};

const amplitudeApiKey =
  process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ||
  process.env.REACT_APP_AMPLITUDE_API_KEY;

const initalizeAmplitude = (deviceId) => {
  setUserId();
  setUserProperties();
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: SESSION_REPLAY_SAMPLE_RATE,
  });
  amplitude.add(sessionReplayTracking);
  amplitude.init(amplitudeApiKey, deviceId);
  amplitudeInitWasCalled = true;
};

/**
 * Sample a device ID by a given sample rate
 * @param deviceId
 * @param sampleRate
 * @param salt
 */
export const shouldSampleByDeviceId = (
  deviceId: string,
  sampleRate?: number | null,
  salt: string = ''
) => {
  if (sampleRate === 1 || sampleRate === null) {
    return true;
  }
  // Salt the device ID
  deviceId = deviceId + salt;
  let hash = 0;
  // Hash the device ID
  for (var i = 0; i < deviceId.length; i++) {
    var char = deviceId.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  // Get the number of significant figures in the sample rate
  const numSigFigs = Math.ceil(Math.abs(Math.log10(sampleRate)));
  // Get that number of lowest-power digits from the hash
  const divisor = Math.pow(10, numSigFigs);
  const sigFigs = Math.abs(hash % divisor);
  // Divide by the divisor to get a number between 0 and 1
  const sample = sigFigs / divisor;
  // Return true if the sample is less than the sample rate
  return sample < sampleRate;
};

/**
 * Track an event in Amplitude
 * @param action -- e.g., click, view, load
 * @param component -- component (generally composite), optionally prefixed with a description
 * @param element -- optional UI element name and descriptor, e.g. signupButton
 * @param sampleRate -- optional sample rate between 0 and 1, no lower than 0.01
 * @param options -- optional object to be sent to Amplitude along with event name
 */
export const trackEvent = (
  action: string,
  component: string,
  element?: string,
  sampleRate = 1.0,
  options: Record<string, any> = {}
) => {
  let eventName = `${action}_${component}`;

  let deviceId = retrieveDeviceId() || '';

  if (!amplitudeInitWasCalled) {
    let customDeviceId = null;

    const amplitudeId = getDeviceIdFromSeachParams();

    if (amplitudeId) {
      customDeviceId = amplitudeId;
    }

    deviceId = customDeviceId || deviceId;

    initalizeAmplitude(deviceId);
    if (isDevelopment()) {
      console.info(`$[Amplitude] init ${amplitudeApiKey} ${deviceId}`);
    }
  }
  if (!userPropertiesSet) {
    setUserProperties();
  }

  // Sampler is a deterministic function of the deviceID.
  // So, we can sample the same user consistently.
  const shouldSample = shouldSampleByDeviceId(deviceId, sampleRate);
  if (element) {
    eventName += `_${element}`;
  }
  if (isDevelopment()) {
    console.info(
      `[Amplitude] ${shouldSample ? `[sample]` : `[skip]`} ${eventName}`,
      options
    );
  }
  if (shouldSample && sampleRate < 1.0) {
    // Rename the event to indicate that it was sampled
    eventName = `${eventName}_@${parseInt((sampleRate * 100).toString())}`;
  }
  if (shouldSample) {
    amplitude.track(eventName, options);
  }
};

/**
 * Track event in Hubspot
 * @param eventName
 * @param userEmail
 */
export const trackHubspotEvent = ({ eventName, userEmail }) => {
  fetch(`${process.env.NEXT_PUBLIC_BACKEND_HOSTNAME}/v2/hubspot/events`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      eventName,
      userEmail,
    }),
  });
};

const isDevelopment = () => {
  return (
    process.env.REACT_APP_ENV === 'development' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'development' ||
    process.env.NODE_ENV == 'development'
  );
};
