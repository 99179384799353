import React, { useState } from 'react';
import supabaseClient from '../../utils/supabaseClient';
import zxcvbn from 'zxcvbn';
import {
  Role,
  RoleIndustry,
  ROLES,
  SignUpModalSources,
  SignUpStage,
} from '../../constants/signUpModal';
import useAuthReduxState from './useAuthReduxState';
import { dashboardRoutes } from '../../constants/dashboardRoutes';
import { isDisposableEmail } from '../../utils/emailUtils';
import { setEmailToVerify } from '../../app/store/authSlice';
import { useDispatch } from 'react-redux';

const useSignup = () => {
  const [registrationDetails, setRegistrationDetails] = useState({
    firstName: '',
    lastName: '',
    orgRole: '',
    email: '',
    password: '',
    captchaToken: '',
    industry: '',
  });
  const [signUpStage, setSignUpStage] = useState(SignUpStage.Email);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState<RoleIndustry>(null);
  const dispatch = useDispatch();

  const { signUpModalSource } = useAuthReduxState();

  const params = new URLSearchParams(window.location.search);

  const aiDocumentId = params.get('aiDocumentId');

  const isRegistrationView = signUpStage === SignUpStage.PersonalInfo;

  function getRedirectUrl(
    aiDocumentId: string,
    oauth: boolean = false,
    role?: Role,
    industry?: RoleIndustry
  ) {
    const type = oauth ? 'OAuth-success' : 'signup-success';

    let redirectUrl = `${window.location.origin}${dashboardRoutes.AI_SCAN}/?type=${type}&nexus=true`;

    if (aiDocumentId) {
      redirectUrl += `&aiDocumentId=${aiDocumentId}`;
    }

    if (signUpModalSource === SignUpModalSources.ADVANCED_SCAN) {
      redirectUrl += `&advancedScanRequested=true`;
    }

    if (role) {
      redirectUrl += `&role=${role}`;
    }
    if (industry) {
      redirectUrl += `&industry=${industry.label}`;
    }

    return redirectUrl;
  }

  const signUp = async (
    registrationDetails,
    industry: RoleIndustry,
    role: string,
    aiDocumentId?: string
  ) => {
    try {
      const requestData = {
        email: registrationDetails.email,
        full_name:
          registrationDetails.firstName + ' ' + registrationDetails.lastName,
        first_name: registrationDetails.firstName,
        last_name: registrationDetails.lastName,
        org_role: role || ``,
        new_user: 'Y',
        industry: industry?.label || ``,
      };

      const { email, password, captchaToken } = registrationDetails;

      const redirectUrl = getRedirectUrl(aiDocumentId);

      const { data, error } = await supabaseClient.auth.signUp({
        email,
        password,
        options: {
          data: requestData,
          captchaToken,
          emailRedirectTo: redirectUrl,
        },
      });
      return error;
    } catch (error) {
      throw error;
    }
  };

  const oauthSignup = async (
    provider: any,
    aiDocumentId: string,
    role: Role,
    industry?: RoleIndustry
  ) => {
    const redirectUrl = getRedirectUrl(aiDocumentId, true, role, industry);

    try {
      const { data, error } = await supabaseClient.auth.signInWithOAuth({
        provider: provider,
        options: {
          redirectTo: redirectUrl,
        },
      });
      if (error) {
        console.error(error);
        return;
      }
    } catch (error) {
      throw error;
    }
    localStorage.removeItem('previousProfileUpdateTime');
  };

  const isValidEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return re.test(String(email.trim()).toLowerCase());
  };

  const isStrongPassword = (password: string): boolean =>
    zxcvbn(password).score > 2;

  const getValidationErrorMessage = (
    password: string,
    firstName: string,
    email: string
  ): string => {
    if (!isValidEmail(email.trim())) {
      return 'Please enter a valid email.';
    } else if (isDisposableEmail(email.trim())) {
      return "Sorry, we don't allow emails from this email domain. Please choose a different email to register.";
    } else if (firstName.trim() === '') {
      return 'Please enter your name.';
    } else if (!isStrongPassword(password.trim())) {
      return 'Please enter a stronger password.';
    }

    return null;
  };

  const shouldDisableOption = (
    role: Role | null,
    industry: RoleIndustry | null
  ) => {
    if (!role && !industry) {
      return true;
    }

    if (role === ROLES.STUDENT || role === ROLES.EDUCATOR) return false;

    if (role === ROLES.WRITER && !industry) return true;

    if (role === ROLES.REVIEWER && !industry) return true;

    return false;
  };

  const continueWithEmailSubmitHandler = (e: any) => {
    e.preventDefault();

    const disabled = shouldDisableOption(selectedRole, selectedIndustry);

    if (disabled) {
      return;
    }
    setErrorMessage('');
    setSignUpStage(SignUpStage.PersonalInfo);
  };

  const updateFormValue = ({ updateType, value }) => {
    if (!updateType || !value) {
      return;
    }
    if (updateType === 'email') {
      dispatch(setEmailToVerify(value));
    }
    setRegistrationDetails({ ...registrationDetails, [updateType]: value });
  };

  const onSelectIndustry = (industry: RoleIndustry) => {
    setSelectedIndustry(industry);
    setErrorMessage(null);
  };

  const onSelectRole = (role: Role) => {
    onSelectIndustry(null);
    setSelectedRole(role);
    setErrorMessage(null);
  };

  return {
    signUpStage,
    errorMessage,
    isLoading,
    selectedRole,
    selectedIndustry,
    setSignUpStage,
    setErrorMessage,
    setSelectedRole,
    setSelectedIndustry,
    setIsLoading,
    oauthSignup,
    signUp,
    isValidEmail,
    getRedirectUrl,
    aiDocumentId,
    onSelectRole,
    onSelectIndustry,
    updateFormValue,
    shouldDisableOption,
    getValidationErrorMessage,
    registrationDetails,
    isRegistrationView,
    setRegistrationDetails,
    continueWithEmailSubmitHandler,
  };
};

export default useSignup;
