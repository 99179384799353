import React from 'react';
import { trackEvent } from 'component-library';
import useSignup from '../../../hooks/auth/useSignup';
import { Role, RoleIndustry } from '../../../constants/signUpModal';

export const OAuthSignUp = ({
  disabled = false,
  aiDocumentId,
  role,
  industry,
}: {
  disabled?: boolean;
  aiDocumentId: string;
  role: Role;
  industry: RoleIndustry;
}) => {
  const COMPONENT_LABEL = 'signUpModal-dashboard';
  const { oauthSignup } = useSignup();

  async function signInWithGoogle() {
    if (disabled) return;
    const provider = 'google';
    signInToSocial(provider);
    trackEvent('signin', COMPONENT_LABEL, 'signInWithGoogle');
  }

  const signInWithFacebook = () => {
    if (disabled) return;
    const provider = 'facebook';
    signInToSocial(provider);
    trackEvent('signin', COMPONENT_LABEL, 'signInWithFacebook');
  };

  const signInWithGithub = () => {
    if (disabled) return;
    const provider = 'github';
    signInToSocial(provider);
    trackEvent('signin', COMPONENT_LABEL, 'signInWithGithub');
  };

  async function signInToSocial(provider) {
    oauthSignup(provider, aiDocumentId, role, industry);
  }

  return (
    <div>
      <div className={`flex w-full justify-center gap-4 items-center py-8`}>
        <button
          className={`bg-white flex border ${
            disabled
              ? 'border-g0-gray-100 text-g0-gray-100 cursor-not-allowed'
              : 'border-g0-gray-400 text-g0-primary'
          } gap-3 px-6 py-3 md:gap-3 label-medium rounded-full`}
          onClick={signInWithGoogle}
          type="button"
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="&#240;&#159;&#166;&#134; icon &#34;google&#34;">
              <path
                id="Vector"
                d="M18.3249 7.23735H17.6V7.2H9.5V10.8H14.5864C13.8443 12.8956 11.8503 14.4 9.5 14.4C6.51785 14.4 4.1 11.9822 4.1 9C4.1 6.01785 6.51785 3.6 9.5 3.6C10.8765 3.6 12.1289 4.1193 13.0824 4.96755L15.6281 2.4219C14.0207 0.92385 11.8706 0 9.5 0C4.52975 0 0.5 4.02975 0.5 9C0.5 13.9703 4.52975 18 9.5 18C14.4703 18 18.5 13.9703 18.5 9C18.5 8.39655 18.4379 7.8075 18.3249 7.23735Z"
                fill={`${disabled ? '#DCDCDC' : '#FFC107'}`}
              />
              <path
                id="Vector_2"
                d="M1.53906 4.81095L4.49601 6.9795C5.29611 4.9986 7.23381 3.6 9.50136 3.6C10.8779 3.6 12.1303 4.1193 13.0838 4.96755L15.6295 2.4219C14.0221 0.92385 11.872 0 9.50136 0C6.04446 0 3.04656 1.95165 1.53906 4.81095Z"
                fill={`${disabled ? '#DCDCDC' : '#FF3D00'}`}
              />
              <path
                id="Vector_3"
                d="M9.50053 17.9999C11.8252 17.9999 13.9375 17.1103 15.5346 15.6635L12.7491 13.3064C11.8455 13.9909 10.7223 14.3999 9.50053 14.3999C7.15963 14.3999 5.17198 12.9073 4.42318 10.8242L1.48828 13.0855C2.97778 16.0001 6.00268 17.9999 9.50053 17.9999Z"
                fill={`${disabled ? '#DCDCDC' : '#4CAF50'}`}
              />
              <path
                id="Vector_4"
                d="M18.325 7.23742H17.6V7.20007H9.5V10.8001H14.5864C14.23 11.8067 13.5824 12.6748 12.7472 13.307C12.7476 13.3066 12.7481 13.3066 12.7485 13.3061L15.534 15.6632C15.3369 15.8423 18.5 13.5001 18.5 9.00007C18.5 8.39662 18.4379 7.80757 18.325 7.23742Z"
                fill={`${disabled ? '#DCDCDC' : '#1976D2'}`}
              />
            </g>
          </svg>
          Continue with Google
        </button>
        <button
          className={`bg-white border p-3 rounded-full ${
            disabled
              ? 'border-g0-gray-100 cursor-not-allowed'
              : 'border-g0-gray-400'
          }`}
          onClick={signInWithFacebook}
          type="button"
        >
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="&#240;&#159;&#166;&#134; icon &#34;facebook&#34;">
              <path
                id="Vector"
                d="M12.75 0.5H2.25C1.00736 0.5 0 1.50736 0 2.75V13.25C0 14.4926 1.00736 15.5 2.25 15.5H12.75C13.9926 15.5 15 14.4926 15 13.25V2.75C15 1.50736 13.9926 0.5 12.75 0.5Z"
                fill={`${disabled ? '#DCDCDC' : '#3B3A3B'}`}
              />
              <path
                id="Vector_2"
                d="M10.4199 10.168L10.7539 8H8.67383V6.59375C8.67383 6.00195 8.96387 5.42187 9.89551 5.42187H10.8418V3.57617C10.8418 3.57617 9.9834 3.42969 9.16309 3.42969C7.44922 3.42969 6.33008 4.4668 6.33008 6.34766V8H4.42578V10.168H6.33008V15.5H8.67383V10.168H10.4199Z"
                fill="white"
              />
            </g>
          </svg>
        </button>
        <button
          className={`bg-white border p-3 rounded-full ${
            disabled
              ? 'border-g0-gray-100 cursor-not-allowed'
              : 'border-g0-gray-400'
          }`}
          onClick={signInWithGithub}
          type="button"
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M9.49825 0.000482105C7.02629 -0.0246371 4.6455 0.932739 2.8791 2.6622C1.1127 4.39167 0.105238 6.75171 0.078125 9.22364C0.0876499 11.1772 0.716076 13.0774 1.87314 14.6515C3.03021 16.2255 4.65637 17.3923 6.51807 17.9844C6.98907 18.07 7.16035 17.7874 7.16035 17.539C7.16035 17.2907 7.16035 16.7426 7.16035 15.9719C4.53984 16.5285 3.9832 14.7387 3.9832 14.7387C3.80875 14.1768 3.43788 13.6965 2.93842 13.3856C2.08204 12.8204 3.00693 12.829 3.00693 12.829C3.30294 12.8686 3.58614 12.9746 3.83531 13.1393C4.08447 13.3039 4.29313 13.5228 4.44564 13.7796C4.7114 14.2404 5.14747 14.5785 5.65999 14.721C6.17251 14.8636 6.72051 14.7992 7.18604 14.5417C7.23392 14.0728 7.44628 13.6359 7.7855 13.3086C5.69595 13.0773 3.50363 12.2895 3.50363 8.75264C3.48494 7.83005 3.82583 6.93636 4.4542 6.26058C4.16803 5.4685 4.20182 4.59606 4.5484 3.82848C4.5484 3.82848 5.34483 3.58013 7.11753 4.77049C8.66063 4.35929 10.2845 4.35929 11.8276 4.77049C13.626 3.58013 14.3967 3.82848 14.3967 3.82848C14.7433 4.59606 14.7771 5.4685 14.4909 6.26058C15.1339 6.92397 15.4933 7.81166 15.4929 8.73551C15.4929 12.2809 13.2834 13.0602 11.211 13.2914C11.4379 13.5117 11.6132 13.7796 11.7243 14.0758C11.8353 14.3719 11.8794 14.689 11.8533 15.0042V17.5305C11.8533 17.5305 12.0246 18.07 12.4956 17.9758C14.352 17.3801 15.9727 16.213 17.1262 14.6412C18.2796 13.0694 18.9069 11.1733 18.9184 9.22364C18.8913 6.75171 17.8838 4.39167 16.1174 2.6622C14.351 0.932739 11.9702 -0.0246371 9.49825 0.000482105Z"
              fill={`${disabled ? '#DCDCDC' : '#3B3A3B'}`}
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
