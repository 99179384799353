import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsAnonymousUser as setUserAnonymous,
  setShowLoginModal as setShowLoginModalRedux,
  setShowSignUpModal as setShowSignUpModalRedux,
  setShowConfirmEmailModal as setShowConfirmEmailModalRedux,
  setShowForgotPasswordModal as setShowForgotPasswordModalRedux,
  setSignUpModalSource as setSignUpModalSourceRedux,
  AuthGlobalState,
} from '../../app/store/authSlice';
import { getDecodedToken } from '../../utils/cookieUtils';
import { ScanTypes } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import { useGetAiUtilitiesHubStateValues } from '../../features/aiUtilitiesHub/hooks/useStore';

const useAuthReduxState = () => {
  const dispatch = useDispatch();

  const { activeView } = useGetAiUtilitiesHubStateValues();

  const {
    isAnonymousUser,
    emailToVerify,
    showLoginModal,
    showSignUpModal,
    signUpModalSource,
    showConfirmEmailModal,
    showForgotPasswordModal,
  } = useSelector((state: AuthGlobalState) => state.auth);

  const shouldBlurPage = isAnonymousUser && activeView !== ScanTypes.basic;

  const setIsAnonymousUser = (isAnonymousUser: boolean) => {
    dispatch(setUserAnonymous(isAnonymousUser));
  };

  const setShowSignUpModal = (showLoginModal: boolean) => {
    dispatch(setShowSignUpModalRedux(showLoginModal));
  };

  const setShowLoginModal = (showLoginModal: boolean) => {
    dispatch(setShowLoginModalRedux(showLoginModal));
  };

  const setSignUpModalSource = (source: string) => {
    dispatch(setSignUpModalSourceRedux(source));
  };

  const setUserStateFromToken = (token: string) => {
    const decodedToken = getDecodedToken(token);
    // @ts-ignore
    const isAnonymousUser = decodedToken?.is_anonymous;
    dispatch(setUserAnonymous(isAnonymousUser));
  };

  const setShowConfirmEmailModal = (showConfirmEmailModal: boolean) => {
    dispatch(setShowLoginModalRedux(false));
    dispatch(setShowSignUpModalRedux(false));
    dispatch(setShowForgotPasswordModalRedux(false));
    dispatch(setShowConfirmEmailModalRedux(showConfirmEmailModal));
  };

  const setShowForgotPasswordModal = (showForgotPasswordModal: boolean) => {
    dispatch(setShowLoginModalRedux(false));
    dispatch(setShowSignUpModalRedux(false));
    dispatch(setShowConfirmEmailModalRedux(false));
    dispatch(setShowForgotPasswordModalRedux(showForgotPasswordModal));
  };

  return {
    emailToVerify,
    showLoginModal,
    showSignUpModal,
    isAnonymousUser,
    signUpModalSource,
    shouldBlurPage,
    showConfirmEmailModal,
    showForgotPasswordModal,
    setIsAnonymousUser,
    setShowSignUpModal,
    setShowLoginModal,
    setSignUpModalSource,
    setUserStateFromToken,
    setShowConfirmEmailModal,
    setShowForgotPasswordModal,
  };
};

export default useAuthReduxState;
