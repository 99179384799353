import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import SuspenseContent from './containers/SuspenseContent';

const { REACT_APP_ENV, REACT_APP_SENTRY_DSN, REACT_APP_GOOGLE_TAG_MANAGER } =
  process.env;
const isProduction = REACT_APP_ENV === 'production';

if (!process.env.CIRCLECI) {
  Sentry.init({
    environment: REACT_APP_ENV,
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api.gptzero.me/,
          /^https:\/\/api.pukkagpt.com/,
          /^https:\/\/staging-api.gptzero.me/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    sampleRate: 0.05,
    // Session Replay
    replaysSessionSampleRate: isProduction ? 0.01 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampler: ({ name, attributes, parentSampled }) => {
      if (!isProduction) return 1.0;

      if (name?.includes('TypeError') || name?.includes('TurnstileError')) {
        return 0.0001;
      }

      // Continue trace decision, if there is any parentSampled information
      if (typeof parentSampled === 'boolean') {
        return parentSampled;
      }

      // Default sample rate
      return 0.01;
    },
  });
}

async function enableApiMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
}

const tagManagerArgs = {
  gtmId: REACT_APP_GOOGLE_TAG_MANAGER,
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
enableApiMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Suspense fallback={<SuspenseContent />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
