export const ALL_LANGUAGES: Record<string, string> = {
  aa: 'Afar',
  ab: 'Абхазский',
  ace: 'Achinese',
  ach: 'Acoli',
  ada: 'Adangme',
  ady: 'Адыгейский',
  ae: 'Avestan',
  aeb: 'Tunisian Arabic',
  af: 'Afrikaans',
  afh: 'Afrihili',
  agq: 'Aghem',
  ain: 'アイヌ語',
  ak: 'Akan',
  akk: 'Akkadian',
  akz: 'Alabama',
  ale: 'Aleut',
  aln: 'Gheg Albanian',
  alt: 'Southern Altai',
  am: 'አማርኛ',
  an: 'Aragonese',
  ang: 'Old English',
  anp: 'Angika',
  ar: 'العربية',
  'ar-001': 'Modern Standard Arabic',
  arc: 'Aramaic',
  arn: 'Mapudungun',
  aro: 'Araona',
  arp: 'Arapaho',
  arq: 'الجزائرية',
  ars: 'Najdi Arabic',
  'ars-alt-menu': 'Arabic, Najdi',
  arw: 'Arawak',
  ary: 'العربية المغربية',
  arz: 'العربية المصرية',
  as: 'অসমীয়া',
  asa: 'Asu',
  ase: 'American Sign Language',
  ast: 'Asturianu',
  av: 'Аварский',
  avk: 'Kotava',
  awa: 'Awadhi',
  ay: 'Aymara',
  az: 'Azərbaycanca',
  'az-alt-short': 'Azeri',
  ba: 'Башкир',
  bal: 'Baluchi',
  ban: 'Bali',
  bar: 'Bairisch',
  bas: 'Basaa',
  bax: 'Bamun',
  bbc: 'Batak Toba',
  bbj: 'Ghomala',
  be: 'Белорусский',
  bej: 'Beja',
  bem: 'Bemba',
  bew: 'Betawi',
  bez: 'Bena',
  bfd: 'Bafut',
  bfq: 'Badaga',
  bg: 'Български',
  bgn: 'Western Balochi',
  bho: 'भोजपुरी',
  bi: 'Bislama',
  bik: 'Bikol',
  bin: 'Bini',
  bjn: 'Banjar',
  bkm: 'Kom',
  bla: 'Siksika',
  bm: 'Bambara',
  bn: 'বাংলা',
  bo: 'བོད་ཡིག',
  bpy: 'Bishnupriya',
  bqi: 'Bakhtiari',
  br: 'Breton',
  bra: 'Braj',
  brh: 'Brahui',
  brx: 'Bodo',
  bs: 'Bosanski',
  bss: 'Akoose',
  bua: 'Бурят',
  bug: 'Buginese',
  bum: 'Bulu',
  byn: 'Blin',
  byv: 'Medumba',
  ca: 'Català',
  cad: 'Caddo',
  car: 'Carib',
  cay: 'Cayuga',
  cch: 'Atsam',
  ccp: 'Chakma',
  ce: 'ЦӀахӀа',
  ceb: 'Cebuano',
  cgg: 'Chiga',
  ch: 'Chamoru',
  chb: 'Chibchan',
  chg: 'Chagatai',
  chk: 'Chuukese',
  chm: 'Mari',
  chn: 'Chinook Jargon',
  cho: 'Choctaw',
  chp: 'Chipewyan',
  chr: 'Cherokee',
  chy: 'Cheyenne',
  cic: 'Chickasaw',
  ckb: 'Kurdî',
  'ckb-alt-menu': 'Kurdish, Central',
  'ckb-alt-variant': 'Kurdish, Sorani',
  co: 'Corsu',
  cop: 'Coptic',
  cps: 'Capiznon',
  cr: 'Cree',
  crh: 'Kırım Tatarca',
  crs: 'Seselwa Creole French',
  cs: 'Čeština',
  csb: 'Kaszub',
  cu: 'Церковнославянский',
  cv: 'Чуваш',
  cy: 'Cymraeg',
  da: 'Dansk',
  dak: 'Dakota',
  dar: 'Dargwa',
  dav: 'Taita',
  de: 'Deutsch',
  'de-AT': 'Österreichisches Deutsch',
  'de-CH': 'Schweizer Hochdeutsch',
  del: 'Delaware',
  den: 'Slave',
  dgr: 'Dogrib',
  din: 'Dinka',
  dje: 'Zarma',
  doi: 'Dogri',
  dsb: 'Niedersorbisch',
  dtp: 'Central Dusun',
  dua: 'Duala',
  dum: 'Middle Dutch',
  dv: 'ދިވެއްލަ',
  dyo: 'Jola-Fonyi',
  dyu: 'Dyula',
  dz: 'ཇོང་ཁ',
  dzg: 'Dazaga',
  ebu: 'Embu',
  ee: 'Ewe',
  efi: 'Efik',
  egl: 'Emiliano',
  egy: 'مصرى',
  eka: 'Ekajuk',
  el: 'Ελληνικά',
  elx: 'Elamite',
  en: 'English',
  'en-AU': 'Australian English',
  'en-CA': 'Canadian English',
  'en-GB': 'British English',
  'en-GB-alt-short': 'UK English',
  'en-US': 'American English',
  'en-US-alt-short': 'US English',
  enm: 'Middle English',
  eo: 'Esperanto',
  es: 'Español',
  'es-419': 'Español Latinoamericano',
  'es-ES': 'Español Europeo',
  'es-MX': 'Español Mexicano',
  esu: 'Central Yupik',
  et: 'Eesti',
  eu: 'Euskara',
  ewo: 'Ewondo',
  ext: 'Extremaduran',
  fa: 'فارسی',
  'fa-AF': 'دری',
  fan: 'Fang',
  fat: 'Fanti',
  ff: 'Fulah',
  fi: 'Suomi',
  fil: 'Filipino',
  fit: 'Tornedalen Finnish',
  fj: 'Fijian',
  fo: 'Føroyskt',
  fon: 'Fon',
  fr: 'Français',
  'fr-CA': 'Français canadien',
  'fr-CH': 'Français de Suisse',
  frc: 'Français cadien',
  frm: 'Français moyen',
  fro: 'Français ancien',
  frp: 'Arpitan',
  frr: 'Friesisch',
  frs: 'Oostfrysk',
  fur: 'Furlan',
  fy: 'Frysk',
  ga: 'Gaeilge',
  gaa: 'Gã',
  gag: 'Gagauz',
  gan: '赣语',
  gay: 'Gayo',
  gba: 'Gbaya',
  gbz: 'Zoroastrian Dari',
  gd: 'Gàidhlig',
  gez: 'ግዕዝ',
  gil: 'Kiribati',
  gl: 'Galego',
  glk: 'گیلکی',
  gmh: 'Mittelhochdeutsch',
  gn: 'Guarani',
  goh: 'Althochdeutsch',
  gom: 'Goan Konkani',
  gon: 'Gondi',
  gor: 'Gorontalo',
  got: 'Gothic',
  grb: 'Grebo',
  grc: 'Ancient Greek',
  gsw: 'Schweizer Deutsch',
  gu: 'ગુજરાતી',
  guc: 'Wayuu',
  gur: 'Frafra',
  guz: 'Gusii',
  gv: 'Gaeilge',
  gwi: 'Gwichʼin',
  ha: 'Hausa',
  hai: 'Haida',
  hak: '客家话',
  haw: 'Hawaiian',
  he: 'עברית',
  hi: 'हिन्दी',
  hif: 'Fiji Hindi',
  hil: 'Hiligaynon',
  hit: 'Hittite',
  hmn: 'Hmong',
  ho: 'Hiri Motu',
  hr: 'Hrvatski',
  hsb: 'Obersorbisch',
  hsn: '湘语',
  ht: 'Kreyòl Ayisyen',
  hu: 'Magyar',
  hup: 'Hupa',
  hy: 'Հայերեն',
  hz: 'Herero',
  ia: 'Interlingua',
  iba: 'Iban',
  ibb: 'Ibibio',
  id: 'Bahasa Indonesia',
  ie: 'Interlingue',
  ig: 'Igbo',
  ii: 'Sichuan Yi',
  ik: 'Inupiat',
  ilo: 'Iloko',
  iml: 'Milo',
  in: 'Indonesian',
  io: 'Ido',
  is: 'Íslenska',
  it: 'Italiano',
  iu: 'Inuktitut',
  ja: '日本語',
  jbo: 'Lojban',
  jgo: 'Ngongo',
  ji: 'Yiddish',
  jmc: 'Machame',
  jv: 'Javanese',
  ka: 'ქართული',
  kab: 'Kabyle',
  kac: 'Kachin',
  kaj: 'Jju',
  kam: 'Kamba',
  kbd: 'Кабардинский',
  kea: 'Kabuverdianu',
  ken: 'Kenyang',
  kg: 'Kongo',
  kh: 'ខ្មែរ',
  kj: 'Kuanyama',
  kk: 'Қазақша',
  kl: 'Kalaallisut',
  km: 'ភាសាខ្មែរ',
  kn: 'ಕನ್ನಡ',
  ko: '한국어',
  kpe: 'Kpelle',
  kr: 'Kanuri',
  krc: 'Карачаевский',
  krl: 'Karelian',
  ksb: 'Shambala',
  kgp: 'Kagoro',
  ki: 'Kikuyu',
  kjh: 'Kahugu',
  kjl: 'Kunjara',
  kjr: 'Kujamaat',
  kjw: 'Kaju',
  kmb: 'Kimbundu',
  kmr: 'Kurdî',
  knb: 'Kuna',
  kox: 'Kokota',
  kps: 'Kipsigis',
  kpv: 'Koryak',
  kqu: 'Kedang',
  ksc: 'Kusu',
  ks: 'कश्मीरी',
  ksf: 'Bafia',
  ksh: 'Kölsch',
  ktn: 'Kunama',
  ktu: 'Kutiyath',
  kv: 'Коми',
  kxk: 'Kakua',
  ky: 'Кыргызча',
  kza: 'Kizigua',
  kz: 'Kazakh',
  la: 'Latin',
  lad: 'Ladino',
  lb: 'Lëtzebuergesch',
  le: 'Lendu',
  lg: 'Ganda',
  li: 'Limburgs',
  lij: 'Ligurian',
  lkt: 'Lakota',
  ln: 'Lingala',
  lo: 'ລາວ',
  lt: 'Lietuvių',
  lu: 'Luba-Katanga',
  lua: 'Luba-Lulua',
  lub: 'Luba-Katanga',
  lug: 'Ganda',
  luj: 'Luba-Lulua',
  lun: 'Lunda',
  luo: 'Luo',
  lus: 'Mizo',
  lv: 'Latviešu',
  lzh: 'Classical Chinese',
  lzz: 'Laz',
  maa: 'Maasai',
  mad: 'Madurese',
  mag: 'Magahi',
  mai: 'मैथिली',
  mak: 'Makasar',
  mal: 'മലയാളം',
  man: 'Mandingo',
  map: 'Austronesian languages',
  mas: 'Masai',
  mdf: 'Moksha',
  mdv: 'Maldivian',
  men: 'Mende',
  mgo: 'Metaʼ',
  mh: 'Marshallese',
  mi: 'Māori',
  min: 'Minangkabau',
  mk: 'Македонски',
  mkr: 'Mingrelian',
  ml: 'മലയാളം',
  mn: 'Монгол',
  mnc: 'Manchu',
  mo: 'Moldovan',
  mnk: 'Mongol',
  mri: 'Maori',
  ms: 'Bahasa Melayu',
  mt: 'Malti',
  mua: 'Mundang',
  my: 'မြန်မာဘာသာ',
  myv: 'Erzya',
  mzh: 'Mongolian',
  na: 'Nauru',
  nb: 'Norsk bokmål',
  nd: 'North Ndebele',
  ne: 'नेपाली',
  ng: 'Ndonga',
  nmg: 'Kwasio',
  nn: 'Norsk nynorsk',
  no: 'Norsk',
  nr: 'South Ndebele',
  nus: 'Nuer',
  nv: 'Navajo',
  ny: 'Chichewa',
  nyc: 'Nyanga',
  oci: 'Occitan',
  oj: 'Ojibwe',
  om: 'Oromo',
  or: 'ଓଡ଼ିଆ',
  os: 'Осетинский',
  pa: 'ਪੰਜਾਬੀ',
  pag: 'Pangasinan',
  pal: 'Pahlavi',
  pam: 'Pampanga',
  pap: 'Papiamento',
  pas: 'Pushto',
  pcd: 'Picard',
  pcf: 'Patois',
  pch: 'Pashto',
  peo: 'Old Persian',
  phi: 'Philippine languages',
  pl: 'Polski',
  prg: 'Prussian',
  ps: 'پښتو',
  pt: 'Português',
  qu: 'Quechua',
  rm: 'Rumantsch',
  ro: 'Română',
  ru: 'Русский',
  rw: 'Kinyarwanda',
  sa: 'संस्कृतम्',
  sah: 'Sakha',
  saq: 'Samburu',
  sbp: 'Sangu',
  sc: 'Sardu',
  sd: 'سنڌي',
  se: 'Northern Sámi',
  see: 'Seneca',
  sg: 'Sango',
  sh: 'Serbo-Croatian',
  si: 'සිංහල',
  sid: 'Sidamo',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Soomaali',
  sq: 'Shqip',
  sr: 'Српски',
  ss: 'Swati',
  st: 'Sesotho',
  su: 'Sundanese',
  sv: 'Svenska',
  sw: 'Kiswahili',
  syr: 'Syriac',
  ta: 'தமிழ்',
  te: 'తెలుగు',
  tet: 'Tetum',
  tg: 'Тоҷикӣ',
  th: 'ไทย',
  ti: 'Tigrinya',
  tk: 'Türkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga',
  tok: 'Tok Pisin',
  tpi: 'Tok Pisin',
  tr: 'Türkçe',
  ts: 'Tsonga',
  tt: 'Татарча',
  tuv: 'Tuvinian',
  tw: 'Twi',
  ty: 'Reo Tahiti',
  ug: 'ئۇيغۇرچە',
  uk: 'Українська',
  ur: 'اردو',
  uz: 'Oʻzbekcha',
  ve: 'Venda',
  vi: 'Tiếng Việt',
  vo: 'Volapük',
  vot: 'Votic',
  wa: 'Walon',
  wak: 'Wakashan languages',
  wal: 'Wolaitta',
  war: 'Waray',
  was: 'Washo',
  wbq: 'Wandala',
  wbr: 'Wawa',
  wuu: '吴语',
  xh: 'Xhosa',
  xi: 'XIN',
  xm: 'Xing',
  yi: 'יידיש',
  yo: 'Yorùbá',
  za: 'Zhuang',
  zab: 'Zabali',
  zh: '中文',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁體中文',
  zu: 'Zulu',
};
