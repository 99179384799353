import React, { useEffect } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import LocalStorageKeys from '../constants/localStorageKeys';

const FREE_PLAN_ADVANCED_SCAN_LIMIT = 5;
const ONE_MONTH_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

const useAdvancedScanUsage = () => {
  const [advancedScanUsages, storeAdvancedScanUsages] = useLocalStorage<number>(
    LocalStorageKeys.advancedScanUsages,
    0
  );
  const [lastUsageDate, storeLastUsageDate] = useLocalStorage<string>(
    LocalStorageKeys.lastAdvancedScanUsageDate,
    new Date().toISOString()
  );

  const maybeResetUsage = () => {
    const now = new Date();
    const lastUsage = new Date(lastUsageDate);

    if (now.getTime() - lastUsage.getTime() > ONE_MONTH_MILLISECONDS) {
      storeAdvancedScanUsages(0);
    }
  };

  const incrementAdvancedScanUsages = () => {
    maybeResetUsage();
    storeAdvancedScanUsages(advancedScanUsages + 1);
    const now = new Date().toISOString();
    storeLastUsageDate(now);
  };

  const advancedScansRemaining = Math.max(
    0,
    FREE_PLAN_ADVANCED_SCAN_LIMIT - advancedScanUsages
  );

  useEffect(() => {
    maybeResetUsage();
  }, []);

  return {
    advancedScansRemaining,
    incrementAdvancedScanUsages,
    advancedScanUsagesInCurrentPeriod: advancedScanUsages,
  };
};

export default useAdvancedScanUsage;
