import React from 'react';
import { G0Button } from 'component-library';

const ContinueWithEmailButton = ({ disabled }: { disabled: boolean }) => {
  return (
    <div className="flex justify-center items-center">
      <G0Button
        disabled={disabled}
        variant="secondary"
        className={`border-0 pl-0 pt-0 pb-0 ml-6 label-medium bg-transparent hover:bg-transparent ${
          disabled ? '!text-g0-gray-100 cursor-not-allowed' : 'text-g0-primary'
        } `}
      >
        <span className="flex items-center gap-2">
          Continue with email <RightArrow disabled={disabled} />
        </span>
      </G0Button>
    </div>
  );
};

const RightArrow = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow_right_alt_24dp_000000_FILL0_wght300_GRAD0_opsz24 1">
        <path
          id="Vector"
          d="M13.8462 17.6538L12.7923 16.5693L16.6115 12.7501L4.5 12.7501V11.2501L16.6115 11.2501L12.7923 7.43081L13.8462 6.34631L19.5 12.0001L13.8462 17.6538Z"
          fill={disabled ? '#DCDCDC' : '#111928'}
        />
      </g>
    </svg>
  );
};

export default ContinueWithEmailButton;
