import { sendUserPlanDataToChromeExtension } from '../../src/utils/extensionUtils';
import supabase from './supabaseClient';
import { toast } from 'react-toastify';
import {
  checkWhitelistedProfile,
  checkAdvisorAllowlistedProfile,
  checkTeacherWhitelistedProfile,
} from './whitelabelUtils';
import {
  setCookie,
  getCookieDomain,
  deleteCookie,
  getCookie,
} from './cookieUtils';
import whitelabelConfig from '../app/whitelabelConfig';
import {
  apiSendUserWelcomeMessage,
  apiUpdateUserTeamSelection,
  apiUpdateUserInviteAcceptance,
  apiCountUserTeams,
} from '../features/enterprise/api/UserApiCalls';
import { displayToast } from './uiUtil';
import { REQUIRED_VALID_COOKIE_ERROR_MESSAGE } from './constants';
import { planNames } from '../constants/planNames';

export async function saveProfileToLocalStorage(email = null, id = null) {
  if (email == null && id == null) {
    console.error('No email or id provided to saveProfileToLocalStorage');
    return { success: false };
  }
  let profiles = null;
  let error = null;

  if (id != null) {
    const { data, error: fetchError } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', id);
    profiles = data;
    error = fetchError;
  } else {
    const { data, error: fetchError } = await supabase
      .from('profiles')
      .select('*')
      .eq('email', email);
    profiles = data;
    error = fetchError;
  }

  if (error) {
    console.error(error);
    return { success: false };
  } else if (profiles == null || profiles.length === 0) {
    console.error('No profile found for email: ' + email + ' id: ' + id);
    return { success: false };
  } else {
    // Check if user is whitelisted
    let plan = profiles[0].plan;
    let char_limit = profiles[0].char_limit;
    let batch_limit = profiles[0].batch_limit;
    const isAdvisorAllowlisted = await checkAdvisorAllowlistedProfile(
      profiles[0].email
    );

    const isWhitelisted = await checkWhitelistedProfile(profiles[0].email);
    const isTeacherWhitelisted = await checkTeacherWhitelistedProfile(
      profiles[0].email
    );

    if (plan === 'Free' || plan.includes('Paused')) {
      if (isAdvisorAllowlisted) {
        plan = 'Educator';
        char_limit = 'null';
        batch_limit = 'null';
      } else {
        if (isWhitelisted) {
          plan = 'Educator';
          char_limit = 'null';
          batch_limit = 'null';
        } else {
          if (isTeacherWhitelisted) {
            plan = 'Educator';
            char_limit = 'null';
            batch_limit = 'null';
          }
        }
      }
    } else {
    }

    const planFromCookie = getCookie('plan');

    if (plan !== planFromCookie) {
      setCookie('plan', plan, '/', getCookieDomain(), 30);
    }

    localStorage.setItem('plan', plan);
    localStorage.setItem('char_limit', char_limit);
    localStorage.setItem('batch_limit', batch_limit);
    localStorage.setItem('apikey', profiles[0].api_key);
    localStorage.setItem('email', profiles[0].email);
    localStorage.setItem('fullname', profiles[0].full_name);
    localStorage.setItem('firstName', profiles[0].first_name);
    localStorage.setItem('lastName', profiles[0].last_name);
    localStorage.setItem('id', profiles[0].id);
    localStorage.setItem('previousProfileUpdateTime', Date.now());
    localStorage.setItem('hasSeenTutorial', profiles[0].has_seen_tutorial);
    localStorage.setItem(
      'hasHiddenPlagiarismWarning',
      profiles[0].has_hidden_plagiarism_warning
    );
    localStorage.setItem(
      'last_time_usage_reset',
      profiles[0].last_time_usage_reset
    );
    localStorage.setItem('created_at', profiles[0].created_at);
    localStorage.setItem('role_id', profiles[0].role_id);
    localStorage.setItem('isTeacherWhitelisted', isTeacherWhitelisted);
    localStorage.setItem('isAdvisorAllowlisted', isAdvisorAllowlisted);
    localStorage.setItem('isWhiteListed', isWhitelisted);

    localStorage.setItem(
      'studentWhitelistInviteStatus',
      profiles[0].student_whitelist_invite_status
    );
    localStorage.setItem(
      'teacherWhitelistInviteStatus',
      profiles[0].teacher_whitelist_invite_status
    );
    localStorage.setItem('orgRole', profiles[0].org_role);
    localStorage.setItem(
      'hasSeenDefaultProjectBanner',
      profiles[0].has_seen_default_project_banner
    );

    localStorage.setItem('newUserSendWelcome', profiles[0].new_user);
    localStorage.setItem('isSSOUser', profiles[0].is_sso_user);
    localStorage.setItem('teamInviteStatus', profiles[0].team_invite_status);
    localStorage.setItem('userInviteStatus', profiles[0].user_invite_status);
    localStorage.setItem(
      'lastTimeUsageReset',
      profiles[0].last_time_usage_reset
    );
    localStorage.setItem(
      'planCancellationEffectiveDate',
      profiles[0].plan_cancellation_eff_date
    );

    if (profiles?.[0]?.trial_subscription_started) {
      localStorage.setItem(
        'trialSubscriptionStarted',
        profiles[0].trial_subscription_started
      );
    }

    if (profiles?.[0]?.trial_subscription_end_date) {
      localStorage.setItem(
        'trialSubscriptionEndDate',
        profiles[0].trial_subscription_end_date
      );
    }

    if (profiles?.[0]?.trial_subscription_used) {
      localStorage.setItem(
        'trialSubscriptionUsed',
        profiles[0].trial_subscription_used
      );
    }

    if (profiles?.[0]?.tenant_id) {
      localStorage.setItem('tenantId', profiles[0].tenant_id);
      localStorage.setItem('roleId', profiles[0].role_id);
      localStorage.setItem('teamPlan', profiles[0].team_plan);
      if (profiles[0].team_plan !== 'Free') {
        localStorage.setItem('char_limit', 'null');
        localStorage.setItem('batch_limit', 'null');
      }
      addTenantIdToCookies({ tenantId: profiles[0].tenant_id });
    }

    localStorage.setItem('hasExistingTeams', profiles[0].has_team);

    await maybeActivateTeacherWhitelistOnFirstLogin({
      email: profiles[0].email,
      plan,
      teacherWhitelistInviteStatus: profiles[0].teacher_whitelist_invite_status,
    });

    sendUserPlanDataToChromeExtension({
      plan: plan,
      charLimit: char_limit,
      batchLimit: batch_limit,
      apiKey: profiles[0].api_key,
      id: profiles[0].id,
    });
    return { success: true, teamInviteStatus: profiles[0].team_invite_status };
  }
}

export const maybeActivateTeacherWhitelistOnFirstLogin = async ({
  email,
  plan,
  teacherWhitelistInviteStatus,
}) => {
  if (teacherWhitelistInviteStatus === 'pending') {
    await activateTeacherWhitelistOnFirstLogin(email, plan);
    localStorage.setItem('teacherWhitelistInviteStatus', 'active');
  } else {
    const alreadyAskedForTeacherWhitelistInvite = localStorage.getItem(
      'alreadyAskedForTeacherWhitelistInvite'
    );
    if (
      !alreadyAskedForTeacherWhitelistInvite ||
      alreadyAskedForTeacherWhitelistInvite === 'N'
    )
      await checkForPendingTeacherWhitelistInvites({ email });
    localStorage.setItem('alreadyAskedForTeacherWhitelistInvite', 'Y');
  }
};

const checkForPendingTeacherWhitelistInvites = async ({ email }) => {
  const { data, error } = await getPendingTeacherWhitelistInvites({
    email,
  });

  if (error) {
    //users are not aware we're running a background check for pending teacher whitelist invites so we don't want to show them an error message
    console.error('error==', error);
  }

  if (data && data.length > 0) {
    await activateTeacherWhitelistOnFirstLogin(
      localStorage.getItem('email'),
      localStorage.getItem('plan')
    );
    localStorage.setItem('teacherWhitelistInviteStatus', 'active');
  }
};

export async function activateStudentWhitelistOnFirstLogin(email) {
  // first check if user has been invited in the students whitelist table

  const { data, error } = await supabase
    .from('students_whitelist')
    .select('id, inviter_profile_id, inviter_email')
    .eq('email', email)
    .eq('status', 'invited')
    .is('deleted_at', null);

  if (error) {
    console.error(
      'Error checking the invitation status of user in students_whitelist',
      error
    );
    toast.error("Looks like we can't reach our servers at the moment!", {
      theme: 'light',
    });
    return;
  }

  const student = data[0];
  let firstLoginStudentData = {
    isFirstTimeLogin: false,
    inviterProfileId: null,
    inviterEmail: null,
  };

  if (data && data.length > 0) {
    firstLoginStudentData.isFirstTimeLogin = true;
    firstLoginStudentData.inviterProfileId = student.inviter_profile_id;
    firstLoginStudentData.inviterEmail = student.inviter_email;

    const { updateError } = await supabase
      .from('students_whitelist')
      .update({
        status: 'active',
        activated_at: new Date().toISOString(),
      })
      .eq('id', student.id);

    if (updateError) {
      console.error(
        `Error activating student whitelist information: ${JSON.stringify(
          updateError
        )}`
      );
      return;
    }

    const { profileUpdateError } = await supabase
      .from('profiles')
      .update({
        admin_profile_id: student.inviter_profile_id,
        updated_at: new Date().toISOString(),
      })
      .eq('email', email);

    if (profileUpdateError) {
      console.error(
        `Error activating student whitelist information: ${JSON.stringify(
          profileUpdateError
        )}`
      );
      return;
    }
  }

  return firstLoginStudentData;
}

export const getCurrentTenatId = () => {
  return localStorage.getItem('tenantId');
};

export const getUserProfileDetails = () => {
  return {
    TENANT_ID: localStorage.getItem('tenantId'),
    EMAIL: localStorage.getItem('email'),
    PLAN: localStorage.getItem('plan'),
    ORG_ROLE: localStorage.getItem('orgRole'),
    FULL_NAME: localStorage.getItem('fullname'),
    TEAM_NAME: localStorage.getItem('teamName'),
    USER_ROLE: localStorage.getItem('usrRole'),
    ROLE_ID: localStorage.getItem('roleId'),
    TEAM_PLAN: localStorage.getItem('teamPlan'),
    PROFILE_ID: localStorage.getItem('id'),
    FIRST_NAME: localStorage.getItem('firstName'),
    LAST_NAME: localStorage.getItem('lastName'),
    PENDING_TEAM_INVITATIONS: localStorage.getItem('teamInviteStatus'),
    HAS_TEAM_WITH_PAID_PLAN: localStorage.getItem('hasTeamsWithPaidPlans'),
    TRIAL_SUBSCRIPTION_USED: localStorage.getItem('trialSubscriptionUsed'),
  };
};

export const checkIsPremiumUser = () => {
  const { PLAN, TEAM_PLAN } = getUserProfileDetails();

  const hasPremiumPlan = PLAN && PLAN !== planNames.FREE;
  const hasPremiumTeamPlan = TEAM_PLAN && TEAM_PLAN !== planNames.FREE;

  return hasPremiumPlan || hasPremiumTeamPlan;
};

export const removeUserTeamProfileInfo = async () => {
  localStorage.removeItem('teamName');
  localStorage.removeItem('tenantId');
  localStorage.removeItem('usrRole');
  localStorage.removeItem('roleId');
  localStorage.removeItem('teamPlan');

  deleteTenantIdFromCookies();

  await updateSelectedTeamToUserProfile();
};

export const setUserTeamProfileInfo = async ({ tenant, orgUser = null }) => {
  if (!orgUser) {
    orgUser = tenant.org_users.find(
      (user) => user.email === localStorage.getItem('email')
    );
  }

  localStorage.setItem('tenantId', tenant.id);
  localStorage.setItem('teamName', tenant.org_name);
  localStorage.setItem('usrRole', orgUser.roles.name);
  localStorage.setItem('roleId', orgUser.role_id);
  localStorage.setItem('teamPlan', tenant.plan);

  // If the user is not on a free plan, set the char_limit and batch_limit to null
  if (tenant.plan !== 'Free') {
    localStorage.setItem('char_limit', 'null');
    localStorage.setItem('batch_limit', 'null');
  }

  addTenantIdToCookies({ tenantId: tenant.id });

  await updateSelectedTeamToUserProfile();
};

export const setInitialTeamProfileInfo = (tenantId) => {
  localStorage.setItem('teamPlan', 'Free');
  localStorage.setItem('tenantId', tenantId);
  localStorage.setItem('usrRole', 'Admin');

  addTenantIdToCookies({ tenantId: tenantId });
};

export const getCurrentPlan = (tenants) => {
  if (!tenants || !Array.isArray(tenants)) return 'Personal Plan';
  const currentTeam = getCurrentTenatId();

  if (currentTeam === '' || !currentTeam) return 'Personal Plan';
  const tenant = tenants.find((tenant) => tenant.id === currentTeam);
  if (tenant) return tenant.org_name;
  return 'Personal Plan';
};

export const getIsSelectedplan = (tenantId) => {
  const currentTenantId = getCurrentTenatId();

  if (
    (tenantId === '' || !tenantId) &&
    (!currentTenantId || currentTenantId === '')
  ) {
    return true;
  } else {
    if (currentTenantId === tenantId) return true;
  }
  return false;
};

export const addTenantIdToCookies = ({ tenantId }) => {
  console.log('tenant Id being added to cookies....tenantId', tenantId);
  setCookie('tenantId', tenantId, '/', getCookieDomain(), 90);
};

export const deleteTenantIdFromCookies = () => {
  console.log('tenantId being deleted from cookie');
  deleteCookie('tenantId', '/', getCookieDomain());
};

export const sendUserWelcomeMessageOnFirstLogin = async ({
  backendHostname = null,
}) => {
  if (!backendHostname) {
    const WhitelabelContext = whitelabelConfig.gptzero;
    backendHostname = WhitelabelContext.backendHostname;
  }
  const newUserSendWelcome = localStorage.getItem('newUserSendWelcome') === 'Y';
  if (!newUserSendWelcome) {
    return;
  }

  const userProfileDetails = getUserProfileDetails();

  const { error, response } = await apiSendUserWelcomeMessage({
    backendHostname,
    email: userProfileDetails.EMAIL,
  });

  if (error) {
    displayToast('error', error);
  } else {
    localStorage.setItem('newUserSendWelcome', 'N');
    console.log(response.info);
  }
};

export const updateSelectedTeamToUserProfile = async () => {
  const WhitelabelContext = whitelabelConfig.gptzero;
  const backendHostname = WhitelabelContext.backendHostname;

  const userProfileDetails = getUserProfileDetails();

  const { error } = await apiUpdateUserTeamSelection({
    backendHostname,
    tenantId: userProfileDetails.TENANT_ID,
    email: userProfileDetails.EMAIL,
    teamPlan: userProfileDetails.TEAM_PLAN,
  });

  if (error) {
    displayToast('error', error);
  }
};

export const updateUserInvitationAcceptanceOnFirstLogin = async ({
  backendHostname = null,
}) => {
  if (!backendHostname) {
    const WhitelabelContext = whitelabelConfig.gptzero;
    backendHostname = WhitelabelContext.backendHostname;
  }

  const userProfileDetails = getUserProfileDetails();

  const { error, data } = await apiUpdateUserInviteAcceptance({
    backendHostname,
    email: userProfileDetails.EMAIL,
  });

  if (error) {
    displayToast('error', error);
    return { success: false, error };
  } else {
    localStorage.setItem('userInviteStatus', 'active');
    return { success: true, data };
  }
};

export const getPendingTeacherWhitelistInvites = async ({ email }) => {
  const { data, error } = await supabase
    .from('teachers_whitelist')
    .select('*')
    .eq('email', email)
    .eq('status', 'invited')
    .is('deleted_at', null);

  return { data, error };
};

export const updateInvitedUserProfileOnAccept = async ({
  email,
  name,
  organizationName,
  id,
}) => {
  const updateData = {
    first_name: name,
    full_name: name,
    email: email,
    org_role: 'Admin',
    org: organizationName,
    new_user: 'Y',
    user_invite_status: 'active',
  };
  const { data, error } = await supabase
    .from('profiles')
    .update(updateData)
    .eq('id', id)
    .select('*');

  return { data, error };
};

export const activateTeacherWhitelistOnFirstLogin = async (
  email,
  plan = 'Free'
) => {
  //first check if user has been invited in the teachers whitelist table

  const { data, error } = await supabase
    .from('teachers_whitelist')
    .select('id, inviter_profile_id')
    .eq('email', email)
    .eq('status', 'invited')
    .is('deleted_at', null);

  if (error) {
    console.error(
      'Error checking the invitation status of user in teachers_whitelist',
      error
    );
    toast.error("Looks like we can't reach our servers at the moment!", {
      theme: 'light',
    });
    return;
  }

  const teacher = data[0];

  if (data && data.length > 0) {
    const { updateError } = await supabase
      .from('teachers_whitelist')
      .update({
        status: 'active',
        activated_at: new Date().toISOString(),
      })
      .eq('id', teacher.id);

    if (updateError) {
      console.error(
        `Error activating teacher whitelist information: ${JSON.stringify(
          updateError
        )}`
      );
      return;
    }

    const { profileUpdateError } = await supabase
      .from('profiles')
      .update({
        admin_profile_id: teacher.inviter_profile_id,
        updated_at: new Date().toISOString(),
        teacher_whitelist_invite_status: 'active',
      })
      .eq('email', email);

    if (profileUpdateError) {
      console.error(
        `Error activating teacher whitelist information: ${JSON.stringify(
          profileUpdateError
        )}`
      );
      return;
    }

    //now give the user the educator access if they are on a free plan or paused plan
    if (plan === 'Free' || plan.includes('Paused')) {
      localStorage.setItem('plan', 'Educator');
      localStorage.setItem('char_limit', 'null');
      localStorage.setItem('batch_limit', 'null');
    }
  }
};

export async function fetchUserTeamsCount() {
  const WhitelabelContext = whitelabelConfig.gptzero;
  const backendHostname = WhitelabelContext.backendHostname;
  const { data, error } = await apiCountUserTeams({
    backendHostname,
  });

  if (error) {
    if (error !== REQUIRED_VALID_COOKIE_ERROR_MESSAGE) {
      // we are skipping this error especially for non logged in users.
      // These calls run on each re-render, it would require a lot of changes
      // to do it right.
      displayToast('error', error);
    }
  }

  return data;
}

export async function signUpSupabaseUser(registerObj) {
  return await supabase.auth.signUp({
    email: registerObj.emailId,
    password: registerObj.password,
    options: {
      // this data ends up in auth.users.raw_user_meta_data
      data: {
        first_name: registerObj.firstName,
        last_name: registerObj.lastName,
        full_name: `${registerObj.firstName} ${registerObj.lastName}`,
        org: registerObj.org,
        email: registerObj.emailId,
        admin_profile_id: registerObj.adminProfileId,
        org_role:
          registerObj.orgRole === 'Other'
            ? registerObj.otherRoleInputValue
            : registerObj.orgRole,
        industry:
          registerObj.industry === 'Other'
            ? registerObj.otherIndustryInputValue
            : registerObj.industry,
        lms: registerObj.lms,
        institution_size: registerObj.institutionSize,
        team_invite_status: registerObj.teamInvitePendingStatus,
        role_id: registerObj.roleId,
        tenant_id: registerObj.tenantId,
        student_whitelist_invite_status:
          registerObj.studentWhitelistInviteStatus,
        teacher_whitelist_invite_status:
          registerObj.teacherWhitelistInviteStatus,
        new_user: 'Y',
        has_team: registerObj.hasExistingTeams,
        plan: registerObj.plan,
      },
      captchaToken: registerObj.captchaToken,
    },
  });
}
