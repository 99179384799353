import React, { useState } from 'react';
import { PlagiarismHighlightProps, IMatchPlagiarism } from './types';
import {
  createDisjointIntervals,
  createDisjointIntervalsV2,
} from '../../../helpers/plagiarism';

import './styles.css';

const PlagiarismHighlight: React.FC<PlagiarismHighlightProps> = ({
  plagiarismDocument,
  plagiarismActiveMatches,
  setPlagiarismActiveMatches,
  useV2 = false,
}) => {
  const disjointIntervalFunction = useV2
    ? createDisjointIntervalsV2
    : createDisjointIntervals;

  const disjointIntervals = disjointIntervalFunction(
    plagiarismDocument?.inputText,
    plagiarismDocument?.sources
  );

  const handleSetPlagiarismActiveMatches = (matches: IMatchPlagiarism[]) => {
    if (setPlagiarismActiveMatches) {
      setPlagiarismActiveMatches(matches);
    }
  };

  const renderInputText = (disjointIntervals) => {
    let result = [];
    disjointIntervals.forEach((interval) => {
      if (interval?.value?.length > 0) {
        const textColorClass = interval.isIdentical
          ? 'bg-g0-red-100 hover:hover:brightness-95'
          : 'bg-g0-ai-yellow-200 hover:hover:brightness-95';

        result.push(
          <span key={interval.start}>
            <span
              className={`${textColorClass} sources-highlight-view-content no-padding`}
              onClick={() => handleSetPlagiarismActiveMatches(interval.value)}
              title={`Match similarity: ${Math.round(interval.value[0].score)}`}
              key={interval.start}
            >
              {interval.text}
            </span>
            <sup className="ur-citation-text">
              [
              {[...interval.indexes]
                .sort((a, b) => a - b)
                .map((index) => index + 1)
                .join(', ')}
              ]
            </sup>{' '}
          </span>
        );
      } else {
        result.push(
          <span className="sources-highlight-view-content" key={interval.start}>
            {interval.text}
          </span>
        );
      }
    });
    return result;
  };

  return (
    <div className="plagiarism-card">{renderInputText(disjointIntervals)}</div>
  );
};

export default PlagiarismHighlight;
