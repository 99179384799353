import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../app/store/aiUtilitiesHubSlice/types';
import {
  ScanStatus,
  ScanTypes,
} from '../../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import { Link, useParams } from 'react-router-dom';
import useRequestTextAiScan from '../../../hooks/scans/useRequestTextAiScan';
import checkerProfiles from '../../../constants/checkerProfiles';
import { AiUtilitiesHubContext, MultiFileUploadContext } from '../context';
import useRequestTextPlagiarismScan from '../../../hooks/scans/useRequestTextPlagiarismScan';
import useRequestAiVocabScan from '../../../hooks/scans/useRequestAiVocabScan';
import { toast } from 'react-toastify';
import useRequestWritingFeedbackScan from '../../../hooks/scans/useRequestWritingFeedbackScan';
import { trackEvent } from 'component-library';
import { eventTrackingActions } from '../../../constants/trackingEvents';
import {
  PLAGIARISM_SUPPORTED_LANGUAGES,
  SUPPORTED_LANGUAGES,
} from '../utils/constants';
import { ALL_LANGUAGES } from '../utils/allLanguages';
import { isObjectEmpty } from '../../../utils/common';
import useRequestFilePlagiarismScan from '../../../hooks/scans/useRequestFilePlagiarismScan';
import useRequestFileAiScan from '../../../hooks/scans/useRequestFileAiScan';
import useRequestTextSourcesScan from '../../../hooks/scans/useRequestTextSourcesScan';
import useRequestFileSourcesScan from '../../../hooks/scans/useRequestFileSourcesScan';
import useAuthReduxState from '../../../hooks/auth/useAuthReduxState';
import {
  setBasicScanStatus,
  setSourcesScanStatus,
  setAiVocabScanStatus,
  setAdvancedScanStatus,
  setPlagiarismScanStatus,
  setWritingFeedbackScanStatus,
} from '../../../app/store/aiUtilitiesHubSlice';

export function useGetAiUtilitiesHubStateValues() {
  const {
    basicScan,
    advancedScan,
    plagiarismScan,
    aiVocabScan,
    activeView,
    overallScanId,
    requestedScanTypes,
    writingFeedbackScan,
    sourcesScan,
    scanTitle,
    scanAuthor,
    scanDate,
    isMultilingualChecked,
    latestInputText,
    isResultsViewOpen,
    isEditModeOpen,
    editorDocument,
    isShareModalOpen,
    isPublicScan,
    publicScanScanTypes,
    plagiarismActiveMatches,
    inputTextChangedSinceScan,
    isScanHistoryView,
    outOfAdvancedScans,
    outOfBasicScans,
    textExtraction,
  } = useSelector((state: GlobalState) => state?.aiUtilitiesHub);

  return {
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    sourcesScan,
    aiVocabScan,
    activeView,
    overallScanId,
    requestedScanTypes,
    scanTitle,
    scanAuthor,
    scanDate,
    isMultilingualChecked,
    latestInputText,
    isResultsViewOpen,
    isEditModeOpen,
    editorDocument,
    isShareModalOpen,
    isPublicScan,
    publicScanScanTypes,
    plagiarismActiveMatches,
    inputTextChangedSinceScan,
    isScanHistoryView,
    outOfAdvancedScans,
    outOfBasicScans,
    textExtraction,
  };
}

export function useHasViewedResults() {
  const {
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    aiVocabScan,
  } = useGetAiUtilitiesHubStateValues();

  return {
    [ScanTypes.basic]: basicScan?.hasViewedResults,
    [ScanTypes.advanced]: advancedScan?.hasViewedResults,
    [ScanTypes.plagiarism]: plagiarismScan?.hasViewedResults,
    [ScanTypes.writingFeedback]: writingFeedbackScan?.hasViewedResults,
    [ScanTypes.aiVocab]: aiVocabScan?.hasViewedResults,
  };
}

export function useGetIsLoading() {
  const {
    activeView,
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    sourcesScan,
    aiVocabScan,
  } = useGetAiUtilitiesHubStateValues();
  switch (activeView) {
    case ScanTypes.basic:
      return basicScan?.status === ScanStatus.loading;
    case ScanTypes.advanced:
      return advancedScan?.status === ScanStatus.loading;
    case ScanTypes.plagiarism:
      return plagiarismScan?.status === ScanStatus.loading;
    case ScanTypes.writingFeedback:
      return writingFeedbackScan?.status === ScanStatus.loading;
    case ScanTypes.investigateSources:
      return sourcesScan?.status === ScanStatus.loading;
    case ScanTypes.aiVocab:
      return aiVocabScan?.status === ScanStatus.loading;
    default:
      return false;
  }
}

export function useGetIsLoadingTextExtraction() {
  const { textExtraction } = useGetAiUtilitiesHubStateValues();
  return textExtraction?.status === ScanStatus.loading;
}

export function useResultsLoaded() {
  const {
    activeView,
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    sourcesScan,
    aiVocabScan,
  } = useGetAiUtilitiesHubStateValues();

  switch (activeView) {
    case ScanTypes.basic:
      return basicScan?.status === ScanStatus.success || basicScan?.data;
    case ScanTypes.advanced:
      return advancedScan?.status === ScanStatus.success || advancedScan?.data;
    case ScanTypes.plagiarism:
      return (
        plagiarismScan?.status === ScanStatus.success || plagiarismScan?.data
      );
    case ScanTypes.writingFeedback:
      return (
        writingFeedbackScan?.status === ScanStatus.success ||
        writingFeedbackScan?.data?.document ||
        writingFeedbackScan?.data?.feedbacks?.length > 0
      );
    case ScanTypes.investigateSources:
      return (
        sourcesScan?.status === ScanStatus.success && sourcesScan?.data?.results
      );
    case ScanTypes.aiVocab:
      return aiVocabScan?.status === ScanStatus.success || aiVocabScan?.data;
    default:
      return false;
  }
}

export const useAllScanStatuses = () => {
  const {
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    aiVocabScan,
    sourcesScan,
  } = useGetAiUtilitiesHubStateValues();

  return {
    [ScanTypes.basic]: basicScan.status,
    [ScanTypes.advanced]: advancedScan.status,
    [ScanTypes.plagiarism]: plagiarismScan.status,
    [ScanTypes.writingFeedback]: writingFeedbackScan.status,
    [ScanTypes.aiVocab]: aiVocabScan.status,
    [ScanTypes.investigateSources]: sourcesScan.status,
  };
};

export const useSetSelectedViewStatus = () => {
  const dispatch = useDispatch();

  const actionMap = {
    [ScanTypes.basic]: setBasicScanStatus,
    [ScanTypes.advanced]: setAdvancedScanStatus,
    [ScanTypes.plagiarism]: setPlagiarismScanStatus,
    [ScanTypes.writingFeedback]: setWritingFeedbackScanStatus,
    [ScanTypes.aiVocab]: setAiVocabScanStatus,
    [ScanTypes.investigateSources]: setSourcesScanStatus,
  };

  const setSelectedViewStatus = ({ selectedView, status }) => {
    const actionCreator = actionMap[selectedView];
    if (actionCreator) {
      dispatch(actionCreator({ status }));
    } else {
      console.error(`Unhandled selectedView: ${selectedView}`);
    }
  };

  return { setSelectedViewStatus };
};

export const useRetryFailedScan = () => {
  const { projectId } = useParams();
  const { activeView, latestInputText, overallScanId } =
    useGetAiUtilitiesHubStateValues();
  const { editorRef } = useAiUtilitiesHubContextValues();
  const { requestTextAiScan } = useRequestTextAiScan();
  const { requestFileAiScan } = useRequestFileAiScan();
  const { requestTextPlagiarismScan } = useRequestTextPlagiarismScan();
  const { requestFilePlagiarismScan } = useRequestFilePlagiarismScan();
  const { requestWritingFeedback } = useRequestWritingFeedbackScan();
  const { requestTextSourcesScan } = useRequestTextSourcesScan();
  const { requestFileSourcesScan } = useRequestFileSourcesScan();

  const { fileToScan, ocrSuccessful } = useContext(AiUtilitiesHubContext);

  const textFromEditor = editorRef.current?.getEditor().getText();
  const textToScan = latestInputText || textFromEditor;

  const retryScan = useCallback(async () => {
    try {
      if (activeView === ScanTypes.plagiarism) {
        fileToScan && !ocrSuccessful
          ? await requestFilePlagiarismScan({
              projectId,
              files: [fileToScan],
              scanId: overallScanId,
              checkerProfile: checkerProfiles.plagiarism,
            })
          : await requestTextPlagiarismScan({
              text: textToScan,
              scanId: overallScanId,
              checkerProfile: checkerProfiles.plagiarism,
              files: [fileToScan],
            });
      } else if (activeView === ScanTypes.writingFeedback) {
        await requestWritingFeedback({
          scanId: overallScanId,
          input: fileToScan || textToScan,
        });
      } else if (activeView === ScanTypes.investigateSources) {
        fileToScan
          ? await requestFileSourcesScan({
              projectId,
              files: [fileToScan],
              scanId: overallScanId,
              checkerProfile: checkerProfiles.sources,
            })
          : await requestTextSourcesScan({
              text: textToScan,
              scanId: overallScanId,
              checkerProfile: checkerProfiles.sources,
              projectId,
            });
      } else {
        fileToScan && !ocrSuccessful
          ? await requestFileAiScan({
              projectId,
              files: [fileToScan],
              scanId: overallScanId,
              editorDocumentId: null,
              checkerProfile: checkerProfiles.ai,
            })
          : await requestTextAiScan({
              projectId,
              scanId: overallScanId,
              text: textToScan,
              checkerProfile: checkerProfiles.ai,
              editorDocumentId: null,
              files: [fileToScan],
            });
      }
    } catch (error) {}
  }, [
    projectId,
    activeView,
    fileToScan,
    textToScan,
    overallScanId,
    requestFileAiScan,
    requestTextAiScan,
    requestWritingFeedback,
    requestFilePlagiarismScan,
    requestTextPlagiarismScan,
    requestTextSourcesScan,
    ocrSuccessful,
  ]);

  return {
    retryScan,
  };
};

export function useHasScanErrorStatus() {
  const {
    activeView,
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    sourcesScan,
  } = useGetAiUtilitiesHubStateValues();

  const [hasError, setHasError] = useState(false);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    switch (activeView) {
      case ScanTypes.basic:
        setHasError(basicScan?.status === ScanStatus.error);
        setHasData(!!basicScan?.data && !isObjectEmpty(basicScan?.data));
        break;
      case ScanTypes.advanced:
        setHasError(advancedScan?.status === ScanStatus.error);
        setHasData(!!advancedScan?.data && !isObjectEmpty(advancedScan?.data));
        break;
      case ScanTypes.plagiarism:
        setHasError(plagiarismScan?.status === ScanStatus.error);
        setHasData(
          !!plagiarismScan?.data && !isObjectEmpty(plagiarismScan?.data)
        );
        break;
      case ScanTypes.writingFeedback:
        setHasError(writingFeedbackScan?.status === ScanStatus.error);
        setHasData(
          !!writingFeedbackScan?.data &&
            !isObjectEmpty(writingFeedbackScan?.data)
        );
        break;
      case ScanTypes.investigateSources:
        setHasError(sourcesScan?.status === ScanStatus.error);
        setHasData(!!sourcesScan?.data?.results);
        break;
    }
  }, [
    activeView,
    basicScan?.data,
    basicScan?.status,
    advancedScan?.data,
    advancedScan?.status,
    plagiarismScan?.data,
    plagiarismScan?.status,
    writingFeedbackScan?.data,
    writingFeedbackScan?.status,
    sourcesScan?.data,
    sourcesScan?.status,
  ]);

  return {
    hasError,
    hasData,
  };
}

export const useCurrentViewScanData = () => {
  const {
    activeView,
    basicScan,
    advancedScan,
    plagiarismScan,
    writingFeedbackScan,
    sourcesScan,
    aiVocabScan,
  } = useGetAiUtilitiesHubStateValues();

  switch (activeView) {
    case ScanTypes.basic:
      return basicScan;
    case ScanTypes.advanced:
      return advancedScan;
    case ScanTypes.plagiarism:
      return plagiarismScan;
    case ScanTypes.writingFeedback:
      return writingFeedbackScan;
    case ScanTypes.investigateSources:
      return sourcesScan;
    case ScanTypes.aiVocab:
      return aiVocabScan;
    default:
      return null;
  }
};

export const useFetchMissingOrUpdatedResults = () => {
  const { projectId } = useParams();
  const { activeView, latestInputText, overallScanId, isPublicScan } =
    useGetAiUtilitiesHubStateValues();
  const doResultsExist = useResultsLoaded();
  const isFetchingResults = useGetIsLoading();
  const { requestTextAiScan } = useRequestTextAiScan();
  const { requestFileAiScan } = useRequestFileAiScan();
  const { requestTextPlagiarismScan } = useRequestTextPlagiarismScan();
  const { requestWritingFeedback } = useRequestWritingFeedbackScan();
  const { requestTextSourcesScan } = useRequestTextSourcesScan();
  const { requestFileSourcesScan } = useRequestFileSourcesScan();
  const { requestAiVocabScan } = useRequestAiVocabScan();
  const currentViewScanData = useCurrentViewScanData();
  const { isAnonymousUser } = useAuthReduxState();
  const lastScannedText =
    // @ts-ignore
    currentViewScanData?.data?.inputText ||
    // @ts-ignore
    currentViewScanData?.data?.document?.inputText;

  const areCurrentResultsUpdated =
    doResultsExist && latestInputText === lastScannedText;

  const hasFetched = useRef(false);

  const { fileToScan } = useContext(AiUtilitiesHubContext);
  const {
    showResults: isMultiFileUploadResultsView,
    resultViewFileId,
    uploadedFiles,
    checkedStates: multiFileCheckedStates,
  } = useContext(MultiFileUploadContext);

  const fetchScan = useCallback(async () => {
    if (isAnonymousUser) return;
    if (isPublicScan || hasFetched.current) return;
    if ((!doResultsExist || !areCurrentResultsUpdated) && !isFetchingResults) {
      trackEvent(eventTrackingActions.FETCH, 'scan', null, null, {
        scanType: activeView,
      });
      try {
        if (activeView === ScanTypes.plagiarism) {
          await requestTextPlagiarismScan({
            text: latestInputText,
            scanId: overallScanId,
            checkerProfile: checkerProfiles.plagiarism,
          });
        } else if (activeView === ScanTypes.writingFeedback) {
          await requestWritingFeedback({
            scanId: overallScanId,
            input: latestInputText || fileToScan,
          });
        } else if (activeView === ScanTypes.investigateSources) {
          await requestTextSourcesScan({
            text: latestInputText,
            scanId: overallScanId,
            checkerProfile: checkerProfiles.sources,
            projectId,
          });
        } else if (activeView === ScanTypes.aiVocab) {
          await requestAiVocabScan({
            scanId: overallScanId,
            text: latestInputText,
          });
        } else {
          if (isMultiFileUploadResultsView) {
            const resultViewFile = uploadedFiles.find(
              (file) => file.name === resultViewFileId
            );
            await requestFileAiScan({
              scanId: overallScanId,
              files: [resultViewFile],
              checkerProfile: checkerProfiles.ai,
              projectId,
              editorDocumentId: null,
              isMultiFileUpload: true,
              multiFileCheckedStates,
            });
          } else {
            await requestTextAiScan({
              projectId,
              scanId: overallScanId,
              text: latestInputText,
              checkerProfile: checkerProfiles.ai,
              editorDocumentId: null,
            });
          }
          hasFetched.current = true;
        }
      } catch (error) {
        toast.error('An error occurred while fetching the scan results');
      }
    }
  }, [
    projectId,
    overallScanId,
    doResultsExist,
    requestTextAiScan,
    latestInputText,
    isFetchingResults,
    areCurrentResultsUpdated,
    activeView,
    requestTextPlagiarismScan,
    requestWritingFeedback,
    requestTextSourcesScan,
    requestAiVocabScan,
    fileToScan,
  ]);

  useEffect(() => {
    fetchScan();
  }, [activeView]);

  return {
    renderResultsView: doResultsExist,
  };
};

export const useAiUtilitiesHubContextValues = () => {
  const {
    editorRef,
    fileToScan,
    setFileToScan,
    characterCount,
    setCharacterCount,
    setIsPremiumModalOpen,
    setPremiumModalSource,
    showCharacterCountNotice,
    setShowCharacterCountNotice,
    setShouldShowCharacterCountNotice,
    shouldShowCharacterCountNotice,
  } = useContext(AiUtilitiesHubContext);

  return {
    editorRef: editorRef,
    fileToScan,
    setFileToScan,
    characterCount,
    setCharacterCount,
    setIsPremiumModalOpen,
    setPremiumModalSource,
    showCharacterCountNotice,
    setShowCharacterCountNotice,
    shouldShowCharacterCountNotice,
    setShouldShowCharacterCountNotice,
  };
};

export const useLanguageMeta = () => {
  const { basicScan, activeView, plagiarismScan } =
    useGetAiUtilitiesHubStateValues();
  const [languageWarningTitle, setLanguageWarningTitle] = useState('');
  const [languageWarningMessage, setLanguageWarningMessage] =
    useState<React.ReactNode>('');
  const [language, setLanguage] = useState('en');
  const [supportLevel, setSupportLevel] = useState<
    'supported' | 'partiallySupported' | 'unsupported'
  >('supported');
  const [showBadge, setShowBadge] = useState(false);
  const { setShouldShiftNavDown } = useContext(AiUtilitiesHubContext);

  const resetWarning = useCallback(() => {
    setLanguageWarningTitle('');
    setLanguageWarningMessage('');
    setShouldShiftNavDown(false);
  }, []);

  useEffect(() => {
    if (![ScanTypes.basic, ScanTypes.advanced].includes(activeView)) {
      return;
    }

    const languageCode =
      basicScan?.data?.language || plagiarismScan?.data?.language;
    setLanguage(ALL_LANGUAGES[languageCode] || '');

    if (SUPPORTED_LANGUAGES.includes(languageCode)) {
      setSupportLevel('supported');
      setLanguageWarningTitle('');
      setLanguageWarningMessage('');
      if (languageCode !== 'en') {
        setShowBadge(true);
      } else {
        setShowBadge(false);
      }
      setShouldShiftNavDown(false);
    } else if (Object.keys(ALL_LANGUAGES).includes(languageCode)) {
      setSupportLevel('partiallySupported');
      setLanguageWarningTitle(
        `We partially support ${ALL_LANGUAGES[languageCode]}.`
      );

      // Can't use JSX in ts file, so we need to use React.createElement
      setLanguageWarningMessage(
        React.createElement(
          'span',
          null,
          "We're still working on this language, so the results may be imperfect. Request your language ",
          React.createElement(
            Link,
            {
              className: 'underline',
              to: 'https://forms.gle/vL2SfjtdtdgwrmdEA',
              target: '_blank',
            },
            'here'
          ),
          '.'
        )
      );
      setShouldShiftNavDown(true);
      setShowBadge(true);
    } else if (languageCode) {
      setSupportLevel('unsupported');
      setLanguageWarningTitle(
        `Currently, we don't support ${language || 'this language'}.`
      );
      setLanguageWarningMessage(
        React.createElement(
          'span',
          null,
          'Request the support for your language ',
          React.createElement(
            Link,
            {
              className: 'underline',
              to: 'https://forms.gle/vL2SfjtdtdgwrmdEA',
              target: '_blank',
            },
            'here'
          ),
          '.'
        )
      );
      setShowBadge(true);
      setShouldShiftNavDown(true);
    }
  }, [basicScan?.data?.language, activeView, plagiarismScan?.data?.language]);

  useEffect(() => {
    if (activeView !== ScanTypes.plagiarism) return;

    const detectAndProcessLanguage = () => {
      let languageCode =
        basicScan?.data?.language || plagiarismScan?.data?.language;

      if (languageCode) {
        setLanguage(ALL_LANGUAGES[languageCode] || '');
        if (
          Object.keys(PLAGIARISM_SUPPORTED_LANGUAGES).includes(languageCode)
        ) {
          setSupportLevel('supported');
          setLanguageWarningTitle('');
          setLanguageWarningMessage('');
          setShowBadge(false);
          setShouldShiftNavDown(false);
        } else {
          setSupportLevel('unsupported');
          setLanguageWarningTitle(
            `Currently, we don't support plagiarism scans in ${
              ALL_LANGUAGES[languageCode] || 'this language'
            }.`
          );
          setLanguageWarningMessage(
            React.createElement(
              'span',
              null,
              'Request the support for your language ',
              React.createElement(
                Link,
                {
                  className: 'underline',
                  to: 'https://forms.gle/vL2SfjtdtdgwrmdEA',
                  target: '_blank',
                },
                'here'
              ),
              '.'
            )
          );
          setShowBadge(true);
          setShouldShiftNavDown(true);
        }
      }
    };

    detectAndProcessLanguage();
  }, [activeView, basicScan?.data?.language, plagiarismScan?.data?.language]);

  return {
    languageWarningTitle,
    languageWarningMessage,
    setLanguageWarningTitle,
    setLanguageWarningMessage,
    resetWarning,
    language,
    supportLevel,
    setSupportLevel,
    showBadge,
    setShowBadge,
  };
};
