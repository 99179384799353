import { toast } from 'react-toastify';

interface CheckClassroomAuthParams {
  baseUrl: string;
  userId: string;
}

export async function checkClassroomAuth({
  baseUrl,
  userId,
}: CheckClassroomAuthParams) {
  const authEndpointUrl = `${baseUrl}/v3/google-classroom/users/${userId}/status`;
  const errorMessage =
    'An error occured when checking your authroization. Please try again.';
  try {
    const response = await fetch(authEndpointUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok && response.status !== 404) {
      toast.error(errorMessage);
      return;
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error(errorMessage);
  }
}
interface CreateClassroomUserParams {
  baseUrl: string;
  googleProviderId: string;
  refreshToken: string;
  email: string;
  name: string;
  supabaseUserId: string;
}
export async function createClassroomUser({
  baseUrl,
  googleProviderId,
  refreshToken,
  email,
  name,
  supabaseUserId,
}: CreateClassroomUserParams) {
  const createAttachmentEndpointUrl = `${baseUrl}/v3/google-classroom/users`;
  const errorMessage =
    'There was an error when logging in to Classroom™. Please contact support at team@gptzero.me';
  try {
    const response = await fetch(createAttachmentEndpointUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: googleProviderId,
        refreshToken,
        email,
        name,
        supabaseUserId,
      }),
    });
    if (!response.ok) {
      toast.error(errorMessage);
      return;
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessage);
    return;
  }
}

interface CreateAttachmentParams {
  baseUrl: string;
  userId: string;
  courseId: string;
  itemId: string;
  addonToken: string;
  title: string;
  instructions: string;
}
export async function createAttachment({
  baseUrl,
  userId,
  courseId,
  itemId,
  addonToken,
  title,
  instructions,
}: CreateAttachmentParams) {
  const errorMessage =
    'An error occured when creating the assignment. Please try again.';
  const createAttachmentEndpointUrl = `${baseUrl}/v3/google-classroom/attachments`;
  try {
    const response = await fetch(createAttachmentEndpointUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        courseId,
        itemId,
        userId,
        addonToken,
        config: {
          title,
          instructions,
        },
      }),
    });
    if (!response.ok) {
      toast.error(errorMessage);
      return;
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error(errorMessage);
  }
}

interface PostNewEditorConfigParams {
  baseUrl: string;
  editorDocumentId: string;
  isAiFeedbackEnabled: boolean;
  gradingRubric: File | null;
  additionalBriefing?: string | null;
  aiInterventionLevelIndex: number;
  isWritingVideoEnabled: boolean;
  shouldEnableStudentScanning?: boolean | null;
}
export async function postNewEditorConfig({
  baseUrl,
  editorDocumentId,
  isAiFeedbackEnabled,
  gradingRubric,
  additionalBriefing,
  aiInterventionLevelIndex,
  isWritingVideoEnabled,
  shouldEnableStudentScanning,
}: PostNewEditorConfigParams) {
  const formData = new FormData();
  formData.append('gradingRubric', gradingRubric);
  formData.append('aiFeedbackEnabled', String(isAiFeedbackEnabled));
  formData.append('aiInterventionLevel', String(aiInterventionLevelIndex + 1));
  formData.append('isWritingVideoEnabled', String(isWritingVideoEnabled));
  formData.append(
    'shouldEnableStudentScanning',
    String(shouldEnableStudentScanning)
  );
  if (additionalBriefing) {
    formData.append('additionalBriefing', additionalBriefing);
  }

  try {
    const response = await fetch(
      `${baseUrl}/v3/editor-tutor/${editorDocumentId}/config`,
      {
        method: 'POST',
        body: formData,
      }
    );
    await response.json();
  } catch (error) {
    toast.error(
      'An error occured when saving your settings. Please try again.'
    );
  }
}

interface FetchMasterEditorDocumentIdParams {
  baseUrl: string;
  attachmentId: string;
  courseId: string;
  itemId: string;
  userId: string;
}
export async function fetchMasterEditorDocumentId({
  baseUrl,
  attachmentId,
  courseId,
  itemId,
  userId,
}: FetchMasterEditorDocumentIdParams) {
  const errorMessage =
    'An error occured when looking up the attachment. Please try again later.';
  const fetchMasterEditorDocumentIdEndpointUrl = `${baseUrl}/v3/google-classroom/attachments/${attachmentId}`;
  try {
    const response = await fetch(fetchMasterEditorDocumentIdEndpointUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        courseId,
        itemId,
        userId,
      }),
    });
    if (!response.ok) {
      toast.error(errorMessage);
      return;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error(errorMessage);
  }
}

interface FetchEditorConfigParams {
  baseUrl: string;
  editorDocumentId: string;
}
export async function fetchEditorConfig({
  baseUrl,
  editorDocumentId,
}: FetchEditorConfigParams) {
  const errorMessage =
    'An error occured when fetching the assignment settings.';
  const fetchEditorConfigEndpointUrl = `${baseUrl}/v3/editor-tutor/${editorDocumentId}/config`;
  try {
    const response = await fetch(fetchEditorConfigEndpointUrl, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      toast.error(errorMessage);
      return;
    }
    return await response.json();
  } catch (error) {
    toast.error('An error occured when fetching editor config');
  }
}

interface GetSubmissionParams {
  baseUrl: string;
  submissionId: string;
  attachmentId: string;
  itemId: string;
  courseId: string;
  userId: string;
}
export async function fetchSubmission({
  baseUrl,
  submissionId,
  attachmentId,
  itemId,
  courseId,
  userId,
}: GetSubmissionParams) {
  const errorMessage =
    'An error occured when getting your submission. Please try again later.';
  const getSubmissionEndpointUrl = `${baseUrl}/v3/google-classroom/submissions`;
  try {
    const response = await fetch(getSubmissionEndpointUrl, {
      method: 'Post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        submissionId,
        attachmentId,
        itemId,
        courseId,
        userId,
      }),
    });
    if (!response.ok) {
      toast.error(errorMessage);
      return;
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error(errorMessage);
  }
}

interface GetUserRoleParams {
  baseUrl: string;
  courseId: string;
  itemId: string;
  userId: string;
}
export async function fetchUserRole({
  baseUrl,
  courseId,
  itemId,
  userId,
}: GetUserRoleParams) {
  const errorMessage =
    'An error occured when getting user role. Please try again later.';
  const getUserRoleEndpointUrl = `${baseUrl}/v3/google-classroom/users/${userId}/courses/${courseId}/items/${itemId}/context`;
  try {
    const response = await fetch(getUserRoleEndpointUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      toast.error(errorMessage);
      return;
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error(errorMessage);
  }
}

interface GetStudentSubmissionParams {
  baseUrl: string;
  submissionId: string;
  attachmentId: string;
}
export async function fetchStudentSubmission({
  baseUrl,
  submissionId,
  attachmentId,
}: GetStudentSubmissionParams) {
  const getStudentSubmissionEndpointUrl = `${baseUrl}/v3/google-classroom/submissions/${submissionId}/attachments/${attachmentId}`;
  try {
    const response = await fetch(getStudentSubmissionEndpointUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok && response.status !== 404) {
      throw new Error(
        'Unable to get student submission: ' + response.statusText
      );
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error(
      'Unknown error occured when getting student submission. Contact support.'
    );
  }
}
