import { useNavigate } from 'react-router-dom';
import GptZeroLoaderFullPageLoader from '../components/GptZeroLoader';
import { useClassroomAuthentication } from '../hooks/googleClassroom/useClassroomAuthentication';
import { useClassroomWhitelist } from '../hooks/googleClassroom/useClassroomWhitelist';
import React, { useEffect, useState } from 'react';
import PageContainer from '../features/googleClassroom/components/PageContainer';
import { deleteUsersDataFromBrowser } from '../app/auth';
import { isMobileBrowser } from '../utils/extensionUtils';

const SignOutModal = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Account Mismatch</h2>
        <p className="mb-4">
          There seems to be a mismatch between the logged-in user and the
          current session. Please sign out here and from the GPTZero Dashboard.
        </p>
        <button onClick={onClose} className="btn btn-primary">
          Sign Out
        </button>
      </div>
    </div>
  );
};

const MobileAppsNotSupported = () => {
  return (
    <PageContainer>
      <div className="flex flex-col items-center justify-center h-full px-1">
        <h2 className="text-2xl font-bold mb-4">
          Mobile Devices Not Supported
        </h2>
        <p className="mb-4">
          Please use a desktop browser to access this page.
        </p>
      </div>
    </PageContainer>
  );
};

const NotWhitelisted = () => {
  return (
    <PageContainer>
      <div className="flex flex-col items-center justify-center h-full px-1">
        <h2 className="text-2xl font-bold mb-4">
          The email associated with your account is not whitelisted.
        </h2>
        <p className="mb-4">
          Please contact your institution's administrator to request access, or
          team@gptzero.me if you believe this is a mistake.
        </p>
      </div>
    </PageContainer>
  );
};

const AuthenticatedClassroom = ({ isTeacherView = false, children }) => {
  const { goToAuth, loading, userMismatch } = useClassroomAuthentication();
  const { isWhitelistActive, isUserWhitelisted, loadingWhitelist } =
    useClassroomWhitelist({ isTeacher: isTeacherView });
  const [showModal, setShowModal] = useState(false);
  const isMobile = isMobileBrowser();
  const navigate = useNavigate();

  const handleSignOut = () => {
    setShowModal(false);
    deleteUsersDataFromBrowser();
    navigate('/classroom/connect', {
      state: { from: `${window.location.pathname}${window.location.search}` },
    });
  };

  // Show the modal if there's a mismatch
  useEffect(() => {
    if (userMismatch) {
      setShowModal(true);
    }
  }, [userMismatch]);

  if (isMobile) {
    return <MobileAppsNotSupported />;
  }

  if (!loadingWhitelist && isWhitelistActive && !isUserWhitelisted) {
    return <NotWhitelisted />;
  }

  if (loading) {
    return <GptZeroLoaderFullPageLoader />;
  }

  if (showModal) {
    return (
      <PageContainer>
        <SignOutModal isVisible={showModal} onClose={handleSignOut} />
      </PageContainer>
    );
  }

  return <>{!goToAuth && !userMismatch && children}</>;
};

export default AuthenticatedClassroom;
