import { useContext } from 'react';
import WhitelabelContext from '../../contexts/WhitelabelContext';

export function DashboardPreview() {
  const whitelabelConfig = useContext(WhitelabelContext);
  return (
    <div
      className={
        'w-[55%] lg:px-[70px] 2xl:px-[150px] py-[130px] hidden lg:block'
      }
    >
      <h2 className={'text-[30px] font-bold'}>
        The{' '}
        {whitelabelConfig?.name !== 'GPTZero' && (
          <img
            alt={'logo'}
            src={whitelabelConfig.logo.icon}
            className="w-12 inline-block mr-2"
            width="3rem" // Equivalent to w-12
            height="3rem"
          />
        )}
        {whitelabelConfig.name} Dashboard
      </h2>
      <p className={'mt-5 text-[14px] w-5/6'}>{whitelabelConfig?.message}</p>
      <img
        src={whitelabelConfig.dashboardPromoImage}
        alt={'dashboard promo picture'}
        className={'mt-12 w-full'}
      />
    </div>
  );
}

export default DashboardPreview;
