import { useAuthentication } from '../hooks/useAuthentication';

const AuthenticatedWithoutLayout = ({ children }) => {
  const { goToLogin, token } = useAuthentication();

  if (!token) return null;

  if (goToLogin) return null;

  return <>{children}</>;
};

export default AuthenticatedWithoutLayout;
