import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { today } from '../utils/dateUtils';
import { useNavigate } from 'react-router-dom';
import WhitelabelContext from '../contexts/WhitelabelContext';

const useScanV3 = () => {
  const whitelabelConfig = useContext(WhitelabelContext);
  const navigate = useNavigate();

  const { backendHostname } = whitelabelConfig;

  const createScan = async ({
    author = localStorage.getItem('fullname') || '',
    title = `Document — ${today()}`,
    source = 'dashboard',
    aiDocumentId,
    projectId = undefined,
  }) => {
    if (!backendHostname) {
      return;
    }

    if (!source) {
      source = 'dashboard';
    }

    const bodyPayload = { source, author, title, aiDocumentId };
    if (projectId) {
      bodyPayload.projectId = projectId;
    }

    try {
      const response = await fetch(`${backendHostname}/v3/scan`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(bodyPayload),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        toast.error('Failed to create scan');
      }

      return response.json();
    } catch (error) {
      toast.error('Failed to create scan');
    }
  };

  const redirectToScanResultsPage = ({ scanId }) => {
    return navigate(`/app/documents/${scanId}`);
  };

  return { createScan, redirectToScanResultsPage };
};

export default useScanV3;
