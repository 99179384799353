import React from 'react';
import { G0Button } from 'component-library';
import LoadingSpinner from '../../LoadingSpinner';

export const ConfirmEmailModalHeader = ({
  className = '',
}: {
  className?: string;
}) => {
  return (
    <div className={`text-center ${className}`}>
      <h2 className="title-large text-g0-primary mb-3">
        Please confirm your email!
      </h2>
      <div className="body-medium">
        <span className="text-g0-secondary">
          Please check your email to confirm your account.
        </span>
      </div>
    </div>
  );
};

export const ContactSupport = ({
  onClickCustomerSupport,
  className = '',
  email,
}: {
  email: string;
  className?: string;
  onClickCustomerSupport: () => void;
}) => {
  return (
    <div className={`mb-6 ${className}`}>
      <p className="body-medium md:body-small text-g0-primary md:mx-24">
        You’ll receive an email at {email}. If you have troubles receiving your
        email,{' '}
        <span
          onClick={onClickCustomerSupport}
          className="label-small text-g0-gray-400 underline decoration-g0-gray-400"
        >
          Contact Customer Support
        </span>
        .
      </p>
    </div>
  );
};

export const ReturnToLoginButton = ({
  onClickReturnToLogin,
  className = '',
}: {
  className?: string;
  onClickReturnToLogin: () => void;
}) => {
  return (
    <G0Button
      onClick={onClickReturnToLogin}
      variant="secondary"
      className={`border-0 label-small text-g0-primary ${className}`}
    >
      Return to Log in
    </G0Button>
  );
};

export const ResendEmailButton = ({
  loading,
  className = '',
  onClickResendEmail,
}: {
  onClickResendEmail: () => void;
  className?: string;
  loading: boolean;
}) => {
  return (
    <G0Button size="medium" onClick={onClickResendEmail} className={className}>
      {loading && <LoadingSpinner />}
      Resend email
    </G0Button>
  );
};
