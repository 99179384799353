const lineClass = 'border-t w-[44%]';

export function Separator() {
  return (
    <div className={'flex mt-5 text-[#A4A4A4] justify-between items-center'}>
      <div className={lineClass} />
      <div>or</div>
      <div className={lineClass} />
    </div>
  );
}

export default Separator;
