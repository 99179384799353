import React from 'react';

const UserRoleItem = ({ role, isSelected, onClick, avatar }) => {
  return (
    <div className="flex-1">
      <div
        onClick={onClick}
        className={`flex justify-center items-center border ${
          isSelected ? 'border-g0-primary' : 'border-g0-gray-100'
        } py-6 rounded-lg hover:border-g0-gray-400`}
      >
        {avatar}
      </div>
      <div className="label-small text-g0-gray-400 text-center mt-3">
        {role}
      </div>
    </div>
  );
};

export default UserRoleItem;
