import React from 'react';
import { ClassificationSubHeaderProps } from './ClassificationSubHeader.types';
import ResultsTitleBanner, {
  getBannerStyles,
} from '../../molecules/ResultsTitleBanner/ResultsTitleBanner';
import SubText from '../../atoms/typography/SubText/SubText';
import { confidenceTexts, wordsToBoldAndUnderline } from './data';
import './ClassificationSubHeader.css';

const ClassificationSubHeader: React.FC<ClassificationSubHeaderProps> = ({
  predictedClass,
  confidenceCategory,
  variant = 'legacy',
  // In the Advanced scan usage of this component, we don't want to highlight the classification
  shouldHighlightClassification = true,
}) => {
  const isNexusVersion = variant === 'nexus';
  const getConfidenceText = () =>
    confidenceTexts[confidenceCategory]?.[predictedClass];

  const formatConfidenceText = () => {
    let text = getConfidenceText();
    if (text === undefined) {
      return '';
    }

    const formattedText = text
      .split(' ')
      .map((word) => {
        if (wordsToBoldAndUnderline.includes(word)) {
          return `<span style="font-weight: 500; text-decoration: underline;">${word}</span>`;
        } else {
          return word;
        }
      })
      .join(' ');

    if (!shouldHighlightClassification) {
      const classificationText = getBannerStyles(predictedClass, variant)?.text;
      return `${formattedText} ${classificationText}.`;
    }
    return formattedText;
  };

  return (
    <div
      className={`classification-subheader ${isNexusVersion ? 'g0-nexus nexus-width' : ''}`}
    >
      <SubText>
        <span
          className={`${isNexusVersion ? 'text-g0-primary' : ''}`}
          dangerouslySetInnerHTML={{ __html: formatConfidenceText() }}
        />
      </SubText>
      {shouldHighlightClassification && (
        <ResultsTitleBanner
          variant={variant}
          predictedClass={predictedClass}
          className={`results-title-banner-call-2 ${
            isNexusVersion ? '!py-[2px] !px-[10px] rounded-[12px]' : ''
          }`}
        />
      )}
    </div>
  );
};

export default ClassificationSubHeader;
