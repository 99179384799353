import styled from 'styled-components';
import { ContainerProps } from './container.types';

const borderStyle = 'solid 1px #D9D9D9';

export const StyledContainer = styled.div<ContainerProps>`
  background: ${(props) => props.$background};
  width: ${(props) => (props.$width ? props.$width : 'auto')};
  height: ${(props) => (props.$height ? props.$height : 'auto')};
  border-radius: ${(props) => props.$borderRadius || '0'};
  border-top: ${(props) => (props.$borderTop ? borderStyle : '0')};
  border-bottom: ${(props) => (props.$borderBottom ? borderStyle : '0')};
  margin-top: ${(props) => props.$marginTop};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-bottom: ${(props) => props.$marginBottom};
  padding-top: ${(props) => props.$paddingTop};
  padding-left: ${(props) => props.$paddingLeft};
  padding-right: ${(props) => props.$paddingRight};
  padding-bottom: ${(props) => props.$paddingBottom};
  padding: ${(props) => props.$padding};
  margin: ${(props) => props.$margin};
  display: flex;
  flex-direction: ${(props) => (props.$column ? 'column' : 'row')};
  justify-content: ${(props) =>
    props.$spaceBetween
      ? 'space-between'
      : props.$justifyCenter
        ? 'center'
        : 'left'};
  align-items: ${(props) => (props.$itemsCenter ? 'center' : 'flex-start')};
  overflow: ${(props) => props.$overflow};
  box-shadow: ${(props) => props.$shadow};
  position: ${(props) =>
    props.$relative ? 'relative' : props.$absolute ? 'absolute' : ''};
  z-index: ${(props) => props.$zIndex};
  top: ${(props) => props.$top};
  left: ${(props) => props.$left};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  cursor: ${(props) => props.$cursorPointer && 'pointer'};
  filter: ${(props) => `blur(${props.$blur})`};
  gap: ${(props) => props.$gap};
`;
