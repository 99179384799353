export const SUPPORTED_LANGUAGES = ['fr', 'es', 'en'];

export const PLAGIARISM_SUPPORTED_LANGUAGES = {
  en: 'English (English)',
  ar: 'Arabic (عربى)',
  bn: 'Bengali (বাঙালি)',
  bg: 'Bulgarian (български)',
  ca: 'Catalan (Català)',
  hr: 'Croatian (Hrvatski)',
  cs: 'Czech (čeština)',
  da: 'Danish (dansk)',
  nl: 'Dutch (Nederlands)',
  eo: 'Esperanto (Esperanto)',
  et: 'Estonian (Eesti keel)',
  fil: 'Filipino (Filipino)',
  fi: 'Finnish (suomi)',
  fr: 'French (français)',
  de: 'German (Deutsche)',
  el: 'Greek (Ελληνικά)',
  he: 'Hebrew (עברית)',
  hi: 'Hindi (हिंदी)',
  hu: 'Hungarian (Magyar)',
  id: 'Indonesian (bahasa Indonesia)',
  it: 'Italian (italiano)',
  ja: 'Japanese (日本語)',
  ko: 'Korean (한국어)',
  lv: 'Latvian (Latviešu valoda)',
  lt: 'Lithuanian (Lietuvių)',
  ms: 'Malay (Melayu)',
  ml: 'Malayalam (മലയാളം)',
  mr: 'Marathi (मराठी)',
  no: 'Norwegian (norsk)',
  pl: 'Polish (Polskie)',
  pt: 'Portuguese (Português)',
  ro: 'Romanian (Română)',
  ru: 'Russian (русский)',
  sr: 'Serbian (Српски)',
  sk: 'Slovak (slovenský)',
  sl: 'Slovenian (Slovenščina)',
  es: 'Spanish (Español)',
  sv: 'Swedish (svenska)',
  tg: 'Tajik (Тоҷикӣ)',
  ta: 'Tamil (தமிழ்)',
  te: 'Telugu (తెలుగు)',
  th: 'Thai (ไทย)',
  tr: 'Turkish (Türk)',
  uk: 'Ukrainian (Українська)',
  ur: 'Urdu (اردو)',
  vi: 'Vietnamese (Tiếng Việt)',
};
