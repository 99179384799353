import React, { useEffect } from 'react';
import MobileModal from '../../MobileModal';
import SignUpModalHeader from '../common/SignUpModalHeader';
import useSignup from '../../../../hooks/auth/useSignup';
import { G0Button, PasswordStrengthMeter, trackEvent } from 'component-library';
import { OAuthSignUp } from '../OAuthSignUp';
import NexusInputText from '../../inputs/NexusInputText';
import LoadingSpinner from '../../LoadingSpinner';
import { SignUpStage } from '../../../../constants/signUpModal';
import RoleIndustries from '../RoleIndustries';
import { Modal } from 'react-daisyui';
import { Turnstile } from '@marsidev/react-turnstile';
import { toast } from 'react-toastify';
import UserRolesMobile from '../UserRoles/UserRolesMobile';
import ShouldRender from '../../../ShouldRender';
import ContinueWithEmailButton from '../SignUpModal/continueWithEmailButton';

interface MobileSignupModalProps {
  isOpen: boolean;
  title: string | null;
  handleLogIn: () => void;
  toggleOpen: (open: boolean) => void;
  onOpenEmailConfirmationModal: () => void;
}

const MobileSignupModal = ({
  isOpen,
  title,
  handleLogIn,
  toggleOpen,
  onOpenEmailConfirmationModal,
}: MobileSignupModalProps) => {
  const COMPONENT_IDENTIFIER = 'signUpModal-mobile-dashboard';

  const {
    signUp,
    shouldDisableOption,
    getValidationErrorMessage,
    signUpStage,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    selectedRole,
    selectedIndustry,
    registrationDetails,
    setRegistrationDetails,
    isRegistrationView,
    aiDocumentId,
    updateFormValue,
    continueWithEmailSubmitHandler,
    onSelectRole,
    onSelectIndustry,
  } = useSignup();

  useEffect(() => {
    trackEvent('show', COMPONENT_IDENTIFIER, 'signUp', 1, {
      isAnonymousUser: true,
    });
  }, []);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const validationError = getValidationErrorMessage(
      registrationDetails.password,
      registrationDetails.firstName,
      registrationDetails.email
    );

    if (validationError) {
      setErrorMessage(validationError);
      setIsLoading(false);
      return;
    }

    setRegistrationDetails({
      ...registrationDetails,
    });

    try {
      const error = await signUp(
        registrationDetails,
        selectedIndustry,
        selectedRole,
        aiDocumentId
      );
      if (error) {
        setIsLoading(false);
        toast.error(`Failed: ${error.message}.`, {
          theme: 'light',
        });
        return;
      }
      trackEvent('signup', COMPONENT_IDENTIFIER, 'signUp', 1, {
        isAnonymousUser: true,
        email: registrationDetails.email,
      });
      setIsLoading(false);
      toggleOpen(false);

      onOpenEmailConfirmationModal();
    } catch (error) {
      setIsLoading(false);
      toast.error(`Failed: ${error.message}.`, {
        theme: 'light',
      });
    }
  };

  const emailForm = (
    <form onSubmit={continueWithEmailSubmitHandler}>
      <hr className="border-g0-gray-100" />
      <OAuthSignUp
        disabled={shouldDisableOption(selectedRole, selectedIndustry)}
        aiDocumentId={aiDocumentId}
        role={selectedRole}
        industry={selectedIndustry}
      />
      <hr className="border-g0-gray-100" />
      <div className="flex flex-col mt-4">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ContinueWithEmailButton
            disabled={shouldDisableOption(selectedRole, selectedIndustry)}
          />
        )}
      </div>
    </form>
  );

  const registrationForm = (
    <form onSubmit={submitForm} className="mx-8">
      <Modal.Body>
        <div className="w-full">
          <NexusInputText
            labelTitle="Email"
            updateType="email"
            updateFormValue={updateFormValue}
            defaultValue={''}
            containerStyle={'mt-6'}
            placeholder="example@domain.com"
            required={true}
          />
          <NexusInputText
            labelTitle="First Name"
            updateType="firstName"
            updateFormValue={updateFormValue}
            defaultValue=""
            placeholder="Jon"
            required={true}
          />
          <NexusInputText
            labelTitle="Last Name"
            updateType="lastName"
            updateFormValue={updateFormValue}
            defaultValue=""
            placeholder="Doe"
            required={false}
          />
          <NexusInputText
            labelTitle="Password"
            updateType="password"
            type="password"
            updateFormValue={updateFormValue}
            defaultValue=""
            placeholder="***********"
            required={true}
          />
          <PasswordStrengthMeter password={registrationDetails.password} />
          <Turnstile
            siteKey={process.env.REACT_APP_TURNSTILE_SITEKEY}
            onSuccess={(token) => {
              setRegistrationDetails({
                ...registrationDetails,
                captchaToken: token,
              });
            }}
            style={{ margin: '2rem auto 0 auto' }}
          />
        </div>
      </Modal.Body>
      <Modal.Actions className="flex flex-col">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center text-red-700 mb-2">
              {errorMessage}
            </div>
            <div>
              <G0Button
                size="medium"
                type="submit"
                className="w-full flex justify-center mb-6"
              >
                Create Account
              </G0Button>
            </div>
          </>
        )}
      </Modal.Actions>
    </form>
  );

  const onClose = () => {
    toggleOpen(false);
  };

  return (
    <MobileModal isOpen={isOpen} onClose={onClose}>
      <SignUpModalHeader
        title={title}
        handleLogIn={handleLogIn}
        isRegistrationView={isRegistrationView}
      />

      <hr className="border-g0-gray-100 mt-4" />

      <ShouldRender render={signUpStage === SignUpStage.Email}>
        <div className="title-small text-g0-gray-500 mx-12 mt-4">
          Select your role<span className="text-red-500"> *</span>
        </div>
        <UserRolesMobile
          onSelectRole={onSelectRole}
          selectedRole={selectedRole}
        />
        <RoleIndustries
          role={selectedRole}
          className="mb-4"
          selectedIndustry={selectedIndustry}
          onSelectIndustry={onSelectIndustry}
        />
      </ShouldRender>

      {signUpStage === SignUpStage.Email && emailForm}

      {isRegistrationView && registrationForm}
    </MobileModal>
  );
};

export default MobileSignupModal;
