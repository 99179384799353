import React from 'react';
import { ResultsTitleBannerProps } from './ResultsTitleBanner.types';
import './ResultsTitleBanner.css';

export const getBannerStyles = (
  predictedClass: ResultsTitleBannerProps['predictedClass'],
  variant: ResultsTitleBannerProps['variant']
) => {
  const isNexusVersion = variant === 'nexus';

  const bannerStyles = {
    ai: {
      background: isNexusVersion
        ? 'var(--ai-result-bg-yellow-nexus)'
        : 'var(--ai-result-bg-yellow)',
      color: isNexusVersion
        ? 'var(--ai-result-yellow-nexus)'
        : 'var(--ai-result-yellow)',
      text: 'ai generated',
    },
    mixed: {
      background: isNexusVersion
        ? 'var(--mixed-result-bg-purple-nexus)'
        : 'var(--mixed-result-bg-purple)',
      color: isNexusVersion
        ? 'var(--mixed-result-purple-nexus)'
        : 'var(--mixed-result-purple)',
      text: 'mix of ai and human',
    },
    human: {
      background: isNexusVersion
        ? 'var(--human-result-bg-green-nexus)'
        : 'var(--human-result-bg-green)',
      color: isNexusVersion
        ? 'var(--human-result-green-nexus)'
        : 'var(--human-result-green)',
      text: 'human',
    },
  };

  return bannerStyles[predictedClass];
};

const ResultsTitleBanner: React.FC<ResultsTitleBannerProps> = ({
  predictedClass,
  className = '',
  variant = 'legacy',
}) => {
  const currentStyle = getBannerStyles(predictedClass, variant);

  return (
    <div
      data-testid="results-title-banner"
      className={`results-title-banner ${className}`}
      style={{ background: currentStyle?.background }}
    >
      <span
        className="results-title-banner-title"
        style={{ color: currentStyle?.color }}
      >
        {currentStyle?.text}
      </span>
    </div>
  );
};

export default ResultsTitleBanner;
