/*global chrome*/

const EXTENSION_KEY = 'kgobeoibakoahbfnlficpmibdbkdchap';
export const REQUEST_FROM_EXTENSION = 'source_extension';

export const EXTENSION_LOGIN_SUCCESSFUL_PAGE =
  'https://gptzero.me/extension/login-complete';

export const isMobileBrowser = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Mobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  }
  return false;
};

export const sendTokenToChromeExtension = ({ token, plan }) => {
  if (!window.chrome || isMobileBrowser()) {
    return;
  }
  const domain = window.location.hostname;
  chrome.runtime &&
    chrome.runtime.sendMessage(
      EXTENSION_KEY,
      { token, domain, plan },
      (response) => {
        if (response && !response.success) {
          console.error('error sending message', response);
          return response;
        }
      }
    );
};

export const logoutUserFromChromeExtension = () => {
  if (!window.chrome || isMobileBrowser()) {
    return;
  }
  chrome.runtime &&
    chrome.runtime.sendMessage(EXTENSION_KEY, { ask: 'LOGOUT' });
};

/**
 * Send user plan data to the chrome extension to be used when highlighting text
 * @param plan
 * @param charLimit
 * @param batch_Limit
 * @param apiKey
 * @param id
 */
export const sendUserPlanDataToChromeExtension = ({
  plan,
  charLimit,
  batchLimit,
  apiKey,
  id,
}) => {
  if (!window.chrome || isMobileBrowser()) {
    return;
  }
  chrome.runtime &&
    chrome.runtime.sendMessage(
      EXTENSION_KEY,
      { plan, charLimit, batchLimit, apiKey, id },
      (response) => {
        if (response && !response.success) {
          console.error('error sending message', response);
          return response;
        }
      }
    );
};
