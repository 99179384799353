import React from 'react';
import { G0ButtonProps } from './types';
import { getShapeClasses, getSizeClasses, getVariantClasses } from './helpers';
const G0Button = ({
  children,
  className = '',
  variant = 'primary',
  size = 'medium',
  shape = 'rounded',
  disabled = false,
  active = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type,
  ...props
}: G0ButtonProps) => {
  const variantClasses = getVariantClasses(variant, disabled, active);
  const sizeClasses = getSizeClasses(size);
  const shapeClasses = getShapeClasses(shape);

  return (
    <button
      className={`flex items-center gap-2 ${variantClasses} ${sizeClasses} ${shapeClasses} ${className}`}
      {...props}
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};

export default G0Button;
