export const CLAIM_SOURCE_STANCES = {
  strongly_support: 'strongly support',
  slightly_agree: 'slightly agree',
  strongly_contradict: 'strongly contradict',
  slightly_disagree: 'slightly disagree',
  neutral: 'neutral',
};

export const CLAIM_ANNOTATIONS = {
  supported_by_sources: 'Supported by sources',
  supported_by_some_sources: 'Supported by some sources',
  debated_by_sources: 'Debated by sources',
  contradicted_by_sources: 'Contradicted by sources',
  contradicted_by_some_sources: 'Contradicted by some sources',
  neutral: 'No sources found',
};

export const CLAIM_ANNOTATION_COLORS = {
  supported_by_sources: 'bg-g0-light-blue-200',
  supported_by_some_sources: 'bg-g0-light-blue-100',
  debated_by_sources: 'bg-g0-mixed-purple-200',
  contradicted_by_sources: 'bg-g0-red-200',
  contradicted_by_some_sources: 'bg-g0-red-100',
  neutral: 'bg-g0-gray-100',
};

export const SOURCE_STANCES = {
  'strongly support': {
    text: 'Supports claim',
    color: 'bg-g0-light-blue-600',
  },
  'slightly agree': {
    text: 'May support claim',
    color: 'bg-g0-light-blue-600',
  },
  'strongly contradict': {
    text: 'Contradicts claim',
    color: 'bg-g0-red-600',
  },
  'slightly disagree': {
    text: 'May contradict claim',
    color: 'bg-g0-red-600',
  },
  neutral: {
    text: 'Neutral to claim',
    color: 'bg-g0-gray-400',
  },
};
