import { ClaimSource, SourcesScanState } from "../../../app/store/aiUtilitiesHubSlice/types";
import { CLAIM_SOURCE_STANCES } from '../resultsViews/SourcesScanView/constants';

export function getClaimAnnotation(sources: ClaimSource[]): string {
  const sourceStances = sources.map((source) => source.stance);

  const numSupportingSources = sourceStances.filter((stance) => {
    return [
      CLAIM_SOURCE_STANCES['strongly_support'],
      CLAIM_SOURCE_STANCES['slightly_agree'],
    ].includes(stance);
  }).length;

  const numContradictingSources = sourceStances.filter((stance) => {
    return [
      CLAIM_SOURCE_STANCES['strongly_contradict'],
      CLAIM_SOURCE_STANCES['slightly_disagree'],
    ].includes(stance);
  }).length;

  const sourceConditions = [
    {
      condition: numSupportingSources >= 3 && numContradictingSources === 0,
      key: 'supported_by_sources',
    },
    {
      condition:
        numSupportingSources > 0 &&
        numSupportingSources < 3 &&
        numContradictingSources === 0,
      key: 'supported_by_some_sources',
    },
    {
      condition: numSupportingSources > 0 && numContradictingSources > 0,
      key: 'debated_by_sources',
    },
    {
      condition:
        numContradictingSources > 0 &&
        numContradictingSources < 3 &&
        numSupportingSources === 0,
      key: 'contradicted_by_some_sources',
    },
    {
      condition: numContradictingSources >= 3 && numSupportingSources === 0,
      key: 'contradicted_by_sources',
    },
  ];

  const match = sourceConditions.find(({ condition }) => condition) || {
    key: 'neutral',
  };

  return match.key;
}

export function sortClaimResults(results: SourcesScanState['data']['results']) {

  const filteredResults = results.filter((result) => (
    typeof result.claim.start === 'number' &&
    typeof result.claim.end === 'number'
  ));
  
  return filteredResults.sort((a, b) => a.claim.start - b.claim.start);
}
