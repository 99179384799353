import { useContext, useState, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchOriginalScanApi } from '../../components/Documents/api';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import {
  PLAGIARISM_POLL_LIMIT,
  scan_completion_status,
} from '../../constants/common';
import {
  setPlagiarismScanResults,
  setPlagiarismScanStatus,
  setScanTitle,
  setScanAuthor,
  setScanDate,
  setLatestInputText,
  setMultifilePlagiarismScanResults,
  setInputTextChangedSinceScan,
} from '../../app/store/aiUtilitiesHubSlice';
import { ScanStatus } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';

const usePollPlagiarismResults = () => {
  const whitelabelConfig = useContext(WhitelabelContext);
  const [isComplete, setIsComplete] = useState(false);
  const dispatch = useDispatch();
  const pollCounter = useRef(0);

  const maxPolls = PLAGIARISM_POLL_LIMIT;

  const successStatusPayload = { status: ScanStatus.success };
  const errorStatusPayload = { status: ScanStatus.error };

  const poll = useMemo(() => {
    return async ({
      scanId,
      scanTitle,
      scanAuthor,
      scanDate,
      isMultiFileUpload,
    }) => {
      if (!scanId || (isComplete && pollCounter > 0)) return;

      pollCounter.current += 1;

      try {
        const scan = await fetchOriginalScanApi({
          baseUrl: whitelabelConfig.backendHostname,
          originalScanId: scanId,
        });

        const scanStatus = scan?.plagiarism_document?.[0]?.status;

        if (scanStatus === scan_completion_status.COMPLETE) {
          const { status, outputJson, createdAt, inputText, uuid } =
            scan.plagiarism_document[0];
          const plagiarismDocuments = outputJson;
          plagiarismDocuments.status = status;
          plagiarismDocuments.uuid = uuid;
          plagiarismDocuments.createdAt = createdAt;
          plagiarismDocuments.inputText = inputText;

          const payload = {
            data: plagiarismDocuments,
            status: ScanStatus.success,
            serverStatusCode: status,
          };

          setIsComplete(true);
          toast.success('Your plagiarism scan is complete!');

          if (isMultiFileUpload) {
            dispatch(
              setMultifilePlagiarismScanResults({
                fileName: scanTitle,
                ...payload,
              })
            );
          } else {
            dispatch(setPlagiarismScanResults(payload));
            dispatch(setInputTextChangedSinceScan(false));
            dispatch(setPlagiarismScanStatus(successStatusPayload));
            dispatch(setLatestInputText({ latestInputText: inputText }));
            dispatch(setScanTitle({ scanTitle }));

            if (scanAuthor) {
              dispatch(setScanAuthor({ scanAuthor }));
            }

            if (scanDate) {
              dispatch(setScanDate({ scanDate }));
            }
          }
        } else if (scanStatus === scan_completion_status.FAILED) {
          const error = scan?.plagiarism_document?.[0]?.error;
          toast.error(
            `Plagiarism processing failed. ${
              error ? error : 'Please try again.'
            }`
          );
          dispatch(setPlagiarismScanStatus(errorStatusPayload));
        } else if (pollCounter.current < maxPolls) {
          setTimeout(
            () =>
              poll({
                scanId,
                scanTitle,
                scanAuthor,
                scanDate,
                isMultiFileUpload,
              }),
            5000
          ); // Poll every 5 seconds
        } else {
          toast.error(
            'Processing is taking a while. Get the plagiarism results in document history later.'
          );
          dispatch(setPlagiarismScanStatus(errorStatusPayload));
        }
      } catch (error) {
        toast.error('An error occurred while polling plagiarism results.');
        dispatch(setPlagiarismScanStatus(errorStatusPayload));
      }
    };
  }, [isComplete, whitelabelConfig.backendHostname, dispatch]);

  const startPolling = ({
    scanId,
    scanTitle,
    scanAuthor,
    scanDate,
    isMultiFileUpload = false,
  }) => {
    setIsComplete(false);
    pollCounter.current = 0;
    poll({ scanId, scanTitle, scanAuthor, scanDate, isMultiFileUpload });
  };

  return {
    startPolling,
    isComplete,
  };
};

export default usePollPlagiarismResults;
