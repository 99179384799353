import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useScanV3 from '../useScanV3';
import usePollPlagiarismResults from './usePollPlagiarismResults';
import { ScanStatus } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import {
  setHasViewedPlagiarismScanResults,
  setOverallScanId,
  setPlagiarismScanStatus,
} from '../../app/store/aiUtilitiesHubSlice';
import WhitelabelContext from '../../contexts/WhitelabelContext';

export function useRequestFilePlagiarismScan() {
  const aiUtilitiesHub = useSelector((state) => state?.aiUtilitiesHub);
  const { overallScanId } = aiUtilitiesHub || {};

  const whitelabelConfig = useContext(WhitelabelContext);
  const { backendHostname, dashboardHostname } = whitelabelConfig;

  const dispatch = useDispatch();

  const { createScan } = useScanV3();

  const { startPolling } = usePollPlagiarismResults();

  const handleResponseNotOkay = async (result) => {
    let serverErrorMessage =
      'Please check that your file is an allowed type, try a different file, or copy-and-paste the text into the input box';
    if (result.data && result.data.error) {
      serverErrorMessage = result.data.error;
    }

    let toastOptions = {};
    if (result.status === 429) {
      toastOptions.onClick = () => {
        window.open(`${dashboardHostname}/app/subscription-plans`, '_blank');
      };
    }
    toast.error(
      '🤔 We got an error processing your text: ' + serverErrorMessage.error,
      toastOptions
    );

    const errorStatusPayload = { status: ScanStatus.error };

    dispatch(setPlagiarismScanStatus(errorStatusPayload));
  };

  const requestFilePlagiarismScan = async ({
    scanId,
    files,
    projectId,
    checkerProfile,
    isMultiFileUpload = false,
  }) => {
    const fileToScan = files[0];
    const scanTitle = fileToScan.name;

    if (!backendHostname) {
      return;
    }

    const loadingStatusPayload = { status: ScanStatus.loading };

    dispatch(setPlagiarismScanStatus(loadingStatusPayload));
    dispatch(setHasViewedPlagiarismScanResults({ hasViewedResults: false }));

    let newScanId;
    let scanDate;
    let scanAuthor;

    if (!overallScanId && !scanId) {
      const {
        data: { id, author, createdAt },
      } = await createScan({ title: scanTitle, aiDocumentId: null });

      dispatch(setOverallScanId({ overallScanId: id }));

      newScanId = id;
      scanAuthor = author;
      scanDate = createdAt;
    }

    try {
      const data = new FormData();
      data.append('scanId', scanId || newScanId);
      data.append('files', fileToScan);

      if (projectId) {
        data.append('projectId', projectId);
      }

      const result = await fetch(
        `${backendHostname}${checkerProfile.fileRoute}`,
        {
          method: 'POST',
          credentials: 'include',
          body: data,
        }
      );

      if (!result.ok) {
        return handleResponseNotOkay(result);
      }

      const response = await result.json();

      if (response.scanId) {
        startPolling({
          scanId: response.scanId,
          scanTitle,
          scanAuthor,
          scanDate,
          isMultiFileUpload,
        });
      } else {
        throw new Error('Scan ID not returned');
      }
    } catch (err) {
      toast.error(
        err.message ?? 'We got an issue scanning for plagiarism matches'
      );
      const errorStatusPayload = { status: ScanStatus.error };

      dispatch(setPlagiarismScanStatus(errorStatusPayload));
    }
  };

  return {
    requestFilePlagiarismScan,
  };
}

export default useRequestFilePlagiarismScan;
