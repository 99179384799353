import { getDecodedTokenFromCookie } from './tokenUtils';
import { localStorageItems } from '../constants/localStorage';

export function retrieveDeviceId() {
  const deviceId = localStorage.getItem(localStorageItems.GPTZERO_DEVICE_ID);
  if (!deviceId) {
    return setDeviceId();
  }
  return deviceId;
}

export function getDeviceIdFromSeachParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('deviceId');
}

export function setDeviceId() {
  const decodedToken = getDecodedTokenFromCookie('accessToken4');
  let deviceId = '';
  if (decodedToken?.email) {
    deviceId = decodedToken.email;
  } else {
    const amplitudeId = getDeviceIdFromSeachParams();
    deviceId = amplitudeId || generateUUID();
  }
  localStorage.setItem(localStorageItems.GPTZERO_DEVICE_ID, deviceId);
  return deviceId;
}

export function generateUUID() {
  // Code from https://stackoverflow.com/questions/49663720/equivalent-to-pythons-uuid-uuid4-hex-in-javascript#comment86337474_49663720
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function getViewportSizeGroup() {
  const width = window.innerWidth;
  if (width < 384) {
    return '2xs';
  }
  if (width < 512) {
    return 'xs';
  }
  if (width < 640) {
    return 'sm';
  }
  if (width < 768) {
    return 'md';
  }
  if (width < 1024) {
    return 'lg';
  }
  if (width < 1280) {
    return 'xl';
  }
  if (width < 1536) {
    return '2xl';
  }
  if (width < 1792) {
    return '3xl';
  } else {
    return '4xl';
  }
}

export function userIsLoggedIn() {
  const decodedToken = getDecodedTokenFromCookie('accessToken4');
  return decodedToken?.email;
}
