import React, { useState } from 'react';
import styled from 'styled-components';
import { NPSSurveyProps } from './NPSSurvey.types';
import Button from '../../atoms/button';
import { LoadingSpinner } from '../../atoms/loadingSpinner';

const Container = styled.form`
  width: 100%;
  padding: 5px 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const LikelihoodButton = styled.button<{ isSelected: boolean }>`
  margin: 0 2px;
  padding: 10px 20px;
  border: ${({ isSelected }) =>
    isSelected ? '1.5px solid #000' : '1px solid #ccc'};
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const LikelihoodButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FeedbackTextarea = styled.textarea`
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-family: sans-serif;
`;

const Header = styled.div`
  margin: 0;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: fit;
`;

const NUM_BUTTONS = 10;

const NPSSurvey: React.FC<NPSSurveyProps> = ({
  featureName = 'GPTZero',
  onSubmit,
  isLoading,
  className,
}) => {
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [feedback, setFeedback] = useState('');

  const handleClick = (value: number) => {
    selectedButton === value
      ? setSelectedButton(null)
      : setSelectedButton(value);
  };

  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(selectedButton, feedback);
    setSelectedButton(null);
    setFeedback('');
  };

  return (
    <Container
      data-testid="NPSSurvey"
      className={className}
      onSubmit={handleSubmit}
    >
      <Header>{`How likely are you to recommend ${featureName} to a friend or colleague?`}</Header>
      <LikelihoodButtonsContainer>
        {[...Array(NUM_BUTTONS)].map((_, index) => {
          const score = index + 1;
          return (
            <LikelihoodButton
              key={index}
              isSelected={selectedButton === score}
              onClick={() => handleClick(score)}
              type="button"
            >
              {score}
            </LikelihoodButton>
          );
        })}
      </LikelihoodButtonsContainer>
      <Header>{`Anything else you'd like us to know about your experience?`}</Header>
      <FeedbackTextarea value={feedback} onChange={handleFeedbackChange} />
      <ButtonContainer>
        <Button type="submit">
          {isLoading && <LoadingSpinner />}
          Submit
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default NPSSurvey;
