function getPreviousAndNextResetDates() {
  let previousUsageResetValue = localStorage.getItem('last_time_usage_reset');

  if (previousUsageResetValue === 'null' || !previousUsageResetValue) {
    previousUsageResetValue = localStorage.getItem('created_at');
  }
  if (previousUsageResetValue === 'null' || !previousUsageResetValue) {
    // If no previous usage reset date is found, return empty object
    return {};
  }

  const previousUsageReset = new Date(previousUsageResetValue);

  // nextUsageReset is in 31 days
  const nextUsageReset = new Date(previousUsageReset);
  nextUsageReset.setDate(previousUsageReset.getDate() + 31);

  // Get date string in the format "Month Day, Year"
  const previousUsageResetString = previousUsageReset.toLocaleDateString(
    'en-US',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  );
  const nextUsageResetString = nextUsageReset.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return {
    previousUsageResetValue: previousUsageReset.toISOString(),
    nextUsageResetValue: nextUsageReset.toISOString(),
    previousUsageResetString,
    nextUsageResetString,
  };
}

function formatTimestampString(dateString, format) {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  const formattedDate = format
    .replace('dd', day)
    .replace('mm', month)
    .replace('yyyy', year)
    .replace('Mon', getMonthAbbreviation(month))
    .replace('HH', hours)
    .replace('MM', minutes)
    .replace('SS', seconds);

  return formattedDate;
}

function getMonthAbbreviation(month) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return months[Number(month) - 1];
}

function daysBetweenDates(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  return Math.round(Math.abs((date2 - date1) / oneDay));
}

function checkDateRange(date1, date2) {
  const diffDays = daysBetweenDates(date1, date2);

  return diffDays;
}

function formatDate(date) {
  let formattedDate;
  if (!date) {
    formattedDate = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
    });
  } else {
    formattedDate = new Date(date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
    });
  }
  return formattedDate;
}

function formatTime(datetime) {
  if (!datetime) {
    return null;
  }
  return new Date(datetime).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    hourCycle: 'h12',
  });
}

const today = () => {
  const date = new Date();
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const addMonths = (timestamp, months) => {
  const date = new Date(timestamp);

  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const newMonth = currentMonth + months;
  const newYear = currentYear + Math.floor(newMonth / 12);

  date.setMonth(newMonth % 12);
  date.setFullYear(newYear);

  return date;
};

const epochInSecondsToDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

module.exports = {
  getPreviousAndNextResetDates,
  formatTimestampString,
  checkDateRange,
  formatDate,
  formatTime,
  today,
  addMonths,
  epochInSecondsToDate,
};
