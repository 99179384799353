import React from 'react';
import { ParagraphProps } from './Paragraph.types';
import './Paragraph.css';

const Paragraph: React.FC<ParagraphProps> = ({ children, className }) => (
  <div data-testid="paragraph" className={`paragraph ${className || ''}`}>
    {children}
  </div>
);

export default Paragraph;
