import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import {
  setOverallScanId,
  setBasicScanStatus,
  setBasicScanResults,
  setAdvancedScanResults,
  setAdvancedScanStatus,
  setScanTitle,
  setScanAuthor,
  setScanDate,
  setLatestInputText,
  setMultiFileServerResponse,
  setHasViewedAdvancedScanResults,
  setHasViewedBasicScanResults,
} from '../../app/store/aiUtilitiesHubSlice';
import {
  ScanStatus,
  ScanTypes,
} from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import useScanV3 from '../useScanV3';
import { trackEventV2 } from '../../utils/tracking';
import useUpdateScanData from '../../features/aiUtilitiesHub/hooks/useUpdateScan';

export function useRequestFileAiScan() {
  const COMPONENT_IDENTIFIER = 'requestFileAiScan';
  const aiUtilitiesHub = useSelector((state) => state?.aiUtilitiesHub);
  let { requestedScanTypes, overallScanId } = aiUtilitiesHub || {};
  const { setScanResults } = useUpdateScanData();

  const { isAnonymousUser } = useSelector(
    // @ts-ignore
    (state) => state?.auth
  );

  const whitelabelConfig = useContext(WhitelabelContext);
  const { backendHostname } = whitelabelConfig;

  const dispatch = useDispatch();

  const { createScan } = useScanV3();

  const requestFileAiScan = async ({
    scanId,
    files,
    checkerProfile,
    projectId,
    isQuickAiScan = false,
    editorDocumentId = null,
    isMultiFileUpload = false,
    multiFileCheckedStates = {},
  }) => {
    const fileToScan = files[0];
    const scanTitle = fileToScan.name;

    if (isMultiFileUpload) {
      requestedScanTypes = Object.keys(multiFileCheckedStates).filter(
        (key) => multiFileCheckedStates[key]
      );
    }

    if (!backendHostname || !fileToScan) {
      return;
    }

    const advancedScanRequested = isQuickAiScan
      ? false
      : requestedScanTypes.includes(ScanTypes.advanced);

    const loadingStatusPayload = { status: ScanStatus.loading };
    dispatch(
      advancedScanRequested
        ? setAdvancedScanStatus(loadingStatusPayload)
        : setBasicScanStatus(loadingStatusPayload)
    );

    const hasViewedResultsPayload = { hasViewedResults: false };
    dispatch(
      advancedScanRequested
        ? setHasViewedAdvancedScanResults(hasViewedResultsPayload)
        : setHasViewedBasicScanResults(hasViewedResultsPayload)
    );

    let newScanId;
    let scanDate;
    let scanAuthor;

    // If it's a multi-file upload, we need to create a new scan for each file
    if (!scanId && (!overallScanId || isMultiFileUpload)) {
      try {
        const {
          data: { id, author, createdAt },
        } = await createScan({
          title: scanTitle,
          aiDocumentId: null,
          projectId,
        });
        dispatch(setOverallScanId({ overallScanId: id }));

        newScanId = id;
        scanAuthor = author;
        scanDate = createdAt;
      } catch (error) {
        const payload = {
          data: null,
          error: error.message,
          status: ScanStatus.error,
          serverStatusCode: error.response?.status || 500,
        };

        if (isMultiFileUpload) {
          dispatch(
            setMultiFileServerResponse({
              fileName: scanTitle,
              response: {
                serverResponse: payload,
                serverStatusCode: payload.serverStatusCode,
                serverErrorMessage: payload.error,
                fileName: scanTitle,
                author: scanAuthor,
                scanDate: scanDate,
                scanId: scanId || newScanId,
                hasAdvancedScan: advancedScanRequested,
              },
            })
          );
        } else {
          dispatch(
            advancedScanRequested
              ? setAdvancedScanResults(payload)
              : setBasicScanResults(payload)
          );
        }
      }
    }

    let scanIdToUse = scanId || newScanId;

    if (!isMultiFileUpload) {
      scanIdToUse = scanIdToUse || overallScanId;
    }

    try {
      const data = new FormData();
      data.append('scanId', scanIdToUse);
      data.append('files', fileToScan);
      data.append('multilingual', true);
      data.append('writing_stats_required', 'true');
      data.append('interpretability_required', advancedScanRequested);

      if (projectId) {
        data.append('projectId', projectId);
      }

      const response = await fetch(
        `${backendHostname}${checkerProfile.fileRoute}`,
        {
          body: data,
          method: 'POST',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const { error } = await response.json();

        const payload = {
          error,
          data: null,
          status: ScanStatus.error,
          serverStatusCode: response.status,
        };

        if (isMultiFileUpload) {
          dispatch(
            setMultiFileServerResponse({
              fileName: scanTitle,
              response: {
                serverResponse: payload,
                serverStatusCode: payload.serverStatusCode,
                serverErrorMessage: payload.error,
                fileName: scanTitle,
                author: scanAuthor,
                scanDate: scanDate,
                scanId: scanIdToUse,
                hasAdvancedScan: advancedScanRequested,
              },
            })
          );
        } else {
          dispatch(
            advancedScanRequested
              ? setAdvancedScanResults(payload)
              : setBasicScanResults(payload)
          );
        }
        return;
      }

      const { documents, version } = await response.json();

      trackEventV2({
        action: 'results',
        component: COMPONENT_IDENTIFIER,
        element: 'aiScan',
        options: {
          plan: localStorage.getItem('plan'),
          isAnonymousUser,
        },
      });

      const resultsPayload = {
        version,
        data: documents[0],
        status: ScanStatus.success,
        serverStatusCode: response.status,
      };

      if (isMultiFileUpload) {
        dispatch(
          setMultiFileServerResponse({
            fileName: scanTitle,
            response: {
              serverResponse: resultsPayload,
              serverStatusCode: response.status,
              serverErrorMessage: '',
              fileName: scanTitle,
              author: scanAuthor,
              scanDate: scanDate,
              scanId: scanIdToUse,
              hasAdvancedScan: advancedScanRequested,
            },
          })
        );
      } else {
        setScanResults(resultsPayload, advancedScanRequested);
      }

      dispatch(setLatestInputText({ latestInputText: documents[0].inputText }));

      dispatch(setScanTitle({ scanTitle }));

      if (scanAuthor) {
        dispatch(setScanAuthor({ scanAuthor }));
      }

      if (scanDate) {
        dispatch(setScanDate({ scanDate }));
      }
    } catch (error) {
      const payload = {
        data: null,
        error: error.message,
        status: ScanStatus.error,
        serverStatusCode: error.response?.status || 500,
      };

      if (isMultiFileUpload) {
        dispatch(
          setMultiFileServerResponse({
            fileName: scanTitle,
            response: {
              serverResponse: payload,
              serverStatusCode: payload.serverStatusCode,
              serverErrorMessage: payload.error,
              fileName: scanTitle,
              author: scanAuthor,
              scanDate: scanDate,
              scanId: scanIdToUse,
              hasAdvancedScan: advancedScanRequested,
            },
          })
        );
      } else {
        dispatch(
          advancedScanRequested
            ? setAdvancedScanResults(payload)
            : setBasicScanResults(payload)
        );
      }
    }
  };

  return {
    requestFileAiScan,
  };
}

export default useRequestFileAiScan;
