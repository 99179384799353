import React from 'react';
import './checkbox.css';

export interface CheckboxProps {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked,
  onChange,
  label,
  className,
}) => {
  return (
    <div className={`custom-checkbox-container ${className}`}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="custom-checkbox-input"
      />
      <label htmlFor={id} className="custom-checkbox-label" />
      {label && (
        <label htmlFor={id} className="custom-checkbox-text">
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
