import React from 'react';
import SentenceImpactScore from '../SentenceImpactScore';
import { ISentence } from '../../../types/types';

const tooltipClass = `text-[16px] translate-x-[-50%] left-1/2 z-[100] before:content-[''] 
  before:absolute before:left-1/2 before:translate-x-[-50%] before:w-[16px] 
  before:h-[16px] before:rotate-45 before:bg-white before:border-b before:border-r 
  before:border-[#e5e5e5] before:z-10 before:bottom-[-8px]`;

interface TooltipProps {
  data: ISentence;
  showSentence?: boolean;
  show?: boolean;
  setShow: (show: boolean) => void;
  position?: string;
  className?: string;
}

const AdvancedScanTooltip = ({
  data,
  showSentence = false,
  show = false,
  setShow,
  className = 'bottom-6',
}: TooltipProps) => {
  return (
    <>
      {show && (
        <div
          onMouseLeave={() => setShow(false)}
          className={`z-50 absolute border bg-white rounded-md ${tooltipClass} ${className} py-3 px-4 shadow`}
        >
          <div className={'flex gap-2 items-center'}>
            <SentenceImpactScore sentence={data} showText />
          </div>
          {showSentence && (
            <p className={'mt-3 text-[13px]'}>{data.sentence}</p>
          )}
        </div>
      )}
    </>
  );
};

export default AdvancedScanTooltip;
