import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import { useLocalStorage } from '@uidotdev/usehooks';
import { setCookie, getCookieDomain } from '../../utils/cookieUtils';
import {
  saveProfileToLocalStorage,
  sendUserWelcomeMessageOnFirstLogin,
  activateTeacherWhitelistOnFirstLogin,
} from '../../utils/profileUtils';
import { sendTokenToChromeExtension } from '../../utils/extensionUtils';
import supabase from '../../utils/supabaseClient';
import { setUserId, setUserProperties } from '../../utils/tracking';
import LoginForm from './components/LoginForm';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import AuthenticationTemplate from '../../containers/AuthenticationTemplate';
import { Links } from '../../constants/links';
import { trackEventV2 } from '../../utils/tracking';

import { HUBSPOT_EVENTS, trackHubspotEvent } from '../../utils/hubspot';
import { isNewUser } from '../../helpers/user';
import {
  getLocalStorageItem,
  replaceWithJSONParseableString,
} from '../../hooks/useLocalStorage';
import LocalStorageKeys from '../../constants/localStorageKeys';
import { dashboardRoutes } from '../../constants/dashboardRoutes';
import { setLocalStorageItem } from '../../helpers/common';
import useAuthReduxState from '../../hooks/auth/useAuthReduxState';
import { useSelector } from 'react-redux';
import { logoutUser } from '../../app/auth';
import { CheckEmailModal } from '../../components/v2/AuthModals/CheckEmailModal';

function Login() {
  const { setIsAnonymousUser } = useAuthReduxState();

  const COMPONENT_LABEL = 'loginPage';
  const EMAIL_TOKEN = getLocalStorageItem(LocalStorageKeys.email);

  replaceWithJSONParseableString(LocalStorageKeys.referralName);
  const [referralName, storeReferralName] = useLocalStorage(
    LocalStorageKeys.referralName
  );

  let INITIAL_LOGIN_OBJ = {
    password: '',
    emailId: '',
  };

  if (EMAIL_TOKEN) {
    INITIAL_LOGIN_OBJ.emailId = EMAIL_TOKEN;
  }

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const whitelabelConfig = useContext(WhitelabelContext);
  const { isAnonymousUser } = useAuthReduxState();

  const { setIdentity: setUserIdentityInHubspot } = useTrackingCode();

  useEffect(() => {
    if (isAnonymousUser) {
      logoutUser();
    }
  }, [isAnonymousUser]);

  useEffect(() => {
    const type = searchParams.get('type');
    if (type === 'OAuth-success') {
      setLoading(true);
    }
    trackEventV2({
      action: 'load',
      component: COMPONENT_LABEL,
      options: { type, isAnonymousUser },
    });
  }, []);

  const getFullUrlAndQueryParamsFromPath = (path) => {
    const fullUrl = new URL(window.location.origin + path);
    const searchParams = fullUrl.searchParams;
    return { fullUrl, searchParams };
  };

  const signInUser = async (sessionData) => {
    const { data: returnedUser, error: userFetchError } = await supabase
      .from('profiles')
      .select('id')
      .eq('id', sessionData.user.id);

    if (userFetchError || !returnedUser || returnedUser.length === 0) {
      setLoading(false);
      setErrorMessage('Login failed. Please check your credentials.');
      return;
    }

    let plan = getLocalStorageItem(LocalStorageKeys.plan);
    sendTokenToChromeExtension({
      token: sessionData.session.access_token,
      plan,
    });

    const success = await saveUserProfileAndSessionDetails({
      sessionUserId: sessionData.session.user.id,
      sessionAccessToken: sessionData.session.access_token,
    });

    plan = getLocalStorageItem(LocalStorageKeys.plan);

    const studentWhitelistPendingInvite =
      localStorage.getItem('studentWhitelistInviteStatus') === 'pending';

    const teacherWhitelistPendingInvite =
      localStorage.getItem('teacherWhitelistInviteStatus') === 'pending';

    //first activate student whitelist on first signon
    if (studentWhitelistPendingInvite) {
      const firstLoginStudentData = await activateStudentWhitelistOnFirstLogin(
        sessionData.user.email
      );
      localStorage.setItem(
        'firstLoginStudentData',
        JSON.stringify(firstLoginStudentData)
      );
      // if student is on a free plan then set plan to Educator
      if (plan === 'Free' || plan.includes('Paused')) {
        localStorage.setItem('plan', 'Educator');
        localStorage.setItem('char_limit', 'null');
        localStorage.setItem('batch_limit', 'null');
      }
      localStorage.setItem('studentWhitelistInviteStatus', 'active');
    }

    if (teacherWhitelistPendingInvite) {
      await activateTeacherWhitelistOnFirstLogin(sessionData.user.email, plan);
      // if educator is on a free plan then set plan to Educator
      if (plan === 'Free' || plan.includes('Paused')) {
        localStorage.setItem('plan', 'Educator');
        localStorage.setItem('char_limit', 'null');
        localStorage.setItem('batch_limit', 'null');
      }
      localStorage.setItem('teacherWhitelistInviteStatus', 'active');
    }
    // Do the same as the line above, but rename the variable to success
    setLoading(false);
    if (!success) {
      console.error('Error saving profile to local storage');
      return;
    }

    if (!plan) {
      console.error('No plan found in local storage');
      return;
    }

    const hasSeenTutorial =
      getLocalStorageItem(LocalStorageKeys.hasSeenTutorial) === 'true';
    const joinPageLink = getLocalStorageItem(LocalStorageKeys.joinPageLink);

    const acceptUserInviteLink = getLocalStorageItem(
      LocalStorageKeys.acceptUserInviteLink
    );
    const hasPendingTeamInvites =
      getLocalStorageItem(LocalStorageKeys.teamInviteStatus) === 'pending';
    const isNewUserSendWelcome =
      getLocalStorageItem(LocalStorageKeys.newUserSendWelcome) === 'Y';

    const createTeamLink = getLocalStorageItem(LocalStorageKeys.createTeamLink);
    const acceptTeamInviteLink = getLocalStorageItem(
      LocalStorageKeys.acceptTeamInviteLink
    );

    if (isNewUserSendWelcome) {
      sendUserWelcomeMessageOnFirstLogin({});
    }

    const redirectToPath = searchParams.get('redirectTo');

    if (acceptTeamInviteLink) {
      setLocalStorageItem(
        LocalStorageKeys.newUserUpdateDetailsAndJoinTeam,
        'Y'
      );
      const { searchParams } =
        getFullUrlAndQueryParamsFromPath(acceptTeamInviteLink);
      navigate({
        pathname: dashboardRoutes.HOME,
        search: searchParams.toString(),
      });
    } else if (joinPageLink) {
      const { fullUrl, searchParams } =
        getFullUrlAndQueryParamsFromPath(joinPageLink);
      navigate({
        pathname: fullUrl.pathname,
        search: searchParams.toString(),
      });
    } else if (acceptUserInviteLink) {
      const { fullUrl, searchParams } =
        getFullUrlAndQueryParamsFromPath(acceptUserInviteLink);
      navigate({
        pathname: fullUrl.pathname,
        search: searchParams.toString(),
      });
    } else if (createTeamLink) {
      const { fullUrl, searchParams } =
        getFullUrlAndQueryParamsFromPath(createTeamLink);
      navigate({
        pathname: fullUrl.pathname,
        search: searchParams.toString(),
      });
    } else if (
      location.state &&
      location.state.from &&
      !location.state.from.startsWith('/login')
    ) {
      // Take them to where they were originally going
      // This only works for the Email and Password auth flow
      // since it doesn't involve any redirects
      const path = location.state.from;
      const { fullUrl, searchParams } = getFullUrlAndQueryParamsFromPath(path);
      navigate({
        pathname: fullUrl.pathname,
        search: searchParams.toString(),
      });
    } else if (redirectToPath) {
      // Take them to where they were originally going
      // This for Oauth sign in flows
      navigate(redirectToPath);
    } else if (hasPendingTeamInvites) {
      navigate(Links.LIST_TEAMS);
    } else if (!hasSeenTutorial && whitelabelConfig.features.showTutorial) {
      supabase
        .from('profiles')
        .update({
          has_seen_tutorial: true,
        })
        .eq('email', localStorage.email)
        .then((response) => {
          if (response.error) {
            console.error('Error updating user data:', response.error);
            return;
          }
          localStorage.setItem('hasSeenTutorial', 'true');
        });
      navigate(dashboardRoutes.HOME);
    } else {
      navigate(dashboardRoutes.HOME);
    }
  };

  const signInIfPossible = () => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error(error);
      }
      if (data && data.session) {
        setIsAnonymousUser(data.session.user.is_anonymous);
        supabase.auth.getUser().then((response) => {
          if (response.error) {
            console.error(response.error);
          }
          if (response.data) {
            data.user = response.data.user;
            trackHubspotEvent({
              eventName: isNewUser(data.user)
                ? HUBSPOT_EVENTS.REGISTRATION
                : HUBSPOT_EVENTS.LOGIN,
              userEmail: data?.user?.email,
            });

            if (isNewUser) {
              const provider = response.data.user.app_metadata.provider;
              trackEventV2({
                action: 'signup',
                component: COMPONENT_LABEL,
                element: `userSuccessfulSignup${provider}`,
                options: {
                  email: data?.user?.email,
                },
              });
            }

            signInUser(data);
          }
        });
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      const welcomed = localStorage.getItem('welcomed');
      if (welcomed === 'yes') return;
      if (typeof referralName === 'string' && referralName !== 'none') {
        toast.info(`${referralName} welcomes you to GPTZero!`, {
          icon: false,
          autoClose: 5000,
          theme: 'dark',
        });
        localStorage.setItem('welcomed', 'yes');
      }
    }, 500);
    const previousProfileUpdateTime = localStorage.getItem(
      'previousProfileUpdateTime'
    );
    // Only update if more than 0.5 seconds have passed since last update
    if (
      (!isAnonymousUser && !previousProfileUpdateTime) ||
      Date.now() - parseInt(previousProfileUpdateTime) >= 500
    ) {
      localStorage.setItem('previousProfileUpdateTime', Date.now());
      signInIfPossible();
    }
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (loginObj.emailId.trim() === '')
      return setErrorMessage('Email Address is required!');
    if (loginObj.password.trim() === '')
      return setErrorMessage('Password is required!');
    else {
      setLoading(true);
      let { data, error } = await supabase.auth.signInWithPassword({
        email: loginObj.emailId,
        password: loginObj.password,
      });

      if (error) {
        console.error('Error getting user from supabase:', error);
        setLoading(false);
        if (error.message === 'Email not confirmed') {
          setErrorMessage(
            'Email is not confirmed. Please check your email to confirm sign up.'
          );
        } else {
          setErrorMessage('Failed to login. Please check your credentials.');
        }
      } else {
        setIsAnonymousUser(data.user.is_anonymous);
        trackHubspotEvent({
          eventName: HUBSPOT_EVENTS.LOGIN,
          userEmail: loginObj.emailId,
        });

        signInUser(data);
      }
    }
  };
  const updateFormValue = ({ updateType, value }) => {
    setErrorMessage('');
    setLoginObj({ ...loginObj, [updateType]: value });
  };

  let searchParams = new URLSearchParams(window.location.search);
  // Check for server error
  let errorDescription = searchParams.get('error_description');
  if (errorDescription !== null) {
    // Check if description starts with "Multiple accounts"
    if (errorDescription.startsWith('Multiple accounts')) {
      errorDescription =
        'An account with the same email address already ' +
        'exists. Please try logging in with your email address and password.';
    }
    if (errorMessage !== errorDescription) {
      setErrorMessage(errorDescription);
    }
  }

  useEffect(() => {
    const showVerificationModal = searchParams.get('showVerificationModal');
    if (showVerificationModal === 'true') {
      setShowVerificationModal(true);
    }
  }, []);

  return (
    <AuthenticationTemplate
      title={'Log in'}
      subTitle={`Log in to access your ${whitelabelConfig?.name} account. `}
    >
      <LoginForm
        loginObj={loginObj}
        updateFormValue={updateFormValue}
        loading={loading}
        submitForm={submitForm}
        errorMessage={errorMessage}
      />
      <CheckEmailModal
        toggleOpen={() => setShowVerificationModal(!showVerificationModal)}
        isOpen={showVerificationModal}
      />
    </AuthenticationTemplate>
  );
}

export const activateStudentWhitelistOnFirstLogin = async (email) => {
  //first check if user has been invited in the students whitelist table

  const { data, error } = await supabase
    .from('students_whitelist')
    .select('id, inviter_profile_id, inviter_email')
    .eq('email', email)
    .eq('status', 'invited')
    .is('deleted_at', null);

  if (error) {
    console.error(
      'Error checking the invitation status of user in students_whitelist',
      error
    );
    toast.error("Looks like we can't reach our servers at the moment!", {
      theme: 'light',
    });
    return;
  }

  const student = data[0];
  let firstLoginStudentData = {
    isFirstTimeLogin: false,
    inviterProfileId: null,
    inviterEmail: null,
  };

  if (data && data.length > 0) {
    firstLoginStudentData.isFirstTimeLogin = true;
    firstLoginStudentData.inviterProfileId = student.inviter_profile_id;
    firstLoginStudentData.inviterEmail = student.inviter_email;

    const { updateError } = await supabase
      .from('students_whitelist')
      .update({
        status: 'active',
        activated_at: new Date().toISOString(),
      })
      .eq('id', student.id);

    if (updateError) {
      console.error(
        `Error activating student whitelist information: ${JSON.stringify(
          updateError
        )}`
      );
      return;
    }

    const { error: profileUpdateError } = await supabase
      .from('profiles')
      .update({
        admin_profile_id: student.inviter_profile_id,
        updated_at: new Date().toISOString(),
        student_whitelist_invite_status: 'active',
      })
      .eq('email', email);

    if (profileUpdateError) {
      console.error(
        `Error activating student whitelist information: ${JSON.stringify(
          profileUpdateError
        )}`
      );
      return;
    }
  }
  return firstLoginStudentData;
};

export const saveUserProfileAndSessionDetails = async ({
  sessionUserId,
  sessionAccessToken,
}) => {
  const { success } = await saveProfileToLocalStorage(null, sessionUserId);

  if (success) {
    setUserId();
    setUserProperties();

    setCookie('accessToken4', sessionAccessToken, '/', getCookieDomain(), 30);
  }
  return success;
};

export default Login;
