import React from 'react';
import { getCookieDomain, setCookie } from '../../utils/cookieUtils';
import supabaseClient from '../../utils/supabaseClient';
import useAuthReduxState from './useAuthReduxState';

const usePasswordLogin = () => {
  const { setIsAnonymousUser } = useAuthReduxState();

  const logIn = async (
    email: string,
    password: string,
    captchaToken: string
  ) => {
    try {
      let { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
        options: { captchaToken },
      });
      if (error) {
        return error;
      } else if (data) {
        setUpAuthState(data.session);
      }
    } catch (error) {
      throw error;
    }
  };

  const setUpAuthState = async (session) => {
    setCookie('accessToken4', session.access_token, '/', getCookieDomain(), 30);
    setIsAnonymousUser(session.user.is_anonymous);
  };

  return { logIn };
};

export default usePasswordLogin;
