import React from 'react';
import { gptZeroLoading } from '../../../assets/images';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
`;

const LoadingImage = styled.img`
  box-shadow: 3px 3px 20px 2px rgb(0, 0, 0, 0.15);
  z-index: 1;
  color: black;
  background: #649ef8;
  border-radius: 50%;
  width: 100px;
  margin: auto;
`;

export const Loading = () => (
  <LoadingContainer>
    <LoadingImage src={gptZeroLoading} />
  </LoadingContainer>
);
