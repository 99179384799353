export const Links = {
  CREATE_TEAM: `/app/tenants/create`,
  VIEW_TEAM: `/app/org-users`,
  EDUCATION_PLANS: `/app/subscription-plans/education`,
  LIST_TEAMS: `/app/tenants`,
  TENANT_GROUP_PLANS: `/app/tenants/group-plans`,
  TEAM_SUBSCRIPTIONS: `/app/org-subscriptions-plans`,
  JOIN_TEAM: `/app/org-users/join-team`,
  ACCEPT_USER_INVITE: `/app/accept-user-invite`,
  UPDATE_PASSWORD: `/update-password`,
  ACCEPT_TEAM_INVITE: `/app/accept-team-invite`,
  CONTACT_US: `/app/contact-us`,
};
