import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import SentenceImpactScore from '../SentenceImpactScore';
import Tooltip from '../AdvancedScanTooltip';
import { ISentence } from '../../../types/types';

interface CollapsibleSentenceProps {
  sentence: ISentence;
  className?: string;
}

function CollapsibleSentence({
  sentence,
  className = 'px-5 py-4',
}: CollapsibleSentenceProps) {
  const [showArrow, setShowArrow] = useState(false);
  const [showFullSentence, setShowFullSentence] = useState(false);
  const toggleArrow = () => setShowArrow(!showArrow);
  const toggleSentence = () => setShowFullSentence(!showFullSentence);

  return (
    <div
      className={`w-full relative flex cursor-pointer hover:bg-g0-gray-50 ${className} ${showFullSentence ? 'bg-g0-gray-50' : ''}`}
      onMouseEnter={toggleArrow}
      onMouseLeave={toggleArrow}
      onClick={toggleSentence}
      onKeyDown={toggleSentence}
    >
      <div className={'flex items-start gap-6 md:gap-8 w-[94%] md:w-[96%]'}>
        <SentenceImpactScore sentence={sentence} />
        <div
          className={`body-small ${showFullSentence ? 'text-black' : 'line-clamp-1 text-g0-primary'}`}
        >
          {sentence?.sentence}
        </div>
      </div>
      <div>
        {showArrow && (
          <ChevronDownIcon
            className={`w-4 absolute right-6 ${showFullSentence ? 'rotate-180' : ''}`}
          />
        )}
      </div>
    </div>
  );
}

export default CollapsibleSentence;
