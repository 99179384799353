import React from 'react';

import { SubTextProps } from './SubText.types';

import './SubText.css';

const SubText: React.FC<SubTextProps> = ({ children, className, style }) => (
  <div
    data-testid="sub-text"
    className={`sub-text ${className || ''}`}
    style={style}
  >
    {children}
  </div>
);

export default SubText;
