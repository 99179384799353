import React from 'react';
import { UpgradeCardsProps } from './UpgradeCard.types';
import Button from '../../atoms/button';
import { CheckmarkIcon } from '../../../assets/icons';
import './UpgradeCard.css';

const UpgradeCard: React.FC<UpgradeCardsProps> = ({
  planName,
  monthlyPrice,
  bulletPoints,
  onClickCta,
}) => {
  return (
    <div className="upgrade-card" onClick={onClickCta}>
      <div className="upgrade-card--kicker">{planName}</div>
      <div className="upgrade-card--price-container">
        <div className="upgrade-card--price">{monthlyPrice}</div>
        <div className="upgrade-card--per-unit">{` / month`}</div>
      </div>
      <hr className="upgrade-card--divider" />
      <div className="upgrade-card--bullets-container">
        {bulletPoints.map((bulletPoint, index) => (
          <div key={index} className="upgrade-card--bullet-container">
            <CheckmarkIcon />
            <div className="upgrade-card--bullet-point">{bulletPoint}</div>
          </div>
        ))}
      </div>
      <Button $width="100%">Upgrade</Button>
    </div>
  );
};

export default UpgradeCard;
