import React from 'react';
import { InfoIconProps } from './InfoIcon.types';
import Info from '../../icons/info';

import './InfoIcon.css';

const InfoIcon: React.FC<InfoIconProps> = ({ className = null }) => {
  return (
    <div data-testid="info-icon" className={`info-icon ${className || ''}`}>
      <Info />
    </div>
  );
};

export default InfoIcon;
