import { getWhitelabelDomain } from '../utils/whitelabelUtils';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export interface Token extends JwtPayload {
  email?: string;
}

function getCookieDomain() {
  const whitelabel = getWhitelabelDomain();
  if (process.env.NODE_ENV === 'production') {
    return whitelabel.domain;
  }
  return whitelabel.devDomain;
}

function checkCookie(name: string): boolean {
  // Check if cookie exists
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return true;
    }
  }
  return false;
}

function getCookie(name: string): string {
  // Get cookie if cookie exists
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.split('=')[1];
    }
  }
  return undefined;
}

function deleteCookie(name: string, path: string, domain: string) {
  // Delete cookie by making it expire
  if (checkCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT' +
      ';SameSite=None;Secure';
  }
}

function setCookie(
  name: string,
  value: string,
  path: string,
  domain: string,
  days?: number
) {
  // Set cookie with name=value; path=path; domain=domain; expires=current day + days ; SameSite=None; Secure;
  // Added SameSite=None;Secure; to the cookie to be accessible in the Google Classroom iframe
  let extireString = '';
  if (days) {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    extireString = ';expires=' + d.toUTCString();
  }
  document.cookie =
    name +
    '=' +
    value +
    (path ? ';path=' + path : '') +
    (domain ? ';domain=' + domain : '') +
    (extireString ? extireString : '') +
    ';SameSite=None;Secure';
}

export const getDecodedToken = (token: string): Token | null => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

export const getDecodedTokenFromCookie = (cookieName: string): Token | null => {
  const token = getCookie(cookieName);
  if (!token) {
    return null;
  }
  const decodedToken = getDecodedToken(token);
  return decodedToken;
};

// Export
export { checkCookie, deleteCookie, setCookie, getCookieDomain, getCookie };
