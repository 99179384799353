// Generated with util/create-component.js
import React from 'react';
import styled from 'styled-components';
import { APIPricingCardProps } from './APIPricingCard.types';
import { CheckmarkIcon } from '../../../assets/icons';

const Container = styled.div`
  width: 300px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
`;
const DollarAmountDiv = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const PeriodDiv = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-left: 5px;
`;

const BulletContainer = styled.div`
  display: flex;
  font-size: 12px;
  margin: 10px 0;
  align-items: center;
`;

const BulletsContainer = styled.div`
  margin: 20px 0;
  width: 70%;
`;

const Bullet = styled.div`
  margin-left: 10px;
`;

const IconContainer = styled.div`
  flex-shrink: 1;
`;

const CTAButton = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 10px 40px;
  display: flex;
  margin: 40px auto 0 auto;
  font-family: Ubuntu;
`;

const cardBullets = [
  '50 file batch uploads',
  '50k characters per doc',
  'Access to GPTZero’s premium detection model',
];

const APIPricingCard: React.FC<APIPricingCardProps> = ({
  subscriptionIsActive,
  planCost,
  wordsPerMonth,
  className,
  onClickCTA,
}) => (
  <Container
    className={className}
    onClick={onClickCTA}
    data-testid="APIPricingCard"
  >
    <div>{`${wordsPerMonth} words each month`}</div>
    <PriceContainer>
      <DollarAmountDiv>${planCost}</DollarAmountDiv>
      <PeriodDiv>/month</PeriodDiv>
    </PriceContainer>
    <BulletsContainer>
      {cardBullets.map((bulletPoint, idx) => (
        <BulletContainer key={idx}>
          <IconContainer>
            <CheckmarkIcon />
          </IconContainer>
          <Bullet>{bulletPoint}</Bullet>
        </BulletContainer>
      ))}
    </BulletsContainer>
    {subscriptionIsActive ? (
      <CTAButton> Manage subscription</CTAButton>
    ) : (
      <CTAButton>Get started</CTAButton>
    )}
  </Container>
);

export default APIPricingCard;
