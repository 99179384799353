import React, { useCallback } from 'react';
import CitationBadge from '../../atoms/citationBadge';
import { SourceResultHeadingProps } from './types';
import './styles.css';

export function SourceResultHeading({
  publisher,
  title,
  url,
  number,
  isExactMatch,
}: SourceResultHeadingProps) {
  const Title = () => (
    <a
      href={url}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={'source-result-heading-a text-g0-primary'}
    >
      {title}
    </a>
  );

  return (
    <div className={`source-result-heading-wrapper`}>
      <CitationBadge number={number} isExactMatch={isExactMatch} />
      <div className={`source-result-heading ur-text-truncate`}>
        {publisher && `${publisher}: `}
        <Title />
      </div>
    </div>
  );
}

export default SourceResultHeading;
