import { getLocalStorageItem } from '../helpers/common';

export const localStorageItems = {
  EMAIL: 'email',
  ROLE: 'orgRole',
  PLAN: 'plan',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  FULL_NAME: 'fullname',
  TEAM_PLAN: 'teamPlan',
  TRIAL_PLAN_SOURCE: 'trialPlanSource',
  RUNNING_TRIAL_PLAN_SOURCE: 'runningTrialPlanSource',
  USER_EMAIL: 'email',
  IS_TEACHER_WHITELISTED: 'isTeacherWhitelisted',
  IS_ADVISOR_ALLOWED_LIST: 'isAdvisorAllowlisted',
  HAS_EXISTING_TEAMS: 'hasExistingTeams',
  GPTZERO_DEVICE_ID: 'gptzero_device_id',
  ID: 'id',
  TRIAL_SUBSCRIPTION_STARTED_AT: 'trialSubscriptionStartedAt',
  TRIAL_SUBSCRIPTION_STARTED: 'trialSubscriptionStarted',
  TRIAL_SUBSCRIPTION_END_DATE: 'trialSubscriptionEndDate',
  NEXUS_BANNER_DISMISSED: 'nexusBannerDismissed',
  TRIAL_PLAN_PERIOD: 'trialPlanPeriod',
  RUNNING_TRIAL_PLAN_PERIOD: 'runningTrialPlanPeriod',
};

export const EMAIL = getLocalStorageItem(localStorageItems.EMAIL);
export const USER_PLAN = getLocalStorageItem(localStorageItems.PLAN);
export const ORG_ROLE = getLocalStorageItem(localStorageItems.ROLE);
export const FIRST_NAME = localStorage.getItem(localStorageItems.FIRST_NAME);
export const LAST_NAME = localStorage.getItem(localStorageItems.LAST_NAME);
export const FULL_NAME = getLocalStorageItem(localStorageItems.FULL_NAME);
export const TEAM_PLAN = getLocalStorageItem(localStorageItems.TEAM_PLAN);
export const TRIAL_PLAN_SOURCE = getLocalStorageItem(
  localStorageItems.TRIAL_PLAN_SOURCE
);
export const USER_EMAIL = getLocalStorageItem(localStorageItems.USER_EMAIL);
export const IS_TEACHER_WHITELISTED = getLocalStorageItem(
  localStorageItems.IS_TEACHER_WHITELISTED
);
export const IS_ADVISOR_ALLOWED_LIST = getLocalStorageItem(
  localStorageItems.IS_ADVISOR_ALLOWED_LIST
);
export const HAS_EXISTING_TEAMS = getLocalStorageItem(
  localStorageItems.HAS_EXISTING_TEAMS
);
export const USER_ID = getLocalStorageItem(localStorageItems.ID);

export const getUserProfileFromLocalStorage = () => {
  return {
    EMAIL: getLocalStorageItem(localStorageItems.EMAIL),
    ORG_ROLE: getLocalStorageItem(localStorageItems.ROLE),
    USER_PLAN: getLocalStorageItem(localStorageItems.PLAN),
    FIRST_NAME: getLocalStorageItem(localStorageItems.FIRST_NAME),
    LAST_NAME: getLocalStorageItem(localStorageItems.LAST_NAME),
    FULL_NAME: getLocalStorageItem(localStorageItems.FULL_NAME),
    TEAM_PLAN: getLocalStorageItem(localStorageItems.TEAM_PLAN),
    TRIAL_PLAN_SOURCE: getLocalStorageItem(localStorageItems.TRIAL_PLAN_SOURCE),
    USER_EMAIL: getLocalStorageItem(localStorageItems.USER_EMAIL),
    IS_TEACHER_WHITELISTED: getLocalStorageItem(
      localStorageItems.IS_TEACHER_WHITELISTED
    ),
    IS_ADVISOR_ALLOWED_LIST: getLocalStorageItem(
      localStorageItems.IS_ADVISOR_ALLOWED_LIST
    ),
    HAS_EXISTING_TEAMS: getLocalStorageItem(
      localStorageItems.HAS_EXISTING_TEAMS
    ),
    USER_ID: getLocalStorageItem(localStorageItems.ID),
  };
};
