// Generated with util/create-component.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { APIPricingCalculatorProps } from './APIPricingCalculator.types';

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
`;

const LineItemLabel = styled.div`
  width: 40%;
`;

const Container = styled.div`
  width: 385px;
  padding: 5px 40px;
`;

const RightMarginItem = styled.div`
  color: #000;
  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TotalPriceContainer = styled.div`
  color: #000;
  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const WordsInput = styled.input`
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.22);
  background: #fff;
  height: 24px;
  width: 35%;
  padding-left: 10px;
`;

const COST_PER_WORD_IN_CENTS = 0.15;

const calculateAdditionalCostInDollars = (
  baseWords: number,
  totalWords: number,
  costPerWordInCents: number
) => {
  const additionalWords = Math.max(totalWords - baseWords, 0);
  return ((additionalWords * costPerWordInCents) / 1000).toFixed(2);
};

const APIPricingCalculator: React.FC<APIPricingCalculatorProps> = ({
  className,
  selectedPlanCost,
  selectedPlanNumWords,
}) => {
  const [totalWords, setTotalWords] = useState<string>(
    selectedPlanNumWords.toLocaleString()
  );
  const [totalWordsInt, setTotalWordsInt] =
    useState<number>(selectedPlanNumWords);
  const [didSetCustomTotalWords, setDidSetCustomTotalWords] =
    useState<boolean>(false);

  const handleWordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDidSetCustomTotalWords(true);
    const { value } = event.target;
    const integerRegex = /^\d*$/;
    const intValue = value.replace(/,/g, '');
    if (integerRegex.test(intValue)) {
      setTotalWords(Number(intValue).toLocaleString());
      setTotalWordsInt(Number(intValue));
    }
  };

  useEffect(() => {
    if (didSetCustomTotalWords) {
      return;
    }
    setTotalWordsInt(selectedPlanNumWords);
    setTotalWords(selectedPlanNumWords.toLocaleString());
  }, [selectedPlanNumWords]);

  const additionalWordsCostInDollars = calculateAdditionalCostInDollars(
    selectedPlanNumWords,
    totalWordsInt,
    COST_PER_WORD_IN_CENTS
  );

  const totalCostInDollars = (
    Number(additionalWordsCostInDollars) + selectedPlanCost
  ).toFixed(2);

  return (
    <Container data-testid="APIPricingCalculator" className={className}>
      <LineItem>
        <LineItemLabel>
          <label htmlFor={'apiPriceCalculatorTotalWords'}>Total words</label>
        </LineItemLabel>
        <WordsInput
          id={'apiPriceCalculatorTotalWords'}
          type="text"
          value={totalWords}
          onChange={handleWordsChange}
        />
      </LineItem>
      <LineItem>
        <LineItemLabel>Additional words:</LineItemLabel>
        <RightMarginItem>{`${Math.max(
          totalWordsInt - selectedPlanNumWords,
          0
        ).toLocaleString()}`}</RightMarginItem>
      </LineItem>
      <LineItem>
        <LineItemLabel>Base plan cost:</LineItemLabel>
        <RightMarginItem>{`$${selectedPlanCost.toFixed(2)}`}</RightMarginItem>
      </LineItem>
      <LineItem>
        <LineItemLabel>Additional usage cost:</LineItemLabel>
        <RightMarginItem>{`$${additionalWordsCostInDollars}`}</RightMarginItem>
      </LineItem>
      <hr />
      <LineItem>
        <LineItemLabel>Estimated total monthly cost:</LineItemLabel>
        <TotalPriceContainer>{`$${totalCostInDollars}`}</TotalPriceContainer>
      </LineItem>
    </Container>
  );
};

export default APIPricingCalculator;
