import { createSlice } from '@reduxjs/toolkit';
import { UserInvite } from '../../types/types';

interface UserInvitesState {
  userInvites: UserInvite[];
  loading: boolean;
  error: boolean;
}

const initialState: UserInvitesState = {
  userInvites: [],
  loading: false,
  error: false,
};

export const userInvitesSlice = createSlice({
  name: 'userTeams',
  initialState,
  reducers: {
    setUserInvites(state, action) {
      state.userInvites = action.payload.userInvites;
    },
    addUserInvite(state, action) {
      state.userInvites.push(action.payload.userInvite);
    },
    removeUserInvite(state, action) {
      state.userInvites = state.userInvites.filter(
        (userInvite) => userInvite.id !== action.payload.id
      );
    },
    setLoading(state, action) {
      state.loading = action.payload.loading;
    },
    setError(state, action) {
      state.error = action.payload.error;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserInvites,
  addUserInvite,
  removeUserInvite,
  setLoading,
  setError,
} = userInvitesSlice.actions;

export default userInvitesSlice.reducer;
