import React from 'react';
import './PasswordStrengthMeter.css';
import PasswordStrengthMeterProps from './PasswordStrengthMeter.types';
import zxvbn from 'zxcvbn';
import Tooltip from '../Tooltip/Tooltip';

const createPasswordLabel = (score: number) => {
  switch (score) {
    case 0:
      return 'Weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Fair';
    case 3:
      return 'Good';
    case 4:
      return 'Strong';
    default:
      return 'Weak';
  }
};

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const testedPassword = zxvbn(password);

  return (
    <div className="password-strength-meter">
      <progress
        className={`password-strength-meter-progress strength-${createPasswordLabel(
          testedPassword.score
        )}`}
        value={testedPassword.score}
        max="4"
      />
      <br />
      <label className="password-strength-meter-label">
        <div className="classification">
          <strong>Password strength:</strong>{' '}
          {password && <>{createPasswordLabel(testedPassword.score)}</>}
        </div>
        <Tooltip content="Password must be at least 8 characters long and contain a mix of lower and uppercase letters, numbers, and special characters." />
      </label>
    </div>
  );
};

export default PasswordStrengthMeter;
