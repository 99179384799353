import React, { useEffect } from 'react';
import { Modal } from 'react-daisyui';
import { Turnstile } from '@marsidev/react-turnstile';
import { toast } from 'react-toastify';
import { OAuthSignUp } from '../OAuthSignUp';
import LoadingSpinner from '../../LoadingSpinner';
import { G0Button, trackEvent } from 'component-library';
import { PasswordStrengthMeter } from 'component-library';
import useSignup from '../../../../hooks/auth/useSignup';
import '../styles.css';
import NexusInputText from '../../inputs/NexusInputText';
import UserRoles from '../UserRoles';
import BackButton from '../BackButton';
import RoleIndustries from '../RoleIndustries';
import { SignUpStage } from '../../../../constants/signUpModal';
import SignUpModalHeader from '../common/SignUpModalHeader';
import ContinueWithEmailButton from './continueWithEmailButton';

interface SignUpModalProps {
  title: string;
  isOpen: boolean;
  toggleOpen: (open: boolean) => void;
  handleLogIn: () => void;
  onOpenEmailConfirmationModal: () => void;
}

export const SignUpModal = ({
  title,
  isOpen,
  toggleOpen,
  handleLogIn,
  onOpenEmailConfirmationModal,
}: SignUpModalProps) => {
  const COMPONENT_IDENTIFIER = 'signUpModal-dashboard';

  const {
    signUp,
    shouldDisableOption,
    getValidationErrorMessage,
    signUpStage,
    setSignUpStage,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    selectedRole,
    aiDocumentId,
    updateFormValue,
    selectedIndustry,
    isRegistrationView,
    registrationDetails,
    setRegistrationDetails,
    continueWithEmailSubmitHandler,
    onSelectRole,
    onSelectIndustry,
  } = useSignup();

  useEffect(() => {
    trackEvent('show', COMPONENT_IDENTIFIER, 'signUp', 1, {
      isAnonymousUser: true,
    });
  }, []);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const validationErrorMessage = getValidationErrorMessage(
      registrationDetails.password,
      registrationDetails.firstName,
      registrationDetails.email
    );

    if (validationErrorMessage) {
      setErrorMessage(validationErrorMessage);
      setIsLoading(false);
      return;
    }

    setRegistrationDetails({
      ...registrationDetails,
    });

    try {
      const error = await signUp(
        registrationDetails,
        selectedIndustry,
        selectedRole,
        aiDocumentId
      );
      if (error) {
        setIsLoading(false);
        toast.error(`Failed: ${error.message}.`, {
          theme: 'light',
        });
        return;
      }
      trackEvent('signup', COMPONENT_IDENTIFIER, 'signUp', 1, {
        isAnonymousUser: true,
        email: registrationDetails.email,
      });
      setIsLoading(false);
      toggleOpen(false);

      onOpenEmailConfirmationModal();
    } catch (error) {
      setIsLoading(false);
      toast.error(`Failed: ${error.message}.`, {
        theme: 'light',
      });
    }
  };

  const registrationForm = (
    <form onSubmit={submitForm} className="mx-28">
      <Modal.Body>
        <div className="w-full">
          <NexusInputText
            labelTitle="Email"
            updateType="email"
            updateFormValue={updateFormValue}
            defaultValue={''}
            containerStyle={'mt-6'}
            placeholder="example@domain.com"
            required={true}
          />
          <div className="flex gap-3">
            <NexusInputText
              labelTitle="First Name"
              updateType="firstName"
              updateFormValue={updateFormValue}
              defaultValue=""
              placeholder="John"
              required={true}
            />
            <NexusInputText
              labelTitle="Last Name"
              updateType="lastName"
              updateFormValue={updateFormValue}
              defaultValue=""
              placeholder="Doe"
              required={false}
            />
          </div>
          <NexusInputText
            labelTitle="Password"
            updateType="password"
            type="password"
            updateFormValue={updateFormValue}
            defaultValue=""
            placeholder="***********"
            required={true}
          />
          <PasswordStrengthMeter password={registrationDetails.password} />
          <Turnstile
            siteKey={process.env.REACT_APP_TURNSTILE_SITEKEY}
            onSuccess={(token) => {
              registrationDetails.captchaToken = token;
            }}
            style={{ margin: '2rem auto 0 auto' }}
          />
        </div>
      </Modal.Body>
      <Modal.Actions className="flex flex-col">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center text-red-700 mb-2">
              {errorMessage}
            </div>
            <div>
              <G0Button
                type="submit"
                className="w-full flex justify-center"
                size="medium"
              >
                CreateAccount
              </G0Button>
            </div>
          </>
        )}
      </Modal.Actions>
    </form>
  );

  const emailForm = (
    <form onSubmit={continueWithEmailSubmitHandler}>
      <Modal.Body>
        <hr className="border-g0-gray-100" />
        <OAuthSignUp
          disabled={shouldDisableOption(selectedRole, selectedIndustry)}
          aiDocumentId={aiDocumentId}
          role={selectedRole}
          industry={selectedIndustry}
        />
        <hr className="border-g0-gray-100" />
      </Modal.Body>
      <Modal.Actions className="flex flex-col md:py-4">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <ContinueWithEmailButton
            disabled={shouldDisableOption(selectedRole, selectedIndustry)}
          />
        )}
      </Modal.Actions>
    </form>
  );

  return (
    <Modal
      id="sign-up-modal"
      className="p-0 auth-modal py-6"
      responsive={true}
      open={isOpen}
      onClickBackdrop={() => toggleOpen(false)}
    >
      <Modal.Header>
        {isRegistrationView && (
          <BackButton onClick={() => setSignUpStage(SignUpStage.Email)} />
        )}
        <SignUpModalHeader
          handleLogIn={handleLogIn}
          isRegistrationView={isRegistrationView}
          title={title}
        />
      </Modal.Header>

      <hr className="border-g0-gray-100" />

      {signUpStage === SignUpStage.Email && (
        <>
          <div className="title-small text-g0-gray-500 mx-12 mt-6">
            Select your role<span className="text-red-500"> *</span>
          </div>
          <UserRoles onSelectRole={onSelectRole} selectedRole={selectedRole} />
          <RoleIndustries
            role={selectedRole}
            className="mb-9"
            selectedIndustry={selectedIndustry}
            onSelectIndustry={onSelectIndustry}
          />
        </>
      )}

      {signUpStage === SignUpStage.Email && emailForm}

      {isRegistrationView && registrationForm}
    </Modal>
  );
};
