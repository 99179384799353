import styled from 'styled-components';
import { ReactComponent as Screenshot1 } from './screenshot1.svg';
import { ReactComponent as Screenshot2 } from './screenshot2.svg';

export const ScreenShot1 = styled(Screenshot1)`
  position: absolute;
`;

export const ScreenShot2 = styled(Screenshot2)`
  position: absolute;
  left: 35%;
  top: 30px;
`;
