type FetchApiWrapperOptions = {
  httpRequestType: string;
  url: string;
  data?: any;
  contentType?: string;
};

const buildHeaders = (contentType: string): HeadersInit => {
  const headers: HeadersInit = {
    'Content-Type': contentType,
  };

  if (contentType === 'multipart/form-data') {
    delete headers['Content-Type'];
  }

  return headers;
};

const handleSuccessResponse = async (
  fetchResponse: Response,
  httpRequestType: string
): Promise<any> => {
  if (httpRequestType !== 'DELETE') {
    const response = await fetchResponse.json();
    return {
      success: true,
      data: response.data,
      error: null,
      response: response,
    };
  } else {
    return {
      success: true,
    };
  }
};

const handleErrorResponse = async (fetchResponse: Response): Promise<any> => {
  const serverErrorMessage = await fetchResponse.json();

  let errorMessage = null;
  if (serverErrorMessage?.error?.error)
    errorMessage = serverErrorMessage?.error?.error;
  else if (serverErrorMessage?.error) errorMessage = serverErrorMessage?.error;
  else if (serverErrorMessage.message)
    errorMessage = serverErrorMessage?.message;
  else
    errorMessage = 'We could not reach our servers. Please try again later. ';

  return {
    success: false,
    error: errorMessage,
    data: null,
  };
};

const handleCatchError = (error: any): any => {
  let errorMessage = null;

  if (error.response && error.response.data && error.response.data.error) {
    errorMessage = error.response.data.error;
  } else {
    errorMessage = 'We could not reach our servers. Please try again later. ';
  }

  return { success: false, error: errorMessage, data: null };
};

export const fetchApiWrapper = async ({
  httpRequestType,
  url,
  data = null,
  contentType = 'application/json',
}: FetchApiWrapperOptions): Promise<any> => {
  try {
    const headers = buildHeaders(contentType);
    const options: RequestInit & { credentials: RequestCredentials } = {
      method: httpRequestType,
      credentials: 'include',
      headers: headers,
    };

    if (['POST', 'PUT', 'PATCH'].includes(httpRequestType)) {
      options.body =
        contentType === 'multipart/form-data' ? data : JSON.stringify(data);
    }

    const fetchResponse = await fetch(url, options);

    if (fetchResponse.ok) {
      return await handleSuccessResponse(fetchResponse, httpRequestType);
    } else {
      return await handleErrorResponse(fetchResponse);
    }
  } catch (error) {
    return handleCatchError(error);
  }
};
