import React from 'react';
import NexusInputText from '../../inputs/NexusInputText';
import CreateAccount from '../CreateAccount';
import { G0Button } from 'component-library';
import { ReactComponent as CheckIcon } from '../../../../assets/check-icon-circle-green.svg';

export const ResetPasswordView = ({
  className,
  updateFormValue,
  clickResetPassword,
  onClickCreateAccount,
}: {
  clickResetPassword: () => void;
  onClickCreateAccount: () => void;
  className?: string;
  updateFormValue: ({
    updateType,
    value,
  }: {
    updateType: string;
    value: string;
  }) => void;
}) => {
  return (
    <>
      <div className={className}>
        <NexusInputText
          labelTitle="Email"
          updateType="email"
          updateFormValue={updateFormValue}
          defaultValue={''}
          placeholder="example@domain.com"
          required={true}
        />
      </div>
      <div className="mx-12 md:mx-40 mt-8">
        <G0Button
          size="medium"
          onClick={clickResetPassword}
          className="w-full flex justify-center md:flex-unset md:justify-center-unset"
        >
          Reset Password
        </G0Button>
      </div>
      <div className="flex justify-center items-center mt-6 mb-8 md:mb-4">
        <CreateAccount onClickCreateAccount={onClickCreateAccount} />
      </div>
    </>
  );
};

export const SentForgotPasswordView = ({
  clickContactCustomerSupport,
  resetReqEmail,
}: {
  resetReqEmail: string;
  clickContactCustomerSupport: () => void;
}) => {
  return (
    <div className="mx-12 mb-8 flex flex-col gap-4 items-center text-center body-medium">
      <CheckIcon width={32} height={32} />
      <p>
        {`You’ll receive an email at ${resetReqEmail}. If you have
            troubles receiving your email, `}
        <span
          onClick={clickContactCustomerSupport}
          className="underline cursor-pointer"
        >
          Contact Customer Support
        </span>
        .
      </p>
    </div>
  );
};

export const ForgotPasswordModalHeader = ({
  didSendReq,
}: {
  didSendReq: boolean;
}) => {
  return (
    <div className="text-center my-8 md:my-4">
      <h2 className="title-large text-g0-primary mb-3">
        {didSendReq ? 'Check your email' : 'Forgot Password?'}
      </h2>
      <div className="body-medium">
        <span className="text-g0-secondary">
          {didSendReq
            ? 'That’s where we’ve sent your reset link.'
            : 'No worries, we’ll send you reset instructions.'}
        </span>
      </div>
    </div>
  );
};
