import { jwtDecode } from 'jwt-decode';
import { Token } from '../types/types';

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.split('=')[1];
    }
  }
  return undefined;
};

export const getDecodedToken = (token: string): Token | null => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

export const getDecodedTokenFromCookie = (cookieName: string): Token | null => {
  const token = getCookie(cookieName);
  if (!token) {
    return null;
  }
  const decodedToken = getDecodedToken(token);
  return decodedToken;
};
