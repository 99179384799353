import React from 'react';
import {
  determineIfSentenceIsDesignatedHuman,
  determineImpactScore,
  getPlotBackground,
} from './helpers';

interface SentenceImpactScoreProps {
  sentence: {
    interpretability_alpha: number;
    interpretability_designation: string;
  };
  showText?: boolean;
}

function SentenceImpactScore({ sentence, showText }: SentenceImpactScoreProps) {
  const impactScore = determineImpactScore(sentence.interpretability_alpha);
  const isHumanValue = determineIfSentenceIsDesignatedHuman(sentence);
  const background = getPlotBackground({ sentence: sentence, isHumanValue });
  const circleStyle = (category: string) => ({
    backgroundColor: impactScore === category ? background : 'transparent',
  });

  const text = `${impactScore[0].toUpperCase() + impactScore.slice(1)} ${
    isHumanValue ? 'Human' : 'AI'
  } impact`;

  const scale = [
    { key: 'low', label: 'Low impact' },
    { key: 'medium', label: 'Medium impact' },
    { key: 'high', label: 'High impact' },
  ];

  return (
    <div className="flex items-center gap-1">
      {scale.map((category, index) => (
        <div
          key={category.key}
          role="status"
          aria-label={category.label}
          className={`rounded-full ${
            impactScore === category.key
              ? 'w-[13px] h-[13px]'
              : 'w-3 h-3 border'
          }`}
          style={circleStyle(category.key)}
        >
          <span className="sr-only">{text}</span>
        </div>
      ))}
      {showText && (
        <span className={'label-extra-small text-g0-secondary pl-3'}>
          {text}
        </span>
      )}
    </div>
  );
}

export default SentenceImpactScore;
