import { createContext, Dispatch, SetStateAction, RefObject } from 'react';
import ReactQuill from 'react-quill';
import { Claim } from '../../app/store/aiUtilitiesHubSlice/types';

interface AiUtilitiesHubContextType {
  editorRef: RefObject<ReactQuill | null>;
  advancedScanContainerRef: RefObject<any>;
  feedbackContainerRef: RefObject<any>;
  writingFeedbackParagraphsContainerRef: RefObject<any>;
  fileToScan: File | null;
  setFileToScan: Dispatch<SetStateAction<File | null>>;
  characterCount: number;
  setCharacterCount: Dispatch<SetStateAction<number>>;
  showCharacterCountNotice: boolean;
  setShowCharacterCountNotice: Dispatch<SetStateAction<boolean>>;
  shouldShowCharacterCountNotice: boolean;
  setShouldShowCharacterCountNotice: Dispatch<SetStateAction<boolean>>;
  activeAdvancedScanSentence: string | null;
  setActiveAdvancedScanSentence: Dispatch<
    SetStateAction<string | number | null>
  >;
  isPremiumModalOpen: boolean;
  premiumModalTitle: string;
  premiumModalSource: string;
  setIsPremiumModalOpen: Dispatch<SetStateAction<boolean>>;
  setPremiumModalTitle: Dispatch<SetStateAction<string>>;
  setPremiumModalSource: Dispatch<SetStateAction<string>>;
  activeWritingFeedbackElement: string | null;
  setActiveWritingFeedbackElement: Dispatch<
    SetStateAction<string | number | null>
  >;
  filteredFeedback: any[];
  setFilteredFeedback: Dispatch<SetStateAction<any[]>>;
  activeWritingFeedbackFilter: string;
  setActiveWritingFeedbackFilter: Dispatch<SetStateAction<string>>;
  activeWritingFeedbackSentence: string | null;
  setActiveWritingFeedbackSentence: Dispatch<
    SetStateAction<string | number | null>
  >;
  shouldShiftNavDown: boolean;
  setShouldShiftNavDown: Dispatch<SetStateAction<boolean>>;
  isUpgradeBannerOpen: boolean;
  setIsUpgradeBannerOpen: Dispatch<SetStateAction<boolean>>;
  activeSourcesScanClaim: Claim | null;
  setActiveSourcesScanClaim: Dispatch<SetStateAction<Claim | null>>;
  selectedSourcesScanClaimIndex: number | null;
  setSelectedSourcesScanClaimIndex: Dispatch<SetStateAction<number | null>>;
  ocrSuccessful: boolean;
  setOcrSuccessful: Dispatch<SetStateAction<boolean>>;
}

export const AiUtilitiesHubContext = createContext<AiUtilitiesHubContextType>({
  editorRef: null,
  advancedScanContainerRef: null,
  writingFeedbackParagraphsContainerRef: null,
  feedbackContainerRef: null,
  fileToScan: null,
  setFileToScan: () => {},
  characterCount: 0,
  setCharacterCount: () => {},
  showCharacterCountNotice: false,
  setShowCharacterCountNotice: () => {},
  shouldShowCharacterCountNotice: false,
  setShouldShowCharacterCountNotice: () => {},
  activeAdvancedScanSentence: null,
  setActiveAdvancedScanSentence: () => {},
  isPremiumModalOpen: false,
  premiumModalTitle: '',
  premiumModalSource: '',
  setIsPremiumModalOpen: () => {},
  setPremiumModalTitle: () => {},
  setPremiumModalSource: () => {},
  activeWritingFeedbackElement: null,
  setActiveWritingFeedbackElement: () => {},
  filteredFeedback: [],
  setFilteredFeedback: () => {},
  activeWritingFeedbackFilter: 'all feedback',
  setActiveWritingFeedbackFilter: () => {},
  activeWritingFeedbackSentence: null,
  setActiveWritingFeedbackSentence: () => {},
  shouldShiftNavDown: false,
  setShouldShiftNavDown: () => {},
  isUpgradeBannerOpen: false,
  setIsUpgradeBannerOpen: () => {},
  activeSourcesScanClaim: null,
  setActiveSourcesScanClaim: () => {},
  selectedSourcesScanClaimIndex: null,
  setSelectedSourcesScanClaimIndex: () => {},
  ocrSuccessful: false,
  setOcrSuccessful: () => {},
});

interface MultiFileUploadContextType {
  checkedStates: {
    [key: string]: boolean;
  };
  setCheckedStates: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  uploadedFiles: File[];
  setUploadedFiles: Dispatch<SetStateAction<File[]>>;
  selectedFileIds: string[];
  setSelectedFileIds: Dispatch<SetStateAction<string[]>>;
  showResults: boolean;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  resultViewFileId: string;
  setResultViewFileId: Dispatch<SetStateAction<string>>;
}

export const MultiFileUploadContext = createContext<MultiFileUploadContextType>(
  {
    checkedStates: {},
    setCheckedStates: () => {},
    uploadedFiles: [],
    setUploadedFiles: () => {},
    selectedFileIds: [],
    setSelectedFileIds: () => {},
    showResults: false,
    setShowResults: () => {},
    resultViewFileId: '',
    setResultViewFileId: () => {},
  }
);
