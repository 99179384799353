import { Link } from 'react-router-dom';
import ErrorText from '../../../components/Typography/ErrorText';
import InputText from '../../../components/Input/InputText';
import { SubmitButton } from './Buttons';
import OAuthOptions from '../OAuth';
import Footer from './Footer';
import Separator from './seperator';

function LoginForm({
  loginObj,
  updateFormValue,
  loading,
  submitForm,
  errorMessage,
}) {
  return (
    <div className="">
      <OAuthOptions />
      <Separator />
      <form onSubmit={(e) => submitForm(e)}>
        <div className="mb-2">
          <InputText
            id={'loginFormEmail'}
            type="emailId"
            required={true}
            defaultValue={loginObj.emailId}
            updateType="emailId"
            containerStyle="mt-4"
            labelTitle="Email"
            name="email"
            updateFormValue={updateFormValue}
          />

          <InputText
            id={'loginFormPassword'}
            defaultValue={loginObj.password}
            type="password"
            required={true}
            updateType="password"
            containerStyle="mt-2"
            labelTitle="Password"
            name="password"
            updateFormValue={updateFormValue}
          />
        </div>
        <div className="text-right text-primary">
          <Link to="/forgot-password">
            <span className="text-sm  inline-block  hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
              Forgot Password?
            </span>
          </Link>
        </div>
        <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
        <SubmitButton id="login-button" loading={loading}>
          Login
        </SubmitButton>
        <Footer login />
      </form>
    </div>
  );
}

export default LoginForm;
