import React, { useEffect } from 'react';
import supabaseClient from '../../utils/supabaseClient';
import { saveUserProfileAndSessionDetails } from '../../features/user/Login';
import supabase from '../../utils/supabaseClient';
import useAuthReduxState from './useAuthReduxState';
import useFetchTeams from '../useFetchTeams';
import useUpdateProfileAfterSignup from './useUpdateProfileAfterSignup';

const useUserLoggedIn = () => {
  const { setIsAnonymousUser } = useAuthReduxState();
  const { fetchTeams } = useFetchTeams();
  const { updateUserProfileRoleFromQueryParams } =
    useUpdateProfileAfterSignup();

  useEffect(() => {
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'INITIAL_SESSION':
        case 'SIGNED_IN':
        case 'TOKEN_REFRESHED':
          if (session) {
            (async () => {
              const email = session.user.email;
              if (session.user.is_anonymous) {
                setIsAnonymousUser(session.user.is_anonymous);
                return;
              }
              await saveUserProfileAndSessionDetails({
                sessionUserId: session.user.id,
                sessionAccessToken: session.access_token,
              });
              await fetchTeams();
              updateUserProfileRoleFromQueryParams(email);
            })();
          }
          break;
        default:
          console.warn(`Unhandled auth event: ${event}`);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signInIfPossible = () => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (error) {
        console.error(error);
      }
      if (data && data.session) {
        setIsAnonymousUser(data.session.user.is_anonymous);

        if (data.session.user.is_anonymous) {
          return;
        }

        supabase.auth.getUser().then((response) => {
          if (response.error) {
            console.error(response.error);
          }
          if (response.data) {
            saveUserProfileAndSessionDetails({
              sessionUserId: data.session.user.id,
              sessionAccessToken: data.session.access_token,
            })
              .then(() => fetchTeams())
              .catch((error) =>
                console.error('signInIfPossible - useUserLoggedIn', error)
              );
          }
        });
      }
    });
  };

  return { signInIfPossible };
};

export default useUserLoggedIn;
