import { createSlice } from '@reduxjs/toolkit';
import whitelabelConfig from '../whitelabelConfig';
import axios from 'axios';

export const fetchPlagiarismTextCheck =
  (scanId, text, checkerProfile) => async (dispatch) => {
    const baseUrl = whitelabelConfig.gptzero.backendHostname;

    dispatch(setPlagiarismCheckLoading({ plagiarismCheckLoading: true }));
    try {
      return await fetch(`${baseUrl}${checkerProfile.textRoute}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          scanId,
          document: text,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

export const fetchPlagiarismFileCheck =
  (scanId, files, checkerProfile) => async (dispatch) => {
    const baseUrl = whitelabelConfig.gptzero.backendHostname;

    const data = new FormData();
    data.append('scanId', scanId);
    data.append('files', files[0]);

    dispatch(setPlagiarismCheckLoading({ plagiarismCheckLoading: true }));
    try {
      return await axios.post(`${baseUrl}${checkerProfile.fileRoute}`, data, {
        withCredentials: true,
      });
    } catch (error) {
      throw error;
    }
  };

export const plagiarismCheckSlice = createSlice({
  name: 'plagiarismCheck',
  initialState: {
    plagiarismScanResults: null,
    plagiarismCheckLoading: false,
    isPlagiarismChecked: false,
  },
  reducers: {
    setPlagiarismScanResults(state, action) {
      state.plagiarismScanResults = action.payload.plagiarismScanResults;
    },
    setPlagiarismCheckLoading(state, action) {
      state.plagiarismCheckLoading = action.payload.plagiarismCheckLoading;
    },
    setIsPlagiarismChecked(state, action) {
      state.isPlagiarismChecked = action.payload.isPlagiarismChecked;
    },
  },
});

export const {
  setPlagiarismScanResults,
  setPlagiarismCheckLoading,
  setIsPlagiarismChecked,
} = plagiarismCheckSlice.actions;

export default plagiarismCheckSlice.reducer;
