import React from 'react';
import './styles.css';
import MatchesSummaryV2 from '../MatchesSummaryV2';
import { PlagiarismDocument } from '../../../../types/sourcesSurfacing';
import { getPlagiarismMatchesCount } from '../../../../helpers/plagiarism';
import { MatchesSummarySkeleton } from '../../../../assets/skeletons';
import PlagiarismMatchCardsV2 from './plagMatchCardsV2';
import { IMatchPlagiarism } from '../../../molecules/PlagiarismHighlight/types';
import { getNumWords } from '../../../../helpers/textUtils';

interface PlagiarismTabContentV2Props {
  plagiarismDocuments: Array<PlagiarismDocument> | undefined;
  loading: boolean;
  isLoggedIn: boolean;
  userLoggedInSourcesRateLimitReached?: boolean;
  userCanViewPlagiarism: boolean;
  plagiarismActiveMatches: IMatchPlagiarism[];
}

const PlagiarismTabContentV2 = ({
  plagiarismDocuments,
  loading,
  isLoggedIn,
  userLoggedInSourcesRateLimitReached,
  userCanViewPlagiarism,
  plagiarismActiveMatches,
}: PlagiarismTabContentV2Props) => {
  const [showExactMatches, setShowExactMatches] = React.useState<boolean>(true);
  const [showFuzzyMatches, setShowFuzzyMatches] = React.useState<boolean>(true);

  const toggleShowExactMatches = () => setShowExactMatches(!showExactMatches);
  const toggleShowFuzzyMatches = () => setShowFuzzyMatches(!showFuzzyMatches);

  const { exactPlagiarismMatchesCount, similarPlagiarismMatchesCount } =
    getPlagiarismMatchesCount(plagiarismDocuments);

  const plagiarismPercentage = plagiarismDocuments?.[0]?.percentPlagiarism ?? 0;
  const inputText = plagiarismDocuments?.[0]?.inputText;
  const sources = plagiarismDocuments?.[0]?.sources || [];

  // total matched words from all sources
  const totalMatchedWords = sources.reduce((total, source) => {
    const matchedText = source?.matches[0]?.matchText || '';
    return total + getNumWords(matchedText);
  }, 0);

  return (
    <div className={'plag-tab-content-wrapper'}>
      <div
        className={`ur-matches-summary-wrapper ${
          !isLoggedIn || userLoggedInSourcesRateLimitReached
            ? 'ur-blur-div'
            : ''
        }`}
      >
        {loading && !userCanViewPlagiarism ? (
          <MatchesSummarySkeleton />
        ) : (
          <MatchesSummaryV2
            exactMatchesCount={exactPlagiarismMatchesCount}
            similarMatchesCount={similarPlagiarismMatchesCount}
            percentageMatch={plagiarismPercentage}
            category={'plagiarism'}
            showExactMatches={showExactMatches}
            showFuzzyMatches={showFuzzyMatches}
            toggleExactMatchesVisibility={toggleShowExactMatches}
            toggleFuzzyMatchesVisibility={toggleShowFuzzyMatches}
            totalMatchedWords={totalMatchedWords}
          />
        )}
      </div>
      <div
        className={`ur-matches-cards-wrapper ${
          !isLoggedIn || userLoggedInSourcesRateLimitReached
            ? 'ur-blur-div'
            : ''
        }`}
      >
        <PlagiarismMatchCardsV2
          showFuzzyMatches={showFuzzyMatches}
          showExactMatches={showExactMatches}
          sources={sources}
          plagiarismPercentage={plagiarismPercentage}
          loading={loading}
          userCanViewPlagiarism={userCanViewPlagiarism}
          plagiarismActiveMatches={plagiarismActiveMatches}
          inputText={inputText}
        />
      </div>
    </div>
  );
};

export default PlagiarismTabContentV2;
