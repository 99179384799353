export const deleteTeamScansApi = async ({
  baseUrl,
  teamId,
  selectedScans,
}) => {
  const endpoint = `${baseUrl}/v2/scan-history/team`;
  const body = JSON.stringify({ teamId, scanIds: selectedScans });
  const response = await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body,
  });
  return response;
};

export const deleteIndividualScansApi = async ({ baseUrl, selectedScans }) => {
  const endpoint = `${baseUrl}/v2/scan-history`;
  const body = JSON.stringify({ scanIds: selectedScans });
  const response = await fetch(endpoint, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body,
  });
  return response;
};

export const fetchDocumentsApi = async ({
  baseUrl,
  queryParamsString,
  isTeamScansTable,
  isEditorDocumentTable,
  teamId,
}) => {
  const getFetchEndpoint = () => {
    if (isTeamScansTable) {
      return `${baseUrl}/v2/scan-history/team/${teamId}${queryParamsString}`;
    } else if (isEditorDocumentTable) {
      return `${baseUrl}/v3/editor-documents${queryParamsString}&include=invitations`;
    } else {
      return `${baseUrl}/v2/scan-history${queryParamsString}`;
    }
  };

  const response = await fetch(getFetchEndpoint(), {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  });

  return response;
};

export const fetchOriginalScanApi = async ({
  baseUrl,
  originalScanId,
  teamId,
}) => {
  const fetchUrl = `${baseUrl}/v2/scan-history/${originalScanId}${teamId ? `?teamId=${teamId}` : ''}`;

  const response = await fetch(fetchUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Error fetching scan history');
  }

  const originalScan = await response.json();
  return originalScan.data;
};
