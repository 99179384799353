/**
 * @description Returns true if the user was created within the last minute.
 * @param user
 * @returns {boolean}
 */
export const isNewUser = (user: any): boolean => {
  if (!user || !user.created_at) {
    return false;
  }
  const { created_at } = user;

  const now = new Date();
  const createdAt = new Date(created_at);
  const duration = (now.getTime() - createdAt.getTime()) / (1000 * 60);

  return duration < 1;
};
