import * as amplitude from '@amplitude/analytics-browser';
import { trackEvent as trackEventComponentLibrary } from 'component-library';

let amplitudeInitialized = false;

/**
 * Set user ID in Amplitude
 */
export const setUserId = () => {
  const userId = localStorage.getItem('id');
  if (userId) {
    amplitude.setUserId(userId);
  }
};

/**
 * Reset user data in Amplitude
 */
export const resetUserData = () => {
  amplitude.reset();
};

/**
 * Set user properties in Amplitude
 */
export const setUserProperties = () => {
  const identifyEvent = new amplitude.Identify();

  const localStorageKeys = [
    'plan',
    'email',
    'orgRole',
    'fullname',
    'referralId',
    'char_limit',
    'created_at',
    'batch_limit',
    'referralName',
    'isWhiteListed',
    'isAdvisorAllowlisted',
    'isTeacherWhitelisted',
    'studentWhitelistInviteStatus',
    'teacherWhitelistInviteStatus',
  ];

  localStorageKeys.forEach((key) => {
    const value = localStorage.getItem(key);

    if (value) {
      identifyEvent.set(key, value);
    }
  });

  amplitude.identify(identifyEvent);
};

/**
 * Track an event in Amplitude
 * @deprecated
 * @param eventName
 * @param eventProperties
 */
export const trackEvent = (eventName, eventProperties = {}) => {
  console.warn('Call to deprecated `trackEvent`. Use `trackEventV2` instead.');
  if (!amplitudeInitialized) {
    amplitudeInitialized = true;
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }
  amplitude.track(eventName, eventProperties);
};

/**
 * Track an event in Amplitude
 * @param action -- e.g., click, view, load
 * @param component -- component (generally composite), optionally prefixed with a description
 * @param element -- optional UI element name and descriptor, e.g. signupButton
 * @param options -- optional object to be sent to Amplitude along with event name
 */
export const trackEventV2 = ({
  action,
  component,
  element = null,
  options = {},
  sampleRate = 1.0,
}) => {
  trackEventComponentLibrary(action, component, element, sampleRate, options);
  let eventName = `${action}_${component}`;
  eventName = element ? `${eventName}_${element}` : eventName;
  eventName =
    sampleRate < 1.0 ? `${eventName}_@${sampleRate * 100}` : eventName;
  if (isDevelopment()) {
    console.info(`[Amplitude] ${eventName}`, options);
  }
};

const isDevelopment = () => process.env.NODE_ENV == 'development';
