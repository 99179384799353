import { createSlice } from '@reduxjs/toolkit';

export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    currentPlan: localStorage.getItem('plan') || '',
  },
  reducers: {
    updatePlan: (state, action) => {
      state.currentPlan = action.payload;
    },
  },
});

export const { updatePlan } = planSlice.actions;
export default planSlice.reducer;
