import { Disjoiner } from 'disjoiner';
import {
  SourcePlagiarism,
  MatchPlagiarism,
  PlagiarismDocument,
} from '../types/sourcesSurfacing';

export function createDisjointIntervals(
  inputText: string,
  sources: SourcePlagiarism[]
) {
  // Specify how to disjoin the intervals
  const disjoiner = new Disjoiner<number, MatchPlagiarism[]>({
    // Specify how to compare two numbers
    // Same type of compare function that is used in Array.sort
    // (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
    compare: (num1, num2) => num1 - num2,

    // Set how to merge two values of overlapping intervals
    // In this case, we concatenate the match arrays
    mergeValues: (val1, val2) => [...val1, ...val2],

    // Check if two match arrays are equal
    // We consider two arrays equal if they have the same elements in the same order
    equals: (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2),
  });

  // Create the array of initial intervals
  const intervals = sources?.flatMap((source) =>
    source?.matches.map((match) => ({
      start: match.inputStart,
      end: match.inputEnd,
      value: [
        {
          ...match,
          url: source.url,
          title: source.title,
        },
      ],
    }))
  );
  intervals?.push({
    start: 0,
    end: inputText?.length,
    value: [],
  });

  // Use the disjoiner to create the disjoint intervals
  const disjointIntervals = disjoiner.disjoin(intervals);

  // For each disjoint interval, create an object that includes the input text for that interval
  return disjointIntervals.map((interval) => {
    const indexes = interval.value.map((match) => match.index);

    return {
      ...interval,
      text: inputText.slice(interval.start, interval.end),
      indexes: indexes,
      // Check if the text is included in any of the matchText's in the values
      isIdentical: interval.value.some((match) => match.score === 100),
    };
  });
}

// similar to createDisjointIntervals but uses one index (citation number) per source
export function createDisjointIntervalsV2(
  inputText: string,
  sources: SourcePlagiarism[]
) {
  const disjoiner = new Disjoiner<number, MatchPlagiarism[]>({
    compare: (num1, num2) => num1 - num2,

    mergeValues: (val1, val2) => [...val1, ...val2],

    equals: (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2),
  });

  const intervals = sources?.flatMap((source, index) =>
    source?.matches.map((match) => ({
      start: match.inputStart,
      end: match.inputEnd,
      value: [
        {
          ...match,
          url: source.url,
          title: source.title,
          index: index,
        },
      ],
    }))
  );
  intervals?.push({
    start: 0,
    end: inputText?.length,
    value: [],
  });

  const disjointIntervals = disjoiner.disjoin(intervals);

  return disjointIntervals.map((interval) => {
    const indexes = interval.value.map((match) => match.index);

    return {
      ...interval,
      text: inputText.slice(interval.start, interval.end),
      indexes: indexes,
      isIdentical: interval.value.some((match) => match.score === 100),
    };
  });
}

export const getPlagiarismMatchesCount = (
  plagiarismDocuments: PlagiarismDocument[]
) => {
  let totalPlagiarismCount = 0;
  let exactPlagiarismMatchesCount = 0;
  let similarPlagiarismMatchesCount = 0;

  if (plagiarismDocuments) {
    plagiarismDocuments[0]?.sources?.forEach((source: SourcePlagiarism) => {
      totalPlagiarismCount += source.matches.length;

      exactPlagiarismMatchesCount += source.matches.filter(
        (match) => Math.round(match.score) === 100
      ).length;
    });

    similarPlagiarismMatchesCount =
      totalPlagiarismCount - exactPlagiarismMatchesCount;
  }
  return {
    totalPlagiarismCount,
    exactPlagiarismMatchesCount,
    similarPlagiarismMatchesCount,
  };
};
