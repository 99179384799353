import React from 'react';
import { Modal } from 'react-daisyui';
import './styles.css';
import BackButton from '../BackButton';
import useForgotPassword from '../../../../hooks/auth/useForgotPassword';
import {
  ForgotPasswordModalHeader,
  ResetPasswordView,
  SentForgotPasswordView,
} from '../common/ResetPasswordElements';

interface ForgotPasswordModalProps {
  isOpen: boolean;
  toggleOpen: (open: boolean) => void;
  onClickBackButton: () => void;
  onClickCreateAccount: () => void;
}

const ForgotPasswordModal = ({
  isOpen,
  toggleOpen,
  onClickBackButton,
  onClickCreateAccount,
}: ForgotPasswordModalProps) => {
  const {
    resetReqEmail,
    didSendReq,
    updateFormValue,
    clickResetPassword,
    clickContactCustomerSupport,
  } = useForgotPassword({ isOpen });

  return (
    <Modal
      id="forgot-password-modal"
      className="p-0 auth-modal py-6"
      responsive={true}
      open={isOpen}
      onClickBackdrop={() => toggleOpen(false)}
    >
      <Modal.Header>
        <BackButton onClick={onClickBackButton} />
        <ForgotPasswordModalHeader didSendReq={didSendReq} />
        <hr className="border-g0-gray-100 mt-6" />
      </Modal.Header>
      <Modal.Body>
        {didSendReq ? (
          <SentForgotPasswordView
            clickContactCustomerSupport={clickContactCustomerSupport}
            resetReqEmail={resetReqEmail}
          />
        ) : (
          <ResetPasswordView
            className="mx-40"
            updateFormValue={updateFormValue}
            clickResetPassword={clickResetPassword}
            onClickCreateAccount={onClickCreateAccount}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;
