import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FeedbackSurveyProps } from './FeedbackSurvey.types';
import Button from '../../atoms/button';
import { LoadingSpinner } from '../../atoms/loadingSpinner';

const Container = styled.form`
  width: 100%;
  padding: 5px 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  cursor: pointer;
`;

const StarsButtonsContainer = styled.div`
  display: flex;
  gap: 55px;
`;

const FeedbackTextarea = styled.textarea`
  width: 600px;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-family: sans-serif;
`;

const Header = styled.div`
  margin: 0;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: fit;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin: 10px auto;
`;

const NUM_BUTTONS = 5;

const StarButton: React.FC<{
  id: number;
  isSelected?: boolean;
  shouldHighlight?: boolean;
  onClick?: () => void;
  onHighlight?: (didStart: boolean, id: number) => void;
}> = ({
  id,
  isSelected = false,
  shouldHighlight = false,
  onClick,
  onHighlight,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [fill, setFill] = useState(getFill());

  useEffect(() => {
    setFill(getFill());
  }, [isHovering, isSelected, shouldHighlight]);

  function getFill() {
    if (isHovering) {
      return '#FFB657';
    } else if (isSelected || shouldHighlight) {
      return '#FFCE8F';
    } else {
      return '#A7A6A5';
    }
  }

  const handleMouseOver = () => {
    setIsHovering(true);
    onHighlight && onHighlight(true, id);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    onHighlight && onHighlight(false, id);
  };

  return (
    <UnstyledButton
      type="button"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <svg
        width="44"
        height="42"
        viewBox="0 0 44 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M22 0L27.1638 15.8926H43.8743L30.3552 25.7148L35.5191 41.6074L22 31.7852L8.48094 41.6074L13.6448 25.7148L0.1257 15.8926H16.8362L22 0Z"
          fill={fill}
        />
      </svg>
    </UnstyledButton>
  );
};

const FeedbackSurvey: React.FC<FeedbackSurveyProps> = ({
  onSubmit,
  isLoading,
  className,
  ratePrompt = null,
  textFeedbackPrompt = null,
}) => {
  const [selectedButton, setSelectedButton] = useState<number | null>(null);
  const [highlightedButton, setHighlightedButton] = useState<number | null>(
    null
  );
  const [feedback, setFeedback] = useState('');

  const handleClick = (value: number) => {
    selectedButton === value
      ? setSelectedButton(null)
      : setSelectedButton(value);
  };

  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await onSubmit(selectedButton, feedback);
    resetState();
  };

  const resetState = () => {
    setFeedback('');
    setSelectedButton(null);
    setHighlightedButton(null);
  };

  const handleHighlightStar = (didStart: boolean, id: number) => {
    if (didStart) {
      setHighlightedButton(id);
    } else {
      setHighlightedButton(null);
    }
  };

  return (
    <Container
      data-testid="FeedbackSurvey"
      className={className}
      onSubmit={handleSubmit}
    >
      <Section>
        <Header>
          {ratePrompt || `Rate your experience with GPTZero today`}
        </Header>
        <StarsButtonsContainer>
          {[...Array(NUM_BUTTONS)].map((_, index) => {
            const score = index + 1;
            return (
              <StarButton
                id={score}
                onClick={() => handleClick(score)}
                key={index}
                isSelected={selectedButton >= score}
                shouldHighlight={highlightedButton > score}
                onHighlight={handleHighlightStar}
              />
            );
          })}
        </StarsButtonsContainer>
      </Section>
      <Section>
        <Header>
          {textFeedbackPrompt || `What’s your feedback for the GPTZero team?`}
        </Header>
        <FeedbackTextarea value={feedback} onChange={handleFeedbackChange} />
        <ButtonContainer>
          <Button type="submit">
            {isLoading && <LoadingSpinner />}
            Submit
          </Button>
        </ButtonContainer>
      </Section>
    </Container>
  );
};

export default FeedbackSurvey;
