import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAuth } from '../app/auth';

export function useAuthentication() {
  // Check for login
  const { token, goToLogin } = checkAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (goToLogin && window.location.pathname !== '/login') {
      navigate('/login', {
        state: { from: `${window.location.pathname}${window.location.search}` },
      });
    }
  }, [token]);

  return { goToLogin, token };
}
