export const determineImpactScore = (value: number) => {
  if (value <= 0.33) {
    return 'low';
  } else if (value <= 0.66) {
    return 'medium';
  } else {
    return 'high';
  }
};

export const getPlotBackground = ({ sentence, isHumanValue }) => {
  const opacity =
    sentence.interpretability_alpha < 0.2
      ? '0.2'
      : sentence.interpretability_alpha;

  switch (true) {
    case isHumanValue:
      return `rgba(80, 202, 143, ${opacity})`;
    default:
      return `rgba(244, 191, 79, ${opacity})`;
  }
};

export function determineIfSentenceIsDesignatedHuman(sentence) {
  return sentence.interpretability_designation === 'human';
}
