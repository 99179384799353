import { configureStore } from '@reduxjs/toolkit';
import headerSlice from '../../features/common/headerSlice';
import modalSlice from '../../features/common/modalSlice';
import rightDrawerSlice from '../../features/common/rightDrawerSlice';
import scanSummarySlice from './scanResultsSlice';
import textScanSlice from './textScanSlice';
import planSlice from './planSlice';
import usageStatsSlice from './usageStatsSlice';
import profileSlice from './profileSlice';
import writingFeedbackSlice from './writingFeedbackSlice';
import userTeamsSlice from './userTeamsSlice';
import userSelectedTeamSlice from './userSelectedTeamSlice';
import userInvitesSlice from './userInvitesSlice';
import aiCheckSlice from './aiCheckSlice';
import plagiarismCheckSlice from './plagiarismCheckSlice';
import switchToTeamNotificationSlice from './switchToTeamNotificationSlice';
import aiUtilitiesHubSlice from './aiUtilitiesHubSlice/index';
import authSlice from './authSlice';
import editorSlice from './editor/index';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  textToScan: textScanSlice,
  scanResults: scanSummarySlice,
  plan: planSlice,
  usageStats: usageStatsSlice,
  profile: profileSlice,
  writingFeedback: writingFeedbackSlice,
  userTeams: userTeamsSlice,
  userSelectedTeam: userSelectedTeamSlice,
  userInvites: userInvitesSlice,
  aiCheck: aiCheckSlice,
  plagiarismCheck: plagiarismCheckSlice,
  switchToTeamNotification: switchToTeamNotificationSlice,
  aiUtilitiesHub: aiUtilitiesHubSlice,
  auth: authSlice,
  editor: editorSlice,
};

const store = configureStore({
  reducer: combinedReducer,
});

export { store };
export default store;
