import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useClassroomQueryParams from './useClassroomQueryParams';
import { getCookie } from '../../utils/cookieUtils';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import { checkClassroomAuth } from '../../features/googleClassroom/api';
import supabase from '../../utils/supabaseClient';

enum AUTHORIZATION_STATUS {
  AUTHORIZED = 'authorized',
  INVALID_TOKEN = 'invalid_token',
  UNAUTHORIZED = 'unauthorized',
  USER_MISMATCH = 'user_mismatch',
  UNAUTHENTICATED = 'unauthenticated',
  REVOKED_TOKEN = 'revoked_token',
  UNKNOWN = 'unknown',
}
async function hasClassroomAuthorization(
  classroomLoginHint: string,
  baseUrl: string
) {
  supabase.auth.getSession().then(({ data: session, error }) => {
    if (error || !session) {
      return AUTHORIZATION_STATUS.UNAUTHENTICATED;
    }
  });
  const accessToken = getCookie('accessToken4');
  const plan = localStorage.getItem('plan');
  const localLoginHint = localStorage.getItem('login_hint');

  const userIsNotLoggedIn = !plan || !accessToken;
  if (userIsNotLoggedIn) {
    return AUTHORIZATION_STATUS.UNAUTHENTICATED;
  }

  const userIsSigningInForTheFirstTime = !classroomLoginHint && !localLoginHint;
  if (userIsSigningInForTheFirstTime) {
    return AUTHORIZATION_STATUS.UNAUTHORIZED;
  }

  const userIsSigningInWithDifferentAccount =
    classroomLoginHint !== localLoginHint;
  if (classroomLoginHint && userIsSigningInWithDifferentAccount) {
    return AUTHORIZATION_STATUS.USER_MISMATCH;
  }

  const userCompletedAuth =
    localStorage.getItem('firstLoginToClassroom') === 'true';
  const userIsSigningInAfterAuth =
    !classroomLoginHint && localLoginHint && userCompletedAuth;

  const userRevokedAccess =
    !classroomLoginHint && localLoginHint && !userCompletedAuth;
  if (userRevokedAccess) {
    return AUTHORIZATION_STATUS.REVOKED_TOKEN;
  }

  if (classroomLoginHint && userCompletedAuth) {
    localStorage.removeItem('firstLoginToClassroom');
  }

  const userIsAlreadySignedIn = classroomLoginHint === localLoginHint;
  if (userIsAlreadySignedIn || userIsSigningInAfterAuth) {
    const response = await checkClassroomAuth({
      baseUrl: baseUrl,
      userId: localLoginHint,
    });
    if (!response) return AUTHORIZATION_STATUS.UNKNOWN;
    if (response.status === 404) return AUTHORIZATION_STATUS.UNAUTHORIZED;

    if (response.authState === 'authorized')
      return AUTHORIZATION_STATUS.AUTHORIZED;
    else if (response.authState === 'unauthorized')
      return AUTHORIZATION_STATUS.INVALID_TOKEN;
  }

  return AUTHORIZATION_STATUS.UNKNOWN;
}

export function useClassroomAuthentication() {
  const queryParams = useClassroomQueryParams();
  const [goToAuth, setGoToAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userMismatch, setUserMismatch] = useState(false);
  const navigate = useNavigate();
  const whitelabelConfig = useContext(WhitelabelContext);

  useEffect(() => {
    async function checkAuthorization() {
      const authStatus = await hasClassroomAuthorization(
        queryParams.login_hint,
        whitelabelConfig.backendHostname
      );

      if (authStatus === AUTHORIZATION_STATUS.USER_MISMATCH) {
        setUserMismatch(true);
      } else {
        setUserMismatch(false);
      }

      setGoToAuth(authStatus !== AUTHORIZATION_STATUS.AUTHORIZED);
      setLoading(false);
    }
    checkAuthorization();
  }, [queryParams.login_hint]);

  useEffect(() => {
    if (!loading && goToAuth && !userMismatch) {
      navigate('/classroom/connect', {
        state: { from: `${window.location.pathname}${window.location.search}` },
      });
    }
  }, [loading, goToAuth, userMismatch]);

  return { goToAuth, loading, userMismatch };
}
