export function EditorPreview() {
  return (
    <div
      className={
        'w-[55%] lg:px-[70px] 2xl:px-[150px] py-[130px] hidden lg:block'
      }
    >
      <h2 className={'text-[30px] font-bold'}>GPTZero for Education</h2>
      <p className={'mt-5 text-[14px] w-5/6'}>
        The ultimate writing platform designed for seamless document creation,
        advanced AI content scanning, and verified authorship.
      </p>
      <img
        src={'/editor-promo.svg'}
        alt={'editor promo picture'}
        className={'mt-12 w-full'}
      />
    </div>
  );
}

export default EditorPreview;
