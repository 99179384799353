import React, { useState, useEffect } from 'react';
import {
  REVIEWER_INDUSTRIES,
  Role,
  RoleIndustry,
  ROLES,
  WRITER_INDUSTRIES,
} from '../../../constants/signUpModal';
import { G0Button } from 'component-library';

const RoleIndustries = ({
  role,
  className = '',
  selectedIndustry,
  onSelectIndustry,
}: {
  role: Role;
  className?: string;
  selectedIndustry: RoleIndustry;
  onSelectIndustry: (industry: RoleIndustry) => void;
}) => {
  const [industries, setIndustries] = useState<RoleIndustry[]>([]);

  useEffect(() => {
    if (role === ROLES.WRITER) {
      setIndustries(WRITER_INDUSTRIES);
      return;
    }

    if (role === ROLES.REVIEWER) {
      setIndustries(REVIEWER_INDUSTRIES);
      return;
    }

    setIndustries([]); // Default to empty array
  }, [role]);

  return (
    <div className={`flex flex-wrap gap-3 justify-center mx-12 ${className}`}>
      {industries.map((industry) => (
        <G0Button
          key={industry.id}
          shape="pill"
          size="small"
          variant="secondary"
          onClick={() => onSelectIndustry(industry)}
          className={`${industry.id === selectedIndustry?.id && 'border-2'}`}
        >
          {industry.label}
        </G0Button>
      ))}
    </div>
  );
};

export default RoleIndustries;
