import { G0ButtonShape, G0ButtonSize, G0ButtonVariant } from './types';

export const getVariantClasses = (
  variant: G0ButtonVariant,
  disabled: boolean,
  active: boolean
) => {
  const baseDisabledClasses =
    'cursor-not-allowed bg-g0-gray-100 text-g0-secondary';
  const baseActiveClasses = 'bg-g0-gray-950 text-white';

  switch (variant) {
    case 'primary':
      return disabled
        ? baseDisabledClasses
        : active
          ? baseActiveClasses
          : 'bg-g0-primary text-white hover:bg-g0-gray-950';
    case 'secondary':
      return disabled
        ? baseDisabledClasses
        : active
          ? 'bg-g0-gray-50 text-g0-primary border-g0-secondary border border-2'
          : 'border border-g0-secondary border-1 text-g0-primary hover:bg-g0-gray-100';
    case 'tertiary':
      return disabled
        ? baseDisabledClasses
        : active
          ? 'text-g0-gray-950 bg-g0-gray-200'
          : 'text-g0-primary hover:bg-g0-gray-50';
    default:
      return '';
  }
};

export const getSizeClasses = (size: G0ButtonSize) => {
  const sizeMap: Record<G0ButtonSize, string> = {
    'extra small': 'px-3 py-[6px] label-extra-small',
    small: 'px-4 py-2 label-small',
    medium: 'px-6 py-3 label-medium',
    large: 'px-[18px] py-3 title-medium',
  };

  return sizeMap[size] || '';
};

export const getShapeClasses = (shape: G0ButtonShape) => {
  const shapeMap: Record<G0ButtonShape, string> = {
    square: 'rounded-none',
    rounded: 'rounded-md',
    pill: 'rounded-full',
  };

  return shapeMap[shape] || '';
};
