import React from 'react';
import { TooltipProps } from './Tooltip.types';
import InfoIcon from '../../atoms/icons/InfoIcon/InfoIcon';
import SubText from '../../atoms/typography/SubText/SubText';

import './Tooltip.css';

const Tooltip: React.FC<TooltipProps> = ({
  content,
  position = 'right',
  listItems,
  width,
}) => {
  const tooltipStyle = {
    width: width ? `${width}vw` : 'max-content',
  };

  return (
    <div className={`custom-tooltip custom-tooltip-${position}`}>
      <InfoIcon />
      <SubText className="custom-tooltip-text" style={tooltipStyle}>
        {content}
        {listItems && (
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </SubText>
    </div>
  );
};

export default Tooltip;
