/* eslint-disable import/no-anonymous-default-export */
export default {
  SHOW_PLAGIARISM_RESULTS: 'show_plag_results',
  SHOW_TEXT_RESULTS: 'show_text_results',
  SHOW_TEXT_STATS: 'show_text_stats',
  SUCCESSFUL_FILE_CHECK: 'successful_file_check',
  SUCCESSFUL_BATCH_FILE_CHECK: 'successful_batch_file_check',
  BATCH_FILES_UPLOADED: 'batch_files_uploaded',
  TOS_CHECKBOX_TOGGLED: 'terms_of_service_checkbox_toggled',
  TOS_LINK_CLICKED: 'terms_of_service_link_clicked',
  CLICK_WRITING_FEEDBACK: 'click_writing_feedback',
  CLICK_INSIDE_VIEW: 'click_inside_view',
  CLICK_MULTILINGUAL: 'click_multilingual',
  LOAD_MULTILINGUAL_FREE_TRIAL_MODAL: 'load_multilingual_free_trial_modal',

  // Scan History
  DELETE_SCAN_HISTORIES: 'delete_scan_histories',
  CLICK_SCAN_HISTORY_MENU_ITEM: 'click_scan_history_menu_item',
  CLICK_SCAN_HISTORY_RESULTS_BUTTON: 'click_scan_history_results_button',
  SORT_SCAN_HISTORY_TABLE_BY_DATE: 'sort_scan_history_table_by_date',
  CHOOSE_SCAN_SOURCE_HISTORY: 'choose_scan_source_history_table',
  CLICK_SCAN_HISTORY_ADD_DOCUMENT_BUTTON:
    'click_scan_history_add_document_button',
  CLICK_SCAN_HISTORY_RETRY_FETCH_BUTTON:
    'click_scan_history_retry_fetch_button',
  HIDE_PREVIOUS_SCANS_RESULTS_BANNER: 'hide_previous_scans_results_banner',
  CLICK_EXPORT_SCANS_TO_CSV_BUTTON: 'click_export_scans_to_csv_button',

  // Projects
  CLICK_PROJECTS_MENU_ITEM: 'click_projects_menu_item',
  CLICK_CREATE_PROJECT_BUTTON: 'click_create_project_button',
  CLICK_CREATE_PROJECT_MODAL_CONFIRM_BUTTON:
    'click_create_project_modal_confirm_button',
  PROJECT_CREATE_SUCCESS: 'project_create_success',
  CLICK_CREATE_PROJECT_MODAL_CLOSE_BUTTON:
    'click_create_project_modal_close_button',
  CLICK_PROJECT_DELETE_BUTTON: 'click_project_delete_button',
  CLICK_PROJECT_RENAME_BUTTON: 'click_project_rename_button',
  CLICK_DELETE_MODAL_CONFIRM_BUTTON: 'click_delete_modal_confirm_button',
  PROJECT_DELETE_SUCCESS: 'project_delete_success',
  CLICK_DELETE_MODAL_CLOSE_BUTTON: 'click_delete_modal_close_button',
  CLICK_RENAME_MODAL_CONFIRM_BUTTON: 'click_rename_modal_confirm_button',
  PROJECT_RENAME_SUCCESS: 'project_rename_success',
  CLICK_RENAME_MODAL_CLOSE_BUTTON: 'click_rename_modal_close_button',
  CLICK_SINGLE_PROJECT_ICON: 'click_single_project_icon',
  CLICK_PROJECT_ADD_DOCUMENTS_BUTTON: 'click_project_add_documents_button',
  CLICK_RELOAD_PROJECTS_AFTER_ERROR: 'click_reload_projects_after_error',
  HIDE_DEFAULT_PROJECTS_ALERT_BANNER: 'hide_default_projects_alert_banner',

  // Subscriptions
  CLICK_SUBSCRIPTIONS_SIDEBAR_MENU_ITEM:
    'click_subscriptions_sidebar_menu_item',
  CLICK_UPGRADE_PLAN_NAVBAR_MENU_ITEM: 'click_upgrade_plan_navbar_menu_item',
  CLICK_SUBSCRIPTIONS_CARD_GET_STARTED_BUTTON:
    'click_subscriptions_card_get_started_button',
  CLICK_GROUP_PLANS_BUTTON: 'click_group_plans_button',
  CLICK_VIEW_API_PLANS_BUTTON: 'click_view_api_plans_button',
  CLICK_COMPARISON_TABLE_CHOOSE_PLAN_BUTTON:
    'click_comparison_table_choose_plan_button',
  CHECKOUT_SESSION_CREATION_SUCCESS: 'checkout_session_creation_success',
  CHECKOUT_SESSION_CREATION_FAILURE: 'checkout_session_creation_failure',
  CLICK_TEAM_SUBSCRIPTIONS_CARD_GET_STARTED_BUTTON:
    'click_team_subscriptions_card_get_started_button',
  CLICK_TEAM_EDIT_NAME_TOTAL_SEATS_BUTTON:
    'click_team_edit_name_total_seats_button',
  START_FREE_TRIAL: 'start_free_trial',
  START_FREE_TRIAL_FROM_FREE_TRIAL_MODAL:
    'start_free_trial_from_free_trial_modal',
  START_FREE_TRIAL_FROM_INSIDE_VIEW_MODAL:
    'start_free_trial_from_inside_view_modal',
  FREE_TRIAL_CREATION_FAILURE: 'free_trial_creation_failure',
  PURCHASE_DISCOUNTED_PLAN_FROM_TRIAL_ENDING_SOON_MODAL:
    'purchase_discounted_plan_from_trial_ending_soon_modal',
  PURCHASE_DISCOUNTED_PLAN_FROM_TRIAL_ENDED_MODAL:
    'purchase_discounted_plan_from_trial_ended_modal',
  load_cancelledFreeTrial: 'load_cancelledFreeTrial',
  load_cancelledSubscription: 'load_cancelledSubscription',
  click_manageFreeTrial_upgradeButton: 'click_manageFreeTrial_upgradeButton',
  click_premiumDiscountModal_getPremiumButton:
    'click_premiumDiscountModal_getPremiumButton',
  PURCHASE_PAID_PLAN_AFTER_FREE_TRIAL: 'purchase_paid_plan_after_free_trial',
  PAUSED_SUBSCRIPTION: 'paused_subscription',
  RESUMED_SUBSCRIPTION: 'resumed_subscription',
  REACTIVATED_SUBSCRIPTION: 'reactivated_subscription',

  // Teams pages
  CLICK_UPGRADE_PLAN_FROM_TEAM_SETTINGS_PAGE:
    'click_upgrade_plan_from_team_settings_page',
  CLICK_PROJECTS_BUTTON_FROM_TEAM_SUBSCRIPTIONS_SUCCESS_PAGE:
    'click_teams_subscriptions_success_click_projects_menu_item',
  CLICK_AI_DETECTION_BUTTON_FROM_TEAM_SUBSCRIPTIONS_SUCCESS_PAGE:
    'click_teams_subscriptions_success_click_ai_detection_menu_item',
  CLICK_CHROME_EXTENSION_BUTTON_FROM_TEAM_SUBSCRIPTIONS_SUCCESS_PAGE:
    'click_teams_subscriptions_success_click_chrome_extensions_link',

  CLICK_BATCH_FILE_UPLOAD_BUTTON_FROM_TEAM_INVITATION_ACCEPT_PAGE:
    'click_batch_file_menu_item_from_team_invitation_accept_page',
  CLICK_WRITING_REPORTS_BUTTON_FROM_TEAM_INVITATION_ACCEPT_PAGE:
    'click_writing_reports_menu_item_from_team_invitation_accept_page',
  CLICK_SCAN_HISTORY_BUTTON_FROM_TEAM_INVITATION_ACCEPT_PAGE:
    'click_scan_history_menu_item_from_team_invitation_accept_page',
  CLICK_CANCEL_TEAM_PLAN_FROM_TEAM_SETTINGS_PAGE:
    'click_cancel_team_plan_from_team_settings_page',
  CLICK_CONFIRM_CANCEL_TEAM_PLAN_FROM_THE_CONFIRMAION_MODAL:
    'click_confirm_cancel_team_plan_from_the_confirmation_modal',
  CLICK_MY_TEAMS_MENU_ITEM: 'click_my_teams_menu_item',
  CLICK_TEAM_MEMBERS_MENU_ITEM: 'click_team_members_menu_item',
  CLICK_TEAM_SUBSCRIPTIONS_MENU_ITEM: 'click_team_subscriptions_menu_item',
  CLICK_DECLINE_TEAM_INVITATION_BUTTON: 'click_decline_team_invitation_button',
  CLICK_ACCEPT_TEAM_INVITATION_BUTTON: 'click_accept_team_invitation_button',
  CLICK_CREATE_TEAM_BUTTON: 'click_create_team_button',
  CLICK_CREATE_TEAM_FORM_SUBMIT_BUTTON: 'click_create_team_form_submit_button',
  CLICK_CREATE_TEAM_PURCHASE_PLAN_BUTTON:
    'click_create_team_purchase_plan_button',
  CLICK_EDIT_TEAM_BUTTON: 'click_edit_team_button',
  CLICK_EDIT_TEAM_FORM_SUBMIT_BUTTON: 'click_edit_team_form_submit_button',
  CLICK_ADD_TEAM_MEMBER_BUTTON: 'click_add_team_member_button',
  CLICK_DELETE_TEAM_MEMBER_BUTTON: 'click_delete_team_member_button',
  CLICK_EDIT_TEAM_MEMBER_BUTTON: 'click_edit_team_member_button',
  CLICK_DELETE_TEAM_MEMBERS_MODAL_CONFIRM_BUTTON:
    'click_delete_team_members_modal_confirm_button',
  CLICK_RESEND_TEAM_INVITATION_BUTTON: 'click_resend_team_invitation_button',
  CLICK_INVITE_TEAM_MEMBERS_FORM_SUBMIT_BUTTON:
    'click_invite_team_members_form_submit_button',
  CLICK_FILTER_SEARCH_TEAM_MEMBERS: 'click_filter_search_team_members',

  // Share Scans
  CLICK_SCAN_VISIBILITY_TOGGLE: 'click_scan_visibility_toggle',
  CLICK_SHARE_SCAN_BUTTON: 'click_share_scan_button',
  CLICK_SHARE_SCAN_MODAL_COPY_LINK_BUTTON:
    'click_share_scan_modal_copy_link_button',
  CLICK_SHARE_SCAN_MODAL_CLOSE_BUTTON: 'click_share_scan_modal_close_button',
  CLICK_PUBLIC_SCAN_GET_STARTED_BUTTON: 'click_public_scan_get_started_button',
  OPENED_GDOCS_SHARED_REPORT: 'opened_gdocs_shared_report',

  // humanmark
  SORT_HUMANMARK_TABLE_BY_DATE: 'sort_humanmark_table_by_date',
  CLICK_HUMANMARK_DETAILS_BUTTON: 'click_humanmark_details_button',
  CLICK_HUMANMARK_RETRY_FETCH_BUTTON: 'click_humanmark_retry_fetch_button',
  UPDATE_HUMANMARK_STATUS: 'update_humanmark_status',

  CLICK_UPGRADE_PLAN_FROM_LEFT_SIDE_BAR:
    'click_upgrade_plan_from_left_side_bar',

  // promocode
  CLICK_UPGRADE_PLAN_USING_COUPON: 'click_upgrade_plan_using_coupon',
  CLICK_CANCEL_UPGRADE_PLAN_USING_COUPON:
    'click_cancel_upgrade_plan_using_coupon',
  CLICK_COPY_COUPON_CODE: 'click_copy_coupon_code',

  // credits
  CLICK_REDEEM_CREDITS_BUTTON: 'click_redeem_credits_button',
  REDEEM_CREDITS_SUCCESS: 'click_redeem_credits_success',
  REDEEM_CREDITS_FAILURE: 'click_redeem_credits_failure',

  //   writing feedback
  GET_WRITING_FEEDBACK_FAILURE: 'get_writing_feedback_failure',
  GET_WRITING_FEEDBACK_SUCCESS: 'get_writing_feedback_success',

  // ai check
  AI_CHECK_SUCCESS: 'ai_check_success',
  AI_CHECK_FAILURE: 'ai_check_failure',

  // plagiarism check
  PLAGIARISM_CHECK_SUCCESS: 'plagiarism_check_success',
  PLAGIARISM_CHECK_FAILURE: 'plagiarism_failure',
};

// ------- New constants in line with trackEventsV2 ----------
export const eventTrackingActions = {
  CLICK: 'click',
  HOVER: 'hover',
  TOGGLE: 'toggle',
  FETCH: 'fetch',
  SUBSCRIBE: 'subscribe',
  LOAD: 'load',
  VIEW: 'view',
  DROP: 'drop', // useful for tracking when a user drops a file into a dropzone
  DISMISS: 'dismiss', // useful for tracking when a user dismisses a modal, but didn't necessarily click a button
};

export const componentsToTrack = {
  ONBOARDING_GUIDES: 'onboardingGuides',
  ONBOARDING_FEATURES: 'onboardingFeatures',
  ONBOARDING_TUTORIALS: 'onboardingTutorials',
  ONBOARDING_NEW_SCAN: 'onboardingNewScan',
  ONBOARDING_WELCOME_BANNER: 'onboardingWelcomeBanner',
  ONBOARDING_INTEGRATIONS: 'onboardingIntegrations',
  LEFT_SIDEBAR: 'leftSidebar',
  ONBOARDING_BANNER: 'onboardingBanner',
  SCAN_RESULTS_VIEW: 'scanResultsView',
  EDIT_PROGRESS_BAR: 'editProgressBar',
  EDITOR_MENU_BAR: 'editorMenuBar',
  EDITOR_TOOLBAR: 'editorToolbar',
  EDITOR_DOCUMENT: 'editorDocument',
  PRE_SUBMISSION_MODAL: 'preSubmissionModal',
  WRITING_VIDEO: 'writingVideo',
  DOCUMENT_HISTORY_CONTAINER: 'documentHistoryContainer',
  MOVE_SCAN_MODAL: 'moveScanModal',
  PAGINATION: 'pagination',
  EDITOR_BANNER: 'editorBanner',
  WAITLIST_WELCOME_MODAL: 'waitListWelcomeModal',
  OFF_WAITLIST_MODAL: 'offWaitListModal',
  FREE_TRIAL: 'freeTrial',
  PLAGIARISM_FREE_TRIAL_MODAL: 'plagiarismFreeTrialModal',
  EDITOR_INTRO_TOOLTIP: 'editorIntroTooltip',
  PREMIUM_DISCOUNT_MODAL: 'premiumDiscountModal',
  WRITING_FEEDBACK_FREE_TRIAL_MODAL: 'writingFeedbackFreeTrialModal',
  TEAMS_ONBOARDING_BANNER: 'teamsOnboardingBanner',
  BILLING: 'billing',
  ADD_PLAGIARISM: 'addPlagiarism',
  ADD_WRITING_ANALYSIS: 'addWritingAnalysis',
  TEAMS_SETTINGS: 'teamSettings',
  TEAMS_DASHBOARD: 'teamDashboard',
  ADD_DEEP_SCAN: 'addDeepScan',
  ADD_WRITING_FEEDBACK: 'addWritingFeedback',
  TEAMS_HOME_BANNER: 'teamsHomeBanner',
  PREMIUM_BENEFITS_CARD: 'premiumBenefitsCard',
  TEAM_SETUP_CHECKLIST: 'teamSetupChecklist',
  TEAMS_WELCOME_BANNER: 'teamsWelcomeBanner',
  SWITCH_TO_TEAM_BANNER: 'switchToTeamBanner',
  TEAM_MEMBERS_PAGE: 'teamMembersPage',
  DELETE_TEAM_MEMBERS_MODAL: 'deleteTeamMembersModal',
  RESEND_TEAM_INVITES_MODAL: 'resendTeamInvitesModal',
  EDIT_TEAM_MEMBER_MODAL: 'editTeamMemberModal',
  INVITE_TEAM_MEMBERS_MODAL: 'inviteTeamMembersModal',
  CSV_UPLOAD_MODAL: 'csvUploadModal',
  UPGRADE_TEAM_MODAL: 'upgradeTeamModal',
  WRITING_REPORT_ACTIVITY_STATS: 'writingReportActivityStats',
  BILLING_UPGRADE_PLAN: 'billingUpgradePlan',
  ACCOUNT_SETTINGS: 'accountSettings',
  MANAGE_FREE_TRIAL: 'manageFreeTrial',
  SUMMER_SALE_BANNER: 'summerSaleBanner',
  AI_SCAN_PANE: 'aiScanPane',
  HEADER_NAVBAR: 'headerNavbar',
  ANNUAL_FREE_TRIAL_FORM: 'annualFreeTrialForm',
  AI_SCAN: 'aiScan',
  SCAN_DROPDOWN_MENU: 'scanDropdownMenu',
  SIDEBAR_ROUTES_LIST_SECTION: 'sidebarRoutesListSection',
  SIDEBAR_SUBMENU: 'sidebarSubmenu',
  PROFILE_ACTIONS_LIST: 'profileActionsList',
  MIDDLE_DRAWER: 'middleDrawer',
  PUBLIC_SCAN_BANNER: 'publicScanBanner',
  PREMIUM_FEATURE_UPSELL_MODAL: 'premiumFeatureUpsellModal',
  LANDING_TO_DASHBOARD: 'landingToDashboard',
};

export const elementsToTrack = {
  FILE_UPLOAD_BUTTON: 'fileUploadButton',
  NEW_SCAN_BUTTON: 'newScanButton',
  CHANGE_ROLE_BUTTON: 'changeRoleButton',
  UPGRADE_PLAN_BUTTON: 'upgradePlanButton',
  COLLAPSE_BUTTON: 'collapseButton',
  REQUEST_REVISIONS_BUTTON: 'requestRevisionsButton',
  GO_TO_EDITOR_BUTTON: 'goToEditorButton',
  AI_SCAN_BUTTON: 'aiScanButton',
  VIEW_REPORT_BUTTON: 'viewReportButton',
  SHARE_BUTTON: 'shareButton',
  TOGGLE_HIGHLIGHT_SENTENCES: 'toggleHighlightSentences',
  FETCH_EDITOR_DOCUMENT: 'fetchEditorDocument',
  SUBMIT_REPORT_BUTTON: 'submitReportButton',
  GENERATE_WRITING_VIDEO_BUTTON: 'generateWritingVideoButton',
  SHARE_SCAN_MENU_BUTTON: 'shareScanMenuButton',
  MOVE_SCAN_MENU_BUTTON: 'moveScanMenuButton',
  MOVE_SCAN_LIST_BUTTON: 'moveScanListButton',
  MOVE_SCAN_BUTTON: 'moveScanButton',
  ORIGINAL_SCAN_MENU_BUTTON: 'originalScanMenuButton',
  PAGINATION_BUTTON: 'paginationButton',
  JOIN_WAITLIST_BUTTON: 'joinWaitlistButton',
  JOIN_THE_COMMUNITY_BUTTON: 'joinTheCommunityButton',
  WATCH_THE_DEMO_BUTTON: 'watchTheDemoButton',
  PLAGIARISM_CHECKBOX: 'plagiarismCheckbox',
  NEXT_BUTTON: 'nextButton',
  FINISH_BUTTON: 'finishButton',
  GET_PREMIUM_BUTTON: 'getPremiumButton',
  PAUSE_SUBSCRIPTION_BUTTON: 'pauseSubscriptionButton',
  RESUME_SUBSCRIPTION_BUTTON: 'resumeSubscriptionButton',
  REACTIVATE_PLAN_BUTTON: 'reactivatePlanButton',
  ADD_PLAGIARISM_BUTTON: 'addPlagiarismButton',
  ADD_SEATS_BUTTON: 'addSeatsButton',
  CANCEL_PLAN_BUTTON: 'cancelPlanButton',
  PURCHASE_PLAN_BUTTON: 'purchasePlanButton',
  ADD_DEEP_SCAN_BUTTON: 'addDeepScanButton',
  ADD_WRITING_ANALYSIS_BUTTON: 'addWritingAnalysisButton',
  CONFIRM_CANCEL_PLAN_BUTTON: 'confirmCancelPlanButton',
  ADD_WRITING_FEEDBACK_BUTTON: 'addWritingFeedbackButton',
  UPDATE_SEATS_BUTTON: 'updateSeatsButton',
  CREATE_NEW_TEAM_BUTTON: 'createNewTeamButton',
  CHANGE_PLAN_BUTTON: 'changePlanButton',
  ADD_TEAM_MEMBERS_BUTTON: 'addTeamMembersButton',
  SWITCH_TO_TEAM_PLAN_BUTTON: 'switchToTeamPlanButton',
  GET_STARTED_BUTTON: 'getStartedButton',
  DELETE_TEAM_MEMBERS_BUTTON: 'deleteTeamMembersButton',
  RESEND_TEAM_INVITES_BUTTON: 'resendTeamInvitesButton',
  EDIT_TEAM_MEMBER_BUTTON: 'editTeamMemberButton',
  SEND_INVITES_BUTTON: 'sendInvitesButton',
  DOWNLOAD_SAMPLE_CSV_BUTTON: 'downloadSampleCsvButton',
  UPLOAD_CSV_FILE_BUTTON: 'uploadCsvFileButton',
  CHANGE_TEAM_NAME_BUTTON: 'changeTeamNameButton',
  CHANGE_TEAM_DESCRIPTION_BUTTON: 'changeTeamDescriptionButton',
  CHANGE_TEAM_OWNER_BUTTON: 'changeTeamOwnerButton',
  DELETE_TEAM_BUTTON: 'deleteTeamButton',
  CHART_PLAY_VIDEO_BUTTON: 'chartPlayVideoButton',
  CHART_OVERLAY_CLICK: 'chartOverlayClick',
  PLAY_FULL_VIDEO_BUTTON: 'playFullVideoButton',
  SUMMER_SALE_PILL: 'summerSalePill',
  PREMIUM_PLAN_BUTTON: 'premiumPlanButton',
  END_TRIAL_BUTTON: 'endTrialButton',
  CLOSE_SUMMER_SALE_BANNER_BUTTON: 'closeSummerSaleBannerButton',
  CLAIM_SUMMER_DISCOUNT_BUTTON: 'claimSummerDiscountButton',
  TOGGLE_ADVANCED_SCAN: 'toggleAdvancedScan',
  SECOND_FREE_TRIAL_ATTEMPT: 'secondFreeTrialAttempt',
  PREMIUM_MONTHLY_UPGRADE_BUTTON: 'premiumMonthlyUpgradeButton',
  START_FREE_TRIAL_BUTTON: 'startFreeTrialButton',
  TOGGLE_INSIDE_VIEW: 'toggleInsideView',
  EDITOR_NEW_BUTTON: 'editorNewButton',
  SIDEBAR_MENU_ITEM: 'sidebarMenuItem',
  SIDEBAR_SUBMENU_ITEM: 'sidebarSubmenuItem',
  TOGGLE_PLAGIARISM: 'togglePlagiarism',
  ON_LINK_CLICK: 'onLinkClick',
  FEEDBACK_MODAL_OPEN: 'feedbackModalOpen',
  SUB_MENU_ITEM: 'subMenuItem',
  CLICK_PUBLIC_SCAN_GET_STARTED_BUTTON: 'click_public_scan_get_started_button',
  CLICK_PUBLIC_SCAN_CREATE_ACCOUNT_LINK:
    'click_public_scan_create_account_link',
  MONTHLY_FREE_TRIAL_BUTTON: 'monthlyFreeTrialButton',
  ANNUAL_PLAN_BUTTON: 'annualPlanButton',
  DOWNLOAD_SCAN_RESULTS_BUTTON: 'downloadScanResultsButton',
  CREATE_FREE_ACCOUNT_BUTTON: 'createFreeAccountButton',
};
