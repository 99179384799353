export const externalLinks = {
  termsOfService: 'https://gptzero.me/terms-of-use.html',
  privacyPolicy: 'https://gptzero.me/privacy-policy.html',
  modelVersion:
    'https://gptzero.notion.site/GPTZero-Release-Notes-Model-and-API-6f58686f6381498baef35212463b7da6',
};

export const domains = {
  DASHBOARD: process.env.NEXT_PUBLIC_DASHBOARD_HOSTNAME,
};

export const otherLinks = {
  CONTACT_US: '/contact-us',
  DASHBOARD_CREATE_TEAM: `${domains.DASHBOARD}/app/tenants/create`,
  DASHBOARD_SUBSCRIPTION_PLANS: `${domains.DASHBOARD}/app/subscription-plans`,
  DASHBOARD_REGISTER: `${domains.DASHBOARD}/register`,
};
