import React from 'react';
import './ClassificationProbabilityBar.css';
import SubText from '../../atoms/typography/SubText/SubText';

export interface ClassificationProbabilityBarProps {
  aiClassProbability: number;
  mixedClassProbability: number;
  humanClassProbability: number;
  className?: string;
}

const ClassificationProbabilityBar: React.FC<
  ClassificationProbabilityBarProps
> = ({
  aiClassProbability,
  mixedClassProbability,
  humanClassProbability,
  className,
}) => {
  return (
    <div
      data-testid="classification-probability-bar"
      className={`classification-probability-bar ${className || ''}`}
    >
      <div className="bar-container">
        <div
          className="classification-segment human"
          style={{ width: `${humanClassProbability}%` }}
        ></div>
        <div
          className="classification-segment mixed"
          style={{ width: `${mixedClassProbability}%` }}
        ></div>
        <div
          className="classification-segment ai"
          style={{ width: `${aiClassProbability}%` }}
        ></div>
      </div>
      <div className="labels-container">
        <div className="classification-label human">
          <SubText>human</SubText>
          <SubText>{humanClassProbability}%</SubText>
        </div>
        <div className="classification-label mixed">
          <SubText>mixed</SubText>
          <SubText>{mixedClassProbability}%</SubText>
        </div>
        <div className="classification-label ai">
          <SubText>ai</SubText>
          <SubText>{aiClassProbability}%</SubText>
        </div>
      </div>
    </div>
  );
};

export default ClassificationProbabilityBar;
