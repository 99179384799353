import React, { ReactElement, ReactNode } from 'react';

interface ShouldRenderProps {
  render: boolean;
  children: ReactNode;
}

const ShouldRender: React.FC<ShouldRenderProps> = ({
  children,
  render,
}): ReactElement | null => {
  return render ? <>{children}</> : null;
};

export default ShouldRender;
