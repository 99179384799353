import React from 'react';
import useConfirmEmail from '../../../../hooks/auth/useConfirmEmail';
import MobileModal from '../../MobileModal';
import {
  ConfirmEmailModalHeader,
  ContactSupport,
  ResendEmailButton,
  ReturnToLoginButton,
} from '../common/ConfirmEmailElements';

interface MobileConfirmEmailModalProps {
  email: string;
  isOpen: boolean;
  onClickReturnToLogin: () => void;
  toggleOpen: (open: boolean) => void;
}

const MobileConfirmEmailModal = ({
  email,
  isOpen,
  toggleOpen,
  onClickReturnToLogin,
}: MobileConfirmEmailModalProps) => {
  const { loading, onClickResendEmail, onClickCustomerSupport } =
    useConfirmEmail({ email });

  const onClose = () => {
    toggleOpen(false);
  };

  return (
    <MobileModal isOpen={isOpen} onClose={onClose}>
      <ConfirmEmailModalHeader className="my-6" />
      <hr className="border-g0-gray-100 my-4" />
      <ContactSupport
        onClickCustomerSupport={onClickCustomerSupport}
        className="mx-12"
        email={email}
      />
      <div className="flex flex-col justify-center gap-2 my-8 mx-12">
        <ResendEmailButton
          onClickResendEmail={onClickResendEmail}
          className="flex justify-center"
          loading={loading}
        />
        <ReturnToLoginButton
          onClickReturnToLogin={onClickReturnToLogin}
          className="flex justify-center"
        />
      </div>
    </MobileModal>
  );
};

export default MobileConfirmEmailModal;
