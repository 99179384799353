import React, { CSSProperties } from 'react';
import { FilledSphereProps } from './FilledSphere.types';
import './FilledSphere.css';

const FilledSphere: React.FC<FilledSphereProps> = ({
  numSentencesWrittenByAI,
  totalSentences,
  size = '94px',
  color = 'var(--mixed-result-purple)',
  borderSize = '7px',
  borderColor = 'var(--mixed-result-bg-purple)',
  className = '',
}) => {
  const value = (numSentencesWrittenByAI / totalSentences) * 100;
  const sphereStyle = {
    '--value': `${value}`,
    '--size': size,
    '--color': color,
    '--border-color': borderColor,
    '--border-size': borderSize,
  } as CSSProperties;

  return (
    <div
      data-testid="filled-sphere-container"
      className={`filled-sphere-container ${className}`}
      style={sphereStyle}
    >
      <div className="filled-sphere-inner">
        <div className="filled-sphere-fill"></div>
      </div>
    </div>
  );
};

export default FilledSphere;
