import styled from 'styled-components';
import { LinkProps } from './link.types';

export const StyledLink = styled.a<LinkProps>`
  color: ${(props) => props.$color || '#000'};
  opacity: ${(props) => props.$opacity};
  font-size: ${(props) => props.$fontSize};
  text-decoration: underline;

  &:hover {
    opacity: 1;
  }
`;
