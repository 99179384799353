import styled from 'styled-components';
import { ButtonProps } from './button.types';

const primaryColor = '#3B3A3B';
const secondaryColor = '#9099BA';
const tertiaryColor = '#50A0FF';

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: ${(props) => !props.$rounded && '0 20px'};
  cursor: pointer;
  font-weight: 500;
  font-size: ${(props) => (props.$size === 'small' ? '10px' : '14px')};
  width: ${(props) => props.$width || 'auto'};
  border-radius: ${(props) => (props.$rounded ? '50px' : '5px')};
  height: ${(props) =>
    props.$height
      ? props.$height
      : props.$size === 'small'
        ? '30px'
        : props.$size === 'medium'
          ? '34px'
          : '39px'};
  background: ${(props) =>
    props.$outlined || props.$dotted || props.$secondary || props.$ghost
      ? 'transparent'
      : primaryColor};
  color: ${(props) =>
    props.$secondary
      ? secondaryColor
      : props.$tertiary
        ? tertiaryColor
        : props.$outlined || props.$dotted || props.$ghost
          ? primaryColor
          : '#fff'};
  border: ${(props) => props.$outlined && `1px solid`};
  border-color: ${(props) =>
    props.$secondary
      ? secondaryColor
      : props.$tertiary
        ? tertiaryColor
        : props.$outlined
          ? primaryColor
          : ''};
  border-style: ${(props) => props.$dotted && 'dotted'};
`;
