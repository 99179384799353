import React from 'react';
import SingleValuePlot from './singleValuePlot';
import {
  sortAndReverseDesignatedValues,
  sortDesignatedValues,
} from './helpers';
import { IScanResult } from '../../../types/types';

interface AdvancedScanPlotProps {
  document: IScanResult;
  className?: string;
  height?: string;
  setActiveSentence: (activeSentence: number) => void;
  handleClickInterpretabilityValue?: ({ id }: { id: number }) => void;
  tooltipClasses?: string;
}

function AdvancedScanPlot({
  document,
  className,
  height,
  tooltipClasses = 'bottom-8',
  setActiveSentence,
  handleClickInterpretabilityValue,
}: AdvancedScanPlotProps) {
  const normalizeSentenceData = document?.sentences?.map(
    (sentence: any, index: number) => ({
      ...sentence,
      id: index + 1,
      interpretability_normalized_value:
        Math.round(
          (sentence.shapley_normalized_value !== undefined
            ? sentence.shapley_normalized_value
            : sentence.interpretability_normalized_value) * 100
        ) / 100,
    })
  );

  const aiSentences = normalizeSentenceData?.filter(
    (sentence: any) =>
      (sentence &&
        ('shapley_designation' in sentence
          ? sentence.shapley_designation
          : sentence.interpretability_designation)) === 'ai'
  );

  const humanSentences = normalizeSentenceData?.filter(
    (sentence: any) =>
      (sentence &&
        ('shapley_designation' in sentence
          ? sentence.shapley_designation
          : sentence.interpretability_designation)) === 'human'
  );

  const completelyGeneratedProbability =
    document?.completely_generated_prob * 100;

  return (
    <>
      <div
        className={`w-full mx-auto mb-12 border flex flex-row ${className}`}
        style={{ height: height ? height : '16px' }}
      >
        <div
          className={`relative flex justify-between`}
          style={{
            width: `${
              completelyGeneratedProbability > 10
                ? completelyGeneratedProbability
                : 5
            }%`,
          }}
        >
          {sortAndReverseDesignatedValues(aiSentences)?.map(
            (sentence, index) => (
              <SingleValuePlot
                id={sentence?.id}
                key={sentence?.id}
                sentence={sentence}
                index={index}
                setActiveSentence={setActiveSentence}
                handleClickInterpretabilityValue={
                  handleClickInterpretabilityValue
                }
                wrapperWidth={completelyGeneratedProbability}
                tooltipClasses={tooltipClasses}
              />
            )
          )}
          <div
            className={`absolute left-[-5px] label-small text-g0-ai-yellow-500`}
            style={{ top: height ? `calc(${height} + 5px)` : '20px' }}
          >
            AI
          </div>
          <div
            className={'w-[2px] absolute right-0 bg-[#777777] top-0'}
            style={{ height: height ? `calc(${height} - 1px)` : '15px' }}
          ></div>
        </div>
        <div
          style={{
            width: `${
              completelyGeneratedProbability < 90
                ? 100 - completelyGeneratedProbability
                : 10
            }%`,
          }}
          className={`flex relative`}
        >
          {sortDesignatedValues(humanSentences)?.map((sentence, index) => (
            <SingleValuePlot
              id={sentence?.id}
              key={sentence?.id}
              sentence={sentence}
              index={index}
              isHumanValue={true}
              setActiveSentence={setActiveSentence}
              handleClickInterpretabilityValue={
                handleClickInterpretabilityValue
              }
              wrapperWidth={100 - completelyGeneratedProbability}
              tooltipClasses={tooltipClasses}
            />
          ))}
          <div
            className={`absolute right-[-15px] label-small text-g0-human-green-500`}
            style={{ top: height ? `calc(${height} + 5px)` : '20px' }}
          >
            Human
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvancedScanPlot;
