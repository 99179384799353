import { createSlice } from '@reduxjs/toolkit';
import { today } from '../../utils/dateUtils';

const defaultDocTitle = `Document — ${today()}`;

export const textScanSlice = createSlice({
  name: 'uploadedFile',
  initialState: {
    title: defaultDocTitle,
    author: localStorage.getItem('fullname') || '',
    inputtedText: '',
    isFileUploaded: false,
    docHasDefaultTitle: true,
    userHasEditedTitle: false,
  },
  reducers: {
    setTitle(state, action) {
      state.title = action.payload.title;
      state.docHasDefaultTitle = state.title === defaultDocTitle;
    },
    setAuthor(state, action) {
      state.author = action.payload.author;
    },
    setInputtedText(state, action) {
      state.inputtedText = action.payload.inputtedText;
    },
    setUploadedFile(state, action) {
      state.isFileUploaded = action.payload.isFileUploaded;
    },
    setUserHasEditedTitle(state, action) {
      state.userHasEditedTitle = action.payload.userHasEditedTitle;
    },
  },
});

export const {
  setTitle,
  setAuthor,
  setInputtedText,
  setUploadedFile,
  setUserHasEditedTitle,
} = textScanSlice.actions;

export default textScanSlice.reducer;
