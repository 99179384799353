const LocalStorageKeys = {
  email: 'email',
  id: 'id',
  joinPageLink: 'joinPageLink',
  acceptUserInviteLink: 'acceptUserInviteLink',
  createTeamLink: 'createTeamLink',
  plan: 'plan',
  fullName: 'fullname',
  hasExistingTeams: 'hasExistingTeams',
  hasSeenTutorial: 'hasSeenTutorial',
  teamInviteStatus: 'teamInviteStatus',
  newUserSendWelcome: 'newUserSendWelcome',
  editorOnboardingViewed: 'editorOnboardingViewed',
  referralID: 'referralID',
  referralName: 'referralName',
  editorWaitlistBannerDismissed: 'editorWaitlistBannerDismissed',
  editorWaitListBannerViewCount: 'editorWaitListBannerViewCount',
  editorAccessGrantedBannerViewed: 'editorAccessGrantedBannerViewed',
  offEditorWaitListModalDismissed: 'offEditorWaitListModalDismissed',
  editorOnboardingTooltipsDismissed: 'editorOnboardingTooltipDismissed',
  acceptTeamInviteLink: 'acceptTeamInviteLink',
  newUserUpdateDetailsAndJoinTeam: 'newUserUpdateDetailsAndJoinTeam',
  hasTeamsWithPaidPlans: 'hasTeamsWithPaidPlans',
  nexusBannerDismissed: 'nexusBannerDismissed',
  extraChargeForUpgrade: 'extraChargeForUpgrade',
  awinStripeTrackingData: 'awinStripeTrackingData',
  batchLimit: 'batch_limit',
  advancedScanUsages: 'advancedScanUsages',
  lastAdvancedScanUsageDate: 'lastAdvancedScanUsageDate',
  sourcesScanUsages: 'sourcesScanUsages',
  lastSourcesScanUsageDate: 'lastSourcesScanUsageDate',
  showDiscountModal: 'showDiscountModal',
  aiUtilitiesLoadCount: 'aiUtilitiesLoadCount',
};

export default LocalStorageKeys;
