export const splitIntoWords = (text: string) => {
  return text
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .replace(/\s+/g, ' ')
    .split(' ');
};

export const getNumWords = (text: string) => {
  // Convert multiple consecutive spaces and/or newlines to a single space to count words
  return splitIntoWords(text).length;
};
