import styled from 'styled-components';
import { StyledContainer } from '../../../atoms/container/container.styles';

export const EditorWrapper = styled(StyledContainer)`
  width: 86%;

  @media (min-width: 768px) {
    width: 82%;
  }
`;

export const FooterWrapper = styled(StyledContainer)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 5px;
  flex-wrap: wrap;

  div,
  button {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media (min-width: 768px) {
    margin-top: 30px;
    margin-bottom: 20px;

    div,
    button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export const WrapperDesktop = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const WrapperMobile = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const CharLimitAndUpgradeWrapperMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: baseline;
  gap: 10px;
`;

export const CenterWrapper = styled(StyledContainer)`
  @media (min-width: 768px) {
    width: 145px;
  }
`;
