import React, { forwardRef } from 'react';
import './styles.css';
import SourceResultHeading from '../../../molecules/SourceResultHeading';
import { MatchCardContentSkeleton } from '../../../../assets/skeletons';
import { getNumWords } from '../../../../helpers/textUtils';
import { SourcePlagiarism } from '../../../../types/sourcesSurfacing';
import { IMatchPlagiarism } from '../../../molecules/PlagiarismHighlight/types';

interface PlagiarismMatchCardV2Props {
  source: SourcePlagiarism;
  index: number;
  loading: boolean;
  userCanViewPlagiarism: boolean;
  isClicked: boolean;
  onClick: () => void;
  plagiarismActiveMatches: IMatchPlagiarism[];
  inputText: string;
}

const PlagiarismMatchCardV2 = forwardRef<
  HTMLDivElement,
  PlagiarismMatchCardV2Props
>(
  (
    {
      source,
      index,
      loading,
      userCanViewPlagiarism,
      isClicked,
      onClick,
      plagiarismActiveMatches,
      inputText,
    },
    ref
  ) => {
    const score = Math.round(source?.matches[0]?.score);
    const isExactMatch = score >= 100;

    const bgColor = isClicked
      ? isExactMatch
        ? 'bg-g0-red-50 border border-g0-red-400'
        : 'bg-g0-ai-yellow-50 border border-g0-ai-yellow-600'
      : plagiarismActiveMatches?.some((match) => match.url === source.url)
        ? isExactMatch
          ? 'bg-g0-red-50 border border-g0-red-400'
          : 'bg-g0-ai-yellow-50 border border-g0-ai-yellow-600'
        : '';

    const matchedText =
      source?.matches.map((match) => match.matchText).join('... ') || '';
    const matchedWords = getNumWords(matchedText);

    return (
      <div
        ref={ref}
        className={`plagiarism-match-card-wrapper-v2 hover:bg-g0-gray-50 ${bgColor}`}
        onClick={onClick}
      >
        {loading && !userCanViewPlagiarism ? (
          <MatchCardContentSkeleton />
        ) : (
          <>
            <SourceResultHeading
              publisher={source?.publisher}
              title={source?.title}
              url={source?.url}
              number={index + 1}
              isExactMatch={isExactMatch}
            />
            <div className={'citation-card-text-v2 text-g0-secondary'}>
              "{matchedText}"
            </div>
            <div className={'citation-card-footer-v2'}>
              {matchedWords} {matchedWords === 1 ? 'word' : 'words'} matched
            </div>
          </>
        )}
      </div>
    );
  }
);

export default PlagiarismMatchCardV2;
