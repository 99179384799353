import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useScanV3 from '../useScanV3';
import usePollPlagiarismResults from './usePollPlagiarismResults';
import { ScanStatus } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import {
  setHasViewedPlagiarismScanResults,
  setOverallScanId,
  setPlagiarismScanStatus,
} from '../../app/store/aiUtilitiesHubSlice';
import { getFirstSentence } from '../../utils/textUtils';
import WhitelabelContext from '../../contexts/WhitelabelContext';

export function useRequestTextPlagiarismScan() {
  const aiUtilitiesHub = useSelector((state) => state?.aiUtilitiesHub);
  const { overallScanId } = aiUtilitiesHub || {};

  const whitelabelConfig = useContext(WhitelabelContext);
  const { backendHostname, dashboardHostname } = whitelabelConfig;
  const dispatch = useDispatch();
  const { createScan } = useScanV3();

  const { startPolling } = usePollPlagiarismResults();

  const handleResponseNotOkay = async (result) => {
    const serverErrorMessage = await result.json();
    let toastOptions = {};
    if (result.status === 429) {
      toastOptions.onClick = () => {
        window.open(`${dashboardHostname}/app/subscription-plans`, '_blank');
      };
    }
    toast.error(
      '🤔 We got an error processing your text: ' + serverErrorMessage.error,
      toastOptions
    );

    const errorStatusPayload = { status: ScanStatus.error };

    dispatch(setPlagiarismScanStatus(errorStatusPayload));
  };

  const requestTextPlagiarismScan = async ({
    scanId,
    text,
    checkerProfile,
    files = [],
  }) => {
    if (!backendHostname) {
      return;
    }

    if (text.length < checkerProfile.charMinimum) {
      return toast.error(
        `✍️ Try typing in some more text (>${checkerProfile.charMinimum} characters) to get accurate results`
      );
    }

    const loadingStatusPayload = { status: ScanStatus.loading };

    dispatch(setPlagiarismScanStatus(loadingStatusPayload));
    dispatch(setHasViewedPlagiarismScanResults({ hasViewedResults: false }));

    let newScanId;
    let scanTitle;
    let scanDate;
    let scanAuthor;
    if (!overallScanId && !scanId) {
      const fileToScan = files?.[0];
      scanTitle = fileToScan?.name || getFirstSentence(text);
      const {
        data: { id, author, createdAt },
      } = await createScan({ title: scanTitle, aiDocumentId: null });

      dispatch(setOverallScanId({ overallScanId: id }));

      newScanId = id;
      scanAuthor = author;
      scanDate = createdAt;
    }

    try {
      const result = await fetch(
        `${backendHostname}${checkerProfile.textRoute}`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            scanId: scanId || newScanId,
            document: text,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!result.ok) {
        return handleResponseNotOkay(result);
      }

      const response = await result.json();

      if (response.scanId) {
        startPolling({
          scanId: response.scanId,
          scanTitle,
          scanAuthor,
          scanDate,
        });
      } else {
        throw new Error('Scan ID not returned');
      }
    } catch (err) {
      toast.error(
        err.message ?? 'We got an issue scanning for plagiarism matches'
      );
      const errorStatusPayload = { status: ScanStatus.error };

      dispatch(setPlagiarismScanStatus(errorStatusPayload));
    }
  };

  return {
    requestTextPlagiarismScan,
  };
}

export default useRequestTextPlagiarismScan;
