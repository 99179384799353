import React, { useState, useEffect, useRef } from 'react';
import PlagiarismMatchCardV2 from '../PlagiarismMatchCardV2';
import ZeroMatchesCard from '../../../molecules/ZeroMatchesCard';
import { SourcePlagiarism } from '../../../../types/sourcesSurfacing';
import { IMatchPlagiarism } from '../../../molecules/PlagiarismHighlight/types';

interface PlagiarismMatchCardsV2Props {
  showExactMatches: boolean;
  showFuzzyMatches: boolean;
  sources: SourcePlagiarism[];
  plagiarismPercentage: number;
  loading: boolean;
  userCanViewPlagiarism: boolean;
  plagiarismActiveMatches: IMatchPlagiarism[];
  inputText: string;
}

export function PlagiarismMatchCardsV2({
  showExactMatches,
  showFuzzyMatches,
  sources,
  plagiarismPercentage,
  loading,
  userCanViewPlagiarism,
  plagiarismActiveMatches = [],
  inputText,
}: PlagiarismMatchCardsV2Props) {
  const [clickedCardIndex, setClickedCardIndex] = useState<number | null>(null);
  const [matchesToDisplay, setMatchesToDisplay] = useState<SourcePlagiarism[]>(
    []
  );
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleCardClick = (index: number) => {
    if (clickedCardIndex === index) {
      setClickedCardIndex(null);
      return;
    }
    setClickedCardIndex(index);
  };

  useEffect(() => {
    let filteredMatches =
      sources?.filter((source: SourcePlagiarism) => {
        const score = Math.round(source.matches[0]?.score);

        if (showExactMatches && showFuzzyMatches) {
          return true;
        }

        if (showExactMatches) {
          return score >= 100;
        }

        if (showFuzzyMatches) {
          return score < 100;
        }

        return false;
      }) || [];

    setMatchesToDisplay(filteredMatches);
  }, [sources, showExactMatches, showFuzzyMatches]);

  useEffect(() => {
    const firstHighlightedIndex = matchesToDisplay.findIndex((source, index) =>
      plagiarismActiveMatches?.some((match) => match.url === source.url)
    );

    if (
      firstHighlightedIndex !== -1 &&
      cardRefs.current[firstHighlightedIndex]
    ) {
      cardRefs.current[firstHighlightedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [matchesToDisplay, plagiarismActiveMatches]);

  return (
    <>
      {!loading && plagiarismPercentage === 0 ? (
        <ZeroMatchesCard
          message={'No plagiarism matches found for this document.'}
        />
      ) : (
        <>
          {matchesToDisplay.length > 0 ? (
            matchesToDisplay.map((source: SourcePlagiarism, index: number) => (
              <PlagiarismMatchCardV2
                key={index}
                source={source}
                index={index}
                loading={loading}
                userCanViewPlagiarism={userCanViewPlagiarism}
                isClicked={clickedCardIndex === index}
                onClick={() => handleCardClick(index)}
                plagiarismActiveMatches={plagiarismActiveMatches}
                inputText={inputText}
                ref={(el) => (cardRefs.current[index] = el)}
              />
            ))
          ) : (
            <ZeroMatchesCard
              message={
                showExactMatches || showFuzzyMatches
                  ? `No ${
                      showExactMatches ? 'exact' : 'similar'
                    } text matches found for this document`
                  : 'Click checkboxes above to see Plagiarism matches'
              }
            />
          )}
        </>
      )}
    </>
  );
}

export default PlagiarismMatchCardsV2;
