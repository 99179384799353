import React, { createContext, useEffect, useState } from 'react';
import { Container } from '../../atoms/container';
import EditorCard from './EditorCard';
import SummaryCard from './SummaryCard';
import ModelVersion from '../../molecules/ModelVersion';
import { MainWrapper, SummaryCardWrapper, EditorWrapper } from './styles';
import { trackEvent } from '../../../helpers/track';
import { sourcesTabNames } from '../../../constants/sourcesTabNames';
import { ViewFullReport } from '../../atoms/viewFullReport';

export const UnifiedResultsContext = createContext(null);

export function UnifiedResults({
  serverResponse,
  aiScanHandler,
  setAiScanFiles,
  setDeepScanFiles,
  deepScanHandler,
  sourcesScanHandler,
  resultsScanHandler,
  version,
  quillEditor,
  deepAnalysisView,
  deepAnalysisTable,
  handleGoToDashboard,
  selectedTab,
  setSelectedTab,
  shouldBlockScan,
  showSourcesSurfacing,
  triggerAIDatasetsTextComparisonModal,
  inputText,
  loading,
  sourceSurfacingLimitReached,
  plan,
  triggerPremiumUpgradeModal,
  isLoggedIn,
  userLoggedInSourcesRateLimitReached,
  userCanViewSources,
  aiScanDocument,
  deepScanDocument,
  sourcesScanDocument,
  tabNames,
  setShowSignUpModal,
  userCanViewAiDatasets,
  userCanViewPlagiarism,
  onClickViewReport,
  isLandingPage = false,
}) {
  const COMPONENT_LABEL = 'unifiedResults';

  useEffect(() => {
    trackEvent('load', COMPONENT_LABEL, null, 0.1);
  }, []);

  const [activeSourceTab, setActiveSourceTab] = useState(
    sourcesTabNames.OVERVIEW
  );
  const [currentHighlight, setCurrentHighlight] = useState(
    sourcesTabNames.OVERVIEW
  );

  const innerResult = aiScanDocument?.documents?.[0];
  const predictedClass = innerResult?.predicted_class;
  const aiClassProbability = Math.round(
    (innerResult?.class_probabilities?.ai / 1) * 100
  );
  const mixedClassProbability = Math.round(
    (innerResult?.class_probabilities?.mixed / 1) * 100
  );
  const humanClassProbability = Math.round(
    (innerResult?.class_probabilities?.human / 1) * 100
  );
  const confidenceCategory = innerResult?.confidence_category;

  const numSentencesWrittenByAI = innerResult?.sentences?.filter(
    (sentence) => sentence?.highlight_sentence_for_ai
  )?.length;
  const totalSentences = innerResult?.sentences?.length;

  const handleClickViewButtonOrSourceTab = (
    component: string,
    element: string,
    options: any,
    tabName?: string
  ) => {
    trackEvent('click', component, element, 1, options || { component });
    if (userLoggedInSourcesRateLimitReached || plan?.toLowerCase() === 'free') {
      triggerPremiumUpgradeModal();
    } else if (!isLoggedIn) {
      setShowSignUpModal(true);
    } else {
      setActiveSourceTab(tabName || component);
    }
    setCurrentHighlight(tabName || component);
  };

  return (
    <UnifiedResultsContext.Provider
      value={{
        activeSourceTab,
        setActiveSourceTab,
        handleClickViewButtonOrSourceTab,
        triggerAIDatasetsTextComparisonModal,
        triggerPremiumUpgradeModal,
        serverResponse,
        currentHighlight,
        setCurrentHighlight,
        loading,
        sourceSurfacingLimitReached,
        plan,
        setSelectedTab,
        isLoggedIn,
        userLoggedInSourcesRateLimitReached,
        userCanViewSources,
        aiScanDocument,
        deepScanDocument,
        sourcesScanDocument,
        tabNames,
        setShowSignUpModal,
        userCanViewAiDatasets,
        userCanViewPlagiarism,
      }}
    >
      <MainWrapper>
        <EditorWrapper>
          <EditorCard
            aiScanHandler={aiScanHandler}
            setAiScanFiles={setAiScanFiles}
            setDeepScanFiles={setDeepScanFiles}
            deepScanHandler={() => {
              deepScanHandler();
              setSelectedTab(tabNames.deepScan);
            }}
            quillEditor={quillEditor}
            deepAnalysisView={deepAnalysisView}
            selectedTab={selectedTab}
            shouldBlockScan={shouldBlockScan}
            inputText={inputText}
            setSelectedTab={setSelectedTab}
          />
        </EditorWrapper>
        <SummaryCardWrapper $column>
          {isLandingPage && (
            <ViewFullReport onClickViewReport={onClickViewReport} />
          )}
          <SummaryCard
            aiClassProbability={aiClassProbability}
            humanClassProbability={humanClassProbability}
            mixedClassProbability={mixedClassProbability}
            aiProbability={aiClassProbability}
            confidenceCategory={confidenceCategory}
            predictedClass={predictedClass}
            selectedTab={selectedTab}
            didUpdateSelectedTab={setSelectedTab}
            deepAnalysisTable={deepAnalysisTable}
            handleDeepScan={deepScanHandler}
            handleGoToDashboard={handleGoToDashboard}
            handleSourcesScan={sourcesScanHandler}
            handleResultScan={resultsScanHandler}
            shouldBlockScan={shouldBlockScan}
            numSentencesWrittenByAI={numSentencesWrittenByAI}
            totalSentences={totalSentences}
            showSourcesSurfacing={showSourcesSurfacing}
            tabNames={tabNames}
          />
          <Container $width={'100%'} $justifyCenter $marginTop={'23px'}>
            <ModelVersion version={version} />
          </Container>
        </SummaryCardWrapper>
      </MainWrapper>
    </UnifiedResultsContext.Provider>
  );
}

export default UnifiedResults;
