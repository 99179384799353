import React from 'react';
import CreateAccount from '../CreateAccount';

const LoginModalHeader = ({
  onClickCreateAccount,
  className = '',
}: {
  className?: string;
  onClickCreateAccount: () => void;
}) => {
  return (
    <div className={className}>
      <div className="text-center">
        <h2 className="title-large text-g0-primary py-2">Log In</h2>
        <CreateAccount onClickCreateAccount={onClickCreateAccount} />
      </div>
    </div>
  );
};

export default LoginModalHeader;
