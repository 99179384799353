import { getUserProfileFromLocalStorage } from './localStorage';

export const commonConstants = {
  //user invitation constants
  NEW_USER_INVITATION: 'Y',
  EXISTING_USER_INVITATION: 'N',
  USER_INVITATION_ACTIVE_STATUS: 'active',
  USER_INVITATION_INVITED_STATUS: 'invited',
};

const GPTZERO_DOMAIN = '@gptzero.me';
const K16SOLUTIONS_DOMAIN = '@k16solutions.com';
const userProfile = getUserProfileFromLocalStorage();

export const isWhitelistedTeacher =
  userProfile.IS_TEACHER_WHITELISTED === 'true';
export const isAdvisorAllowListed =
  userProfile.IS_ADVISOR_ALLOWED_LIST === 'true';
export const isValidEmail = userProfile.USER_EMAIL != null;
export const FREE_TRIAL_DURATION_DAYS = 14;
export const isGPTZeroTeamMember =
  userProfile.USER_EMAIL?.includes(GPTZERO_DOMAIN);
export const isK16SolutionsUser =
  userProfile.USER_EMAIL?.includes(K16SOLUTIONS_DOMAIN);

export const scanResultSources = {
  BATCH_UPLOAD: 'batchUpload',
  SINGLE_UPLOAD: 'singleUpload',
};

export const languages = {
  ENGLISH: 'en',
  SPANISH: 'es',
  FRENCH: 'fr',
  GERMAN: 'de',
};

export const scan_completion_status = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  FAILED: 'failed',
};

export const PLAGIARISM_POLL_LIMIT = 120;

export const pauseSubMonthOptions = [1, 2, 3];

export const planStatuses = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled',
  TRIALING: 'trialing',
};

export const planStatusColours = {
  GREEN: 'text-green-500',
  YELLOW: 'text-yellow-500',
  GRAY: 'text-gray-500',
};

export const modalActions = {
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
};

export const premiumModalSources = {
  loadNewScanPage: 'loadNewScanPage',
  exceededMonthlyLimits: 'exceededMonthlyLimits',
  scanError: 'scanError',
  getMoreAdvancedScans: 'getMoreAdvancedScans',
};
