import React from 'react';

interface SentenceImpactScaleProps {
  humanScale?: boolean;
}

const humanShades = [
  '--human-impact-light-shade',
  '--human-impact-medium-shade',
  '--human-impact-dark-shade',
];
const aiShades = [
  '--ai-impact-light-shade',
  '--ai-impact-medium-shade',
  '--ai-impact-dark-shade',
];

export function SentenceImpactScale({ humanScale }: SentenceImpactScaleProps) {
  const shades = humanScale ? humanShades : aiShades;

  const r = document.querySelector(':root');
  const rs = getComputedStyle(r);

  return (
    <div className={'flex items-center gap-3'}>
      <div className={'flex gap-1'}>
        {shades.map((shade: string, index: React.Key) => (
          <div
            key={index}
            className={'w-3 h-3 rounded-full'}
            style={{
              backgroundColor: rs
                .getPropertyValue(shade)
                .replace(/'([^']*)'/, '$1'),
            }}
          />
        ))}
      </div>
      <p className={'label-small text-g0-gray-400'}>
        High {humanScale ? 'Human' : 'AI'} impact
      </p>
    </div>
  );
}

export default SentenceImpactScale;
