import React from 'react';

interface SignUpModalHeaderProps {
  isRegistrationView: boolean;
  handleLogIn: () => void;
  title: string | null;
}

const SignUpModalHeader = ({
  title,
  handleLogIn,
  isRegistrationView,
}: SignUpModalHeaderProps) => {
  return (
    <div className="text-center mt-4">
      <h2 className="title-large text-g0-primary py-2">
        {isRegistrationView ? 'Create Account' : title}
      </h2>
      <div className="body-medium">
        <span className="text-g0-secondary">Already have an account?</span>{' '}
        <span
          onClick={handleLogIn}
          className="text-g0-dark-blue-500 underline decoration-g0-dark-blue-500 cursor-pointer"
        >
          Log in
        </span>
      </div>
    </div>
  );
};

export default SignUpModalHeader;
