import React from 'react';
import { CitationBadgeProps } from './types';
import './styles.css';

export function CitationBadge({ number, isExactMatch }: CitationBadgeProps) {
  return (
    <div
      className={`citation-badge-wrapper ${
        isExactMatch ? 'citation-badge-exact-match-bg' : ''
      }`}
    >
      [{number}]
    </div>
  );
}

export default CitationBadge;
