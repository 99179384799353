import { ISentenceWithId } from '../../../types/types';

export const hasSignificantInterpretabilityImpact = (
  sentence: ISentenceWithId
) => {
  return sentence?.interpretability_normalized_value >= 0.01;
};

export const sortDesignatedValues = (values: ISentenceWithId[]) => {
  return values?.sort(
    (a, b) => a?.interpretability_alpha - b?.interpretability_alpha
  );
};

export const sortAndReverseDesignatedValues = (
  sentences: ISentenceWithId[]
) => {
  return sentences
    .filter((sentence: ISentenceWithId) =>
      hasSignificantInterpretabilityImpact(sentence)
    )
    .sort(
      (a, b) =>
        a?.interpretability_normalized_value -
        b?.interpretability_normalized_value
    )
    .reverse();
};

export const getPlotBackground = ({ sentence, isHumanValue }) => {
  const interpretabilityAlpha = sentence.interpretability_alpha.toFixed(1);
  switch (true) {
    case isHumanValue:
      return `rgba(80, 202, 143, ${sentence?.interpretability_alpha?.toFixed(
        1
      )})`;
    default:
      return `rgba(244, 191, 79, ${
        interpretabilityAlpha <= 0.1 ? '0.2' : interpretabilityAlpha
      })`;
  }
};
