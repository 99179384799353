import React from 'react';
import './styles.css';
import { mainTextSuffixByCategoryV2 } from './constants';
import CircularNumber from '../../../atoms/circularNumber';
import Checkbox from '../../../atoms/checkbox/checkbox';
import RadialProgress from '../../../molecules/RadialProgress/RadialProgress';

export interface MatchesSummaryV2Props {
  exactMatchesCount: number;
  similarMatchesCount: number;
  percentageMatch: number;
  category: 'plagiarism' | 'aiDatasets';
  showExactMatches?: boolean;
  showFuzzyMatches?: boolean;
  toggleExactMatchesVisibility?: () => void;
  toggleFuzzyMatchesVisibility?: () => void;
  totalMatchedWords: number;
}

const MatchesSummaryV2: React.FC<MatchesSummaryV2Props> = ({
  exactMatchesCount,
  similarMatchesCount,
  percentageMatch,
  category,
  showExactMatches,
  showFuzzyMatches,
  toggleExactMatchesVisibility,
  toggleFuzzyMatchesVisibility,
  totalMatchedWords,
}) => {
  return (
    <div className={'matches-summary-wrapper'}>
      <div className={'flex flex-wrap gap-8 items-center py-6 border-b'}>
        <RadialProgress
          size="5rem"
          isPlagiarism={true}
          value={percentageMatch}
        />
        <div className={'main-text'}>
          {percentageMatch}% of this document was found{' '}
          {mainTextSuffixByCategoryV2[category]}
          <div className={'text-g0-secondary'}>
            {totalMatchedWords} words matched online sources
          </div>
        </div>
      </div>

      <div className={'matches-category pt-6'}>
        <Checkbox
          id={'exact-matches-checkbox'}
          label={'Exact text matches'}
          checked={showExactMatches}
          onChange={toggleExactMatchesVisibility}
        />
        <CircularNumber number={exactMatchesCount} isExactMatch />
      </div>

      <div className={'matches-category'}>
        <Checkbox
          id={'fuzzy-matches-checkbox'}
          label={'Similar text matches'}
          checked={showFuzzyMatches}
          onChange={toggleFuzzyMatchesVisibility}
        />

        <CircularNumber number={similarMatchesCount} />
      </div>
    </div>
  );
};

export default MatchesSummaryV2;
