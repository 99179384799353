// Generated with util/create-component.js
import React from 'react';

import styled from 'styled-components';
import { RegisterCardProps } from './RegisterCard.types';
import { SmileyBot } from '../../../assets/images';
import { Button } from '../../atoms/button';
const StyledRegisterCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 75%;
  margin: 40px auto;
`;

const Title = styled.h1`
  font-size: 1.3em;
  font-weight: 600;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FlexContainer = styled.div`
  gap: 35px;
  display: flex;
  font-size: 0.9em;
  align-items: center;
`;

const TextContainer = styled.div`
  width: 75%;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  margin-top: 30px;
  width: 45%;
`;

const StyledSmileyBot = styled(SmileyBot)`
  width: 75px;
  height: auto;
  object-fit: contain;
`;

const RegisterCard: React.FC<RegisterCardProps> = ({
  ctaHandler,
  title,
  description,
}) => (
  <div data-testid="RegisterCard">
    <StyledRegisterCard>
      <CenteredContainer>
        <FlexContainer>
          <StyledSmileyBot />
          <TextContainer>
            <Title>{title ? title : 'Get more scans!'}</Title>
            {description ? (
              <p>{description}</p>
            ) : (
              <>
                <p>You have reached your daily limit of 7 free scans.</p>
                <p>
                  Create a free account to get up to 100 free scans per day.
                </p>
              </>
            )}
          </TextContainer>
        </FlexContainer>
        <ButtonContainer>
          <Button $width="100%" onClickHandler={ctaHandler}>
            Sign Up
          </Button>
        </ButtonContainer>
      </CenteredContainer>
    </StyledRegisterCard>
  </div>
);

export default RegisterCard;
