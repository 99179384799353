import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

interface ProbabilityTooltipProps {
  position?: 'bottom' | 'left' | 'right' | 'top';
}

export const ProbabilityTooltip = ({
  position = 'bottom',
}: ProbabilityTooltipProps) => {
  return (
    <Tooltip
      content={
        'Our deep learning model predicts the probability this text has been entirely generated by AI. For instance, a 40% AI probability does not indicate that the text contains 40% AI-written content. Rather, it indicates the text is more likely to be partially human written than be entirely AI-written.'
      }
      position={position}
      width={52}
    />
  );
};

export default ProbabilityTooltip;
