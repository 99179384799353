import styled from 'styled-components';
import { ResultTabProps, ResultTabButtonProps } from './resultTabs.types';

export const Tab = styled.div<ResultTabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 0;
  width: 32%;
  border-bottom: ${(props) =>
    props.$isActive ? '5px solid rgba(233, 235, 245, 1)' : 'none'};
`;

export const Button = styled.button<ResultTabButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  margin-right: 10px;
  background: ${(props) => (props.$isActive ? '#E9EBF5' : '#E7E7E7')};
`;

export const TabContentContainer = styled.div`
  width: 100%;
  height: 650px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    height: 750px;
    padding-bottom: unset;
  }
`;
