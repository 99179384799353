import React from 'react';
import { SentenceRatioProps } from './SentenceRatio.types';
import SubText from '../../atoms/typography/SubText/SubText';
import Paragraph from '../../atoms/typography/Paragraph/Paragraph';

import './SentenceRatio.css';

const SentenceRatio: React.FC<SentenceRatioProps> = ({
  numSentencesWrittenByAI,
  totalSentences,
}) => {
  return (
    <div className="sentence-ratio">
      <Paragraph className="sentence-ratio-ratio">
        {numSentencesWrittenByAI}/{totalSentences}
      </Paragraph>
      <SubText>Sentences likely AI generated</SubText>
    </div>
  );
};

export default SentenceRatio;
