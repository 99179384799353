import React from 'react';
import useForgotPassword from '../../../../hooks/auth/useForgotPassword';
import MobileModal from '../../MobileModal';
import {
  ForgotPasswordModalHeader,
  ResetPasswordView,
  SentForgotPasswordView,
} from '../common/ResetPasswordElements';

interface MobileForgotPasswordModalProps {
  isOpen: boolean;
  toggleOpen: (open: boolean) => void;
  onClickCreateAccount: () => void;
}

const MobileForgotPasswordModal = ({
  isOpen,
  toggleOpen,
  onClickCreateAccount,
}: MobileForgotPasswordModalProps) => {
  const {
    resetReqEmail,
    didSendReq,
    updateFormValue,
    clickResetPassword,
    clickContactCustomerSupport,
  } = useForgotPassword({ isOpen });

  const onClose = () => {
    toggleOpen(false);
  };

  return (
    <MobileModal isOpen={isOpen} onClose={onClose}>
      <ForgotPasswordModalHeader didSendReq={didSendReq} />
      <hr className="border-g0-gray-100 my-6" />
      {didSendReq ? (
        <SentForgotPasswordView
          clickContactCustomerSupport={clickContactCustomerSupport}
          resetReqEmail={resetReqEmail}
        />
      ) : (
        <ResetPasswordView
          className="mx-12 mb-20 mt-8"
          updateFormValue={updateFormValue}
          clickResetPassword={clickResetPassword}
          onClickCreateAccount={onClickCreateAccount}
        />
      )}
    </MobileModal>
  );
};

export default MobileForgotPasswordModal;
