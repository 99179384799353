import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import { AiUtilitiesHubContext } from '../../features/aiUtilitiesHub/context';
import {
  ScanStatus,
  ScanTypes,
} from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import { getFirstSentence } from '../../utils/textUtils';
import useScanV3 from '../useScanV3';
import { dashboardRoutes } from '../../constants/dashboardRoutes';
import useUpdateScanData from '../../features/aiUtilitiesHub/hooks/useUpdateScan';
import {
  setRequestedScanType as setRequestedScanTypesRedux,
  addRequestedScanType as addRequestedScanTypeRedux,
  setHasViewedAdvancedScanResults,
  setHasViewedBasicScanResults,
} from '../../app/store/aiUtilitiesHubSlice';
import { trackEventV2 } from '../../utils/tracking';
import useAdvancedScanUsage from '../useAdvancedScanUsage';
import useAuthReduxState from '../auth/useAuthReduxState';
import { SignUpModalSources } from '../../constants/signUpModal';

const updateOutOfScans = ({
  advancedScanRequested,
  setOutOfAdvancedScans,
  setOutOfBasicScans,
}) => {
  if (advancedScanRequested) {
    setOutOfAdvancedScans(true);
  } else {
    setOutOfBasicScans(true);
  }
};

const handleErrorResponse = async ({
  response,
  setScanResults,
  setScanStatus,
  advancedScanRequested,
  setOutOfAdvancedScans,
  setOutOfBasicScans,
  isAnonymousUser,
  setShowSignUpModal,
  setSignUpModalSource,
}) => {
  const { error } = await response.json();
  const scanPayload = {
    error,
    data: null,
    status: ScanStatus.error,
    serverStatusCode: response.status,
    // MARK: not setting scanId here
  };
  setScanResults(scanPayload, advancedScanRequested);
  setScanStatus({ status: ScanStatus.error }, advancedScanRequested);

  if (response.status === 429 || response.status === 402) {
    if (isAnonymousUser) {
      setShowSignUpModal(true);
      setSignUpModalSource(SignUpModalSources.SCAN_LIMIT);
      return;
    }

    updateOutOfScans({
      advancedScanRequested,
      setOutOfAdvancedScans,
      setOutOfBasicScans,
    });
  } else {
    const toastOptions = {
      onClick: () => {
        window.open(
          `${process.env.REACT_APP_DASHBOARD_HOSTNAME}${dashboardRoutes.SUBSCRIPTION_PLANS}`,
          '_blank'
        );
      },
    };
    return toast.error(`${error}`, toastOptions);
  }
};

export function useRequestTextAiScan() {
  const COMPONENT_IDENTIFIER = 'requestTextAiScan';

  const aiUtilitiesHub = useSelector((state) => state?.aiUtilitiesHub);
  const dispatch = useDispatch();

  const { requestedScanTypes, overallScanId } = aiUtilitiesHub || {};

  const { incrementAdvancedScanUsages, advancedScanUsagesInCurrentPeriod } =
    useAdvancedScanUsage();

  const whitelabelConfig = useContext(WhitelabelContext);
  const {
    setScanResults,
    setScanStatus,
    setScanId,
    setLatestInputText,
    setScanTitle,
    setScanAuthor,
    setScanDate,
    setScanServerStatusCode,
    setEditorDocument,
    setOutOfAdvancedScans,
    setOutOfBasicScans,
  } = useUpdateScanData();
  const { backendHostname } = whitelabelConfig;

  const { createScan } = useScanV3();

  const { isAnonymousUser, setShowSignUpModal, setSignUpModalSource } =
    useAuthReduxState();

  const requestTextAiScan = async ({
    scanId,
    text,
    files = [],
    checkerProfile,
    projectId,
    isQuickAiScan = false,
    editorDocumentId = null,
  }) => {
    if (!backendHostname || text.length < checkerProfile.charMinimum) {
      return;
    }

    const advancedScanRequested = isQuickAiScan
      ? false
      : requestedScanTypes.includes(ScanTypes.advanced);

    setScanStatus({ status: ScanStatus.loading }, advancedScanRequested);

    const hasViewedResultsPayload = { hasViewedResults: false };
    dispatch(
      advancedScanRequested
        ? setHasViewedAdvancedScanResults(hasViewedResultsPayload)
        : setHasViewedBasicScanResults(hasViewedResultsPayload)
    );

    let newScanId;

    if (!overallScanId && !scanId) {
      const fileToScan = files?.[0];
      const scanTitle = fileToScan?.name || getFirstSentence(text);
      const {
        data: { id, author, createdAt },
      } = await createScan({ title: scanTitle, aiDocumentId: null });

      setScanId(id);

      newScanId = id;

      if (scanTitle) {
        setScanTitle({ scanTitle });
      }

      if (author) {
        setScanAuthor({ scanAuthor: author });
      }

      if (createdAt) {
        setScanDate({ scanDate: createdAt });
      }
    }

    try {
      const response = await fetch(
        `${backendHostname}${checkerProfile.textRoute}`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            document: text,
            scanId: scanId || newScanId,
            writing_stats_required: false,
            interpretability_required: advancedScanRequested,
            multilingual: true,
            ...(projectId && { projectId }),
            ...(editorDocumentId && { editorDocumentId }),
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        return handleErrorResponse({
          response,
          setScanResults,
          setScanStatus,
          advancedScanRequested,
          setOutOfAdvancedScans,
          setOutOfBasicScans,
          isAnonymousUser,
          setShowSignUpModal,
          setSignUpModalSource,
        });
      }

      const scanResults = await response.json();

      trackEventV2({
        action: 'results',
        component: COMPONENT_IDENTIFIER,
        element: 'aiScan',
        options: {
          plan: localStorage.getItem('plan'),
          isAnonymousUser,
          isAdvancedScan: advancedScanRequested,
          advancedScanUsages: advancedScanUsagesInCurrentPeriod,
        },
      });

      setEditorDocument(null);
      const { documents, version } = scanResults;
      const payload = {
        version,
        data: documents[0],
        status: ScanStatus.success,
        serverStatusCode: response.status,
        scanId: scanId || newScanId,
      };

      setLatestInputText(documents[0].inputText);
      if (advancedScanRequested) {
        incrementAdvancedScanUsages();
      }
      setScanResults(payload, advancedScanRequested);
    } catch (error) {
      console.error('Error processing text', error);
      setScanStatus({ status: ScanStatus.error }, advancedScanRequested);
      setScanServerStatusCode({ serverStatusCode: 500 });
    }
  };

  const setRequestedScanType = async (scanType) => {
    await dispatch(setRequestedScanTypesRedux(scanType));
  };

  const addRequestedScanType = (scanType) => {
    dispatch(addRequestedScanTypeRedux(scanType));
  };

  return {
    setRequestedScanType,
    addRequestedScanType,
    requestTextAiScan,
  };
}

export default useRequestTextAiScan;
