export const endpoints = {
  // tenant/team endpoints
  USER_TEAMS: '/v2/org-users/teams',
  GET_TENANT: '/v2/tenants/',
  ADMIN_TENANTS: '/v2/org-users/admin-teams',
  USER_DEFAULT_TENANT: '/v2/tenants/user-tenant',
  CREATE_TENANT: '/v2/tenants/create',
  PUT_TENANT: '/v2/tenants/',
  GET_ALL_TENANTS: '/v2/tenants/all',
  ASSIGN_FREE_TRIAL_TO_TENANT: '/v2/tenants/assign-free-trial',
  REVOKE_FREE_TRIAL_FROM_TENANT: '/v2/tenants/:tenantId/revoke-free-trial',
  GET_FREE_TRIAL_TENANTS: '/v2/tenants/free-trial-teams',
  PERMANENTLY_DELETE_TENANT: '/v2/tenants/delete?tenantId=',
  REASSIGN_TEAM_OWNERSHIP: '/v2/tenants/:tenantId/reassign-team-ownership',
  SOFT_DELETE_TENANT: '/v3/tenants/:tenantId/delete',
  //   team members/users endpoints
  GET_USER_ROLES: '/v2/org-users/roles?userId=',
  INVITE_USERS: '/v2/org-users/invite',
  INVITE_USERS_V3: '/v3/org-users/invite',
  UPDATE_USER: '/v2/org-users/',
  ACCEPT_USER_INVITATION: '/v2/org-users/activateInvited/',
  GET_TENANT_USERS: '/v2/org-users?tenantId=',
  DELETE_USER: '/v2/org-users/delete?userIds=',
  BULK_RESEND_USER_INVITES: '/v2/org-users/:tenantId/bulk-resend-invites',
  GET_PENDING_TEAM_INVITES: '/v2/org-users/pending-invitations',
  REJECT_TEAM_INVITATION: '/v2/org-users/reject-invitation',
  TEAM_MEMBER_CREDIT_USAGE: '/v2/org-users/members-usage?tenantId=',
  USER_TEAM_SELECTION: '/v2/org-users/user-team-selection',
  UPLOAD_USERS_CSV: '/v2/org-users/upload-users',
  COUNT_TEAMS_USER_BELONGS_TO: '/v2/org-users/count-user-teams',
  GET_TEAM_MEMBER_CREDIT_USAGE: '/v2/org-users/:userId/member-usage?tenantId=',
  // users endpoints
  SEND_WELCOME_MESSAGE: '/v2/user/send-welcome-message',
  UPDATE_USER_INVITE_ACCEPTANCE: '/v2/user/accept-user-invite',
  GET_USER_INVITES: '/v2/user/invited-users',
  CREATE_USER_INVITE: '/v2/user/invite-user',
  DELETE_USER_INVITE: '/v2/user/invited-users',
  RESEND_USER_INVITE: '/v2/user/resend-invite?id=',
  GET_USER_INFO: '/v2/users/me',
  TEST_USER: '/v2/user/test-user',
  // stripe
  CANCEL_TEAM_PLAN: '/cancel-team-subscription-plan',
  CREATE_TEAM_CHECKOUT_SESSION: '/create-team-checkout-session',
  CREATE_TEAM_CHECKOUT_SESSION_UPDATE: '/create-team-checkout-session-update',
  REACTIVATE_SUBSCRIPTION: '/reactivate-subscription',
  //   scan history endpoints
  GET_SCAN_HISTORY: '/v2/scan-history',
  GET_ONBOARDING_PROGRESS: '/v2/tenants/:tenantId/progress-tracker',
  GET_TEAM_USAGE_STATS: '/v2/org-users/team-usage?tenantId=',
  // ai document
  GET_AI_DOCUMENT: '/v3/ai-documents/:aiDocumentId',

  EXTRACT_TEXT_FROM_FILE: '/v3/ocr/files',
  // writing feedback endpoints
  GET_TEXT_WRITING_FEEDBACK: 'v2/writing_feedback/text',
  GET_FILE_WRITING_FEEDBACK: 'v2/writing_feedback/files',
  // Send document feedback
  SEND_DOCUMENT_FEEDBACK: '/v2/predict/feedback',
  CREATE_AI_VOCAB_SCAN: '/v3/ai-vocab-scan',
};
