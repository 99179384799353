import React, { useContext } from 'react';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import { ScanStatus } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import { getFirstSentence } from '../../utils/textUtils';
import useScanV3 from '../useScanV3';
import useUpdateScanData from '../../features/aiUtilitiesHub/hooks/useUpdateScan';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAiVocabScanResults,
  setAiVocabScanStatus,
  setAiVocabScanError,
  setAiVocabScanServerStatusCode,
  setHasViewedAiVocabScanResults,
} from '../../app/store/aiUtilitiesHubSlice';
import { toast } from 'react-toastify';
import { trackEvent } from 'component-library';
import { eventTrackingActions } from '../../constants/trackingEvents';
import { endpoints } from '../../constants/endpoints';

const useRequestAiVocabScan = () => {
  const COMPONENT_IDENTIFIER = 'requestTextAiVocabScan';
  const aiUtilitiesHub = useSelector((state) => state?.aiUtilitiesHub);

  const whitelabelConfig = useContext(WhitelabelContext);
  const {
    setScanId,
    setScanTitle,
    setScanAuthor,
    setScanDate,
    setLatestInputText,
    setInputTextChangedSinceScan,
  } = useUpdateScanData();
  const { overallScanId } = aiUtilitiesHub || {};
  const dispatch = useDispatch();
  const { backendHostname } = whitelabelConfig;

  const { createScan } = useScanV3();

  const requestAiVocabScan = async ({ scanId, text }) => {
    if (!backendHostname) {
      return;
    }
    dispatch(setAiVocabScanStatus({ status: ScanStatus.loading }));
    dispatch(setHasViewedAiVocabScanResults({ hasViewedResults: false }));

    let newScanId = null;
    const isNewScan = !overallScanId && !scanId;
    if (isNewScan) {
      const scanTitle = getFirstSentence(text);
      const {
        data: { id, author, createdAt },
      } = await createScan({ title: scanTitle, aiDocumentId: null });
      setScanId(id);
      newScanId = id;
      if (scanTitle) {
        setScanTitle({ scanTitle });
      }
      if (author) {
        setScanAuthor({ scanAuthor: author });
      }
      if (createdAt) {
        setScanDate({ scanDate: createdAt });
      }
    }
    trackEvent(eventTrackingActions.FETCH, COMPONENT_IDENTIFIER, null, 1.0, {
      isInitialScan: isNewScan,
    });
    try {
      const response = await fetch(
        `${backendHostname}${endpoints.CREATE_AI_VOCAB_SCAN}`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            text,
            scanId: scanId || newScanId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Error getting AI Vocab scan results');
      }
      const { outputJson, inputText } = await response.json();
      const payload = {
        data: {
          nGrams: [
            ...outputJson.unigrams,
            ...outputJson.bigrams,
            ...outputJson.trigrams,
            ...outputJson.quadgrams,
          ],
          inputText,
        },
        status: ScanStatus.success,
        serverStatusCode: response.status,
        scanId: scanId || newScanId,
      };
      setLatestInputText(inputText);
      setInputTextChangedSinceScan(false);
      dispatch(setAiVocabScanResults(payload));
      dispatch(setAiVocabScanStatus({ status: ScanStatus.success }));
      dispatch(setAiVocabScanError({ error: null }));
    } catch (error) {
      toast.error('Error getting AI Vocab scan results');
      dispatch(setAiVocabScanStatus({ status: ScanStatus.error }));
      dispatch(setAiVocabScanServerStatusCode({ serverStatusCode: 500 }));
      console.error('Error getting AI Vocab scan results', error);
      dispatch(setAiVocabScanError({ error: error.message }));
    }
  };

  return {
    requestAiVocabScan,
  };
};

export default useRequestAiVocabScan;
