import React from 'react';
import { Modal } from 'react-daisyui';
import './styles.css';
import useConfirmEmail from '../../../../hooks/auth/useConfirmEmail';
import {
  ConfirmEmailModalHeader,
  ContactSupport,
  ResendEmailButton,
  ReturnToLoginButton,
} from '../common/ConfirmEmailElements';

interface CheckEmailModalProps {
  isOpen: boolean;
  email: string;
  toggleOpen: (open: boolean) => void;
  onClickReturnToLogin: () => void;
}

export const ConfirmEmailModal = ({
  isOpen,
  toggleOpen,
  email,
  onClickReturnToLogin,
}: CheckEmailModalProps) => {
  const { loading, onClickResendEmail, onClickCustomerSupport } =
    useConfirmEmail({ email });

  return (
    <Modal
      id="confirm-email-modal"
      className="p-0 auth-modal py-6"
      responsive={true}
      open={isOpen}
      onClickBackdrop={() => toggleOpen(false)}
    >
      <Modal.Header>
        <ConfirmEmailModalHeader className="my-4" />
        <hr className="border-g0-gray-100 mt-6" />
      </Modal.Header>
      <Modal.Body>
        <ContactSupport
          onClickCustomerSupport={onClickCustomerSupport}
          className="text-center"
          email={email}
        />
        <div className="flex justify-center gap-2 my-8">
          <ReturnToLoginButton onClickReturnToLogin={onClickReturnToLogin} />
          <ResendEmailButton
            onClickResendEmail={onClickResendEmail}
            loading={loading}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
