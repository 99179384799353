import React from 'react';
import { InfoProps } from './info.types';

export function InfoIcon({ $fill, $size }: InfoProps) {
  return (
    <svg
      width={$size || '13'}
      height={$size || '13'}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.85 3.25V4.55H7.15V3.25H5.85ZM7.8 9.75V8.45H7.15V5.85H5.2V7.15H5.85V8.45H5.2V9.75H7.8ZM13 6.5C13 10.075 10.075 13 6.5 13C2.925 13 0 10.075 0 6.5C0 2.925 2.925 0 6.5 0C10.075 0 13 2.925 13 6.5ZM11.7 6.5C11.7 3.627 9.373 1.3 6.5 1.3C3.627 1.3 1.3 3.627 1.3 6.5C1.3 9.373 3.627 11.7 6.5 11.7C9.373 11.7 11.7 9.373 11.7 6.5Z"
        fill={$fill || '#D9D9D9'}
      />
    </svg>
  );
}

export default InfoIcon;
