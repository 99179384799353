import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import { ScanStatus } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import {
  setSourcesScanServerStatusCode,
  setSourcesScanStatus,
  setSourcesScanError,
  setSourcesScanResults,
  setScanTitle,
  setOverallScanId,
  setScanAuthor,
  setScanDate,
  setLatestInputText,
} from '../../app/store/aiUtilitiesHubSlice';
import useScanV3 from '../useScanV3';
import { dashboardRoutes } from '../../constants/dashboardRoutes';
import { trackEventV2 } from '../../utils/tracking';
import { RootState } from '../../app/store/rootState';
import useSourcesScanUsage from '../useSourcesScanUsage';
import { sortClaimResults } from '../../features/aiUtilitiesHub/utils/sourcesUtils';

export function useRequestFileSourcesScan() {
  const COMPONENT_IDENTIFIER = 'requestFileSourcesScan';

  const aiUtilitiesHub = useSelector(
    (state: RootState) => state?.aiUtilitiesHub
  );
  const dispatch = useDispatch();

  const { overallScanId } = aiUtilitiesHub || {};
  const { isAnonymousUser } = useSelector((state: RootState) => state?.auth);

  const whitelabelConfig = useContext(WhitelabelContext);

  const { backendHostname } = whitelabelConfig;

  const { createScan } = useScanV3();
  const { incrementSourcesScanUsages, sourcesScanUsagesInCurrentPeriod } =
    useSourcesScanUsage();

  const updateOutOfScansAndPresentToast = ({ toastMessage }) => {
    const toastOptions = {
      onClick: () => {
        window.open(
          `${process.env.REACT_APP_DASHBOARD_HOSTNAME}${dashboardRoutes.SUBSCRIPTION_PLANS}`,
          '_blank'
        );
      },
    };
    return toast.error(toastMessage, toastOptions);
  };

  const handleErrorResponse = async ({ response }) => {
    const { error } = await response.json();

    const scanPayload = {
      error,
      data: null,
      status: ScanStatus.error,
      serverStatusCode: response.status,
    };
    dispatch(setSourcesScanStatus({ status: ScanStatus.error }));
    dispatch(setSourcesScanResults(scanPayload));
    dispatch(setSourcesScanError({ error }));
    dispatch(
      setSourcesScanServerStatusCode({ serverStatusCode: response.status })
    );

    const toastOptions = {
      onClick: () => {
        window.open(
          `${process.env.REACT_APP_DASHBOARD_HOSTNAME}${dashboardRoutes.SUBSCRIPTION_PLANS}`,
          '_blank'
        );
      },
    };

    if (response.status === 429 || response.status === 402) {
      const toastMessage =
        response.status === 429
          ? `${error}`
          : `You've run out of monthly credits. Click here to upgrade your plan.`;
      updateOutOfScansAndPresentToast({
        toastMessage,
      });
      return toast.error(`${error}`, toastOptions);
    }
  };

  const requestFileSourcesScan = async ({
    scanId,
    files,
    checkerProfile,
    projectId,
  }) => {
    const fileToScan = files[0];
    const scanTitle = fileToScan.name;

    if (!backendHostname || !fileToScan) {
      return;
    }

    dispatch(setSourcesScanStatus({ status: ScanStatus.loading }));

    let newScanId;

    if (!overallScanId && !scanId) {
      const {
        data: { id, author, createdAt },
      } = await createScan({ title: scanTitle, aiDocumentId: null });

      dispatch(setOverallScanId({ overallScanId: id }));

      newScanId = id;

      if (scanTitle) {
        dispatch(setScanTitle({ scanTitle }));
      }

      if (author) {
        dispatch(setScanAuthor({ scanAuthor: author }));
      }

      if (createdAt) {
        dispatch(setScanDate({ scanDate: createdAt }));
      }
    }

    try {
      const data = new FormData();
      data.append('scanId', scanId || newScanId);
      data.append('files', fileToScan);

      if (projectId) {
        data.append('projectId', projectId);
      }

      const response = await fetch(
        `${backendHostname}${checkerProfile.fileRoute}`,
        {
          method: 'POST',
          credentials: 'include',
          body: data,
        }
      );

      if (!response.ok) {
        return handleErrorResponse({
          response,
        });
      }

      const scanResults = await response.json();

      trackEventV2({
        action: 'results',
        component: COMPONENT_IDENTIFIER,
        element: checkerProfile.fileCheckTag,
        options: {
          plan: localStorage.getItem('plan'),
          isAnonymousUser,
          isSourcesScan: true,
          sourcesScanUsages: sourcesScanUsagesInCurrentPeriod,
        },
      });

      const { results, text } = scanResults;
      const sortedResults = sortClaimResults(results);

      const payload = {
        data: {
          results: sortedResults,
          inputText: text || '',
        },
        status: ScanStatus.success,
        serverStatusCode: response.status,
        scanId: scanId || newScanId,
      };

      incrementSourcesScanUsages();
      dispatch(setLatestInputText({ latestInputText: text || '' }));
      dispatch(setSourcesScanResults(payload));
    } catch (error) {
      console.error('Error processing file', error);
      dispatch(setSourcesScanStatus({ status: ScanStatus.error }));
      dispatch(setSourcesScanError({ error }));
    }
  };

  return {
    requestFileSourcesScan,
  };
}

export default useRequestFileSourcesScan;
