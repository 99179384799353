import { removeLocalStorageItem } from '../hooks/useLocalStorage';
import LocalStorageKeys from '../constants/localStorageKeys';
import { Links } from '../constants/links';

export const getUpdatedQueryParams = ({ searchParams, key, value }) => {
  let params = new URLSearchParams(searchParams);
  if (params.has(key)) params.delete(key);
  params.append(key, value);
  return params;
};

export const QUERY_STRINGS = {
  WRITING_FEEDBACK: 'writing-feedback',
  INSIDE_VIEW: 'inside-view',
  PLAGIARISM: 'plagiarism',
  MULTILINGUAL: 'multilingual',
};

export const QUERY_STRINGS_VALUES = {
  TRUE: 'true',
  FALSE: 'false',
  NULL: null,
};

export const handleFeatureSwitchState = ({
  searchParams,
  featureAccess,
  queryParamName,
  updateQueryParam,
  toggleFeatureSwitch,
}: {
  featureAccess: boolean;
  queryParamName: string;
  searchParams: URLSearchParams;
  updateQueryParam: (key: string, value: string) => void;
  toggleFeatureSwitch: (value: boolean) => void;
}) => {
  const queryParamValue = searchParams.get(queryParamName);

  if (featureAccess && queryParamValue === QUERY_STRINGS_VALUES.TRUE) {
    toggleFeatureSwitch(true);
  }

  if (
    featureAccess &&
    getLocalStorageItem(queryParamName) === QUERY_STRINGS_VALUES.TRUE
  ) {
    toggleFeatureSwitch(true);
    updateQueryParam(queryParamName, QUERY_STRINGS_VALUES.TRUE);
  }
};

export const setLocalStorageItem = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeLocalStorageItems = (keys: string[]) => {
  for (const key of keys) {
    removeLocalStorageItem(key);
  }
};

export function handleRedirectToLoginFromAnotherPage(currentPathname) {
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const pathAndQuery = url.pathname + url.search;

  if (currentPathname === Links.JOIN_TEAM) {
    setLocalStorageItem(LocalStorageKeys.joinPageLink, pathAndQuery);
  } else if (currentPathname === Links.ACCEPT_USER_INVITE) {
    setLocalStorageItem(LocalStorageKeys.acceptUserInviteLink, pathAndQuery);
  } else if (currentPathname === Links.CREATE_TEAM) {
    setLocalStorageItem(LocalStorageKeys.createTeamLink, pathAndQuery);
  } else if (currentPathname === Links.ACCEPT_TEAM_INVITE) {
    setLocalStorageItem(LocalStorageKeys.acceptTeamInviteLink, pathAndQuery);
  }
}
