import React from 'react';
import { StyledButton } from './button.styles';
import { IButtonProps } from './button.types';

export const Button = ({
  $outlined,
  $dotted,
  $medium,
  $small,
  $rounded,
  $secondary,
  $tertiary,
  $ghost,
  $width,
  $height,
  className,
  children,
  type,
  onClickHandler,
}: IButtonProps) => {
  const size = $medium ? 'medium' : $small ? 'small' : 'normal';

  return (
    <StyledButton
      data-testid="button"
      $width={$width}
      $height={$height}
      $secondary={$secondary}
      $tertiary={$tertiary}
      $ghost={$ghost}
      $outlined={$outlined}
      $dotted={$dotted}
      $size={size}
      $rounded={$rounded}
      className={className}
      onClick={onClickHandler}
      type={type}
    >
      {children}
    </StyledButton>
  );
};
export default Button;
