import React from 'react';

interface MobileModalProps {
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const MobileModal = ({
  isOpen,
  onClose,
  children,
  className = '',
}: MobileModalProps) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}>
      <div
        className={`bg-white absolute bottom-0 w-full rounded-t-3xl ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default MobileModal;
