export const ScanTypes = {
  basic: 'basic',
  advanced: 'advanced',
  plagiarism: 'plagiarism',
  writingFeedback: 'writing_feedback',
  investigateSources: 'investigate_sources',
  aiVocab: 'ai_vocab',
};

export type ScanType = (typeof ScanTypes)[keyof typeof ScanTypes];

export const MenuItemLabels = {
  basic: 'Basic scan',
  advanced: 'Advanced scan',
  writingFeedback: 'Writing feedback',
  plagiarism: 'Plagiarism',
  investigateSources: 'Investigate Sources',
  aiVocab: 'AI Vocabulary',
};

export const MenuItemLabelsV2 = {
  basic: 'Quick AI Scan',
  advanced: 'Advanced scan',
  writingFeedback: 'Writing feedback',
  plagiarism: 'Plagiarism scan',
};

export const ScanStatus = {
  pending: 'pending',
  loading: 'loading',
  success: 'success',
  error: 'error',
};

export const PremiumScanTypes = [
  ScanTypes.plagiarism,
  ScanTypes.writingFeedback,
];

export const LanguageSupportLevels = {
  supported: 'supported',
  partiallySupported: 'partiallySupported',
  unsupported: 'unsupported',
};

export const EDIT_ONLY_VIEWS = [ScanTypes.basic, ScanTypes.aiVocab];
export const FileUploadSizeLimit = '15mb';
