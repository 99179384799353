import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from './types';

const initialState = {
  status: 'pending',
  percentageCompletion: null,
} as EditorState;

export const index = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setStatus(
      state,
      action: PayloadAction<'pending' | 'complete' | 'in_progress' | 'failed'>
    ) {
      state.status = action.payload;
    },
    setPercentageCompletion(state, action: PayloadAction<number>) {
      state.percentageCompletion = action.payload;
    },
  },
});

export const { setStatus, setPercentageCompletion } = index.actions;

export default index.reducer;
