import { createSlice } from '@reduxjs/toolkit';
import { Tenant } from '../../types/types';
import { RootState } from '../../components/teamsV3/types';

interface UserTeamsState {
  tenants: Tenant[];
  loading: boolean;
  error: boolean;
}

const initialState: UserTeamsState = {
  tenants: [],
  loading: false,
  error: false,
};

export const userTeamsSlice = createSlice({
  name: 'userTeams',
  initialState,
  reducers: {
    setTenants(state, action) {
      state.tenants = action.payload.tenants;
    },
    addTenant(state, action) {
      state.tenants.push(action.payload.tenant);
    },
    updateTenant(state, action) {
      const { id, updatedTenant } = action.payload;
      const index = state.tenants.findIndex((tenant) => tenant.id === id);

      if (index !== -1) {
        state.tenants[index] = updatedTenant;
      }
    },
    deleteStateTenant(state, action) {
      const { id } = action.payload;
      state.tenants = state.tenants.filter((tenant) => tenant.id !== id);
    },
    setLoading(state, action) {
      state.loading = action.payload.loading;
    },
    setError(state, action) {
      state.error = action.payload.error;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTenants,
  addTenant,
  updateTenant,
  setLoading,
  setError,
  deleteStateTenant,
} = userTeamsSlice.actions;

export const selectTenants = (state: RootState) => state.userTeams.tenants;

export default userTeamsSlice.reducer;
