import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';

/**
 * Hook to determine if the user is on a mobile device.
 */
const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const debouncedCheckIfMobile = debounce(checkIfMobile, 250);

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
      debouncedCheckIfMobile.cancel(); // Cancel any pending debounce calls
    };
  }, []);

  return { isMobile };
};

export default useMobile;
