import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../components/v2/LoadingSpinner';
import { loaderQuotes } from './loaderQuotes';

export type Quote = {
  name: string;
  message: string;
};

const QuoteLoaderBody = ({ quote }) => (
  <div className="w-60 flex flex-col gap-6 items-center text-center text-g0-secondary caption">
    <LoadingSpinner fill={'fill-g0-ai-yellow-500'} />
    <p>{quote.message}</p>
    <p>{quote.name}</p>
  </div>
);

const QuoteLoader = () => {
  const [quote, setQuote] = useState<Quote>({ name: '', message: '' });

  useEffect(() => {
    setQuote(loaderQuotes[Math.floor(Math.random() * loaderQuotes.length)]);
  }, []);

  return (
    <div className="flex justify-center mt-36 w-full h-full">
      <QuoteLoaderBody quote={quote} />
    </div>
  );
};

export default QuoteLoader;
