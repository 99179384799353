import React, { useEffect, useState } from 'react';
import {
  getPlotBackground,
  hasSignificantInterpretabilityImpact,
} from './helpers';
import { ISentenceWithId } from '../../../types/types';
import AdvancedScanTooltip from '../../molecules/AdvancedScanTooltip';

interface SingleValuePlotProps {
  id: number;
  sentence: ISentenceWithId;
  index: number;
  wrapperWidth: number;
  isHumanValue?: boolean;
  setActiveSentence: (activeSentence: number) => void;
  handleClickInterpretabilityValue: ({ sentence, id }) => void;
  tooltipClasses?: string;
}

const SingleValuePlot = ({
  id,
  sentence,
  index,
  wrapperWidth,
  isHumanValue,
  setActiveSentence,
  handleClickInterpretabilityValue,
  tooltipClasses,
}: SingleValuePlotProps) => {
  if (!sentence || !id) {
    return null;
  }

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState('right');

  const handleMouseEnter = (e) => {
    const cursorX = e.clientX;

    if (cursorX < 260) {
      setTooltipPosition('left');
    } else {
      setTooltipPosition('right');
    }

    setShowTooltip(true);
    setActiveSentence(sentence?.id);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setActiveSentence(null);
  };

  const assignedWrapperWidth = wrapperWidth > 10 ? wrapperWidth : 10;

  const width = (() => {
    if (sentence === undefined) {
      return undefined;
    }

    return (
      ('shapley_normalized_value' in sentence
        ? sentence.shapley_normalized_value
        : sentence.interpretability_normalized_value) *
      100 *
      assignedWrapperWidth
    );
  })();

  const [containerWidth, setContainerWidth] = useState(0);
  useEffect(() => {
    const element = document.getElementById(`interpretabilityPlot-${id}`);
    if (element) {
      setContainerWidth(element.clientWidth);
    }
  }, [id, wrapperWidth]);

  return (
    <>
      {hasSignificantInterpretabilityImpact(sentence) && (
        <>
          <div
            id={`interpretabilityPlot-${id}`}
            onClick={() => handleClickInterpretabilityValue({ sentence, id })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            key={index}
            style={{
              width: `${width}%`,
              background: getPlotBackground({ sentence, isHumanValue }),
            }}
            className={`relative border-l-2 first:border-l-0 border-white h-full flex cursor-pointer
             `}
          >
            <AdvancedScanTooltip
              data={sentence}
              showSentence={true}
              show={showTooltip}
              setShow={setShowTooltip}
              position={tooltipPosition}
              className={`w-[320px] ${tooltipClasses}`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SingleValuePlot;
