import React, { useCallback } from 'react';
import supabase from '../../utils/supabaseClient';
import { deleteQueryParamsAndReplaceUrl } from '../../utils/common';
import {
  REVIEWER_INDUSTRIES,
  Role,
  RoleIndustry,
  ROLES,
  WRITER_INDUSTRIES,
} from '../../constants/signUpModal';

/**
 *
 * Used to update profile data for a user after they sign up.(social sign up from the sign up modal)
 */
const useUpdateProfileAfterSignup = () => {
  const updateUserProfileRoleFromQueryParams = useCallback(
    async (email: string) => {
      const params = new URLSearchParams(window.location.search);
      const role = validateRole(params.get('role'));
      const industry = validateIndustry(params.get('industry'));

      let updates = null;

      if (role) {
        updates = { org_role: role };
      }

      if (industry) {
        updates = { ...updates, industry };
      }

      if (updates && email) {
        const { error } = await supabase
          .from('profiles')
          .update(updates)
          .eq('email', email);

        deleteQueryParamsAndReplaceUrl(['role', 'industry']);
      }
    },
    []
  );

  const validateRole = (role: string | null): string | null => {
    const validRoles = Object.values(ROLES);
    return role && validRoles.includes(role as Role) ? role : null;
  };

  const validateIndustry = (industry: string | null): string | null => {
    const validIndustries = [...WRITER_INDUSTRIES, REVIEWER_INDUSTRIES].map(
      (industry: RoleIndustry) => industry.label
    );
    return industry && validIndustries.includes(industry) ? industry : null;
  };

  return { updateUserProfileRoleFromQueryParams };
};

export default useUpdateProfileAfterSignup;
