import { StyledContainer } from '../../../atoms/container/container.styles';
import styled from 'styled-components';

export const Wrapper = styled(StyledContainer)`
  padding-bottom: 50px;

  @media (min-width: 1024px) {
    height: 785px;
  }
`;

export const WritingFeedbackPreviewContainer = styled.div`
  cursor: pointer;
`;

export const WritingFeedbackPreviewImage = styled.img`
  object-fit: contain;
`;

export const DashboardImage = styled.img`
  color: white;
  margin-right: 15px;
`;

export const ViewInDashboardCard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(52, 52, 52);
  color: white;
  padding: 20px 40px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const DisclaimerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`;
