import React from 'react';

const PageContainer = ({ children }) => {
  return (
    <div className="bg-[#f4f0ea] flex justify-center w-full h-screen py-10">
      {children}
    </div>
  );
};

export default PageContainer;
