import React from 'react';
import Tooltip from '../Tooltip/Tooltip';

export const ConfidenceScoreTooltip = () => {
  return (
    <Tooltip
      content={
        "Confidence scores are a safeguard to better understand AI identification results. We trained our deep learning model on a diverse dataset of millions of human and AI-written documents. Green scores indicate that you can scan with confidence that our model has classified many similar documents with high accuracy. Red scores indicate that this text is dissimilar to the ones in our training set, which can impact the model's accuracy, and to proceed with caution."
      }
      position="left"
      width={52}
    />
  );
};

export default ConfidenceScoreTooltip;
