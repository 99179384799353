import React, { useState } from 'react';
import usePasswordLogin from './usePasswordLogin';
import useUserLoggedIn from './useUserLoggedIn';

const useLogin = () => {
  const [registrationDetails, setRegistrationDetails] = useState({
    email: '',
    password: '',
    captchaToken: '',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);

  const aiDocumentId = params.get('aiDocumentId');

  const { logIn } = usePasswordLogin();

  const { signInIfPossible } = useUserLoggedIn();

  const updateFormValue = ({ updateType, value }) => {
    if (!updateType || !value) {
      return;
    }
    setRegistrationDetails({ ...registrationDetails, [updateType]: value });
  };

  return {
    registrationDetails,
    setRegistrationDetails,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    aiDocumentId,
    logIn,
    signInIfPossible,
    updateFormValue,
  };
};

export default useLogin;
