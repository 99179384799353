import { localStorageItems } from '../constants/localStorage';
import { getDecodedTokenFromCookie } from './cookieUtils';
import axios from 'axios';
import whitelabelConfig from '../app/whitelabelConfig';
import debounce from 'lodash.debounce';

export function retrieveDeviceId() {
  return localStorage.getItem(localStorageItems.GPTZERO_DEVICE_ID);
}

export function setDeviceId() {
  const decodedToken = getDecodedTokenFromCookie('accessToken4');
  let deviceId = '';
  if (decodedToken?.email) {
    deviceId = decodedToken.email;
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const amplitudeId = urlParams.get('deviceId');
    deviceId = amplitudeId || generateUUID();
  }
  localStorage.setItem(localStorageItems.GPTZERO_DEVICE_ID, deviceId);
  return deviceId;
}

export function generateUUID() {
  // Code from https://stackoverflow.com/questions/49663720/equivalent-to-pythons-uuid-uuid4-hex-in-javascript#comment86337474_49663720
  // TODO Dhiren: use UUID library instead
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function userIsLoggedIn() {
  const decodedToken = getDecodedTokenFromCookie('accessToken4');
  return decodedToken?.email;
}

export const fetchAndSetSessionCSRFToken = debounce(async function () {
  try {
    if (sessionStorage.getItem('csrfToken')) {
      return;
    }
    const response = await axios.get(
      `${whitelabelConfig.gptzero.backendHostname}/csrf/generateToken`,
      { withCredentials: true }
    );

    if (response.status === 200) {
      const token = response.data;
      sessionStorage.setItem('csrfToken', token);
      // when making requests for APIs that are guarded by doubleCsrfProtection middleware we shoud retrieve the csrfToken and add it to the x-csrf-token HTTP header
    } else {
      console.error(
        'Failed to retrieve CSRF token. Status code:',
        response.status
      );
    }
  } catch (error) {
    console.error('Error occurred while fetching CSRF token:', error);
  }
}, 1000);
