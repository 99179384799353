export const ORIGIN_CHROME_STORE_LINK =
  'https://chromewebstore.google.com/detail/origin-by-gptzero-chatgpt/kgobeoibakoahbfnlficpmibdbkdchap';

export const ORIGIN_SOURCE = 'origin';

export const UNTITILED = 'Untitled document';

export const WRITING_REPORT_STATUSES = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'complete',
};

export const REQUEST_MORE_LANGUAGES_FORM_LINK =
  'https://forms.gle/vL2SfjtdtdgwrmdEA';

export const SUMMER_SALE_END_DATE = new Date('2024-08-21T23:59:59Z');

export const AdvancedScanClasses = {
  HIGH_HUMAN: 'high-human',
  MEDIUM_HUMAN: 'medium-human',
  LOW_HUMAN: 'low-human',
  LOW_AI: 'low-ai',
  MEDIUM_AI: 'medium-ai',
  HIGH_AI: 'high-ai',
};

export const AdvancedScanColors = {
  [AdvancedScanClasses.HIGH_HUMAN]: '#50C08A',
  [AdvancedScanClasses.MEDIUM_HUMAN]: '#87D3AF',
  [AdvancedScanClasses.LOW_HUMAN]: '#D1ECDF',
  [AdvancedScanClasses.LOW_AI]: '#FDEFD5',
  [AdvancedScanClasses.MEDIUM_AI]: '#FBE3B4',
  [AdvancedScanClasses.HIGH_AI]: '#F0C05C',
};

export const AdvancedScanBreakpoints = {
  LOW: 0.4,
  MEDIUM: 0.7,
  HIGH: 1.0,
};

export const AdvancedScanDesignations = {
  AI: 'ai',
  HUMAN: 'human',
};

export const ALLOWED_SCAN_FILE_EXTENSIONS = ['pdf', 'txt', 'doc', 'docx'];

export const REQUIRED_VALID_COOKIE_ERROR_MESSAGE = 'Require valid cookie';
