import { fetchApiWrapper } from './FetchApiWrapper';
import { endpoints } from '../../../constants/endpoints';

export const apiFetchTenants = async ({ backendHostname, plan = null }) => {
  const url = plan
    ? backendHostname + endpoints.GET_ALL_TENANTS + `?plan=${plan}`
    : backendHostname + endpoints.GET_ALL_TENANTS;
  return await fetchApiWrapper({
    url,
    httpRequestType: 'GET',
  });
};

export const apiUpdateTenant = async ({
  backendHostname,
  insertData,
  tenantId,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.PUT_TENANT + tenantId,
    httpRequestType: 'PUT',
    data: { tenant: insertData },
  });
};

export const apiChangeTenantOwner = async ({
  backendHostname,
  newOwnerOrgUserId,
  deleteCurrentOwner,
  tenantId,
}) => {
  return await fetchApiWrapper({
    url: `${backendHostname}${endpoints.REASSIGN_TEAM_OWNERSHIP.replace(
      ':tenantId',
      tenantId
    )}`,
    httpRequestType: 'PUT',
    data: {
      userId: newOwnerOrgUserId,
      deleteCurrentOwnerFromteam: deleteCurrentOwner,
    },
  });
};

export const apiCreateTenant = async ({ backendHostname, insertData }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.CREATE_TENANT,
    httpRequestType: 'POST',
    data: { tenant: insertData },
  });
};

export const apiGetUserTenant = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.USER_DEFAULT_TENANT,
    httpRequestType: 'GET',
  });
};

export const apiFetchUserAdminTenants = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.ADMIN_TENANTS,
    httpRequestType: 'GET',
  });
};

export const apiGetTenant = async ({ backendHostname, tenantId }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.GET_TENANT + tenantId,
    httpRequestType: 'GET',
  });
};

export const apiGetUserTeams = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.USER_TEAMS,
    httpRequestType: 'GET',
  });
};

export const apiGetUserPendingTeamInvitations = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.GET_PENDING_TEAM_INVITES,
    httpRequestType: 'GET',
  });
};

export const apiGetFreeTrialTeams = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.GET_FREE_TRIAL_TENANTS,
    httpRequestType: 'GET',
  });
};

export const apiAssignFreeTrialToTeam = async ({
  backendHostname,
  plan,
  tenantId,
  userProfileId,
  planType,
  planDurationMonths,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.ASSIGN_FREE_TRIAL_TO_TENANT,
    httpRequestType: 'PUT',
    data: { plan, tenantId, userProfileId, planType, planDurationMonths },
  });
};

export const apiRevokeFreeTrialFromTeam = async ({
  backendHostname,
  tenantId,
}) => {
  const endpointTemplate = endpoints.REVOKE_FREE_TRIAL_FROM_TENANT;
  const endpoint = endpointTemplate.replace(':tenantId', tenantId);

  return await fetchApiWrapper({
    url: backendHostname + endpoint,
    httpRequestType: 'PUT',
    data: {},
  });
};

export const apiDeleteTenant = async ({ backendHostname, tenantId }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.PERMANENTLY_DELETE_TENANT + tenantId,
    httpRequestType: 'DELETE',
  });
};

export const apiDeleteTenantV3 = async ({ backendHostname, tenantId }) => {
  const endpointTemplate = endpoints.SOFT_DELETE_TENANT;
  const endpoint = endpointTemplate.replace(':tenantId', tenantId);
  return await fetchApiWrapper({
    url: backendHostname + endpoint,
    httpRequestType: 'DELETE',
  });
};
