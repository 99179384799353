import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant } from '../../types/types';

interface UserSelectedTeamState {
  tenant: Tenant | null;
  loading: boolean;
  error: boolean;
}

const initialState: UserSelectedTeamState = {
  tenant: null,
  loading: false,
  error: false,
};

export const userSelectedTeamSlice = createSlice({
  name: 'userSelectedTeamDetails',
  initialState,
  reducers: {
    setSelectedTenant(state, action: PayloadAction<Tenant | any>) {
      state.tenant = action.payload;
    },
    updateSelectedTenant(
      state,
      action: PayloadAction<{ id: string; updatedTenant: Tenant }>
    ) {
      if (state.tenant && state.tenant.id === action.payload.id) {
        state.tenant = action.payload.updatedTenant;
      }
    },
    removeTenant(state) {
      state.tenant = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
  },
});

export const {
  setSelectedTenant,
  updateSelectedTenant,
  removeTenant,
  setLoading,
  setError,
} = userSelectedTeamSlice.actions;

export default userSelectedTeamSlice.reducer;
