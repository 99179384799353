import React from 'react';
import { ConfidenceScoreProps } from './types';
import './styles.css';
import ConfidenceScoreTooltip from '../ConfidenceScoreTooltip';

const generateText = (confidenceCategory: string) => {
  switch (confidenceCategory) {
    case 'high':
      return 'highly confident';
    case 'low':
      return 'uncertain';
    default:
      return 'moderately confident';
  }
};

export function ConfidenceScore({ confidenceCategory }: ConfidenceScoreProps) {
  return (
    <div className={'confidence-score-wrapper'}>
      <div
        className={`confidence-score-circle bg-red-light ${
          confidenceCategory !== 'low' && 'opacity-light-off'
        }`}
      />
      <div
        className={`confidence-score-circle bg-orange-light ${
          confidenceCategory !== 'medium' && 'opacity-light-off'
        }`}
      />
      <div
        className={`confidence-score-circle bg-green-light ${
          confidenceCategory !== 'high' && 'opacity-light-off'
        }`}
      />

      <div
        className={'ur-text ur-text-light ur-text-grey confidence-score-text'}
      >
        {generateText(confidenceCategory)}
      </div>
      <div className={'confidence-score-tooltip-wrapper'}>
        <ConfidenceScoreTooltip />
      </div>
    </div>
  );
}

export default ConfidenceScore;
