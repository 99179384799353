/*global chrome*/
import axios from 'axios';
import { getCookie, deleteCookie, getCookieDomain } from '../utils/cookieUtils';
import { deleteTenantIdFromCookies } from '../utils/profileUtils';
import {
  logoutUserFromChromeExtension,
  REQUEST_FROM_EXTENSION,
  sendTokenToChromeExtension,
} from '../utils/extensionUtils';
import { resetUserData } from '../utils/tracking';
import { dashboardRoutes } from '../constants/dashboardRoutes';
import supabase from '../utils/supabaseClient';
import { fetchAndSetSessionCSRFToken } from '../utils/session';

const isRequestFromExtension = () => {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    const param = params.get(REQUEST_FROM_EXTENSION);
    return param === 'true';
  }
  return false;
};

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page
      for all internal dashboard routes  */
  const TOKEN = getCookie('accessToken4');
  const plan = localStorage.getItem('plan');
  const PUBLIC_ROUTES = [
    'login',
    'forgot-password',
    'register',
    'documentation',
    'update-password',
  ];

  const isAnonymousSignInPage = window.location.href.includes(
    dashboardRoutes.ANONYMOUS_SIGN
  );

  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );

  // setting CSRF session token
  fetchAndSetSessionCSRFToken();

  if (isRequestFromExtension())
    localStorage.setItem(REQUEST_FROM_EXTENSION, JSON.stringify(true));

  if ((!TOKEN || !plan) && !isPublicPage) {
    return { token: TOKEN, goToLogin: true, isAnonymousSignInPage };
  } else {
    axios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    sendTokenToChromeExtension({ token: TOKEN, plan });
    return { token: TOKEN, goToLogin: false, isAnonymousSignInPage };
  }
};

const deleteUsersDataFromBrowser = () => {
  const welcomed = localStorage.getItem('welcomed');
  localStorage.clear();
  localStorage.setItem('welcomed', welcomed);
  sessionStorage.clear();
  deleteCookie('accessToken4', '/', getCookieDomain());
  deleteCookie('plan', '/', getCookieDomain());
  deleteCookie('awc', '/', getCookieDomain());
  deleteTenantIdFromCookies();
  logoutUserFromChromeExtension();
  resetUserData();
};

const deleteSupabaseSession = async () => {
  // This will remove Supabase session in the DB, effectively terminating the current session
  // At the current moment, as we do not have Supabase session expiry, this is our only way to ensure that the session is properlly terminated and deleted instead of accumulating in Supabase's DB, generating an unintentional high load
  const { error } = await supabase.auth.signOut({ scope: 'local' });
  if (error) {
    console.error(`Failed to sign out from Supabase. Error: ${error}`);
  } else {
    console.log('deleted');
  }
};

const onLogout = async () => {
  await deleteSupabaseSession();
  deleteUsersDataFromBrowser();
};

const logoutUser = () => {
  onLogout().then(() => {
    window.location.href = '/';
  });
};

const logoutWithoutRedirecting = () => {
  onLogout();
};

const canRedirectToExtensionLoginSuccessfulPage = () => {
  const item = localStorage.getItem(REQUEST_FROM_EXTENSION);
  return JSON.parse(item) === true;
};

// Export all functions
export {
  checkAuth,
  logoutUser,
  logoutWithoutRedirecting,
  canRedirectToExtensionLoginSuccessfulPage,
  deleteUsersDataFromBrowser,
};
