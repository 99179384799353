import React, { useContext, useEffect, useState } from 'react';
import WhitelabelContext from '../../contexts/WhitelabelContext';
import supabase from '../../utils/supabaseClient';
import { toast } from 'react-toastify';

const useForgotPassword = ({ isOpen }: { isOpen: boolean }) => {
  const [resetReqEmail, setResetReqEmail] = useState('');
  const [didSendReq, setDidSendReq] = useState(false);

  const whitelabelConfig = useContext(WhitelabelContext);

  const updateFormValue = ({ updateType, value }) => {
    if (!updateType || !value) {
      return;
    }
    if (updateType === 'email') {
      setResetReqEmail(value);
    }
  };

  const clickResetPassword = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(resetReqEmail, {
      redirectTo: whitelabelConfig.dashboardHostname + '/',
    });
    if (error) {
      toast.error(error.message);
    } else {
      setDidSendReq(true);
    }
  };

  const clickContactCustomerSupport = () => {
    window.open('https://gptzero.me/contact-us', '_blank');
  };

  useEffect(() => {
    setResetReqEmail('');
    setDidSendReq(false);
  }, [isOpen]);

  return {
    resetReqEmail,
    didSendReq,
    updateFormValue,
    clickResetPassword,
    clickContactCustomerSupport,
  };
};

export default useForgotPassword;
