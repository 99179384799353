// Generated with util/create-component.js
import React from 'react';
import styled from 'styled-components';
import { SelectablePricingCardProps } from './SelectablePricingCard.types';

const PriceContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center
  margin-top: 18px;
`;

const commonContainerStyles = `
  padding: 18px;
  text-align: center;
  border-radius: 10px;
  width: 150px;
`;

const SelectedContainer = styled.div`
  border: 2px solid #3a44a5;
  background: rgba(229, 238, 252, 0.44);
  ${commonContainerStyles}
`;

const DefaultContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.26);
  background: #fff;
  ${commonContainerStyles}
`;

const DollarAmountDiv = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const PeriodDiv = styled.div`
  font-size: 12px;
  font-weight: 300;
  margin-left: 5px;
`;

const SelectablePricingCard: React.FC<SelectablePricingCardProps> = ({
  header,
  price,
  isSelected = false,
  period = 'month',
  id,
  onClick,
}) => {
  let Container = isSelected ? SelectedContainer : DefaultContainer;
  return (
    <Container
      onClick={(e) => onClick(e, id)}
      data-testid="SelectablePricingCard"
    >
      {header}
      <PriceContainer>
        <DollarAmountDiv>{price}</DollarAmountDiv>
        <PeriodDiv>/{period}</PeriodDiv>
      </PriceContainer>
    </Container>
  );
};

export default SelectablePricingCard;
