import background from '../assets/background.svg';
import DashboardPreview from '../components/v2/dashboardPreview';
import React from 'react';
import EditorPreview from '../components/v2/editorPreview';

export function AuthenticationTemplate({
  title,
  subTitle,
  editorPreview = false,
  children,
}) {
  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={
        'h-[100vh] bg-no-repeat bg-cover flex font-ubuntu text-primary'
      }
    >
      {editorPreview ? <EditorPreview /> : <DashboardPreview />}
      <div
        className={
          'bg-white w-full lg:w-[45%] px-8 md:px-[50px] lg:px-[70px] 2xl:px-[150px] pt-6 pb-16 lg:py-[130px] overflow-auto'
        }
      >
        <div className={'flex lg:hidden mb-12'}>
          <img
            src="/origin_logo_bw.svg"
            alt="GPTZero Logo"
            width={29}
            height={29}
            className=" mr-1"
          />
          <span className={'ml-1 font-semibold text-lg lg:text-2xl'}>
            GPTZero
          </span>
        </div>
        <div className={'font-[500] text-[23px] mb-2'}>{title}</div>
        <h6 className={'font-light'}>{subTitle}</h6>
        <div className={'mt-5'}>{children}</div>
      </div>
    </div>
  );
}

export default AuthenticationTemplate;
