import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import whitelabelConfig from '../whitelabelConfig';

export const fetchAiTextCheck =
  (
    scanId,
    text,
    checkerProfile,
    multilingual,
    checkInterpretability,
    projectId,
    editorDocumentId
  ) =>
  async (dispatch) => {
    const baseUrl = whitelabelConfig.gptzero.backendHostname;

    dispatch(setAiCheckLoading({ aiCheckLoading: true }));
    try {
      return await fetch(`${baseUrl}${checkerProfile.textRoute}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          scanId,
          document: text,
          multilingual,
          writing_stats_required: true,
          interpretability_required: checkInterpretability,
          ...(projectId && { projectId }),
          ...(editorDocumentId && { editorDocumentId }),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

export const fetchAiFileCheck =
  (
    scanId,
    files,
    checkerProfile,
    multilingual,
    checkInterpretability,
    projectId
  ) =>
  async (dispatch) => {
    const baseUrl = whitelabelConfig.gptzero.backendHostname;

    const data = new FormData();
    data.append('scanId', scanId);
    data.append('files', files[0]);
    data.append('multilingual', multilingual);
    data.append('writing_stats_required', 'true');
    data.append('interpretability_required', checkInterpretability);

    if (projectId) {
      data.append('projectId', projectId);
    }

    dispatch(setAiCheckLoading({ aiCheckLoading: true }));
    try {
      return await axios.post(`${baseUrl}${checkerProfile.fileRoute}`, data, {
        withCredentials: true,
      });
    } catch (error) {
      throw error;
    }
  };

export const aiCheckSlice = createSlice({
  name: 'aiCheck',
  initialState: {
    serverStatusCode: null,
    aiScanResults: null,
    aiCheckLoading: false,
    isInsideViewChecked: false,
    isMultilingualChecked: false,
  },
  reducers: {
    setAIScanResults(state, action) {
      state.aiScanResults = action.payload.aiScanResults;
      state.serverStatusCode = action.payload.serverStatusCode;
    },
    setAiCheckLoading(state, action) {
      state.aiCheckLoading = action.payload.aiCheckLoading;
    },
    setIsInsideViewChecked(state, action) {
      state.isInsideViewChecked = action.payload.isInsideViewChecked;
    },
    setServerStatusCode(state, action) {
      state.serverStatusCode = action.payload.serverStatusCode;
    },
    setIsMultilingualChecked(state, action) {
      state.isMultilingualChecked = action.payload.isMultilingualChecked;
    },
  },
});

export const {
  setAIScanResults,
  setAiCheckLoading,
  setIsInsideViewChecked,
  setServerStatusCode,
  setIsMultilingualChecked,
} = aiCheckSlice.actions;

export default aiCheckSlice.reducer;
