import React from 'react';
import UserRoleItem from './UserRoleItem';
import { ReactComponent as StudentAvatar } from '../../../../assets/role-avatars/student.svg';
import { ReactComponent as EducatorAvatar } from '../../../../assets/role-avatars/educator.svg';
import { ReactComponent as WriterAvatar } from '../../../../assets/role-avatars/writer.svg';
import { ReactComponent as ReviewerAvatar } from '../../../../assets/role-avatars/reviewer.svg';
import { ROLES } from '../../../../constants/signUpModal';

interface UserRolesProps {
  selectedRole: string | null;
  onSelectRole: (role: string) => void;
}

const UserRoles = ({ selectedRole, onSelectRole }: UserRolesProps) => {
  return (
    <div className="flex space-x-4 mx-12 mt-4 mb-6">
      <UserRoleItem
        role="Student"
        onClick={() => onSelectRole(ROLES.STUDENT)}
        isSelected={selectedRole === ROLES.STUDENT}
        avatar={<StudentAvatar />}
      />
      <UserRoleItem
        role="Educator"
        onClick={() => onSelectRole(ROLES.EDUCATOR)}
        isSelected={selectedRole === ROLES.EDUCATOR}
        avatar={<EducatorAvatar />}
      />
      <UserRoleItem
        role="Writer"
        onClick={() => onSelectRole(ROLES.WRITER)}
        isSelected={selectedRole === ROLES.WRITER}
        avatar={<WriterAvatar />}
      />
      <UserRoleItem
        role="Reviewer"
        onClick={() => onSelectRole(ROLES.REVIEWER)}
        isSelected={selectedRole === ROLES.REVIEWER}
        avatar={<ReviewerAvatar />}
      />
    </div>
  );
};

export default UserRoles;
