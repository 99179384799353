import React from 'react';
import ShouldRender from '../../ShouldRender';
import { LogInModal } from './LoginModal';
import { SignUpModal } from './SignUpModal';
import {
  SignUpModalHeaderMessages,
  SignUpModalSources,
} from '../../../constants/signUpModal';
import { ConfirmEmailModal } from './ConfirmEmailModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import MobileSignupModal from './mobile/MobileSignupModal';
import MobileLoginModal from './mobile/MobileLoginModal';
import MobileConfirmEmailModal from './mobile/MobileConfirmEmailModal';
import MobileForgotPasswordModal from './mobile/MobileForgotPasswordModal';
import useAuthReduxState from '../../../hooks/auth/useAuthReduxState';
import useMobile from '../../../hooks/useMobile';
import { setActiveView } from '../../../app/store/aiUtilitiesHubSlice';
import { useDispatch } from 'react-redux';
import { ScanTypes } from '../../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';

const GuestAccountAuthModals = () => {
  const {
    emailToVerify,
    showLoginModal,
    showSignUpModal,
    isAnonymousUser,
    signUpModalSource,
    showConfirmEmailModal,
    showForgotPasswordModal,
    setShowSignUpModal,
    setShowLoginModal,
    setShowConfirmEmailModal,
    setShowForgotPasswordModal,
  } = useAuthReduxState();

  const { isMobile } = useMobile();

  const dispatch = useDispatch();

  const setBasicViewAsActiveView = () => {
    dispatch(setActiveView(ScanTypes.basic));
  };

  return (
    <>
      <ShouldRender render={isAnonymousUser && showLoginModal}>
        <ShouldRender render={!isMobile}>
          <LogInModal
            isOpen={isAnonymousUser && showLoginModal}
            onClickBackButton={() => {
              setShowLoginModal(false);
              setShowSignUpModal(true);
            }}
            onClickCreateAccount={() => {
              setShowLoginModal(false);
              setShowSignUpModal(true);
            }}
            toggleOpen={() => {
              setShowLoginModal(!showLoginModal);
              setBasicViewAsActiveView();
            }}
            onForgotPasswordClick={() => {
              setShowLoginModal(false);
              setShowForgotPasswordModal(true);
            }}
          />
        </ShouldRender>

        <ShouldRender render={isMobile}>
          <MobileLoginModal
            isOpen={isAnonymousUser && showLoginModal}
            toggleOpen={() => {
              setShowLoginModal(!showLoginModal);
              setBasicViewAsActiveView();
            }}
            onClickCreateAccount={() => {
              setShowLoginModal(false);
              setShowSignUpModal(true);
            }}
            onForgotPasswordClick={() => {
              setShowLoginModal(false);
              setShowForgotPasswordModal(true);
            }}
          />
        </ShouldRender>
      </ShouldRender>

      <ShouldRender render={isAnonymousUser && showSignUpModal}>
        <ShouldRender render={!isMobile}>
          <SignUpModal
            title={
              SignUpModalHeaderMessages[
                signUpModalSource ?? SignUpModalSources.OTHER
              ]
            }
            isOpen={isAnonymousUser && showSignUpModal}
            toggleOpen={() => {
              setShowSignUpModal(!showSignUpModal);
              setBasicViewAsActiveView();
            }}
            handleLogIn={() => {
              setShowLoginModal(true);
              setShowSignUpModal(false);
            }}
            onOpenEmailConfirmationModal={() => {
              setShowSignUpModal(false);
              setShowConfirmEmailModal(true);
            }}
          />
        </ShouldRender>

        <ShouldRender render={isMobile}>
          <MobileSignupModal
            toggleOpen={() => {
              setShowSignUpModal(!showSignUpModal);
              setBasicViewAsActiveView();
            }}
            isOpen={isAnonymousUser && showSignUpModal}
            title={
              SignUpModalHeaderMessages[
                signUpModalSource ?? SignUpModalSources.OTHER
              ]
            }
            handleLogIn={() => {
              setShowLoginModal(true);
              setShowSignUpModal(false);
            }}
            onOpenEmailConfirmationModal={() => {
              setShowSignUpModal(false);
              setShowConfirmEmailModal(true);
            }}
          />
        </ShouldRender>
      </ShouldRender>

      <ShouldRender render={showConfirmEmailModal}>
        <ShouldRender render={!isMobile}>
          <ConfirmEmailModal
            toggleOpen={() => {
              setShowConfirmEmailModal(false);
              setBasicViewAsActiveView();
            }}
            isOpen={showConfirmEmailModal}
            email={emailToVerify}
            onClickReturnToLogin={() => {
              setShowConfirmEmailModal(false);
              setShowLoginModal(true);
            }}
          />
        </ShouldRender>

        <ShouldRender render={isMobile}>
          <MobileConfirmEmailModal
            email={emailToVerify}
            isOpen={showConfirmEmailModal}
            onClickReturnToLogin={() => {
              setShowConfirmEmailModal(false);
              setShowLoginModal(true);
            }}
            toggleOpen={() => {
              setShowConfirmEmailModal(false);
              setBasicViewAsActiveView();
            }}
          />
        </ShouldRender>
      </ShouldRender>

      <ShouldRender render={showForgotPasswordModal}>
        <ShouldRender render={!isMobile}>
          <ForgotPasswordModal
            isOpen={showForgotPasswordModal}
            toggleOpen={() => {
              setShowForgotPasswordModal(false);
              setBasicViewAsActiveView();
            }}
            onClickBackButton={() => {
              setShowForgotPasswordModal(false);
              setShowLoginModal(true);
            }}
            onClickCreateAccount={() => {
              setShowForgotPasswordModal(false);
              setShowSignUpModal(true);
            }}
          />
        </ShouldRender>

        <ShouldRender render={isMobile}>
          <MobileForgotPasswordModal
            isOpen={showForgotPasswordModal}
            onClickCreateAccount={() => {
              setShowForgotPasswordModal(false);
              setShowLoginModal(true);
            }}
            toggleOpen={() => {
              setShowForgotPasswordModal(false);
              setBasicViewAsActiveView();
            }}
          />
        </ShouldRender>
      </ShouldRender>
    </>
  );
};

export default GuestAccountAuthModals;
