import styled from 'styled-components';
import { TextProps } from './text.types';

export const StyledText = styled('p')<TextProps>`
  font-size: ${(props) => props.$fontSize || '14px'};
  color: ${(props) =>
    props.$secondary
      ? `rgba(110, 128, 190, ${props.$opacity || 1})`
      : props.$color
        ? props.$color
        : `rgba(59, 58, 59, ${props.$opacity || 1})`};
  font-weight: ${(props) =>
    props.$bold
      ? '700'
      : props.$light
        ? '300'
        : props?.$medium
          ? '500'
          : '400'};
  font-style: ${(props) => (props.$italicize ? 'italic' : 'none')};
  text-transform: ${(props) =>
    props.$capitalize ? 'capitalize' : props.$uppercase ? 'uppercase' : 'none'};
  text-decoration: ${(props) => props.$underline && 'underline'};
  margin: ${(props) => props.$margin || '0px'};
  margin-top: ${(props) => props.$marginTop};
  margin-left: ${(props) => props.$marginLeft};
  margin-right: ${(props) => props.$marginRight};
  margin-bottom: ${(props) => props.$marginBottom};
  text-align: ${(props) => (props.$textCenter ? 'center' : 'left')};
  line-height: ${(props) => props.$lineHeight || '1.4'};
  letter-spacing: ${(props) => props.$letterSpacing || 'normal'};
  ${(props) => props.$fontFamily && `font-family: ${props.$fontFamily};`}
  ${(props) => props.$textIndent && `text-indent: ${props.$textIndent};`}
`;
