import React, { useEffect, useState } from 'react';
import supabase from '../../utils/supabaseClient';
import { getUserProfileDetails } from '../../utils/profileUtils';

interface CheckWhitelistParams {
  emailDomain: string | undefined;
}

interface UseClassroomWhitelistResult {
  isWhitelistActive: boolean;
  isUserWhitelisted: boolean;
  loadingWhitelist: boolean;
}

const checkIfInstitutionWhitelistActive = async ({
  emailDomain,
}: CheckWhitelistParams): Promise<boolean> => {
  const { data, error } = await supabase
    .from('google_classroom_trial_customers')
    .select('*')
    .or(`expires_at.gt.${new Date().toISOString()},expires_at.is.null`)
    .eq('institution_email_domain', emailDomain);

  if (error) {
    console.error('Error fetching data:', error);
    return false;
  }
  return data.length > 0;
};

const checkIfUserWhitelisted = async ({
  email,
}: {
  email: string;
}): Promise<boolean> => {
  const { data, error } = await supabase
    .from('google_classroom_trial_whitelist')
    .select('*')
    .eq('user_email', email);

  console.log('data', data, 'error', error, 'email', email);
  if (error) {
    console.error('Error fetching data:', error);
    return true;
  }
  return data.length > 0;
};

export const useClassroomWhitelist = ({
  isTeacher,
}): UseClassroomWhitelistResult => {
  const [isWhitelistActive, setIsWhitelistActive] = useState<boolean>(false);
  const [isUserWhitelisted, setIsUserWhitelisted] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(false);
  const { EMAIL: userEmail } = getUserProfileDetails();
  const institutionEmailDomain = userEmail?.split('@')[1];

  useEffect(() => {
    checkWhitelist();
  }, [institutionEmailDomain, userEmail]);

  const checkWhitelist = async () => {
    try {
      setFetching(true);

      if (!isTeacher || !userEmail) {
        setIsWhitelistActive(false);
        setIsUserWhitelisted(true);
        return;
      }

      const isWhitelistActiveNow = await checkIfInstitutionWhitelistActive({
        emailDomain: institutionEmailDomain,
      });
      setIsWhitelistActive(isWhitelistActiveNow);
      if (isWhitelistActiveNow) {
        const isUserWhitelistedNow = await checkIfUserWhitelisted({
          email: userEmail,
        });
        setIsUserWhitelisted(isUserWhitelistedNow);
      }
    } finally {
      setFetching(false);
    }
  };

  return { isWhitelistActive, isUserWhitelisted, loadingWhitelist: fetching };
};
