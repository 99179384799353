import React, { useRef, useEffect } from 'react';
import { Modal } from 'react-daisyui';
import { Turnstile } from '@marsidev/react-turnstile';
import { toast } from 'react-toastify';
import { G0Button, trackEvent } from 'component-library';
import LoadingSpinner from '../../LoadingSpinner';
import { OAuthSignUp } from '../OAuthSignUp';
import NexusInputText from '../../inputs/NexusInputText';
import BackButton from '../BackButton';
import './styles.css';
import useLogin from '../../../../hooks/auth/useLogin';
import LoginModalHeader from '../common/LoginModalHeader';

interface LogInModalProps {
  isOpen: boolean;
  toggleOpen: (open: boolean) => void;
  onClickBackButton: () => void;
  onClickCreateAccount: () => void;
  onForgotPasswordClick: () => void;
}

export const LogInModal = ({
  isOpen,
  toggleOpen,
  onClickBackButton,
  onClickCreateAccount,
  onForgotPasswordClick,
}: LogInModalProps) => {
  const COMPONENT_IDENTIFIER = 'logInModal-dashboard';

  const turnstileRef = useRef(null);
  const formRef = useRef(null);
  const {
    registrationDetails,
    setRegistrationDetails,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    aiDocumentId,
    logIn,
    signInIfPossible,
    updateFormValue,
  } = useLogin();

  useEffect(() => {
    trackEvent('show', COMPONENT_IDENTIFIER, 'logIn', 1, {
      isAnonymousUser: true,
    });
  }, []);

  const submitForm = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData(formRef.current);
    const captchaToken = formData.get('cf-turnstile-response');
    try {
      const error = await logIn(
        registrationDetails.email,
        registrationDetails.password,
        captchaToken.toString()
      );
      trackEvent('signup', COMPONENT_IDENTIFIER, 'logIn', 1, {
        isAnonymousUser: true,
        email: registrationDetails.email,
      });
      setIsLoading(false);
      if (error) {
        setErrorMessage(error.message);
        setRegistrationDetails({ ...registrationDetails, captchaToken: '' });
        turnstileRef.current.reset();
        return;
      } else {
        toast.info('Log in success!', {
          theme: 'light',
        });
        localStorage.setItem('email', registrationDetails.email);
        // temporarily set plan to free. It will be reset if the user is on a paid plan
        localStorage.setItem('plan', 'Free');
        signInIfPossible();
        toggleOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(`Failed: ${error.message}.`, {
        theme: 'light',
      });
    }
  };

  const logInForm = (
    <form onSubmit={submitForm} ref={formRef}>
      <Modal.Body>
        <div className="w-full">
          <hr className="border-g0-gray-100" />
          <OAuthSignUp
            aiDocumentId={aiDocumentId}
            role={null}
            industry={null}
          />
          <hr className="border-g0-gray-100" />
          <div className="mt-9 mb-6 mx-36">
            <NexusInputText
              labelTitle="Email"
              updateType="email"
              updateFormValue={updateFormValue}
              defaultValue={''}
              placeholder="example@domain.com"
              required={true}
            />
            <NexusInputText
              labelTitle="Password"
              updateType="password"
              type="password"
              updateFormValue={updateFormValue}
              defaultValue=""
              placeholder="***********"
              required={true}
            />
            <div>
              <span
                onClick={onForgotPasswordClick}
                className="label-small pl-2 text-g0-gray-400 cursor-pointer"
              >
                Forgot Password?
              </span>
            </div>
            <Turnstile
              ref={turnstileRef}
              siteKey={process.env.REACT_APP_TURNSTILE_SITEKEY}
              onSuccess={(token) => {
                registrationDetails.captchaToken = token;
              }}
              style={{ margin: '2rem auto 0 auto' }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions className="flex flex-col mx-36 mb-4">
        <div className="mx-auto text-red-700">{errorMessage}</div>
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <G0Button
            size="medium"
            type="submit"
            className="flex justify-center"
          >{`Log In`}</G0Button>
        )}
      </Modal.Actions>
    </form>
  );

  return (
    <Modal
      id="login-modal"
      className="p-0 auth-modal py-6"
      responsive={true}
      open={isOpen}
      onClickBackdrop={() => toggleOpen(false)}
    >
      <Modal.Header className="mb-6">
        <BackButton onClick={onClickBackButton} />
        <LoginModalHeader onClickCreateAccount={onClickCreateAccount} />
      </Modal.Header>
      {logInForm}
    </Modal>
  );
};
