import React from 'react';
import { CircularNumberProps } from './circularNumber.types';
import './circularNumber.css';

export function CircularNumber({
  number,
  isExactMatch,
  isOutlined = false,
}: CircularNumberProps) {
  const matchClass =
    number !== 0
      ? isExactMatch
        ? 'circular-number-exact-match'
        : 'circular-number-similar-match'
      : '';

  return (
    <div
      className={`circular-number-wrapper ${matchClass} ${
        isOutlined ? 'circular-number-outlined' : ''
      }`}
    >
      {number}
    </div>
  );
}

export default CircularNumber;
