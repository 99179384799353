import { ICheckerProfileMap } from '../contexts/ServerResponseContext';

const checkerProfiles: ICheckerProfileMap = {
  ai: {
    fileRoute: '/v3/ai/files',
    textRoute: '/v3/ai/text',
    fileCheckTag: 'successful_file_check',
    textCheckTag: 'successful_text_check',
    resultsButtonClickedTag: 'check_result_button_pressed_dash',
    charMinimum: 250,
    textBoxPlaceholder:
      'Type in the text you want to check for AI involvement (minimum 250 characters)',
    serverResponseType: 'serverResponse',
    setServerResponseType: 'setServerResponse',
  },
  aiV2: {
    fileRoute: '/v2/predict/files',
    textRoute: '/v2/predict/text',
    fileCheckTag: 'successful_file_check',
    textCheckTag: 'successful_text_check',
    resultsButtonClickedTag: 'check_result_button_pressed_dash',
    charMinimum: 250,
    textBoxPlaceholder:
      'Type in the text you want to check for AI involvement (minimum 250 characters)',
    serverResponseType: 'serverResponse',
    setServerResponseType: 'setServerResponse',
  },
  plagiarism: {
    fileRoute: '/v2/async/plagiarism/files',
    textRoute: '/v2/async/plagiarism/text',
    fileCheckTag: 'successful_file_check_plag',
    textCheckTag: 'successful_text_check_plag',
    resultsButtonClickedTag: 'check_result_button_pressed_plag',
    charMinimum: 250,
    textBoxPlaceholder:
      'Type in the text you want to check for plagiarism (minimum 45 characters)',
    serverResponseType: 'serverPlagiarismResponse',
    setServerResponseType: 'setServerPlagiarismResponse',
  },
  sources: {
    fileRoute: '/v2/relevant_sources/files',
    textRoute: '/v2/relevant_sources/text',
    fileCheckTag: 'successful_file_check_sources',
    textCheckTag: 'successful_text_check_sources',
    resultsButtonClickedTag: 'check_result_button_pressed_sources',
    charMinimum: 250,
    textBoxPlaceholder: 'Type in the text you want to check for sources',
    serverResponseType: 'serverSourcesResponse',
    setServerResponseType: 'setServerSourcesResponse',
  },
};

export default checkerProfiles;
