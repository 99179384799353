// https://app.hubspot.com/events/24080138/manage
export const HUBSPOT_EVENTS = {
  LOGIN: 'pe24080138_user_login',
  REGISTRATION: 'pe24080138_user_registration',
};

/**
 * Track event in Hubspot
 * @param eventName
 * @param userEmail
 */
export const trackHubspotEvent = ({ eventName, userEmail }) => {
  fetch(`${process.env.REACT_APP_BACKEND_HOSTNAME}/v2/hubspot/events`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      eventName,
      userEmail,
    }),
  });
};
