import React from 'react';
import { G0Button } from 'component-library';
import { ReactComponent as LeftBackArrow } from '../../../assets/single-arrow-left.svg';

const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <G0Button
      onClick={onClick}
      variant="secondary"
      className="border-0 pl-0 pt-0 pb-0 ml-6 label-small"
    >
      <span className="flex items-center gap-2">
        <LeftBackArrow /> Back
      </span>
    </G0Button>
  );
};

export default BackButton;
