const lowConfidenceText = {
  ai: 'We are uncertain about this document. If we had to classify it, it would be considered',
  human:
    'We are uncertain about this document. If we had to classify it, it would likely be considered',
  mixed:
    'We are uncertain about this document. If we had to classify it, it would likely be a',
};

const mediumConfidenceText = {
  ai: 'We are moderately confident this text was',
  human: 'We are moderately confident this text is entirely',
  mixed: 'We are moderately confident this text is a',
};

const highConfidenceText = {
  ai: 'We are highly confident this text was',
  human: 'We are highly confident this text is entirely',
  mixed: 'We are highly confident this text is a',
};

export const confidenceTexts = {
  low: lowConfidenceText,
  medium: mediumConfidenceText,
  high: highConfidenceText,
};

export let wordsToBoldAndUnderline = [
  'moderately',
  'highly',
  'confident',
  'uncertain',
];
