import { createSlice } from '@reduxjs/toolkit';

export const usageStatsSlice = createSlice({
  name: 'usageStats',
  initialState: {
    loading: false,
    errorMessage: '',
    numWords: 0,
    numDocuments: 0,
    numCharacters: 0,
    allTimeNumWords: 0,
    allTimeNumDocuments: 0,
    allTimeNumCharacters: 0,
  },
  reducers: {
    setStats: (state, action) => {
      state.numWords = action.payload?.numWords;
      state.numDocuments = action.payload?.numDocuments;
      state.numCharacters = action.payload?.numCharacters;
      state.allTimeNumWords = action.payload?.allTimeNumWords;
      state.allTimeNumDocuments = action.payload?.allTimeNumDocuments;
      state.allTimeNumCharacters = action.payload?.allTimeNumCharacters;
      state.loading = false;
    },
    setStatsErrorMessage: (state, action) => {
      state.errorMessage = action?.payload?.errorMessage;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
  },
});

export const { setStats, setStatsErrorMessage, setLoading } =
  usageStatsSlice.actions;

export default usageStatsSlice.reducer;
