import { createSlice } from '@reduxjs/toolkit';

export const switchToTeamNotificationSlice = createSlice({
  name: 'switchToTeamNotification',
  initialState: {
    show: false,
  },
  reducers: {
    closeSwitchToTeamNotification: (state) => {
      state.show = false;
    },
    showSwitchToTeamNotification: (state) => {
      state.show = true;
    },
  },
});

export const { closeSwitchToTeamNotification, showSwitchToTeamNotification } =
  switchToTeamNotificationSlice.actions;
export default switchToTeamNotificationSlice.reducer;
