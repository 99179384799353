import { disposableEmailDomains } from './disposable_email_blocklist';

export const validateEmail = (emailStr) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return emailRegex.test(emailStr);
};

// Checks whether an email is a disposable email
// We consider an email to be a disposable email if it belongs to one of the domains below
// Note that the input is assumed to be an email of the valid format
export const isDisposableEmail = (validEmail) => {
  const parts = validEmail.split('@');
  if (parts.length < 2) return undefined;
  const domain = parts[parts.length - 1];
  return disposableEmailDomains.has(domain);
};
