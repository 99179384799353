export const SignUpModalSources = {
  DOCUMENTS: 'documents',
  PLAGIARISM: 'plagiarism',
  ADVANCED_SCAN: 'advanced_scan',
  MULTILINGUAL: 'multilingual',
  WRITING_FEEDBACK: 'writing_feedback',
  HOME: 'home',
  STATISTICS: 'statistics',
  TEAMS: 'teams',
  API: 'api',
  HELP: 'help',
  FEEDBACK: 'feedback',
  SETTINGS: 'settings',
  SCAN_LIMIT: 'scan_limit',
  OTHER: 'other',
  WRITING_ANALYSIS: 'writing_analysis',
  EDITOR: 'editor',
  BATCH_UPLOAD: 'batch_upload',
  DOWNLOAD_REPORT: 'download_report',
  PAYMENT: 'payment',
  SHARE: 'share',
} as const;

export type SignUpModalSource = keyof typeof SignUpModalSources;

export type SignUpModalSourceValues =
  (typeof SignUpModalSources)[SignUpModalSource];

export const SignUpModalHeaderMessages: Partial<
  Record<SignUpModalSourceValues, string>
> = {
  [SignUpModalSources.DOCUMENTS]: 'First, sign up to view scan histories!',
  [SignUpModalSources.SCAN_LIMIT]: 'Sign up for free to get more scans!',
  [SignUpModalSources.OTHER]:
    'Sign up for free to unlock more dashboard features!',
  [SignUpModalSources.ADVANCED_SCAN]: 'Sign up to unlock free Advanced Scans!',
  [SignUpModalSources.PLAGIARISM]: 'Sign up to unlock Plagiarism!',
  [SignUpModalSources.WRITING_FEEDBACK]:
    'Sign up to unlock free Writing Feedback!',
  [SignUpModalSources.WRITING_ANALYSIS]:
    'Sign up to unlock free Writing Analysis!',
  [SignUpModalSources.MULTILINGUAL]:
    'Sign up to unlock free multilingual AI detection!',
  [SignUpModalSources.EDITOR]: 'Sign up to unlock GPTZero Editor!',
  [SignUpModalSources.BATCH_UPLOAD]: 'Sign up to unlock multi-file upload!',
  [SignUpModalSources.DOWNLOAD_REPORT]: 'Sign up to download the scan report!',
  [SignUpModalSources.PAYMENT]: 'Sign up to purchase a premium plan!',
  [SignUpModalSources.SHARE]: 'Sign up to share a scan!',
};

export const ROLES = {
  STUDENT: 'Student',
  EDUCATOR: 'Educator',
  WRITER: 'Writer',
  REVIEWER: 'Reviewer',
} as const;

type rolesKey = keyof typeof ROLES;

export type Role = (typeof ROLES)[rolesKey];

export const WRITER_INDUSTRIES = [
  {
    id: 'general_writer',
    label: 'General Writer',
  },
  {
    id: 'marketing',
    label: 'SEO / Marketing',
  },
  { id: 'consulting', label: 'Consulting' },
  { id: 'journalism', label: 'Publishing / Journalism' },
  { id: 'content_creation', label: 'Content Creation' },
  { id: 'academic_research', label: 'Academic Research' },
  { id: 'other', label: 'Other' },
];

export const REVIEWER_INDUSTRIES = [
  {
    id: 'hiring_manager',
    label: 'Hiring / Skills assessment',
  },
  { id: 'admissions', label: 'Admissions' },
  { id: 'scholarly_reviewer', label: 'Academic Research' },
  { id: 'publisher', label: 'Publisher' },
  { id: 'marketing', label: 'Marketing' },
  { id: 'data_labeling', label: 'Machine Learning / Data Labeling' },
  { id: 'consulting', label: 'Consulting' },
  { id: 'cyber_security', label: 'Cyber Security' },
  { id: 'legal', label: 'Legal' },
  { id: 'other', label: 'Other' },
];

export type RoleIndustry = { id: string; label: string };

export enum SignUpStage {
  Email,
  PersonalInfo,
}
