import { EMAIL } from '../constants/localStorage';

export function urlEncodeParam(param) {
  if (!param) return null;
  return encodeURIComponent(param);
}

export function formatNumber(number) {
  if (number === 0) return 0;
  else if (!number) return null;
  return number.toLocaleString();
}

export function getCreditsUsed(isOnPremiumTeam, tenant, numWords) {
  if (isOnPremiumTeam) {
    return tenant.credits_used ?? 0;
  } else {
    return numWords;
  }
}

export function getPlanLimit(isOnPremiumTeam, tenant, creditsLimit) {
  if (isOnPremiumTeam) {
    return tenant.total_credits;
  } else {
    return creditsLimit;
  }
}

/**
 * @description function to format large numbers and return abbreviation e.g. 150000 would become 150K; 34784 would become 34K and 5012000 would become 5M
 * @param {*} number
 * @returns string
 */
export function formatLargeNumberAbbreviated(number) {
  // List keys in descending order
  const abbreviations = {
    M: 1000000,
    K: 1000,
  };

  for (const symbol in abbreviations) {
    if (number >= abbreviations[symbol]) {
      return Math.floor(number / abbreviations[symbol]) + symbol;
    }
  }

  return number.toString();
}

/**
 * @description function to format large numbers with some formatting e.g. 12000000 would become 12 million; 300000 would become 300,000
 * @param {*} number
 * @returns string
 */
export function numberToWordsWithFormat(number) {
  const words = [
    '',
    'million',
    'billion',
    'trillion',
    'quadrillion',
    'quintillion',
  ];

  if (number < 100000) {
    return number.toLocaleString();
  }

  let divider = 1000000;
  let wordIndex = 1;

  if (number >= 1000000000) {
    divider = 1000000000;
    wordIndex = 2;
  }

  const formattedNumber = (number / divider).toFixed(1);
  const integerPart = Math.floor(formattedNumber);
  const fractionalPart = Math.round((formattedNumber - integerPart) * 10);

  const formattedIntegerPart = formatNumber(integerPart);
  const formattedFractionalPart =
    fractionalPart === 0 ? '' : `.${fractionalPart}`;

  return `${formattedIntegerPart}${formattedFractionalPart} ${words[wordIndex]}`;
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

/**
 * Generate and download a
 * @param headers
 * @param rowsData
 * @param fileName
 */
export const generateAndDownloadCSV = ({
  headers,
  rowsData,
  fileName = 'export.csv',
}) => {
  const csvFileContent = [];

  if (headers) {
    csvFileContent.push(headers.join(','));
  }

  rowsData.forEach((row) => {
    csvFileContent.push(row.join(','));
  });

  const csvString = csvFileContent.join('\r\n');

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Validate stripeCustomerId
export const isValidStripeCustomerId = (stripeCustomerId) => {
  const stripeCustomerIdRegex = /^cus_[A-Za-z0-9]+$/;
  return stripeCustomerIdRegex.test(stripeCustomerId);
};

// Validate stripeSubscriptionId
export const isValidStripeSubscriptionId = (stripeSubscriptionId) => {
  const stripeSubscriptionIdRegex = /^sub_[A-Za-z0-9]+$/;
  return stripeSubscriptionIdRegex.test(stripeSubscriptionId);
};

export const getBillingUrl = () => {
  const urlEncodedEmail = urlEncodeParam(EMAIL);
  const url =
    process.env.NODE_ENV === 'production'
      ? `https://billing.stripe.com/p/login/14k5n43TMgk08N2fYY?prefilled_email=${urlEncodedEmail}`
      : `https://billing.stripe.com/p/login/test_9AQdR19nT4OI9mU6oo?prefilled_email=${urlEncodedEmail}`;

  return url;
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const convertRange = (value, [oldMin, oldMax], [newMin, newMax]) => {
  return ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
};

export const deleteQueryParamsAndReplaceUrl = (paramsToDelete) => {
  const url = new URL(window.location.href);

  // Remove each query parameter specified in paramsToDelete
  paramsToDelete.forEach((param) => url.searchParams.delete(param));

  // Replace the current URL without reloading the page
  window.history.replaceState(null, '', url.toString());
};
