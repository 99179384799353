import { useMemo } from 'react';
import { ClassroomQueryParams } from '../../types/types';

const useClassroomQueryParams = (): ClassroomQueryParams => {
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      courseId: params.get('courseId'),
      itemId: params.get('itemId'),
      itemType: params.get('itemType'),
      login_hint: params.get('login_hint'),
      attachmentId: params.get('attachmentId'),
      submissionId: params.get('submissionId'),
      addOnToken: params.get('addOnToken'),
      urlToUpgrade: params.get('urlToUpgrade'),
    };
  }, [window.location.search]);

  return queryParams;
};

export default useClassroomQueryParams;
