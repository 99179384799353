import React, { useEffect } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import LocalStorageKeys from '../constants/localStorageKeys';

// TODO: remove. temporarily here so we can test it while it is in beta, and doesn't have a paid view
const FREE_PLAN_SOURCES_SCAN_LIMIT = 50;
const ONE_MONTH_MILLISECONDS = 30 * 24 * 60 * 60 * 1000;

const useSourcesScanUsage = () => {
  const [sourcesScanUsages, storeSourcesScanUsages] = useLocalStorage<number>(
    LocalStorageKeys.sourcesScanUsages,
    0
  );
  const [lastUsageDate, storeLastUsageDate] = useLocalStorage<string>(
    LocalStorageKeys.lastSourcesScanUsageDate,
    new Date().toISOString()
  );

  const maybeResetUsage = () => {
    const now = new Date();
    const lastUsage = new Date(lastUsageDate);

    if (now.getTime() - lastUsage.getTime() > ONE_MONTH_MILLISECONDS) {
      storeSourcesScanUsages(0);
    }
  };

  const incrementSourcesScanUsages = () => {
    maybeResetUsage();
    storeSourcesScanUsages(sourcesScanUsages + 1);
    const now = new Date().toISOString();
    storeLastUsageDate(now);
  };

  const sourcesScansRemaining = Math.max(
    0,
    FREE_PLAN_SOURCES_SCAN_LIMIT - sourcesScanUsages
  );

  useEffect(() => {
    maybeResetUsage();
  }, []);

  return {
    sourcesScansRemaining,
    incrementSourcesScanUsages,
    sourcesScanUsagesInCurrentPeriod: sourcesScanUsages,
  };
};

export default useSourcesScanUsage;
