type LoaderQuote = {
  name: string;
  message: string;
};

export const loaderQuotes: LoaderQuote[] = [
  {
    name: 'Ernest Hemingway',
    message: '“In order to write about life first you must live it.”',
  },
  {
    name: 'C.S. Lewis',
    message: '“You can make anything by writing.”',
  },
  {
    name: 'Maya Angelou',
    message:
      '“There is no greater agony than bearing an untold story inside you.”',
  },
  {
    name: 'Stephen King',
    message: '“The scariest moment is always just before you start.”',
  },
  {
    name: 'Mark Twain',
    message:
      '“Writing is easy. All you have to do is cross out the wrong words.”',
  },
  {
    name: 'Thomas Mann',
    message:
      '“A writer is someone for whom writing is more difficult than it is for other people.”',
  },
  {
    name: 'Ernest Hemingway',
    message:
      '“There is nothing to writing. All you do is sit down at a typewriter and bleed.”',
  },
  {
    name: 'Ray Bradbury',
    message: '“You must stay drunk on writing so reality cannot destroy you.”',
  },
  {
    name: 'Anton Chekhov',
    message:
      "“Don't tell me the moon is shining; show me the glint of light on broken glass.”",
  },
  {
    name: 'Henry Miller',
    message: '“Writing is its own reward.”',
  },
  {
    name: 'Umberto Eco',
    message: '“To survive, you must tell stories.”',
  },
  {
    name: 'Anaïs Nin',
    message:
      '“The role of a writer is not to say what we all can say, but what we are unable to say.”',
  },
  {
    name: 'Margaret Atwood',
    message: '“A word after a word after a word is power.”',
  },
  {
    name: 'Jack London',
    message:
      '“You can’t wait for inspiration. You have to go after it with a club.”',
  },
  {
    name: 'William Zinsser',
    message: '“Writing is thinking on paper.”',
  },
  {
    name: 'Voltaire',
    message: '“Writing is the painting of the voice.”',
  },
  {
    name: 'Burton Rascoe',
    message: "“A writer is working when he's staring out of the window.”",
  },
  {
    name: 'Gustave Flaubert',
    message: '“The art of writing is the art of discovering what you believe.”',
  },
  {
    name: 'Friedrich Nietzsche',
    message:
      '“A good writer possesses not only his own spirit but also the spirit of his friends.”',
  },
  {
    name: 'Nathaniel Hawthorne',
    message: '“Easy reading is damn hard writing.”',
  },
  {
    name: 'Anaïs Nin',
    message: '“We write to taste life twice, in the moment and in retrospect.”',
  },
  {
    name: 'Horace',
    message: '“The pen is the tongue of the mind.”',
  },
  {
    name: 'Doris Lessing',
    message:
      '“There are no laws for the novel. There never have been, nor can there ever be.”',
  },
  {
    name: 'William Wordsworth',
    message: '“Fill your paper with the breathings of your heart.”',
  },
  {
    name: 'Albert Camus',
    message:
      '“The purpose of a writer is to keep civilization from destroying itself.”',
  },
  {
    name: 'E.L. Doctorow',
    message:
      '“Writing is an exploration. You start from nothing and learn as you go.”',
  },
  {
    name: 'Ernest Hemingway',
    message:
      '“We are all apprentices in a craft where no one ever becomes a master.”',
  },
  {
    name: 'Sidney Sheldon',
    message:
      "“A blank piece of paper is God's way of telling us how hard it is to be God.”",
  },
  {
    name: 'Emily Dickinson',
    message:
      '“A word is dead when it is said, some say. I say it just begins to live that day.”',
  },
  {
    name: 'Jack Kerouac',
    message:
      '“One day I will find the right words, and they all will be simple.”',
  },
  {
    name: 'Annie Proulx',
    message:
      '“Writing comes from reading, and reading is the finest teacher of how to write.”',
  },
  {
    name: 'Isabel Allende',
    message: '“Write what should not be forgotten.”',
  },
  {
    name: 'Carson McCullers',
    message: '“The writer is by nature a dreamer—a conscious dreamer.”',
  },
  {
    name: 'Toni Morrison',
    message:
      "“If there's a book that you want to read, but it hasn't been written yet, then you must write it.”",
  },
  {
    name: 'Samuel Johnson',
    message:
      '“The only end of writing is to enable the reader better to enjoy life or better to endure it.”',
  },
  {
    name: 'Stephen King',
    message:
      '“Description begins in the writer’s imagination, but should finish in the reader’s.”',
  },
  {
    name: 'Mark Twain',
    message:
      '“The difference between the almost right word and the right word is really a large matter—it’s the difference between the lightning bug and the lightning.”',
  },
  {
    name: 'F. Scott Fitzgerald',
    message:
      '“You don’t write because you want to say something; you write because you’ve got something to say.”',
  },
  {
    name: 'William Faulkner',
    message:
      "“Get it down. Take chances. It may be bad, but it's the only way you can do anything really good.”",
  },
];
