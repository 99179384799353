import { fetchApiWrapper } from './FetchApiWrapper';
import { endpoints } from '../../../constants/endpoints';

export const apiFetchUserRoles = async ({ backendHostname, userProfileId }) => {
  const url = backendHostname + endpoints.GET_USER_ROLES + userProfileId;

  return await fetchApiWrapper({ url, httpRequestType: 'GET' });
};

export const apiInviteUsers = async ({ backendHostname, insertData }) => {
  const url = backendHostname + endpoints.INVITE_USERS;

  return await fetchApiWrapper({
    url,
    httpRequestType: 'POST',
    data: insertData,
  });
};

export const apiUpdateUser = async ({
  backendHostname,
  orgUserId,
  updateData,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.UPDATE_USER + orgUserId,
    httpRequestType: 'PUT',
    data: { memberData: updateData },
  });
};

export const apiAcceptTeamInvitation = async ({
  backendHostname,
  profileId,
  updateData,
  tenantId,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.ACCEPT_USER_INVITATION + profileId,
    httpRequestType: 'PUT',
    data: { member: updateData, tenantId },
  });
};

export const apiFetchUsers = async ({ backendHostname, tenantId }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.GET_TENANT_USERS + tenantId,
    httpRequestType: 'GET',
  });
};

export const apiDeleteUser = async ({
  backendHostname,
  tenantId,
  selectedIds,
}) => {
  return await fetchApiWrapper({
    url:
      backendHostname +
      endpoints.DELETE_USER +
      selectedIds +
      '&tenantId=' +
      tenantId,
    httpRequestType: 'DELETE',
  });
};

export const apiResendInvitation = async ({
  backendHostname,
  dashboardHostname,
  orgUserId,
  memberData,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.UPDATE_USER + orgUserId + '/resend/',
    httpRequestType: 'PUT',
    data: { memberData: memberData, dashboardHostname: dashboardHostname },
  });
};

export const apiResendInvitations = async ({
  backendHostname,
  dashboardHostname,
  tenantId,
  orgUserIds,
}) => {
  return await fetchApiWrapper({
    url: `${backendHostname}${endpoints.BULK_RESEND_USER_INVITES.replace(
      ':tenantId',
      tenantId
    )}`,
    httpRequestType: 'PUT',
    data: { userIds: orgUserIds, dashboardHostname: dashboardHostname },
  });
};

export const apiRejectTeamInvitation = async ({
  backendHostname,
  updateData,
  tenantId,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.REJECT_TEAM_INVITATION,
    httpRequestType: 'PUT',
    data: { member: updateData, tenantId },
  });
};

export const apiSendUserWelcomeMessage = async ({ backendHostname, email }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.SEND_WELCOME_MESSAGE,
    httpRequestType: 'POST',
    data: { email },
  });
};

export const apiGetTeamMembersCreditUsage = async ({
  backendHostname,
  tenantId,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.TEAM_MEMBER_CREDIT_USAGE + tenantId,
    httpRequestType: 'GET',
  });
};

export const apiUpdateUserTeamSelection = async ({
  backendHostname,
  tenantId,
  email,
  teamPlan,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.USER_TEAM_SELECTION,
    httpRequestType: 'PUT',
    data: { member: { email: email }, tenantId: tenantId, teamPlan: teamPlan },
  });
};

export const apiUploadUserCsv = async ({ backendHostname, formData }) => {
  const url = backendHostname + endpoints.UPLOAD_USERS_CSV;

  return await fetchApiWrapper({
    url,
    httpRequestType: 'POST',
    data: formData,
    contentType: 'multipart/form-data',
  });
};

export const apiUpdateUserInviteAcceptance = async ({
  backendHostname,
  email,
}) => {
  const url = backendHostname + endpoints.UPDATE_USER_INVITE_ACCEPTANCE;
  const response = await fetchApiWrapper({
    url,
    httpRequestType: 'PUT',
    data: { email: email },
  });

  return response;
};

export const apiGetUserInvites = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.GET_USER_INVITES,
    httpRequestType: 'GET',
  });
};

export const apiCreateUserInvite = async ({
  backendHostname,
  inviteData,
  dashboardHostname,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.CREATE_USER_INVITE,
    httpRequestType: 'POST',
    data: { dashboardHostname, inviteData },
  });
};

export const apiDeleteUserInvite = async ({ backendHostname, id }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.DELETE_USER_INVITE + '/' + id,
    httpRequestType: 'DELETE',
  });
};

export const apiCountUserTeams = async ({ backendHostname }) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.COUNT_TEAMS_USER_BELONGS_TO,
    httpRequestType: 'GET',
  });
};

export const apiResendUserInvite = async ({
  backendHostname,
  id,
  dashboardHostname,
}) => {
  return await fetchApiWrapper({
    url: backendHostname + endpoints.RESEND_USER_INVITE + id,
    httpRequestType: 'PUT',
    data: { dashboardHostname },
  });
};

export const apiInviteUsersV3 = async ({ backendHostname, insertData }) => {
  const url = backendHostname + endpoints.INVITE_USERS_V3;

  return await fetchApiWrapper({
    url,
    httpRequestType: 'POST',
    data: insertData,
  });
};

export const apiGetTeamMemberCreditUsage = async ({
  userId,
  tenantId,
  backendHostname,
}) => {
  const url =
    backendHostname +
    endpoints.GET_TEAM_MEMBER_CREDIT_USAGE.replace(':userId', userId) +
    tenantId;
  return await fetchApiWrapper({
    url,
    httpRequestType: 'GET',
  });
};
