import React, { useEffect, useSyncExternalStore, useCallback } from 'react';

export const LocalStorageKeys = {
  editorOnboardingViewed: 'editorOnboardingViewed',
  email: 'email',
  id: 'id',
  joinPageLink: 'joinPageLink',
  acceptUserInviteLink: 'acceptUserInviteLink',
  createTeamLink: 'createTeamLink',
  plan: 'plan',
  hasSeenTutorial: 'hasSeenTutorial',
  teamInviteStatus: 'teamInviteStatus',
  newUserSendWelcome: 'newUserSendWelcome',
  referralID: 'referralID',
  referralName: 'referralName',
  editorWaitlistBannerDismissed: 'editorWaitlistBannerDismissed',
  editorWaitListBannerViewCount: 'editorWaitListBannerViewCount',
  editorAccessGrantedBannerViewed: 'editorAccessGrantedBannerViewed',
  offEditorWaitListModalDismissed: 'offEditorWaitListModalDismissed',
  editorOnboardingTooltipsDismissed: 'editorOnboardingTooltipDismissed',
};

export const getLocalStorageItem = (key) => {
  return window.localStorage.getItem(key);
};

function dispatchStorageEvent(key, newValue) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

const useLocalStorageSubscribe = (callback) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

const getLocalStorageServerSnapshot = () => {
  throw Error('useLocalStorage is a client-only hook');
};

export const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

export const removeLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

// Values stored with useLocalStorage are always JSON.stringified
// but values stored using prior methods (e.g., setLocalStorageItem) are not.
const parseJSONOrReturnOriginal = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export function useLocalStorage(key, initialValue) {
  const getSnapshot = () => getLocalStorageItem(key);

  const store = useSyncExternalStore(
    useLocalStorageSubscribe,
    getSnapshot,
    getLocalStorageServerSnapshot
  );

  const setState = useCallback(
    (v) => {
      const nextState = typeof v === 'function' ? v(JSON.parse(store)) : v;

      if (nextState === undefined || nextState === null) {
        removeLocalStorageItem(key);
      } else {
        setLocalStorageItem(key, nextState);
      }
    },
    [key, store]
  );

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [store ? parseJSONOrReturnOriginal(store) : initialValue, setState];
}

export function replaceWithJSONParseableString(key) {
  const value = getLocalStorageItem(key);
  try {
    JSON.parse(value);
  } catch (e) {
    setLocalStorageItem(key, JSON.stringify(value));
  }
}
