import React, { useState } from 'react';
import supabaseClient from '../../utils/supabaseClient';
import { displayToast } from '../../utils/uiUtil';

const useConfirmEmail = ({ email }: { email: string }) => {
  const [loading, setLoading] = useState(false);

  const onClickResendEmail = async () => {
    setLoading(true);

    const { error } = await supabaseClient.auth.resend({
      type: 'signup',
      email: email,
      options: {
        emailRedirectTo: window.location.origin + '/?type=signup-success',
      },
    });

    if (error) {
      displayToast('error', error.message);
    } else {
      displayToast('success', 'Email has been sent successfully.');
    }

    setLoading(false);
  };

  const onClickCustomerSupport = () => {
    window.open('https://gptzero.me/contact-us', '_blank');
  };

  return {
    loading,
    onClickResendEmail,
    onClickCustomerSupport,
  };
};

export default useConfirmEmail;
