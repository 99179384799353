import whitelabelConfig from '../app/whitelabelConfig';
import supabase from './supabaseClient';

function getWhitelabelDomain() {
  let whitelabel = whitelabelConfig.gptzero;
  if (
    window.location.href.includes(whitelabelConfig.pukkagpt.dashboardHostname)
  ) {
    whitelabel = whitelabelConfig.pukkagpt;
  } else if (
    window.location.href.includes(
      whitelabelConfig.pukkagptDemo.dashboardHostname
    )
  ) {
    whitelabel = whitelabelConfig.pukkagptDemo;
  }
  return whitelabel;
}
async function checkWhitelistedProfile(email) {
  const { data, error } = await supabase
    .from('pukkagpt_whitelist')
    .select('*')
    .eq('email', email);
  if (error) {
    // Handle error
    console.error(
      `Error looking up whitelabel emails: ${JSON.stringify(error)} | ${error}`
    );
    return;
  }

  if (data.length === 0) {
    //check if email is found in the students whitelist
    const { data: student_data, error: student_error } = await supabase
      .from('students_whitelist')
      .select('*')
      .eq('email', email)
      .in('status', ['active', 'invited'])
      .is('deleted_at', null);
    if (student_error) {
      // Handle error
      console.error(
        'Error looking up whitelabel emails in the students whitelist table: ' +
          student_error
      );
      return;
    }

    //only return false if email is also not found in the students_whitelits table
    if (student_data.length === 0) {
      // Email address not found in the whitelist
      return false;
    }
  }
  return true;
}

async function checkAdvisorAllowlistedProfile(email) {
  const { data, error } = await supabase
    .from('advisor_allowlist')
    .select('*')
    .eq('email', email);
  if (error) {
    // Handle error
    console.error(
      `Error looking up advisor allowlist emails: ${JSON.stringify(error)} | ${error}`
    );
    return;
  }

  if (data.length === 0) {
    // Email address not found in the whitelist
    return false;
  }
  return true;
}

async function checkTeacherWhitelistedProfile(email) {
  const { data, error } = await supabase
    .from('teachers_whitelist')
    .select('*')
    .eq('email', email)
    .in('status', ['active', 'invited'])
    .is('deleted_at', null);
  if (error) {
    // Handle error
    console.error(
      `Error looking up teacher whitelist emails: ${JSON.stringify(error)} | ${error}`
    );
    return;
  }

  if (data.length === 0) {
    // Email address not found in the whitelist
    return false;
  }
  return true;
}

export {
  getWhitelabelDomain,
  checkWhitelistedProfile,
  checkAdvisorAllowlistedProfile,
  checkTeacherWhitelistedProfile,
};
