import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  setOverallScanId,
  setWritingFeedbackChunks,
  setWritingFeedbackDocument,
  setWritingFeedbackScanServerStatusCode,
  setWritingFeedbackScanStatus,
  setLatestInputText,
  setScanTitle,
  setScanAuthor,
  setInputTextChangedSinceScan,
  setHasViewedWritingFeedbackScanResults,
} from '../../app/store/aiUtilitiesHubSlice';
import { ScanStatus } from '../../app/store/aiUtilitiesHubSlice/aiUtilitiesHubSlice.constants';
import whitelabelConfig from '../../app/whitelabelConfig';
import { endpoints } from '../../constants/endpoints';
import { getFirstSentence } from '../../utils/textUtils';
import useScanV3 from '../useScanV3';
import { useGetAiUtilitiesHubStateValues } from '../../features/aiUtilitiesHub/hooks/useStore';

const useRequestWritingFeedbackScan = () => {
  const dispatch = useDispatch();

  const { createScan } = useScanV3();
  const { overallScanId } = useGetAiUtilitiesHubStateValues();

  const requestWritingFeedback = async ({ scanId, input }) => {
    dispatch(setWritingFeedbackScanStatus({ status: ScanStatus.loading }));
    dispatch(
      setHasViewedWritingFeedbackScanResults({ hasViewedResults: false })
    );

    let newScanId;
    let scanTitle;
    if (!overallScanId && !scanId) {
      scanTitle = input?.name || getFirstSentence(input);
      const {
        data: { id, author, createdAt },
      } = await createScan({ title: scanTitle, aiDocumentId: null });

      dispatch(setScanTitle({ scanTitle }));
      dispatch(setScanAuthor({ scanAuthor: author }));

      dispatch(setOverallScanId({ overallScanId: id }));
      newScanId = id;
    }

    const { endpoint, options } = await processWritingFeedbackInput(
      scanId || newScanId,
      input
    );
    const baseUrl = whitelabelConfig.gptzero.backendHostname;
    const serverUrl = `${baseUrl}/${endpoint}`;
    dispatch(setWritingFeedbackDocument({ document: null }));
    dispatch(setWritingFeedbackChunks({ feedbacks: [] }));

    try {
      const response = await fetch(serverUrl, options);

      if (!response.body || !response.ok) {
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        const { value } = await reader.read();
        const errorObj = JSON.parse(decoder.decode(value));
        Sentry.captureException(
          `No body or ok in writing feedback for input:\n${JSON.stringify(
            input
          )}`
        );
        throw new Error(errorObj.error, { cause: response.status });
      }
      dispatch(setInputTextChangedSinceScan(false));
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let allChunks = [];

      try {
        let done;

        do {
          const { done, value } = await reader.read();
          if (done) break;
          buffer += decoder.decode(value, { stream: true });

          const parts = buffer.split('\n');
          buffer = parts.pop(); // Last line may be incomplete

          for (let i = 0; i < parts.length; i++) {
            if (parts[i]) {
              const data = JSON.parse(parts[i]); // Parse the chunk of feedback
              if (data.documents) {
                dispatch(
                  setWritingFeedbackDocument({
                    document: data.documents[0],
                  })
                );
                dispatch(
                  setLatestInputText({
                    latestInputText: data?.documents[0].inputText,
                  })
                );
              } else if (data.feedbacks) {
                allChunks = [...allChunks, ...data.feedbacks];
                dispatch(
                  setWritingFeedbackChunks({
                    feedbacks: allChunks,
                  })
                );
                if (data.feedbacks.length > 0) {
                  dispatch(
                    setWritingFeedbackScanStatus({ status: ScanStatus.success })
                  );
                  dispatch(
                    setWritingFeedbackScanServerStatusCode({
                      serverStatusCode: 200,
                    })
                  );
                }
              } else if (data.error) {
                Sentry.captureException(
                  `Writing feedback error buffered:\n${JSON.stringify(
                    data
                  )}\n\n for input:\n${JSON.stringify(input)}`
                );
                dispatch(
                  setWritingFeedbackScanStatus({ status: ScanStatus.error })
                );
                dispatch(
                  setWritingFeedbackScanServerStatusCode({
                    serverStatusCode: 500,
                  })
                );
                throw new Error(data.error, { cause: data.status });
              }
            }
          }
        } while (!done);
      } catch (error) {
        toast.error(error);
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setWritingFeedbackScanStatus({ status: ScanStatus.error }));
      dispatch(
        setWritingFeedbackScanServerStatusCode({ serverStatusCode: 500 })
      );
      toast.error(error.message ?? 'Writing feedback could not be generated');
    }
  };

  return {
    requestWritingFeedback,
  };
};

const processWritingFeedbackInput = async (scanId, input) => {
  if (typeof input === 'object') {
    try {
      const formData = new FormData();
      formData.append('scanId', scanId);
      formData.append('files', input);

      const endpoint = endpoints.GET_FILE_WRITING_FEEDBACK;

      const headers = {};
      const options = {
        method: 'POST',
        credentials: 'include',
        body: formData,
      };

      return {
        endpoint: endpoint,
        headers: headers,
        options: options,
      };
    } catch (err) {
      return err;
    }
  } else {
    const endpoint = endpoints.GET_TEXT_WRITING_FEEDBACK;

    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({
      scanId: scanId,
      document: input,
    });
    const options = {
      headers: headers,
      method: 'POST',
      credentials: 'include',
      body: body,
    };

    return {
      endpoint: endpoint,
      headers: headers,
      options: options,
    };
  }
};

export default useRequestWritingFeedbackScan;
