export const dashboardRoutes = {
  HOME: '/app',
  AI_SCAN: '/app/ai-scan',
  AI_SCAN_BATCH_UPLOAD: '/app/ai-scan?tab=1',
  SUBSCRIPTION_PLANS: '/app/subscription-plans',
  SUMMER_SALE_PLANS: '/app/subscription-plans/summer-sale-2024',
  DOCUMENTS: '/app/documents',
  TUTORIAL: '/app/tutorial',
  SCAN_RESULT: '/app/documents/:scanId',
  SCAN_SHARE: '/documents/:scanId/share',
  SCAN_HISTORY: '/app/scan-history',
  HUMAN_MARK_SUBMISSIONS: '/app/humanmark-submissions',
  USAGE_STATS: '/app/usage-stats',
  CONTACT_US: `/app/contact-us`,
  ANONYMOUS_SIGN: '/anonymous-sign-in',
  LOGIN: '/login',

  //   api routes
  API_SUBSCRIPTION_PLANS: '/app/api-subscription',
  API_KEY: '/app/api',
  API_BETA_INVITE: '/app/beta',

  // settings routes
  ACCOUNT_SETTINGS: '/app/account-settings',
  INVITE_STUDENTS: '/app/invite-students',
  INVITE_USERS: '/app/admin/invite-user',
  INVITE_EDUCATORS: '/app/invite-educators',

  // team routes
  CREATE_TEAM: '/app/tenants/create',
  FREE_TRIAL_SETUP: '/app/teams-free-trial-setup',
  ORG_SUBSCRIPTION_PLANS: '/app/org-subscriptions-plans',
  MY_TEAMS: '/app/tenants',
  TEAM_MEMBERS: '/app/org-users',

  // teams v3
  TEAMS: '/app/teams',
  TEAM_MEMBERS_v3: '/app/teams?tab=1',
  TEAM_SETTINGS_v3: '/app/teams?tab=2',
  EDITOR: '/editor',

  // summer sale
  SUMMER_SALE: '/app/subscription-plans/summer-sale-2024',
};

// For reference, not actually used
export const teamDashboardRoutes = [
  dashboardRoutes.TEAMS,
  dashboardRoutes.MY_TEAMS,
  dashboardRoutes.TEAM_MEMBERS,
  dashboardRoutes.ORG_SUBSCRIPTION_PLANS,
  dashboardRoutes.FREE_TRIAL_SETUP,
  dashboardRoutes.CREATE_TEAM,
];

export const apiDashboardRoutes = [
  dashboardRoutes.API_SUBSCRIPTION_PLANS,
  dashboardRoutes.API_KEY,
  dashboardRoutes.API_BETA_INVITE,
];

export const routeIds = {
  DOCUMENTS: 'documents-link',
  HOME: 'home-link',
};
