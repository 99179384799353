import React, { lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
} from 'react-router-dom';
import { themeChange } from 'theme-change';
import { ToastContainer, Zoom } from 'react-toastify';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import 'react-toastify/dist/ReactToastify.css';
import { useLocalStorage } from '@uidotdev/usehooks';
import LocalStorageKeys from './constants/localStorageKeys';
import { checkAuth } from './app/auth';
import initializeApp from './app/init';
import { saveProfileToLocalStorage } from './utils/profileUtils';
import { retrieveDeviceId, setDeviceId } from './utils/session';
import { deleteCookie } from './utils/cookieUtils';
import WhitelabelContext from './contexts/WhitelabelContext';
import { getWhitelabelDomain } from './utils/whitelabelUtils';
import { setUserId, setUserProperties, trackEventV2 } from './utils/tracking';
import AuthenticatedWithoutLayout from './containers/AuthenticatedWithoutLayout';
import AuthenticatedClassroom from './containers/AuthenticatedClassroom';
import {
  removeLocalStorageItems,
  handleRedirectToLoginFromAnotherPage,
} from './helpers/common';
import { dashboardRoutes } from './constants/dashboardRoutes';
import { replaceWithJSONParseableString } from './hooks/useLocalStorage';
import useAuthReduxState from './hooks/auth/useAuthReduxState';
import { sprig } from '@sprig-technologies/sprig-browser';
import GuestAccountAuthModals from './components/v2/AuthModals';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const SSOLogin = lazy(() => import('./pages/SSOLogin'));
const TestRegister = lazy(() => import('./pages/TestRegister'));
const AcceptLink = lazy(() => import('./pages/AcceptLink'));
const UpdatePassword = lazy(() => import('./pages/UpdatePassword'));
const Register = lazy(() => import('./pages/Register'));
const GDocsWritingReport = lazy(() => import('./pages/GDocsWritingReport'));
const PublicScan = lazy(() => import('./pages/PublicScan'));
const MagicCheckout = lazy(() => import('./pages/MagicCheckout'));

const Editor = lazy(() => import('./pages/Editor'));

const AIDocument = lazy(() => import('./pages/AIDocument'));

// const Documentation = lazy(() => import('./pages/Documentation'))

const LoginFromClassroom = lazy(
  () => import('./features/user/LoginFromClassroom')
);
const CreateAttachment = lazy(
  () => import('./features/googleClassroom/CreateAttachment')
);
const ViewAttachment = lazy(
  () => import('./features/googleClassroom/ViewAttachment')
);
const GradeSubmission = lazy(
  () => import('./features/googleClassroom/GradeSubmission')
);
const ViewSubmission = lazy(
  () => import('./features/googleClassroom/ViewSubmission')
);

export const Sprig = sprig.configure({
  environmentId: process.env.REACT_APP_SPRIG_ENVIRONMENT_ID,
});

// Initializing different libraries
initializeApp();

function App() {
  const { setIdentity: setUserIdentityInHubspot } = useTrackingCode();

  // Since the existing key might not be a JSON string,
  // we need to replace it with a JSON parseable string
  replaceWithJSONParseableString(LocalStorageKeys.referralID);
  replaceWithJSONParseableString(LocalStorageKeys.referralName);
  const [referralID, storeReferralID] = useLocalStorage(
    LocalStorageKeys.referralID
  );
  const [referralName, storeReferralName] = useLocalStorage(
    LocalStorageKeys.referralName
  );

  const { setUserStateFromToken } = useAuthReduxState();

  function updateChakraValueInLocalStorage() {
    if (typeof window !== 'undefined') {
      // Move color mode from local storage that interferes with our UI
      if (
        localStorage.getItem('chakra-ui-color-mode') &&
        !localStorage.getItem('chakra-ui-color-mode').includes('-ext')
      ) {
        localStorage.setItem(
          'chakra-ui-color-mode',
          `${localStorage.getItem('chakra-ui-color-mode')}-ext`
        );
      }
    }
  }

  function maybeSaveProfileToLocalStorage() {
    const userEmail = localStorage.getItem('email');
    if (userEmail) {
      const previousProfileUpdateTime = localStorage.getItem(
        'previousProfileUpdateTime'
      );
      // Only update if more than 10 seconds have passed since last update
      if (
        !previousProfileUpdateTime ||
        Date.now() - parseInt(previousProfileUpdateTime) >= 10000
      ) {
        saveProfileToLocalStorage(userEmail).then(() => {
          setUserId();
          setUserProperties();
          setUserIdentityInHubspot(userEmail);
        });
      }
    }
  }

  function setDeviceIdAndTrack() {
    let deviceId = retrieveDeviceId();
    if (!deviceId) {
      deviceId = setDeviceId();
    }
    trackEventV2({ action: 'load', component: 'dashboard', sampleRate: 0.5 });
  }

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
    setDeviceIdAndTrack();
    maybeSaveProfileToLocalStorage();
    updateChakraValueInLocalStorage();
    addSprigProperties();
  }, []);

  // Filter out bad referrals
  useEffect(() => {
    let referral_url = new URL(window.location.href);
    if (
      referral_url.searchParams.has('gclid') &&
      referral_url.searchParams.has('via')
    ) {
      setTimeout(function () {
        deleteCookie('rewardful.referral', '/', window.location.hostname);
        storeReferralID(null);
        storeReferralName(null);
        console.log(
          'Deleted referral cookie as visit came from Google Ads (forbidden by Terms of Service)'
        );
      }, 2000);
    }
  }, []); // Empty array as dependency to run only on mount

  const addSprigProperties = () => {
    const userEmail = localStorage.getItem('email');
    Sprig('setEmail', userEmail);
  };

  // whitelabeling
  const whitelabel = getWhitelabelDomain();

  // Check for login and initialize axios
  const { token, goToLogin } = checkAuth();
  if (token) {
    setUserStateFromToken(token);
  }

  if (goToLogin) {
    const currentPathname = window.location.pathname;

    handleRedirectToLoginFromAnotherPage(currentPathname);
  } else {
    const localStorageItemsToRemove = [
      LocalStorageKeys.joinPageLink,
      LocalStorageKeys.acceptUserInviteLink,
      LocalStorageKeys.createTeamLink,
      LocalStorageKeys.acceptTeamInviteLink,
    ];
    removeLocalStorageItems(localStorageItemsToRemove);
  }

  const DynamicRedirect = ({ paramKey, to }) => {
    const params = useParams();
    const targetPath = to.replace(`:${paramKey}`, params[paramKey]);

    return <Navigate to={targetPath} replace />;
  };

  return (
    <>
      <WhitelabelContext.Provider value={whitelabel}>
        <Router>
          <ToastContainer position="bottom-center" transition={Zoom} />
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* Google Classroom Routes */}
            <Route path="/classroom/connect" element={<LoginFromClassroom />} />
            <Route
              path="/classroom/create-attachment"
              element={
                <AuthenticatedClassroom isTeacherView={true}>
                  <CreateAttachment />
                </AuthenticatedClassroom>
              }
            />
            <Route
              path="/classroom/view-attachment"
              element={
                <AuthenticatedClassroom isTeacherView={true}>
                  <ViewAttachment />
                </AuthenticatedClassroom>
              }
            />
            <Route
              path="/classroom/view-submission"
              element={
                <AuthenticatedClassroom>
                  <ViewSubmission />
                </AuthenticatedClassroom>
              }
            />
            <Route
              path="/classroom/grade-submission"
              element={
                <AuthenticatedClassroom isTeacherView={true}>
                  <GradeSubmission />
                </AuthenticatedClassroom>
              }
            />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/sso-login" element={<SSOLogin />} />
            <Route path="/test-register" element={<TestRegister />} />
            <Route path="/accept-link" element={<AcceptLink />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/editor/:editorDocumentId"
              element={
                <AuthenticatedWithoutLayout>
                  <Editor />
                </AuthenticatedWithoutLayout>
              }
            />
            <Route path="/editor/:editorDocumentId/view" element={<Editor />} />
            <Route
              path="/ai-document/:aiDocumentId"
              element={
                <AuthenticatedWithoutLayout>
                  <AIDocument />
                </AuthenticatedWithoutLayout>
              }
            />
            {/* <Route path="/documentation" element={<Documentation />} /> */}
            <Route
              path="/g/document/report/:reportId"
              element={<GDocsWritingReport />}
            />
            <Route path="/documents/:scanId/share" element={<PublicScan />} />

            <Route path="/magic-checkout" element={<MagicCheckout />} />
            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={token ? dashboardRoutes.AI_SCAN : '/login'}
                  replace
                />
              }
            />

            {/* obsolete routes */}
            <Route
              path="/scan-history/:scanId/share"
              element={
                <DynamicRedirect
                  paramKey="scanId"
                  to={dashboardRoutes.SCAN_SHARE}
                />
              }
            />
            <Route
              path="app/scan-history/:scanId"
              element={
                <DynamicRedirect
                  paramKey="scanId"
                  to={dashboardRoutes.SCAN_RESULT}
                />
              }
            />
            <Route
              path="/app/welcome"
              element={<Navigate to={dashboardRoutes.AI_SCAN} />}
            />
            <Route
              path="/app/projects"
              element={<Navigate to={dashboardRoutes.DOCUMENTS} />}
            />
            <Route
              path="/app/scan-history"
              element={<Navigate to={dashboardRoutes.DOCUMENTS} />}
            />
            <Route
              path="/app/account-details"
              element={<Navigate to={dashboardRoutes.ACCOUNT_SETTINGS} />}
            />
            <Route
              path="/app/settings-billing"
              element={<Navigate to={dashboardRoutes.ACCOUNT_SETTINGS} />}
            />
          </Routes>
        </Router>
        <GuestAccountAuthModals />
      </WhitelabelContext.Provider>
    </>
  );
}

export default App;
