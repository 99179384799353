import React from 'react';
import { AiResultSummaryProps } from './AiResultSummary.types';
import AiProbabilitySection from '../AiProbabilitySection/AiProbabilitySection';
import ClassificationSection from '../ClassificationSection/ClassificationSection';
import ProbabilityBreakdownSection from '../ProbabilityBreakdownSection/ProbabilityBreakdownSection';

import './AiResultSummary.css';

const AiResultSummary: React.FC<AiResultSummaryProps> = ({
  predictedClass,
  confidenceScore,
  confidenceCategory,
  aiClassProbability,
  humanClassProbability,
  mixedClassProbability,
}) => {
  return (
    <div
      data-testid="results-highlights-container"
      className="results-highlights-container"
    >
      <div className="ai-probability-section-call">
        <AiProbabilitySection aiClassProbability={aiClassProbability} />
      </div>

      <ClassificationSection
        aiClassProbability={aiClassProbability}
        predictedClass={predictedClass}
        confidenceScore={confidenceScore}
        confidenceCategory={confidenceCategory}
      />

      <div data-testid="vertical-divider" className="vertical-divider"></div>

      <ProbabilityBreakdownSection
        aiClassProbability={aiClassProbability}
        humanClassProbability={humanClassProbability}
        mixedClassProbability={mixedClassProbability}
      />
    </div>
  );
};

export default AiResultSummary;
