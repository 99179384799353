import supabase from '../../utils/supabaseClient';
import { LockClosedIcon } from '@heroicons/react/20/solid'; // Import the lock icon
import { trackEventV2 } from '../../utils/tracking';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const SCOPES = [
  'openid',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/classroom.addons.teacher',
  'https://www.googleapis.com/auth/classroom.addons.student',
];

function OAuthOptions({
  className = '',
  signUp = false,
  googleSsoOnly = false,
}) {
  const location = useLocation();
  // Encode the URL so that all the query params are preserved
  const originalDestination = location?.state?.from
    ? encodeURIComponent(location?.state?.from)
    : null;
  const loginPageUrl = googleSsoOnly ? 'classroom/connect' : 'login';
  const redirectUrl = `${window.location.origin}/${loginPageUrl}?type=OAuth-success${
    originalDestination ? `&redirectTo=${originalDestination}` : ''
  }`;
  async function signInWithGoogle(isSigningUp) {
    // Sign in with Google OAuth
    const provider = 'google';

    // Allow user to autosign upon page reload if they use oauth
    localStorage.removeItem('previousProfileUpdateTime');
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: redirectUrl,
        ...(googleSsoOnly && {
          scopes: SCOPES.join(' '),
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        }),
      },
    });

    if (error) {
      console.error(error);
      return;
    }
    trackEventV2({
      action: isSigningUp ? 'signUp' : 'signin',
      component: isSigningUp ? 'signUpPage' : 'loginPage',
      element: 'signInWithGoogle',
      options: {
        social: provider,
      },
    });
  }

  async function signInWithFacebook(isSigningUp) {
    // Sign in with Facebook OAuth
    const provider = 'facebook';
    // Allow user to autosign upon page reload if they use oauth
    localStorage.removeItem('previousProfileUpdateTime');
    const { error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: redirectUrl,
      },
    });
    if (error) {
      console.error(error);
      return;
    }

    trackEventV2({
      action: isSigningUp ? 'signUp' : 'signin',
      component: isSigningUp ? 'signUpPage' : 'loginPage',
      element: 'signInWithFacebook',
      options: {
        social: provider,
      },
    });
  }

  async function signInWithGithub(isSigningUp) {
    // Sign in with Github OAuth
    const provider = 'github';
    // Allow user to autosign upon page reload if they use oauth
    localStorage.removeItem('previousProfileUpdateTime');
    const { error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: redirectUrl,
      },
    });
    if (error) {
      console.error(error);
      return;
    }

    trackEventV2({
      action: isSigningUp ? 'signUp' : 'signin',
      component: isSigningUp ? 'signUpPage' : 'loginPage',
      element: 'signInWithGithub',
      options: {
        social: provider,
      },
    });
  }
  if (googleSsoOnly) {
    return (
      <div className={className}>
        <button
          className={'btn-outlined w-[100%] gap-3 px-4 md:gap-3 font-medium'}
          onClick={() => signInWithGoogle(signUp)}
          type="button"
        >
          <img
            src={'/btn_google_logo.svg'}
            className="w-5 h-5"
            alt={'Continue with Google'}
          />
          Continue with Google
        </button>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={`flex justify-between`}>
        <button
          className={'btn-outlined w-[64%] gap-3 px-4 md:gap-3 font-medium'}
          onClick={() => signInWithGoogle(signUp)}
          type="button"
        >
          <img
            src={'/btn_google_logo.svg'}
            className="w-5 h-5"
            alt={'Login with Google'}
          />
          {signUp ? 'Sign up with Google' : `Log in with Google`}
        </button>

        <button
          className={'btn-outlined w-[15%]'}
          onClick={() => signInWithFacebook(signUp)}
          type="button"
        >
          <img
            src={'/btn_facebook_logo.svg'}
            className="w-5 h-5"
            alt={'Login with Facebook'}
          />
        </button>
        <button
          className={'btn-outlined w-[15%]'}
          onClick={() => signInWithGithub(signUp)}
          type="button"
        >
          <img
            src={'/btn_github_logo.svg'}
            className="w-5 h-5"
            alt={'Login with Github'}
          />
        </button>
      </div>
      <div className="mt-4 flex justify-center">
        {' '}
        <button
          className={'btn-outlined w-[100%] gap-3 px-4 md:gap-3 font-medium'}
          onClick={() => (window.location.href = '/sso-login')}
          type="button"
        >
          <LockClosedIcon className="w-5 h-5" /> {/* Lock icon */}
          {signUp ? 'Sign up with SSO' : `Log in with SSO`}
        </button>
      </div>
    </div>
  );
}

export default OAuthOptions;
