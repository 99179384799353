import styled from 'styled-components';
import { StyledContainer } from '../../atoms/container/container.styles';

export const ToolTipContentWrapper = styled(StyledContainer)`
  width: 82vw;
  left: 0;

  @media (min-width: 1024px) {
    width: 420px;
    left: -30px;
  }
`;
