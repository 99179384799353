import React from 'react';

const CreateAccount = ({
  onClickCreateAccount,
}: {
  onClickCreateAccount: () => void;
}) => {
  return (
    <div className="body-medium">
      <span className="text-g0-secondary">Don’t have an account?</span>{' '}
      <span
        onClick={onClickCreateAccount}
        className="text-g0-dark-blue-500 underline decoration-g0-dark-blue-500"
      >
        Create an account
      </span>
    </div>
  );
};

export default CreateAccount;
