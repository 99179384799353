import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScanStatus, ScanTypes } from './aiUtilitiesHubSlice.constants';
import { AiUtilitiesHubState } from './types';
import { IState } from '../../../types/types';
import { planNames } from '../../../constants/planNames';

export const scanInitialState = {
  data: null,
  error: null,
  serverStatusCode: null,
  status: ScanStatus.pending as 'pending',
  hasViewedResults: false,
};

const userPlanLower = localStorage.getItem('plan')?.toLowerCase();
const isUserOnFreeOrNullPlan =
  !userPlanLower ||
  userPlanLower === planNames.FREE.toLowerCase() ||
  userPlanLower === 'null';

const defaultActiveView = isUserOnFreeOrNullPlan
  ? ScanTypes.basic
  : ScanTypes.advanced;

const defaultRequestedScanTypes = isUserOnFreeOrNullPlan
  ? [ScanTypes.basic]
  : [ScanTypes.advanced];

const initialState = {
  activeView: defaultActiveView,
  overallScanId: null,
  latestInputText: '',
  scanAuthor: '',
  scanDate: new Date().toISOString(),
  scanTitle: 'Untitled Document',
  basicScan: scanInitialState,
  isMultilingualChecked: false,
  advancedScan: scanInitialState,
  aiVocabScan: scanInitialState,
  plagiarismScan: scanInitialState,
  plagiarismActiveMatches: null,
  writingFeedbackScan: {
    ...scanInitialState,
    data: {
      document: null,
      feedbacks: [],
    },
    dataFromScanHistory: {},
  },
  sourcesScan: {
    ...scanInitialState,
    data: {
      results: null,
      inputText: '',
    },
  },
  requestedScanTypes: defaultRequestedScanTypes,
  isResultsViewOpen: false,
  isEditModeOpen: true,
  editorDocument: null,
  isShareModalOpen: false,
  isPublicScan: false,
  publicScanScanTypes: [],
  multiFileServerResponses: {},
  inputTextChangedSinceScan: false,
  isScanHistoryView: false,
  outOfBasicScans: false,
  outOfAdvancedScans: false,
  textExtraction: {
    ...scanInitialState,
    data: {
      text: '',
      name: '',
    },
  },
} as AiUtilitiesHubState;

export const index = createSlice({
  name: 'aiUtilitiesHub',
  initialState,
  reducers: {
    resetAiUtilitiesHubState(state, action) {
      const { excludedFields } = action.payload;

      return {
        ...initialState,
        ...Object.fromEntries(
          Object.entries(state).filter(([key]) => excludedFields.includes(key))
        ),
      };
    },
    setScanAuthor(state, action) {
      state.scanAuthor = action.payload.scanAuthor;
    },
    setScanDate(state, action) {
      state.scanDate = action.payload.scanDate;
    },
    setScanTitle(state, action) {
      state.scanTitle = action.payload.scanTitle;
    },
    setActiveView(state, action) {
      state.activeView = action.payload;
    },
    setLatestInputText(state, action) {
      state.latestInputText = action.payload.latestInputText;
    },
    setOverallScanId(state, action) {
      state.overallScanId = action.payload.overallScanId;
    },
    setIsMultilingualChecked(state, action) {
      state.isMultilingualChecked = action.payload.isMultilingualChecked;
    },
    setRequestedScanType(state, action) {
      state.requestedScanTypes = [action.payload.requestedScanType];
    },
    addRequestedScanType(state, action) {
      if (!state.requestedScanTypes.includes(action.payload.scanType)) {
        state.requestedScanTypes = [
          ...state.requestedScanTypes,
          action.payload.scanType,
        ];
      }
    },
    removeRequestedScanType(state, action) {
      state.requestedScanTypes = state.requestedScanTypes.filter(
        (scanType) => scanType !== action.payload.scanType
      );
    },
    setBasicScanServerStatusCode(state, action) {
      state.basicScan.serverStatusCode = action.payload.serverStatusCode;
    },
    setBasicScanStatus(state, action) {
      state.basicScan.status = action.payload.status;
    },
    setBasicScanError(state, action) {
      state.basicScan.error = action.payload.error;
    },
    setBasicScanResults(state, action) {
      state.basicScan.data = {
        ...(state.basicScan.data || {}),
        ...action.payload.data,
      };
      state.basicScan.serverStatusCode = action.payload.serverStatusCode;
      state.basicScan.status = action.payload.status;
      state.basicScan.version = action.payload.version;
      state.basicScan.scanId = action.payload.scanId;
      if (action.payload.error) {
        state.basicScan.error = { message: action.payload.error };
      }
    },
    setHasViewedBasicScanResults(state, action) {
      state.basicScan.hasViewedResults = action.payload.hasViewedResults;
    },
    setAiVocabScanServerStatusCode(state, action) {
      state.aiVocabScan.serverStatusCode = action.payload.serverStatusCode;
    },
    setAiVocabScanStatus(state, action) {
      state.aiVocabScan.status = action.payload.status;
    },
    setAiVocabScanError(state, action) {
      state.aiVocabScan.error = action.payload.error;
    },
    setAiVocabScanResults(state, action) {
      state.aiVocabScan.data = {
        ...(state.aiVocabScan.data || {}),
        ...action.payload.data,
      };
      state.aiVocabScan.serverStatusCode = action.payload.serverStatusCode;
      state.aiVocabScan.status = action.payload.status;
      state.aiVocabScan.scanId = action.payload.scanId;
    },
    setHasViewedAiVocabScanResults(state, action) {
      state.aiVocabScan.hasViewedResults = action.payload.hasViewedResults;
    },
    setAdvancedScanServerStatusCode(state, action) {
      state.advancedScan.serverStatusCode = action.payload.serverStatusCode;
    },
    setAdvancedScanStatus(state, action) {
      state.advancedScan.status = action.payload.status;
    },
    setAdvancedScanError(state, action) {
      state.advancedScan.error = action.payload.error;
    },
    setAdvancedScanResults(state, action) {
      state.advancedScan.data = {
        ...(state.advancedScan.data || {}),
        ...action.payload.data,
      };
      state.advancedScan.serverStatusCode = action.payload.serverStatusCode;
      state.advancedScan.status = action.payload.status;
      state.advancedScan.scanId = action.payload.scanId;
      if (action.payload.error) {
        state.advancedScan.error = { message: action.payload.error };
      }
    },
    setHasViewedAdvancedScanResults(state, action) {
      state.advancedScan.hasViewedResults = action.payload.hasViewedResults;
    },
    setPlagiarismScanServerStatusCode(state, action) {
      state.plagiarismScan.serverStatusCode = action.payload.serverStatusCode;
    },
    setPlagiarismScanStatus(state, action) {
      state.plagiarismScan.status = action.payload.status;
    },
    setPlagiarismScanError(state, action) {
      state.plagiarismScan.error = action.payload.error;
    },
    setPlagiarismScanResults(state, action) {
      state.plagiarismScan.data = {
        ...(state.plagiarismScan.data || {}),
        ...action.payload.data,
      };
      state.plagiarismScan.serverStatusCode = action.payload.serverStatusCode;
      state.plagiarismScan.status = action.payload.status;
    },
    setHasViewedPlagiarismScanResults(state, action) {
      state.plagiarismScan.hasViewedResults = action.payload.hasViewedResults;
    },
    setPlagiarismActiveMatches(state, action) {
      state.plagiarismActiveMatches = action.payload.plagiarismActiveMatches;
    },
    setWritingFeedbackScanServerStatusCode(state, action) {
      state.writingFeedbackScan.serverStatusCode =
        action.payload.serverStatusCode;
    },
    setWritingFeedbackScanStatus(state, action) {
      state.writingFeedbackScan.status = action.payload.status;
    },
    setWritingFeedbackScanError(state, action) {
      state.writingFeedbackScan.error = action.payload.error;
    },
    setWritingFeedbackChunks(state, action) {
      state.writingFeedbackScan.data.feedbacks = action.payload.feedbacks;
    },
    setWritingFeedbackDocument(state, action) {
      state.writingFeedbackScan.data.document = action.payload.document;
    },
    setWritingFeedbackDataFromScanHistory(state, action) {
      state.writingFeedbackScan.dataFromScanHistory =
        action.payload.dataFromScanHistory;
    },
    setSourcesScanServerStatusCode(state, action) {
      state.sourcesScan.serverStatusCode = action.payload.serverStatusCode;
    },
    setSourcesScanStatus(state, action) {
      state.sourcesScan.status = action.payload.status;
    },
    setSourcesScanError(state, action) {
      state.sourcesScan.error = action.payload.error;
    },
    setSourcesScanResults(state, action) {
      state.sourcesScan.data = {
        ...(state.sourcesScan.data || {}),
        ...action.payload.data,
      };
      state.sourcesScan.serverStatusCode = action.payload.serverStatusCode;
      state.sourcesScan.status = action.payload.status;
    },
    setHasViewedWritingFeedbackScanResults(state, action) {
      state.writingFeedbackScan.hasViewedResults =
        action.payload.hasViewedResults;
    },
    setResultsViewOpen(state, action) {
      state.isResultsViewOpen = action.payload;
    },
    setEditModeOpen(state, action) {
      state.isEditModeOpen = action.payload;
    },
    setEditorDocument(state, action) {
      state.editorDocument = action.payload;
    },
    setShareModalOpen(state, action) {
      state.isShareModalOpen = action.payload;
    },
    setIsPublicScan(state, action) {
      state.isPublicScan = action.payload;
    },
    setPublicScanScanTypes(state, action) {
      state.publicScanScanTypes = action.payload;
    },
    setMultiFileServerResponse: (
      state,
      action: PayloadAction<{
        fileName: string;
        response: IState & {
          fileName: string;
          author: string;
          scanDate: string;
          scanId: string;
          hasAdvancedScan: boolean;
        };
      }>
    ) => {
      state.multiFileServerResponses[action.payload.fileName] =
        action.payload.response;
    },
    setMultifilePlagiarismScanResults(state, action) {
      state.multiFileServerResponses[action.payload.fileName] = {
        ...(state.multiFileServerResponses[action.payload.fileName] || {}),
        plagiarismScanResponse: action.payload,
      };
    },
    setInputTextChangedSinceScan(state, action) {
      state.inputTextChangedSinceScan = action.payload;
    },
    setIsScanHistoryView(state, action) {
      state.isScanHistoryView = action.payload;
    },
    clearMultiFileServerResponses: (state) => {
      state.multiFileServerResponses = {};
    },
    setOutOfBasicScans(state, action) {
      state.outOfBasicScans = action.payload;
    },
    setOutOfAdvancedScans(state, action) {
      state.outOfAdvancedScans = action.payload;
    },
    setTextExtractionStatus(state, action) {
      state.textExtraction.status = action.payload.status;
    },
    setTextExtractionError(state, action) {
      state.textExtraction.error = action.payload.error;
    },
    setTextExtractionResults(state, action) {
      state.textExtraction.data = action.payload.data;
      state.textExtraction.error = action.payload.error;
      state.textExtraction.serverStatusCode = action.payload.serverStatusCode;
      state.textExtraction.status = action.payload.status;
    },
  },
});

export const {
  resetAiUtilitiesHubState,
  setScanDate,
  setScanTitle,
  setScanAuthor,
  setActiveView,
  setOverallScanId,
  setLatestInputText,
  setIsMultilingualChecked,
  setRequestedScanType,
  addRequestedScanType,
  removeRequestedScanType,
  setBasicScanStatus,
  setBasicScanResults,
  setHasViewedBasicScanResults,
  setBasicScanServerStatusCode,
  setAdvancedScanStatus,
  setAdvancedScanResults,
  setHasViewedAdvancedScanResults,
  setAdvancedScanServerStatusCode,
  setPlagiarismScanStatus,
  setPlagiarismScanResults,
  setHasViewedPlagiarismScanResults,
  setPlagiarismScanServerStatusCode,
  setWritingFeedbackScanStatus,
  setWritingFeedbackChunks,
  setWritingFeedbackDocument,
  setWritingFeedbackScanServerStatusCode,
  setSourcesScanStatus,
  setSourcesScanResults,
  setSourcesScanServerStatusCode,
  setSourcesScanError,
  setHasViewedWritingFeedbackScanResults,
  setResultsViewOpen,
  setEditModeOpen,
  setEditorDocument,
  setShareModalOpen,
  setIsPublicScan,
  setPublicScanScanTypes,
  setMultiFileServerResponse,
  setMultifilePlagiarismScanResults,
  setPlagiarismActiveMatches,
  setInputTextChangedSinceScan,
  setIsScanHistoryView,
  clearMultiFileServerResponses,
  setOutOfBasicScans,
  setOutOfAdvancedScans,
  setAiVocabScanResults,
  setHasViewedAiVocabScanResults,
  setAiVocabScanError,
  setAiVocabScanServerStatusCode,
  setAiVocabScanStatus,
  setTextExtractionStatus,
  setTextExtractionError,
  setTextExtractionResults,
} = index.actions;

export default index.reducer;
