import { useState, useEffect } from 'react';
import EyeIcon from '@heroicons/react/24/outline/EyeIcon';
import EyeSlashIcon from '@heroicons/react/24/outline/EyeSlashIcon';

function InputText({
  labelTitle,
  labelStyle,
  type,
  containerStyle,
  defaultValue,
  placeholder,
  updateFormValue,
  updateType,
  readOnly,
  required,
  name = '',
  id,
}) {
  const [value, setValue] = useState(defaultValue);
  const [revealPassword, setrevealPassword] = useState(false);
  const togglePasswordReveal = () => setrevealPassword(!revealPassword);
  const iconClassName = 'w-5 absolute right-4 bottom-4 cursor-pointer';

  useEffect(() => {
    setValue(defaultValue);
  }, []);

  const updateInputValue = (val) => {
    setValue(val);
    updateFormValue({ updateType, value: val });
  };

  return (
    <div
      className={`relative form-control w-full ${containerStyle || 'mt-4 '}`}
    >
      <label className="label" htmlFor={id}>
        <span className={'label-text text-base-content ' + labelStyle}>
          {labelTitle}
          {required && <span className="text-red-500"> *</span>}
        </span>
      </label>
      <input
        id={id}
        readOnly={readOnly}
        type={type === 'password' && !revealPassword ? 'password' : 'text'}
        value={value ? value : defaultValue}
        placeholder={placeholder || ''}
        onChange={(e) => updateInputValue(e.target.value)}
        className="input  input-bordered"
        disabled={readOnly}
        name={name}
      />
      {type === 'password' && !revealPassword && (
        <EyeIcon className={iconClassName} onClick={togglePasswordReveal} />
      )}
      {type === 'password' && revealPassword && (
        <EyeSlashIcon
          className={iconClassName}
          onClick={togglePasswordReveal}
        />
      )}
    </div>
  );
}

export default InputText;
