import styled from 'styled-components';

export const Wrapper = styled.div<{ $noBorder?: boolean }>`
  padding: 0 20px 70px 20px;
  width: 100%;

  @media (min-width: 1024px) {
    padding: 40px 8% 57px 8%;
    border-bottom: ${(props) =>
      props.$noBorder ? 'none' : '1px solid #e5e5e5'};
  }
`;

export const DisclaimerWrapper = styled.div`
  display: none;
  @media (min-width: 768px) {
    width: 100%;
    margin-top: auto;
    display: flex;
  }
`;
